import React, {useMemo} from 'react';
import {Button, Stack, useTheme} from '@mui/material';
import {trackIdToTrackRoute} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import VisionSidebarTitle from 'pages/vision/visionComponents/VisionSidebarTitle.tsx';
import {VisionCurrentValues} from 'pages/vision/visionComponents/VisionCurrentValues.tsx';
import {VisionChoosers} from 'pages/vision/visionComponents/choosers/VisionChoosers.tsx';
import {Close} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {visionNavigatorProps} from 'async/visionAppAsync/visionApiHooks.ts';
import TrainPageMap from 'pages/trainApp/trainPageSubContainers/TrainPageMap.tsx';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';

const sideBarWidth = 300;

/**
 * The Side bar of Vision
 */
const VisionSideBar = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: VisionAppMapDependencyProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const visionProps = trainProps.visionProps;

  // const visionSpatialNavigatorProps = useMemo(() => {
  //   return visionNavigatorProps(trainProps, mapProps);
  // }, [trainProps, visionProps]);

  const trackRouteMinimized = visionProps.visionMeta
    ? trackIdToTrackRoute(visionProps.visionMeta.trackSectionId)
    : visionProps.trackRoute;

  const closeTabOrGoHome = () => {
    // If the user came from clicking the vision icon, navigate home
    if (visionProps.fromIcon) {
      navigate('/home');
    } else {
      // If they came from track, close the tab.
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  };

  return (
    <Stack
      {...{
        sx: {
          padding: 1,
          maxWidth: sideBarWidth,
          minWidth: sideBarWidth,
          backgroundColor: '#343B45',
          color: theme.palette.common.textColor,
          justifyContent: 'space-between',
          alighItems: 'start',
          flex: 1,
          overflow: 'hidden',
          minHeight: 0,
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Button
        {...{
          sx: {
            paddingTop: 0,
            paddingLeft: 0,
            minWidth: 0,
            color: 'grey',
            width: 'fit-content',
            justifyContent: 'flex-start',
          },
          startIcon: <Close {...{color: 'remove'}} />,
          onClick: closeTabOrGoHome,
          color: 'secondary',
        }}
        key="closeButton"
      >
        {visionProps.fromIcon ? appProps.t('goHome') : appProps.t('closeTab')}
      </Button>
      <Stack
        {...{
          direction: 'column',
          sx: {
            minWidth: 0,
            backgroundColor: '#343B45',
            color: theme.palette.common.textColor,
            justifyContent: 'space-between',
            flex: 1,
            overflow: 'hidden',
            minHeight: 0,
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Stack
          {...{
            direction: 'column',
            sx: {
              minWidth: 0,
              minHeight: 0,
              flex: 1,
              overflow: 'hidden',
              width: '100%',
              height: '100px',
              justifyContent: 'space-between',
            },
          }}
        >
          <VisionSidebarTitle {...{t: appProps.t, trackRoute: trackRouteMinimized}} />

          <Stack {...{spacing: 1, direction: 'column'}}>
            <VisionCurrentValues {...{t: appProps.t, visionProps}} />
            <VisionChoosers {...{appProps, trainProps}} />
          </Stack>
        </Stack>

        <Stack
          {...{
            direction: 'column',
            sx: {
              minWidth: 0,
              minHeight: 0,
              flex: 1,
              overflow: 'hidden',
              width: '100%',
              height: '100px',
              justifyContent: 'flex-end',
            },
          }}
        >
          <TrainPageMap
            {...{
              appProps,
              trainProps,
              mapProps,
              componentProps: {hideSearch: true},
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VisionSideBar;
