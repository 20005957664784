/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum VehicleSide {
    RIGHT = 'right',
    LEFT = 'left',
    BOTH = 'both',
    UNSPECIFIED = 'unspecified',
}
