import {Stack} from '@mui/material';
import {map} from 'ramda';
import TrainGroupLineContainer from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupLineContainer.tsx';
import React from 'react';
import {Size} from '../../../../../types/layout/size';
import {
  SingleTrainGroupTrainProps,
  TrainProps,
} from '../../../../../types/propTypes/trainPropTypes/trainProps';
import {
  mergeTrainProps,
  useMemoMergeTrainProps,
} from '../../../../../appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from '../../../../../types/cemitTypename.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 *
 * @param appProps
 * @param organizationProps
 * @param trainProps
 @param [isSelectedTrainRuns] Default false, if true presents the TrainTunIntervals that are currently
  * active, meaning {activity: {isActive: true}}
 * @param [isBaseline] Default false, true if this is the baseline run
 * @param toggleTrainGroupVisibility
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupChooserList = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {isSelectedTrainRuns, toggleTrainGroupVisibility, trainDetailSize},
}: TrainAppTrainDependencyProps & {
  componentProps: {
    isSelectedTrainRuns: boolean;
    toggleTrainGroupVisibility: (trainGroup: TrainGroup) => void;
    trainDetailSize: Size;
  };
}) => {
  const trainGroupLineContainers: JSX.Element[] = map(
    (trainGroup: TrainGroup) => {
      // Scope trainProps trainGroupSingleTrainRunProps.trainGroup to the trainGroup
      const singleTrainGroupTrainProps: SingleTrainGroupTrainProps = mergeTrainProps<
        TrainProps,
        SingleTrainGroupTrainProps
      >(
        trainProps,
        CemitTypename.singleTrainGroupTrainProps,
        'trainGroupSingleTrainRunProps.trainGroup',
        trainGroup,
      );
      return (
        <TrainGroupLineContainer
          key={trainGroup.id}
          {...{
            appProps,
            organizationProps,
            trainProps: singleTrainGroupTrainProps,
            componentProps: {
              isSelectedTrainRuns,
              toggleTrainGroupVisibility,
              trainDetailSize,
            },
          }}
        />
      );
    },
    isSelectedTrainRuns
      ? // Selected list including those with download errors
        trainProps.trainGroupActivityProps.activeTrainGroups || []
      : // All
        trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.list,
  );
  return (
    <Stack
      spacing={0.5}
      key="trainGroups"
      sx={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {trainGroupLineContainers}
    </Stack>
  );
};

export default TrainGroupChooserList;
