/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListPointMachineEventsResponse } from '../models/ListPointMachineEventsResponse';
import type { ListPointMachineMeasurementResponse } from '../models/ListPointMachineMeasurementResponse';
import type { ListTurnoutsResponse } from '../models/ListTurnoutsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TurnoutsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List Turnouts
     * Returns a list of turnouts and their respective point machines
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListTurnoutsResponse Successful Response
     * @throws ApiError
     */
    public listTurnoutsTurnoutsGet(
        page: number = 1,
        pageSize: number = 10,
        withCount: boolean = false,
    ): CancelablePromise<ListTurnoutsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/turnouts/',
            query: {
                'page': page,
                'pageSize': pageSize,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Point Machine Events
     * Returns a list of point machine events for a particular point machine
     * @param machineId UUID for the point machine
     * @param averageByWeeks The number of weeks use to compute the average
     * @param startDate Query by created_at field
     * @param endDate Query by created_at field
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListPointMachineEventsResponse Successful Response
     * @throws ApiError
     */
    public listPointMachineEventsTurnoutsPointMachineEventsGet(
        machineId: string,
        averageByWeeks?: (number | null),
        startDate?: (string | null),
        endDate?: (string | null),
        page: number = 1,
        pageSize: number = 10,
        withCount: boolean = false,
    ): CancelablePromise<ListPointMachineEventsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/turnouts/point_machine_events',
            query: {
                'machineId': machineId,
                'average_by_weeks': averageByWeeks,
                'start_date': startDate,
                'end_date': endDate,
                'page': page,
                'pageSize': pageSize,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Point Machine Measurements
     * Returns a list of point machines measurements for a particular event
     * @param eventId
     * @returns ListPointMachineMeasurementResponse Successful Response
     * @throws ApiError
     */
    public listPointMachineMeasurementsTurnoutsPointMachineEventsEventIdMeasurementGet(
        eventId: string,
    ): CancelablePromise<ListPointMachineMeasurementResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/turnouts/point_machine_events/{event_id}/measurement',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
