import {SortDirectionEnum} from 'types/sorting/sortDirection.ts';
import {North, South} from '@mui/icons-material';
import {
  TrainAppComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppComponentDependencyProps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {IconButton} from '@mui/material';
import React from 'react';
import {Tooltip} from 'recharts';

export interface TrainGroupToggleSortDirectionButtonProps extends CemitComponentProps {
  onClick: () => void;
}

/**
 * Sort direction toggle button
 * @param appProps
 * @constructor
 */
const TrainGroupToggleSortDirectionButton = (
  {
    appProps,
    componentProps
  }: TrainAppComponentDependencyProps<TrainGroupToggleSortDirectionButtonProps>) => {
  return <IconButton {...{
      color: 'secondary', onClick: componentProps.onClick,
      sx: {
        padding: '2px',
        minHeight: 0,
        minWidth: 0
      }
    }}>
      {appProps.trainGroupSortConfigNameDirection == SortDirectionEnum.ascending ? <North /> : <South />}
    </IconButton>
};

export default TrainGroupToggleSortDirectionButton;
