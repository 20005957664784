import {DataFeatureCollection} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DataFeatureSet} from 'types/dataVisualizations/dateFeatureSet';
import {
  ChartDataCommonConfig,
  ChartDataCommonConfigFinalized,
  ChartDataConfig,
  ChartDataConfigFinalized,
  ChartDataConfigWithCustomControls,
} from 'types/dataVisualizations/chartDataConfig.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {compact, mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {
  chain,
  compose,
  concat,
  equals,
  filter,
  findIndex,
  ifElse,
  is,
  length,
  lensIndex,
  lensProp,
  map,
  omit,
  pick,
  reduce,
} from 'ramda';
import {mapMDeep} from '@rescapes/ramda';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  overClassOrType,
  overClassOrTypeList,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {resolveCommonConfig} from 'utils/dataFeatures/dataConfigUtils.ts';

/**
 * Creates the ChartDataConfigFinalized from chartDataConfigs and dataFeatureSets
 * @param loading
 * @param chartDataConfigs
 * @param dataFeatureSets
 */
export const useNotLoadingMemoFinalizeChartDataConfigs = (
  loading: boolean,
  chartDataConfigs: ChartDataConfig[],
  dataFeatureSets: DataFeatureSet[],
) => {
  return useNotLoadingMemo(
    loading,
    () => {
      return compact(
        map((chartDataConfig: ChartDataConfigWithCustomControls) => {
          const {dataFeatureCollectionSourceTypename, dataFeatureCollectionMatches} =
            chartDataConfig;

          // Find the dataFeatureSets that have matching dataFeatureCollections
          // and filter for only the matching dataFeatureCollections
          const filteredDataFeatureSets: DataFeatureSet[] = compose(
            // compact empty
            chain(compact),
            (dataFeatureSets: DataFeatureSet[]) => {
              // Operate on the inner dimension of DataFeatureSet
              try {
                return mapMDeep(
                  2,
                  (dataFeatureSet: DataFeatureSet) => {
                    const matchingDataFeatureCollections = filter(
                      (dataFeatureCollection: DataFeatureCollection) => {
                        return (
                          !dataFeatureCollectionSourceTypename ||
                          (equals(
                            dataFeatureCollectionSourceTypename,
                            dataFeatureCollection.sourceTypename,
                          ) &&
                            (!dataFeatureCollectionMatches ||
                              dataFeatureCollectionMatches(dataFeatureCollection)))
                        );
                      },
                      dataFeatureSet.dataFeatureCollections,
                    );
                    return length(matchingDataFeatureCollections)
                      ? {
                          ...omit(['dataFeatureCollections'], dataFeatureSet),
                          dataFeatureCollections: matchingDataFeatureCollections,
                        }
                      : undefined;
                  },
                  dataFeatureSets,
                );
              } catch (e) {
                throw e;
              }
            },
          )(dataFeatureSets || []) as DataFeatureSet[];
          return clsOrType<ChartDataConfigFinalized>(
            CemitTypename.chartDataConfigAndFilteredDataFeatureSets,
            {
              chartDataConfig,
              dataFeatureSets: map((dataFeatureSet: DataFeatureSet) => {
                return overClassOrType(
                  lensProp('dataFeatureCollections'),
                  (dataFeatureCollections: DataFeatureCollection[]) => {
                    return map((dataFeatureCollection: DataFeatureCollection) => {
                      return mergeRightIfDefined(dataFeatureCollection, {
                        color: ifElse(
                          is(Function),
                          (colorFunc) => colorFunc(chartDataConfig.defaultColor),
                          // TODO we could favor the dataFeatureCollection color over
                          // the chartDataConfig.defaultColor here
                          (color) => chartDataConfig.defaultColor || color,
                        )(dataFeatureCollection.color),
                      });
                    }, dataFeatureCollections);
                  },
                  dataFeatureSet,
                );
              }, filteredDataFeatureSets),
            },
          ) as ChartDataConfigFinalized;
        }, chartDataConfigs),
      );
    },
    [chartDataConfigs, dataFeatureSets] as const,
  );
};

/**
 * Creates ChartDataCommonConfigFinalizeds from ChartDataCommonConfigFinalizeds
 * @param loading
 * @param chartDataConfigAndFilteredDataFeatureSets
 */
export const useNotLoadingMemoCreateChartDataCommonConfigFinalizeds: Perhaps<
  ChartDataCommonConfigFinalized[]
> = (
  loading: boolean,
  chartDataConfigAndFilteredDataFeatureSets: ChartDataCommonConfigFinalized[],
) => {
  return useNotLoadingMemo(
    loading,
    (chartDataConfigAndFilteredDataFeatureSets: ChartDataCommonConfigFinalized[]) => {
      return reduce(
        (
          accumulatedChartDataCommonConfigFinalizeds: ChartDataCommonConfigFinalized[],
          chartDataConfigFinalized: ChartDataConfigFinalized,
        ): ChartDataCommonConfigFinalized[] => {
          const chartDataConfig = chartDataConfigFinalized.chartDataConfig;
          const chartDataConfigGroupingKey = chartDataConfig.chartDataConfigGroupingKey;
          // If chartDataConfigGroupingKey is defined and there is a matching commonConfig from the accumulation, use it
          const indexOfCommonConfig: number = chartDataConfigGroupingKey
            ? findIndex(
                (
                  accumulatedChartDataCommonConfigFinalized: ChartDataCommonConfigFinalized,
                ) => {
                  return equals(
                    chartDataConfigGroupingKey,
                    accumulatedChartDataCommonConfigFinalized.chartDataCommonConfig
                      .chartDataConfigGroupingKey,
                  );
                },
                accumulatedChartDataCommonConfigFinalizeds,
              )
            : -1;
          return ifElse(
            (index: number) => index >= 0,
            () => {
              return overClassOrTypeList(
                lensIndex(indexOfCommonConfig),
                (chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized) => {
                  return clsOrType<ChartDataCommonConfigFinalized>(
                    CemitTypename.chartDataCommonConfigFinalized,
                    {
                      chartDataCommonConfig:
                        accumulatedChartDataCommonConfigFinalizeds[indexOfCommonConfig]
                          .chartDataCommonConfig,
                      chartDataConfigFinalizeds: concat(
                        chartDataCommonConfigFinalized.chartDataConfigFinalizeds,
                        [chartDataConfigFinalized],
                      ),
                    },
                  );
                },
                accumulatedChartDataCommonConfigFinalizeds,
              );
            },
            () => {
              // See if there is a matching commonConfig. Otherwise make a fake one
              const chartDataCommonConfig: ChartDataCommonConfig =
                resolveCommonConfig(chartDataConfigFinalized.chartDataConfig) ||
                clsOrType<ChartDataCommonConfig>(CemitTypename.chartDataCommonConfig, {
                  isVisible: true,
                  ...pick(
                    [
                      // TODO automatically limit to ChartDataCommonConfig keys using a class
                      'isVisible',
                      'chartDataConfigGroupingKey',
                      'xAxisDataPath',
                      'labelYAxis',
                      'labelXAxis',
                      'yAxisDomain',
                      'xAxisDomain',
                      'xAxisScale',
                      'xAxisInterval',
                      'xAxisTickFormatter',
                      'label',
                      'ticks',
                    ],
                    chartDataConfigFinalized.chartDataConfig,
                  ),
                });
              return concat(accumulatedChartDataCommonConfigFinalizeds, [
                clsOrType<ChartDataCommonConfigFinalized>(
                  CemitTypename.chartDataCommonConfigFinalized,
                  {
                    chartDataCommonConfig,
                    chartDataConfigFinalizeds: [chartDataConfigFinalized],
                  },
                ),
              ]);
            },
          )(indexOfCommonConfig);
        },
        [],
        chartDataConfigAndFilteredDataFeatureSets,
      );
    },
    [chartDataConfigAndFilteredDataFeatureSets] as const,
  );
};
