/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StopDetectionOrderBy {
    DEVICE_NAME = 'device_name',
    _DEVICE_NAME = '-device_name',
    DATETIME = 'datetime',
    _DATETIME = '-datetime',
}
