import React, {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Search} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {PointMachineEventSchema, PointMachineMeasurementSchema, TurnoutsSchema} from "../../../net/repository";
import {fetchPointMachineEvents, fetchPointMachineMeasurements} from "../../../net/repo";
import {ChartDialog} from "../../../components/dialogs/ChartDialog";
import IconButton from "@mui/material/IconButton";

interface StatisticsProps {
  turnout: TurnoutsSchema | null
}

export default function Statistics({turnout}: StatisticsProps) {
  const [selectedPointMachine, setPointMachine] = React.useState('0');
  const [maxCount, setMaxCount] = React.useState(20);
  const [averageByWeeks, setAverageByWeeks] = React.useState(2);
  const [isLoading, setLoadingState] = React.useState(true);
  const [selectedStartDate, onDateStartSelected] = useState(null)
  const [selectedEndDate, onDateEndSelected] = useState(null)
  const [pointMachineEvents, setPointMachineEvents] = React.useState<Array<PointMachineEventSchema>>([]);
  const [pointMachineAverageStraight, setPointMachineAverageStraight] = React.useState<Array<number>>([]);
  const [pointMachineAverageCurved, setPointMachineAverageCurved] = React.useState<Array<number>>([]);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    setPointMachine(event.target.value);
  };

  useEffect(() => {
    onFetchData()
  }, [selectedPointMachine, maxCount, turnout, averageByWeeks]);

  const onFetchData = () => {
    const index = parseInt(selectedPointMachine);
    if (turnout && Array.isArray(turnout.pointMachines)) {
      if (turnout.pointMachines.length > 0) {
        const pointMachine = turnout.pointMachines[index];

        if (!maxCount || !averageByWeeks) return;

        const request = fetchPointMachineEvents({
          machineId: pointMachine.id as string,
          page: 1,
          pageSize: maxCount,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          averageByWeeks: averageByWeeks ?? 2
        });

        request.then((response) => {
          setPointMachineEvents(response.data.events);
          setPointMachineAverageStraight(response.data.straightAverage)
          setPointMachineAverageCurved(response.data.curvedAverage)
          setLoadingState(false);
        }).catch((reason) => {
          setLoadingState(false)
        })
        return
      }
    }
  }

  const onFilterByDate = () => {
    setPointMachineEvents([]);
    setLoadingState(true);
    onFetchData()
  }

  const onUpdateMaxCount = (e) => {
    setMaxCount(e.target.value);
  }

  const onUpdateWeekAverage = (e) => {
    setAverageByWeeks(e.target.value);
  }

  const onUpdateSelectedStartDate = (value: any) => {
    if (!value) {
      onDateStartSelected(null);
      return;
    }

    const date = new Date(value.$d);
    const isoDate = date.toISOString();
    onDateStartSelected(isoDate);
  }

  const onUpdateSelectedEndDate = (value: any) => {
    if (!value) {
      onDateEndSelected(null);
      return;
    }

    const date = new Date(value.$d);
    const isoDate = date.toISOString();
    onDateEndSelected(isoDate);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{
          height: 'auto',
          mt: '5vh',
          pt: 2,
          pb: 2,
          flexGrow: 1,
          backgroundColor: '#45545E',
        }}>
          <Grid container sx={{p: '20px'}}>
            <Grid sx={{}}>
              <Typography sx={{color: 'white', mt: 2, fontWeight: 700,}} variant='subtitle1'>Select Point
                Machines:</Typography>
            </Grid>
            <Grid sx={{minWidth: 120, ml: '20px'}}>
              <FormControl fullWidth>
                <InputLabel id="point-machine-select-label">Point Machines</InputLabel>
                <Select
                  labelId="point-machine-select"
                  id="pm-select"
                  value={selectedPointMachine}
                  label="Select Point Machines"
                  onChange={handleChange}
                >
                  {turnout && turnout.pointMachines && turnout.pointMachines.map((pointMachine, index) =>
                    <MenuItem
                      key={pointMachine.id}
                      value={index}>{pointMachine.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{
          height: 'auto',
          mt: '1vh',
          flexGrow: 1,
          backgroundColor: '#45545E',
        }}>
          <Grid container sx={{width: '100%', p: '20px'}}>
            <Grid display="flex" sx={{pt: 2, pr: 2}} xs={12} lg={2} md={1} xl={2} item>
              <Typography fontSize={'16px'} fontWeight='normal' color={theme.palette.action.active} variant='h4'>Filter
                alerts by dates</Typography>
            </Grid>
            <Grid display="flex" lg={1} item/>
            <Grid display="flex" item sx={{width: 'auto', marginLeft: 1,}} container md={11} lg={8} xl={8} xs={12}>
              <Grid item></Grid>
              <Grid item sx={{marginRight: 1}}>
                <DatePicker onChange={onUpdateSelectedStartDate} sx={{border: 0}}/>
              </Grid>
              <Grid sx={{mt: theme.spacing(2),}} item> <Typography color='#fff'> - </Typography></Grid>
              <Grid sx={{marginLeft: 1}} item>
                <DatePicker onChange={onUpdateSelectedEndDate}/>
              </Grid>
              <Grid item>
                <IconButton onClick={onFilterByDate} aria-label="search">
                  <Search fontSize="large"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>


      <Box sx={{height: 'auto', mt: '10vh'}}>
        <Grid sx={{mb: '20px'}} container justifyContent='space-between'>
          <Grid sx={{mt: 2,}}>
            <Typography sx={{fontWeight: 700}} color={theme.palette.action.active}
                        variant='subtitle1'>Latest</Typography>
          </Grid>
          <Grid>
            <TextField sx={{mr: 2}} id="max-count" value={averageByWeeks} onChange={onUpdateWeekAverage}
                       label="Week Average"
                       variant="outlined"/>
            <TextField id="max-count" value={maxCount} onChange={onUpdateMaxCount} label="Max Count"
                       variant="outlined"/>
          </Grid>
        </Grid>
        {isLoading ? <CircularProgress/> : <TableContainer component={Paper} style={{maxHeight: '50vh',}}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 650}} align="center">Date</TableCell>
                <TableCell sx={{fontWeight: 650}} align="center">Pos</TableCell>
                <TableCell sx={{fontWeight: 650}} align="center">OTime</TableCell>

                <TableCell sx={{fontWeight: 650}} align="center">U</TableCell>
                <TableCell sx={{fontWeight: 650}} align="center">O</TableCell>
                <TableCell sx={{fontWeight: 650}} align="center">L</TableCell>
                <TableCell sx={{fontWeight: 650}} align="center"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointMachineEvents === undefined || pointMachineEvents.length < 1 ?
                <TableRow><TableCell colSpan={8}><Typography align={"center"}>No Events for this Point
                  Machine</Typography></TableCell></TableRow>
                :
                pointMachineEvents.map((pointMachineEvent: PointMachineEventSchema, index) => (
                  <TableRowItem
                    key={pointMachineEvent.id}
                    pointMachinePosition={index}
                    pointMachineEvents={pointMachineEvents}
                    pointMachineAverage={pointMachineEvent.turnoutPosition === 'divergent' ? Array.from(pointMachineAverageCurved).reverse() : Array.from(pointMachineAverageStraight).reverse()}
                    pointMachineEvent={pointMachineEvent}/>
                ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Box>
    </>
  );
}

interface ITableRowItem {
  pointMachineEvent: PointMachineEventSchema;
  pointMachineEvents: Array<PointMachineEventSchema>;
  pointMachineAverage: Array<number>;
  pointMachinePosition: number;
}

function TableRowItem({
                        pointMachineEvent,
                        pointMachineAverage,
                        pointMachineEvents,
                        pointMachinePosition
                      }: ITableRowItem) {
  const [showGraph, setShowGraphState] = useState(false);
  const [isLoading, setLoadingState] = useState(true);
  // const [measurementData, setMeasurementData] = useState<PointMachineMeasurementSchema[]>([]);
  const [paddedArray, setPaddedArray] = useState<>([]);

  const createdAt = new Date(pointMachineEvent.createdAt);
  const dateTimeString = `${createdAt.toDateString()} ${createdAt.toTimeString().substring(0, 12)}`
  const startTime = Date.parse(pointMachineEvent.timestampStart);
  const stopTime = Date.parse(pointMachineEvent.timestampStop);

  const oTimeInSecs = (stopTime - startTime) / 1000;
  const direction = pointMachineEvent.turnoutPosition === 'divergent' ? "curved" : pointMachineEvent.turnoutPosition;
  const stripUndefinedPosition = direction === "undefined" ? "N/A" : direction;

  const timeLengthInSeconds = paddedArray ? paddedArray.length : 0;
  const pointMachineAvgLen = pointMachineAverage ? pointMachineAverage.length : 0;

  const machineFirst3rdIndex = timeLengthInSeconds > 0 ? Math.floor(timeLengthInSeconds / 3) : 0;
  const avgFirst3rdIndex = pointMachineAvgLen > 0 ? Math.floor(pointMachineAvgLen / 3) : 0;

  const machinePoint2Third = Math.floor((machineFirst3rdIndex * 2)) - 1;
  const avg2Third = Math.floor((avgFirst3rdIndex * 2)) - 1;

  const firstQuota = paddedArray
    .slice(0, machineFirst3rdIndex - 1);
  const measurementArray3rdSum = firstQuota
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (machineFirst3rdIndex);

  const firstQuotaAvg = pointMachineAverage
    .slice(0, avgFirst3rdIndex - 1);
  const avgArray3rdSum = firstQuotaAvg
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (avgFirst3rdIndex);

  const secondQuota = paddedArray
    .slice(machineFirst3rdIndex, machinePoint2Third - 1)
  const measurementArray2ThirdSum = secondQuota
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (machinePoint2Third - machineFirst3rdIndex);

  const secondQuotaAvg = pointMachineAverage
    .slice(avgFirst3rdIndex, avg2Third - 1);
  const avgArray3rdIndex2ThirdSum = secondQuotaAvg
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (avg2Third - avgFirst3rdIndex);

  const thirdQuota = paddedArray
    .slice(machinePoint2Third, timeLengthInSeconds - 1);
  const measurementArrayLastThirdSum = thirdQuota
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (timeLengthInSeconds - machinePoint2Third);
  const thirdQuotaAvg = pointMachineAverage
    .slice(avg2Third, pointMachineAvgLen - 1)
  const avgArray3rdIndexLastThirdSum = thirdQuotaAvg
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (pointMachineAvgLen - avg2Third);


  // TODO(Ben): Change the API to return a list of data instead
  // Using this extra call for now because a change in the API will take longer to deploy
  useEffect(() => {
    if (pointMachineEvent) {
      const request = fetchPointMachineMeasurements({eventId: pointMachineEvent.id ?? "", page: 1, pageSize: 10});
      request.then((response) => {
        const _measurementData = response.data;
        // setMeasurementData(_measurementData);
        setPaddedArray(splitAndDataCheckForErrors(_measurementData, pointMachineAverage));
        setLoadingState(false);
      }).catch((reason) => {
        setLoadingState(false);
      });
    }
  }, [isLoading]);

  const splitAndDataCheckForErrors = (_machineMeasurementData, _pointMachineAverage) => {
    const avgData = Array.from(_pointMachineAverage)
    const averageMeasurementLength = _pointMachineAverage.length;
    const measurementDataLength = _machineMeasurementData.length;
    const _paddedArray = Array.from([])

    const avgMax: number = Math.max(...avgData);
    const avgMaxIndex = avgData.indexOf(avgMax)

    const currentData = _machineMeasurementData.map((data: PointMachineMeasurementSchema) => data ? data.current : 0);
    const dataMax: number = Math.max(...currentData) ?? null;
    const dataMaxIndex = dataMax ? currentData.indexOf(dataMax) : -1;

    if (averageMeasurementLength > measurementDataLength) {
      const diff = avgMaxIndex - dataMaxIndex;
      const newArraySize = ((averageMeasurementLength - measurementDataLength) - diff) + measurementDataLength ;

      for (let i = 0; i < diff; i++) {
        _paddedArray.push(0)
      }
      for (let i = 0; i < newArraySize; i++) {
        const measurement = _machineMeasurementData[i];
        const current = measurement ? _machineMeasurementData[i].current : 0;
        _paddedArray.push(current);
      }
      return _paddedArray;
    } else {
      return _machineMeasurementData.map((data) => data ? data.current : 0);
    }
  }

  console.log("-------")
  function extracted(measurementQuotaArray: Array<number>, pointMachineQuotaAvgArray: Array<number>, _paddedArray: Array<number>, measurementQuotaAvg: number, pointMachineQuotaAvg: number) {
    let sumOfDifference = 0;
    const avgMax: number = Math.max(...pointMachineQuotaAvgArray);
    const measurementMax: number = Math.max(...measurementQuotaArray);

    for (let i = 0; i < pointMachineQuotaAvgArray.length; i++) {
      const diff = measurementQuotaArray[i] - pointMachineQuotaAvgArray[i];
      sumOfDifference += measurementQuotaArray[i] ? diff : 0;
    }
    const avgOfDifference = (Math.abs(sumOfDifference) / pointMachineQuotaAvgArray.length);

    //TODO(INFO) This approach does not work because it would be hard to detect the changes on the last
    // unlocking phase. We cannot use the largest value as the point reference.

    // Get the percentage of difference from the sum of the measurementQuotaArray average. i.e how much of the
    // measurementQuotaArray average is contained in the sum.
    const percentOfDiffInSumOfQuotaAvg = (avgOfDifference / pointMachineQuotaAvg) * 100;
    const isThereASpike = (measurementMax > (avgMax * 2));

    // Using the scale of 10 AMPs 1.5 AMPs is 15%.
    // Since avg is about 2.3 AMPS and a yellow point starts at 1.5 AMPs to 3 AMPs the we need to adjust the percentage.
    // 1.5 AMPs is 0.65%. We are looking for outlier that are in the 150% - 300% range

    // The values here are in percentages i.e if the percentOfDiffInSumOfQuotaAvg is greater than 50% and lesser than 150%
    // of the point machine average in the quota or section of the chart in view.
    const isYellowOrRed = (avgOfDifference > 1.5 && avgOfDifference < 3.0) || percentOfDiffInSumOfQuotaAvg >= 50 && percentOfDiffInSumOfQuotaAvg < 120 ? "orange" : "red";
    const isGreen = sumOfDifference < 0 || avgOfDifference < 1.5 || percentOfDiffInSumOfQuotaAvg < 50 ? 'green' : isYellowOrRed;

    return isThereASpike ? 'red' : isGreen;
  }

  const showEventIndicator = (measurementData: Array<number>, checkGraphSection: string) => {
    const timeLengthInSeconds = measurementData ? measurementData.length : 0;
    const avgLimit = pointMachineAvgLen + (0.35 * pointMachineAvgLen);
    const isEventInGreenZone = timeLengthInSeconds < avgLimit;
    const isEventInYellowZone = timeLengthInSeconds > avgLimit && timeLengthInSeconds < 700;

    const boxColor = isEventInGreenZone ? checkGraphSection : (isEventInYellowZone ? "orange" : "red")
    return isLoading ? <CircularProgress size={10}/> : <Box sx={{
      height: 20,
      width: 30,
      border: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      borderColor: 'white',
      backgroundColor: boxColor
    }}/>
  }

  return (
    <TableRow
      key={pointMachineEvent.id}
    >
      <TableCell sx={{fontWeight: 650}} size='small'
                 align="center">{dateTimeString}</TableCell>
      <TableCell sx={{fontWeight: 650}}
                 align="center">{stripUndefinedPosition}</TableCell>
      <TableCell sx={{fontWeight: 650}} align="center">{oTimeInSecs} secs</TableCell>
      <TableCell size='small' style={{width: 30}} align="center">
        {showEventIndicator(paddedArray, extracted(firstQuota, firstQuotaAvg, paddedArray, measurementArray3rdSum, avgArray3rdSum))}
      </TableCell>
      <TableCell style={{width: 30}} align="center">
        {showEventIndicator(paddedArray, extracted(secondQuota, secondQuotaAvg, paddedArray, measurementArray2ThirdSum, avgArray3rdIndex2ThirdSum))}
      </TableCell>
      <TableCell style={{width: 30}} align="center">
        {showEventIndicator(paddedArray, extracted(thirdQuota, thirdQuotaAvg, paddedArray, measurementArrayLastThirdSum, avgArray3rdIndexLastThirdSum))}
      </TableCell>

      <TableCell size='small' align="left">
        <Button onClick={() => {
          setShowGraphState(true);
        }}>
          <Search sx={{color: 'white'}} fontSize='large'></Search>
        </Button>
        {!isLoading && showGraph &&
          <ChartDialog open={showGraph}
                       eventId={pointMachineEvent.id as string}
                       onClose={setShowGraphState}
                       pointMachinePosition={pointMachinePosition}
                       pointMachineAverage={pointMachineAverage}
                       measurement={paddedArray}
                       pointMachineEvents={pointMachineEvents}
                       selectedValue={pointMachineEvent}/>}
      </TableCell>
    </TableRow>
  )
}
