import {useTranslation} from 'react-i18next';
import React, {SyntheticEvent} from 'react';
import {IconButton, Tooltip} from '@mui/material';
import {Expand, UnfoldLess} from '@mui/icons-material';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';


const TrainGroupExpandAllButton =
  (
    {
      loading,
      appProps
    }: TrainAppTrainDependencyProps
  ) => {
    const {t} = useTranslation();
    if (loading) {
      return undefined;
    }
    /**
     * Toggle trainGroup.activity.isListingExpanded between true and false and persist the state using crudTrainGroups
     */
    const handleClick = (event: SyntheticEvent): void => {
      event.stopPropagation();
      // Toggle the value between true and false
      appProps.setAreAllTrainGroupsExpanded((value: boolean) => {
        return !value;
      });
    };

    return <Tooltip
      {...{
        arrow: true,
        title: t(
          appProps.areAllTrainGroupsExpanded
            ? 'collapseAllTrainGroupsSummary'
            : 'expandAllTrainGroupsSummary'
        )
      }}
    >
      <IconButton
        key="cemitFilterAllTrainGroupExpanderButton"
        {...{
          sx: {padding: 0},
          color: 'secondary',
          onClick: handleClick
        }}
      >
        {appProps.areAllTrainGroupsExpanded ? <UnfoldLess /> : <Expand />}
      </IconButton>
    </Tooltip>;
  };
export default TrainGroupExpandAllButton;
