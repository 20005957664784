import React, {memo, NamedExoticComponent} from 'react';
import {Stack, Typography} from '@mui/material';
import {always, cond, T} from 'ramda';
import {useTranslation} from 'react-i18next';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {Size} from 'types/layout/size';
import TrainGroupsContainer from '../trainGroupChooserComponents/TrainGroupsContainer.tsx';
import {sortTrainGroups} from 'appUtils/trainAppUtils/trainGroupUtils/trainGroupRouteBasedUtils.ts';
import {hasNonZeroLength} from 'utils/functional/functionalUtils.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';

interface TrainFormationsListProps extends CemitComponentProps {
  trainDetailSize: Size;
}

/**
 * The TrainOverview card for the TrainFormations
 * @param props
 * @param props.appProps
 *  @param props.trainRuns Runs of trains, meaning a run of the trains from an origin to a destination
 * along with the cdcData that has been collected for that run, if any
 * @param props.formations
 * @param props.selectedTrain
 * @param props.hoverTrainRun
 * @param props.setHoverTrain
 * @param props.setSelectedTrain
 * @param props.dateInterval Current datetime range of service to show on the schedule
 * @param props.setDateInterval Setter hook for dateInterval
 * A service currently consists of a {trains: trains id, distanceRange: the part of the route of interest}
 * @returns {JSX.Element} A react-table based table
 */
const TrainFormationsList: NamedExoticComponent = memo(
  ({
    appProps,
    organizationProps,
    trainProps,
    componentProps,
  }: TrainAppTrainComponentDependencyProps<TrainFormationsListProps>) => {
    const {t} = useTranslation();

    const trainFormationsList =
      trainProps.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations
        ?.list;

    const orderedTrainGroupsOnlyTrainFormationWithinFilters: Perhaps<TrainGroup[]> =
      useNotLoadingMemo(
        !trainFormationsList,
        (trainFormationsList) => sortTrainGroups(trainFormationsList),
        [trainFormationsList] as const,
      );

    const trainRunContainer: React.ReactNode = cond([
      [
        always(trainProps.trainGroupOnlyTrainFormationProps.loading),
        () => {
          return (
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                marginTop: '50px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoaderWithText
                {...{
                  text: 'loadingTrainFormations',
                  loadingExplanation:
                    trainProps.trainGroupOnlyTrainFormationProps?.whatIsLoading
                      ?.loadingExplanation,
                }}
              />
            </Stack>
          );
        },
      ],
      [
        hasNonZeroLength,
        (_trainGroups: TrainGroup[]) => {
          return (
            <TrainGroupsContainer
              {...{
                appProps,
                trainProps,
                organizationProps,
              }}
            />
          );
        },
      ],
      [
        T,
        () => {
          return (
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>{t('noTrainRuns')}</Typography>
            </Stack>
          );
        },
      ],
    ])(orderedTrainGroupsOnlyTrainFormationWithinFilters);

    return (
      <Stack
        {...{
          sx: {
            height: '100%',
            maxHeight: 'inherit',
            overflowY: 'auto',
            bgColor: 'background.default',
            justify: 'center',
          },
        }}
      >
        {trainRunContainer}
      </Stack>
    );
  },
);
TrainFormationsList.displayName = 'TrainFormationsList';

export default TrainFormationsList;
