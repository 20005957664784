import {Layer, Source} from 'react-map-gl';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {MapDialog} from 'pages/trafficsim/trafficSimComponents/dialog/mapDialog.tsx';
import CustomPopup from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomPopup.tsx';
import {Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import {MapComponentActionType} from 'pages/trafficsim/trafficSimComponents/track/v2/TrafficSimMap.tsx';
import {AuthContext} from 'pages/trafficsim/context/auth/AuthProviderTODORemove.tsx';
import {ACCESS_LEVELS} from '../../../utils/access_levels.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {IFeedPoint} from "./FeedPoint.tsx";
import {ISection} from "./Section.tsx";

export interface IStation    {
  id: string;
  internal_id: string;
  feedpoints: Array<string>;
  isConnectedSectionOpen: boolean;
  isOpen: boolean
  comments: string;
  props: IStationExtraProps;
}

interface IStationExtraProps {
  ip: string;
  p1: string;
  p2: string;
  p3: string;
  p4: string;
  p5: string;
  p6: string;
  name: string;
  port: string;
  frequency: string;
  fkapprumid: string;
  coordinates: Array<number>
}


interface StationProps {
  mapRef: any;
  station: IStation;
  feedPointSections: Array<ISection>;
  onUpdateStation: (station: IStation, action: MapComponentActionType) => void;
}

export function Station({mapRef, station, feedPointSections, onUpdateStation}: StationProps) {
  const [showInfoDialog, setInfoDialog] = useState(false);
  const [showPopUp, setPopupState] = useState(false);
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const stationInternalId = station !== undefined ? station.internal_id : '';
  const layerId = `station-layer-${stationInternalId}`;
  const theme = useTheme();
  const isStationOpen = station !== undefined ? station.isOpen : true;
  const isAnySectionClosed = feedPointSections.length > 0 ? feedPointSections.some(
    (section: ISection) => !section.isOpen || section.isInSafeTrackMode,
  ) : false;
  const coordinates = station !== undefined ? station.props.coordinates : [0, 0];
  const stationStatus = isAnySectionClosed || !isStationOpen;
  let color = stationStatus ? theme.palette.map.red : theme.palette.map.green;
  const stationLabel = `Likriktarstation: ${stationInternalId}`;
  const stationSourceId = `station-${stationInternalId}`;

  useEffect(() => {
    if (!mapRef) {
      return undefined;
    }
    const onClickLayer = (e) => {
      e.originalEvent.stopPropagation();
      if (e.features.length > 0) {
        const clickedId = e.features[0].properties.id;
        updateState(e);
      }
    };

    if (
      userState.access.trafficSimAccessLevel &&
      userState.access.trafficSimAccessLevel !== ACCESS_LEVELS.READ_ONLY
    ) {
      mapRef.current.on('click', layerId, onClickLayer);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', layerId, onClickLayer);
      }
    };
  }, []);

  let prepareGeoJson = (id: string, station: IStation, color: string, isStationClosed: boolean) => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            id: id,
            color: color,
            name: station.internal_id,
            icon: isStationClosed ? 'station-icon-red' : 'station-icon-green',
          },
          geometry: {
            type: 'Point',
            coordinates: station.props.coordinates,
          },
        },
      ],
    };
  };

  const updateState = (e) => {
    e.originalEvent.stopPropagation();
    const mapZoomLevel = mapRef.current.getZoom();
    const isMapZoomedIn = mapZoomLevel >= 16;

    if (!isMapZoomedIn) {
      mapRef.current.flyTo({
        center: coordinates,
        zoom: 16,
        essential: true,
      });
      return;
    }

    setPopupState(true);
    mapRef.current.flyTo({
      center: coordinates,
      zoom: mapZoomLevel,
      essential: true,
    });
  };

  const onSave = (notes: string, voltageStatus: boolean) => {
    if (voltageStatus === isStationOpen) {
      onClose();
      return;
    }

    let updatedStation = {
      ...station,
      comments: notes,
    };

    const actionType = voltageStatus
      ? MapComponentActionType.OPEN
      : MapComponentActionType.CLOSE;
    onUpdateStation(updatedStation, actionType);
    onClose();
  };

  const onClose = () => {
    setPopupState(false);
  };

  const geoJSON = prepareGeoJson(station.internal_id, station, color, stationStatus);

  return (
    <>
      <Source id={stationSourceId} type="geojson" data={geoJSON}>
        <Layer
          id={layerId}
          type="symbol"
          source={stationLabel}
          layout={{
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.2,
              14,
              0.17,
              14.1,
              0.19,
              15,
              0.24,
              20,
              0.5,
            ],
            'text-field': '{name}',
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              5,
              14,
              ['*', 3, 3],
              17,
              ['*', 3, 4],
              19,
              ['*', 4, 5],
              20,
              ['*', 5, 6],
            ],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Regular'],
            'text-offset': [0, 0],
            'text-allow-overlap': true,
            'text-anchor': 'center',
            'text-justify': 'center',
            'text-rotation-alignment': 'auto',
          }}
          paint={{
            'text-color': '#ffffff',
          }}
        />
      </Source>

      {showPopUp && (
        <CustomPopup
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          anchor="bottom"
          onClose={() => onClose()}
        >
          <MapDialog
            type="station"
            label={stationLabel}
            sectionStatus={isStationOpen}
            data={station}
            onSave={onSave}
            onCancel={onClose}
          />
        </CustomPopup>
      )}

      {showInfoDialog && coordinates && (
        <CustomPopup
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          anchor="bottom"
          collapse={true}
          onClose={() => setInfoDialog(false)}
        >
          <Typography variant="body2" style={{color: theme.palette.text.primary}}>
            This station is currently shut down
          </Typography>
        </CustomPopup>
      )}
    </>
  );
}
