import React, {memo, useMemo} from 'react';
import {CemitTypename} from 'types/cemitTypename.ts';
import CemitFilterTrainGroupsContainer
  from '../../cemitFilterComponents/cemitFilterTrainGroupComponents/CemitFilterTrainGroupsContainer.tsx';
import {IconButton, Stack, Tooltip} from '@mui/material';
import CemitFilterTrainFormationsContainer, {
  CemitFilterTrainFormationsContainerProps
} from '../../cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsContainer.tsx';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {FrontendView, FrontendViewType} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {compact} from 'utils/functional/functionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import TrainGroupTypeChooser, {
  TrainGroupTypeChooserProps
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupSingleTrainRunChooserComponents/trainGroupTypeChooser.tsx';
import TrainGroupSortButtons
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupSortButtons.tsx';
import TrainGroupToggleExpandAllButton
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupExpandAllButton.tsx';
import {areActiveTrainGroupsLoading} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {TableChart} from '@mui/icons-material';
import {
  useNotLoadingEffectSyncToAnyAreActive
} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/activeViewHooks.ts';
import {concat, includes} from 'ramda';
import {ViewSlider} from 'types/propTypes/viewSlider.ts';

/**
 * Container listing TrainGroups. A TrainGroup can represent one or more
 * TrainRuns that have commonalities
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainGroupsContainer = memo(
  ({
     appProps,
     organizationProps,
     trainProps
   }: TrainAppTrainDependencyProps): JSX.Element => {

    const toggleTrainGroupOverview = () => {
      // Force the Overview on even if there are activeTrainGroups
      appProps.setActiveViews((activeViews: FrontendView[]): FrontendView[] => {
        return concat(
          activeViews,
          !includes(
            FrontendView.forceTrainGroupOverview, activeViews
          ) ? [FrontendView.forceTrainGroupOverview] : []
        );
      });
    };
    // As long as the active CrudList TrainGroups are loaded, enable the sort buttons.
    const loading = areActiveTrainGroupsLoading(trainProps);
    // Get active long representations, removing those that are not loaded yet or are not active
    // according to appProps.frontendViews
    const sortedTrainGroupLongContainers = useMemo(() => {
      return compact([
        <CemitFilterTrainFormationsContainer
          key="cemitFilterTrainFormationsContainer"
          {...{
            appProps,
            organizationProps,
            trainProps,
            componentProps: clsOrType<CemitFilterTrainFormationsContainerProps>(
              CemitTypename.cemitFilterTrainFormationsContainerProps,
              {
                maximumAllowed: TRAIN_GROUP_MAX_ACTIVE_COUNT,
                // Only allow 1 TrainFormation for now
                acceptTypes: [FrontendViewType.large]
              }
            )
          }}
        />,
        trainProps.filteredTrainGroupProps.loading ? undefined : (
          <CemitFilterTrainGroupsContainer
            key="cemitFilterTrainGroupsContainer"
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                acceptTypes: [FrontendViewType.large]
              }
            }}
          />
        )
      ]);
    }, [appProps, organizationProps, trainProps]);
    return (
      <Stack
        {...{
          spacing: 1,
          sx: {
            minWidth: 0,
            minHeight: 0,
            overflow: 'auto',
            position: 'relative',
            overflowY: 'auto',
            alignItems: 'left'
          }
        }}
      >
        <Stack {...{
          spacing: 1,
          direction: 'row',
          sx: {
            paddingLeft: '8px',
            minWidth: 0,
            minHeight: 0,
            overflow: 'auto',
            position: 'relative',
            overflowX: 'none',
            justifyContent: 'space-between'
          }
        }} >
          <Stack {...{
            spacing: 1,
            direction: 'row',
            sx: {
              minWidth: 0,
              minHeight: 0,
              overflow: 'auto',
              position: 'relative',
              overflowX: 'none',
              justifyContent: 'start',
              alignItems: 'center'
            }
          }}>
            <TrainGroupToggleExpandAllButton {...{loading, appProps}} />
            {/* Choose between TrainGroupOnlyTrainFormations and TrainGroupSingleTrainRuns of for the long component */}
            <TrainGroupTypeChooser
              key="trainGroupTypeChooser"
              {...{
                appProps,
                trainProps,
                componentProps: useMemoClsOrType<TrainGroupTypeChooserProps>(
                  CemitTypename.trainGroupTypeChooserProps,
                  {}
                )
              }}
            />
          </Stack>
          <Stack {...{
            spacing: 1,
            direction: 'row',
            sx: {
              minWidth: 0,
              minHeight: 0,
              overflow: 'auto',
              position: 'relative',
              overflowX: 'none',
              justifyContent: 'end',
              alignItems: 'center'
            }
          }}>
            {
              // Allows the user to force the TrainGroup overview unless we are in full map mode
              appProps.viewSlider != ViewSlider.collapsed &&
                <Tooltip
                  {...{arrow: true, title: appProps.t('seeOverview')}}
                >
                  <IconButton  {...{
                    sx: {
                      width: 'fit-content',
                      alignSelf: 'center',
                      color: 'secondary',
                      minWidth: 0,
                      minHeight: 0,
                      overflow: 'auto',
                      position: 'relative'
                    },
                    onClick: toggleTrainGroupOverview
                  }}>
                    <TableChart />
                  </IconButton>
                </Tooltip>
            }
            <TrainGroupSortButtons {...{loading, appProps, trainProps, organizationProps}} />
          </Stack>
        </Stack>
        {sortedTrainGroupLongContainers}
      </Stack>
    );
  }
);
export default TrainGroupsContainer;
