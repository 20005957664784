import {lensPath, not} from 'ramda';
import {CrudList} from 'types/crud/crudList';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {TrainRouteGroup} from 'types/trainRouteGroups/trainRouteGroup';
import {Activity} from 'types/userState/activity';
import {OrganizationLoaded} from 'types/organizations/organization.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainRun, TrainRunResponse} from 'types/trainRuns/trainRun';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {UserStateLoaded} from 'types/userState/userState';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {
  TrainGroupsGrouping,
  TrainGroupsGroupingCollection,
} from 'types/trainGroups/trainGroupsGroupingCollection';
import {mergeTrainGroupGroupingCollection} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupGroupingCollectionMerging.ts';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup, TrainGroupMinimized} from 'types/trainGroups/trainGroup';
import {TrainFormationMinimized} from 'types/trains/trainFormation';
import {CemitApiLoadingStatus} from 'types/apis/cemitApiLoadingStatus.ts';
import {createTrainGroupActivityAsActive} from 'classes/typeCrud/trainGroupActivityCrud.ts';

/**
 * Toggles the visibility of the TrainGroup by toggling and saving activity.isVisible
 * @param crudSelectedTrainGroups CRUD operations for saving
 * @param trainGroup The TrainGroup
 */
export const toggleTrainGroupVisibility = (
  crudSelectedTrainGroups: CrudList<TrainGroup>,
  trainGroup: TrainGroup,
) => {
  const modifiedTrainGroup = overClassOrType(
    lensPath(['activity', 'isVisible']),
    not,
    trainGroup,
  );
  crudSelectedTrainGroups.updateOrCreate(modifiedTrainGroup);
};

/**
 * Called by crudTrainGroup after a Crud operation to store
 * the state of crudTrainGroup in trainGroupsByGroupingId thereby caching
 * the state of crudTrainGroup for the current TrainRoute or Organization
 * The parameters passed here are the additionalDependencies of the crudHook
 * @param organization The Cemit organization used to id trainGroups if no TrainRoutes are defined
 * @param trainRouteOrGroup
 * @param setTrainGroupsGroupingCollection
 * @param trainGroups The TrainGroups that were just set
 * @returns {(function(*): void)|*}
 */
export const postSetList = <T extends TrainGroupMinimized>(
  organization: Required<OrganizationLoaded>,
  trainRouteOrGroup: TrainRoute | TrainRouteGroup | undefined,
  setTrainGroupsGroupingCollection: StateSetter<TrainGroupsGroupingCollection<T>>,
  trainGroups: T[],
): void => {
  // Update our TrainGroupsByGroupingId for the current trainRoute or if there are no trainRouteGroups
  // by the organization id
  setTrainGroupsGroupingCollection((existing: TrainGroupsGroupingCollection<T>) => {
    return mergeTrainGroupGroupingCollection(
      existing,
      clsOrType<TrainGroupsGroupingCollection<T>>(
        CemitTypename.trainGroupsGroupingCollection,
        {
          groupingCemitTypename: existing.groupingCemitTypename,
          trainGroupCemitTypename: existing.trainGroupCemitTypename,
          trainGroupsByGroupings: [
            clsOrType<TrainGroupsGrouping<T>>(CemitTypename.trainGroupsGrouping, {
              groupingCemitTypename: existing.groupingCemitTypename,
              trainGroupCemitTypename: existing.trainGroupCemitTypename,
              groupingId: (trainRouteOrGroup || organization).id,
              trainGroups,
            }),
          ],
        },
      ),
    );
  });
};

/**
 * Combines trainGroupSingleTrainRunResponse values with the existing trainGroupSingleTrainRun
 * @param trainGroupSingleTrainRunResponse
 */
export const createTrainGroupSingleTrainRunFromTrainRunResponse = (
  trainGroupSingleTrainRunResponse: TrainRunResponse,
): TrainGroup => {
  return clsOrType<TrainGroupSingleTrainRun>(CemitTypename.trainGroupSingleTrainRun, {
    // TODO Temporarily use the only TrainRun's id as the TrainGroup id until we establish first class TrainGroup ids
    // or have multiple TrainRuns per TrainGroup
    id: trainGroupSingleTrainRunResponse.id,
    // Only true when isPreconfigured is passed as a request prop, indicated that the TrainRuns being requested
    // are preconfigured to be loaded no matter what the user filters for, such as a reference TrainRun
    isPreconfigured: trainGroupSingleTrainRunResponse.isPreconfigured,
    trainRuns: [
      clsOrType<TrainRun>(CemitTypename.trainRun, trainGroupSingleTrainRunResponse),
    ],

    // These values are extract from the trainRun to complete the TrainGroup's interface
    trainFormation: clsOrType<TrainFormationMinimized>(
      CemitTypename.trainFormationMinimized,
      trainGroupSingleTrainRunResponse.trainFormation,
    ),
    // TrainRouteOrGroup currently represents the TrainRoute of the only TrainRun.
    // In the future it will be able to represent a TrainRoute or TrainRouteGroup of its own when
    // no TrainRuns are defined to represent all or a subset of TrainRuns that match
    trainRouteOrGroup: clsOrType<TrainRouteOrGroup>(
      CemitTypename.trainRouteOrGroup,
      trainGroupSingleTrainRunResponse.trainRoute,
    ),
    // Loading is complete is we reach this constructor
    // This error/retry would probably only be used in a case where the Api query failed and we use a
    // TrainGroupMinimized as a placeholder
    loadingStatus: clsOrType<CemitApiLoadingStatus>(CemitTypename.cemitApiLoadingStatus, {
      complete: true,
      retry: false,
      error: false,
      errorDate: undefined,
    }),
    equipmentAttributeSensorLoadingStatus: clsOrType<CemitApiLoadingStatus>(
      CemitTypename.cemitApiLoadingStatus,
      {
        // TODO track sensor data loading here
        complete: false,
        retry: false,
        error: false,
        errorDate: undefined,
      },
    ),
    sensorDataPoints: trainGroupSingleTrainRunResponse.sensorDataPoints,
  });
};

/**
 * Creates a TrainGroup from a user and activity and sets
 * activity.isActive and activity.isVisible to true unless overridden
 * @param userState The userState
 * @param trainGroupMinimized
 * @param [activity] Defaults to { isActive: true }
 */
export const completeAndActivateTrainGroup = (
  userState: UserStateLoaded,
  trainGroupMinimized: TrainGroupMinimized,
  activity?: Perhaps<Activity>,
): TrainGroup => {
  return mergeTrainGroup<TrainGroupMinimized, TrainGroup>(trainGroupMinimized, {
    __typename: CemitTypename.trainGroup,
    userState,
    // Default isActive and isVisible to true
    activity: createTrainGroupActivityAsActive(),
  });
};
