import {ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses} from '@mui/material';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps.ts';
import {
  TrainGroupOverviewViewType
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewTableConfig.ts';

const TrainGroupOverviewToggleButton = ({appProps}: TrainAppTrainDependencyProps) => {

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    viewType: TrainGroupOverviewViewType
  ) => {
    appProps.setTrainGroupOverviewViewType(viewType);
  };

  return <ToggleButtonGroup {...{
    size: 'small',
    sx: {
      [`& .${toggleButtonGroupClasses.grouped}`]: {
        border: 0,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
          border: 0
        }
      }
    },
    color: 'primary',
    value: appProps.trainGroupOverviewViewType,
    exclusive: true,
    onChange: handleChange,
    'aria-label': 'Train Group Overview View Type Toggle Button'
  }}>
    <ToggleButton value="overview">{appProps.t('overview')}</ToggleButton>
    <ToggleButton value="criticalAlerts">{appProps.t('criticalAlerts')}</ToggleButton>
  </ToggleButtonGroup>;
};

export default TrainGroupOverviewToggleButton;
