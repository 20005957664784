import {
  chain,
  cond,
  identity,
  length,
  mergeAll,
  omit,
  pick,
  prop,
  T,
  values,
} from 'ramda';
import {
  CemitApi,
  CemitApiRequest,
  CemitApiResponse,
  CemitApiResponseResolverProps,
  CemitApiRoute,
  CemitApiRouteOrganization,
} from 'types/apis/cemitApi';
import {SWRResponse} from 'swr';
import {camelizeDeep, mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {OrganizationMinimized} from 'types/organizations/organization.ts';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {resolveCemitApiRoute} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {FetcherOptions, reviver} from 'pages/trainApp/api/TrainSWRContainer.tsx';
import {tokenFromStorage} from '../authentication/authenticationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {resolveOrganizationManifest} from 'utils/organization/organizationUtils.ts';
import {dumpDateInterval} from 'utils/datetime/dateUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Process the API response
 * @param cemitApiRoute
 * @param apiConfig
 * @param response
 */
export const processApiResponse = <
  ROUTE extends CemitApiRoute<API_REQUEST, API_RESPONSE, T, DATA, PROPS, PARAMS>,
  API_REQUEST extends CemitApiRequest<PROPS, PARAMS> = ROUTE['request'],
  API_RESPONSE extends CemitApiResponse<T, DATA> = Exclude<ROUTE['response'], undefined>,
  T = ROUTE['t'],
  // Optionally the format of the data that comes back from the API. It might be the same as T
  DATA extends Record<string, any> = Exclude<ROUTE['data'], undefined>,
  // The props sent by the caller that get formated to PARAMS
  PROPS extends Record<string, any> = ROUTE['request']['props'],
  // Optionally the format of the parameters given to the request
  PARAMS extends Record<string, any> = ROUTE['request']['params'],
>(
  cemitApiRoute: ROUTE,
  apiConfig: CemitApi | undefined,
  response: SWRResponse,
): SWRResponse<DATA> => {
  // If overrideResponse is specified, skip the resolution and return the static value
  if (cemitApiRoute?.overrideResponse) {
    return {data: cemitApiRoute.overrideResponse};
  } else if (cemitApiRoute?.request?.url && !response.isLoading) {
    // TODO passing response.props to access request props
    const camelized: Partial<CemitApiResponseResolverProps<ROUTE>> = apiConfig!
      .camelizeResponse
      ? camelizeDeep(pick(['data', 'error', 'errorDate'], response))
      : response;

    const resolvedResponseResolver = cond([
      [
        (cemitApiRoute: ROUTE) => {
          // If the request has a path
          return cemitApiRoute.request.path;
        },
        (cemitApiRoute: ROUTE) => {
          // is there a custom responseResolvers
          return (
            cemitApiRoute?.responsePathResolvers?.[cemitApiRoute.request.path] ||
            cemitApiRoute.responseResolver
          );
        },
      ],
      [T, prop('responseResolver')],
    ])(cemitApiRoute);

    const value = resolvedResponseResolver(
      mergeAll([
        camelized,
        // Anything not in camelized goes here
        omit(['data', 'error', 'errorDate'], response),
        {request: cemitApiRoute.request},
      ]) as CemitApiResponseResolverProps<ROUTE>,
    );
    if (value && (value.data || value.error)) {
      // If responseResolver return value already has data or error, use it directly
      // TODO This should never happen
      return mergeRightIfDefined(response, value);
    } else {
      // If the responseResolver didn't return with data, add it to amke the responseResolver more transparent
      return mergeRightIfDefined(response, {data: value});
    }
  } else {
    if (response?.data?.length == 0) {
      throw Error('Not allowed');
    }
    return response;
  }
};

/**
 * Fetcher to fetch within SWRConfig or without. SWRConfig Seems buggy. It doesn't always fetch
 * Returns [{...args, error}] if all retries fail
 * @param abortController
 * @param retries Default 3. The number of retries
 * @param timeout
 * @param args
 * @returns {Promise<string>}
 */
export const fetcher = (
  {
    abortController = new AbortController(),
    retries = 3,
    timeout = undefined,
    requestMethod = 'GET',
  }: FetcherOptions,
  ...args: any
): Promise<Response> => {
  let id: number | undefined = undefined;
  if (timeout) {
    id = setTimeout(() => abortController.abort(), timeout);
  }
  const token = tokenFromStorage();
  const headers = {
    'Content-Type': 'text/plain', // 'application/json',
    JWT_TOKEN: token,
  };
  return (
    requestMethod == 'POST'
      ? fetch(args[0], {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers,
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(args[1]), // body data type must match "Content-Type" header
        })
      : fetch(...args, {
          headers,
          signal: abortController.signal,
        })
  )
    .then((res) => {
      return res.text();
    })
    .then((txt) => {
      return JSON.parse(txt, reviver);
    })
    .catch((error) => {
      if (retries > 0) {
        console.warn(error);
        console.warn(`Retries left ${length(retries)}`);
        return fetcher({abortController, retries: retries - 1, timeout}, ...args);
      } else {
        return {error, errorDate: new Date()};
      }
    })
    .finally(() => {
      if (timeout) {
        clearTimeout(id);
      }
    });
};
/**
 * Resolves data from the api without using useSWR for cases when we have a dynamic number of requests
 * @param loading
 * @param organization
 * @param cemitApiRouteKey
 * @param props
 */
export const cemitApiResolveData = (
  loading: boolean,
  fetcherOptions: FetcherOptions,
  organization: Perhaps<OrganizationMinimized>,
  cemitApiRouteKey: keyof CemitApiRouteOrganization,
  props: any,
  path?: string,
): Promise<any> => {
  const cemitApiRoute: CemitApiRoute = resolveCemitApiRoute(
    loading,
    organization,
    cemitApiRouteKey,
    props,
    path,
  );
  // The request method defaults to 'GET'
  const requestMethod = cemitApiRoute.requestMethod;
  // Get the api in use. The Route only specifies an api if it is delegating to an external api
  const apiConfig: Perhaps<CemitApi> = unlessLoadingValue(loading, () => {
    return cemitApiRoute.api || resolveOrganizationManifest(organization!).apiConfig.api;
  });

  // if (cemitApiRoute.request.url) {
  //   console.debug(`Querying: ${cemitApiRoute.request.url}`);
  // }
  return fetcher(
    ...[
      mergeRightIfDefined(
        fetcherOptions,
        // Explicit 'GET' or 'POST', default 'GET'
        requestMethod ? {requestMethod} : {},
      ),
      // The url must be defined when we call fetcher without useSwr
      cemitApiRoute.request.url,
      // TODO we are still sending post data via the url for now
      // ...ifElse(equals('POST'),
      //   () => {
      //     const rawParams = (cemitApiRoute?.request?.paramsFilter || identity<any>)(cemitApiRoute?.request.props);
      //     // Not ready
      //     if (!rawParams) {
      //       throw new Error('Attempted post with no params')
      //     }
      //     // Slugify if the API expects slugified params
      //     const params = when(() => {
      //       return cemitApiRoute.api.slugifyRequestParams;
      //     }, slugifyDeep)(rawParams);
      //     return [params];
      //   }, () => []
      // )(requestMethod)
    ],
  ).then((response: Response) => {
    if (response) {
      const sensorDataPoints = response[0]?.sensorDataPoints;
      if (sensorDataPoints) {
        const timezoneStr = organization!.timezoneStr;
        const dateInterval = cemitApiRoute.request.props.dateInterval;
        const sensorDataPointsCount = length(chain(identity, values(sensorDataPoints)));
        const trainGroup: TrainGroup = props.sensorDataTrainGroup.trainGroup;
        console.debug(
          `TrainGroup: ${trainGroup.localizedName(timezoneStr)}, date interval: ${dumpDateInterval(dateInterval, timezoneStr)}, Response from: ${cemitApiRoute.request.url} with response sensor data point length ${sensorDataPointsCount}`,
        );
      }
    }
    return processApiResponse(cemitApiRoute, apiConfig, {data: response, props});
  });
};
