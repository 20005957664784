import {
  all,
  always,
  chain,
  equals,
  fromPairs,
  head,
  identity,
  ifElse,
  init,
  is,
  join,
  last,
  length,
  map,
  prop,
  sortBy,
  tail,
  uniqBy,
  unless,
  when,
  zip,
  zipWith,
} from 'ramda';
import {isWithinInterval, parseISO} from 'date-fns';
import {DateIntervalResponseData} from '../../types/dateIntervalResponseData';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {createDateInterval} from '../../classes/typeCrud/dateIntervalCrud.ts';
import {compact, onlyOneValueOrThrow} from '../functional/functionalUtils.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {formatInTimeZone} from 'date-fns-tz';
import {trainDataSimpleDateTimeStringWithTimeZone} from 'utils/datetime/timeUtils.ts';

/**
 * Converts a dates pair to a datetime-fn range
 * @param rangePair pair of dates
 * @returns Interval
 */
export const dateIntervalPairToObj = ({
  rangePair,
}: {
  rangePair: [Date, Date];
}): DateInterval => {
  return fromPairs(zip(['start', 'end'], rangePair));
};
/**
 * Returns true if dateInterval is within otherDateInterval
 * @param otherDateInterval
 * @param dateInterval
 */
export const dateIntervalIsWithinOtherDateInterval = (
  otherDateInterval: DateInterval,
  dateInterval: DateInterval,
) => {
  return all(
    (startOrEnd) => {
      return isWithinInterval(startOrEnd, otherDateInterval);
    },
    [dateInterval.start, dateInterval.end],
  );
};

/**
 * Convert DateIntervalResponses to DateIntervals
 * @param dateIntervalResponses
 */
export const dateIntervalResponsesToDateIntervals = (
  dateIntervalResponses: DateIntervalResponseData[],
): DateInterval[] => {
  return map<DateIntervalResponseData, DateInterval>(
    (dateInterval: DateIntervalResponseData) => {
      return createDateInterval(

        parseISO(dateInterval.datetimeFrom),
        parseISO(dateInterval.datetimeTo),
      );
    },
    dateIntervalResponses,
  );
};

// TODO for some reason trainGroup.sensorDateIntervals become numbers instead of dates
export const dateIntervalsToElapsedTime = (
  dateIntervals: Perhaps<DateInterval[] | {start: number; end: number}[]>,
) => {
  return (
    dateIntervals &&
    map((dateInterval: DateInterval) => {
      return map(
        (date: Date) => {
          return unless(
            is(Number),
            (date: Date) => {
              return date.getTime();
            },
            date,
          );
        },
        [dateInterval.start, dateInterval.end],
      );
    }, dateIntervals)
  );
};

/**
 * Make sure dateIntervals are Dates, not numbers
 * @param dateIntervals
 */
export const dateElapsedTimeToDates = (
  dateIntervals: Perhaps<DateInterval[] | {start: number; end: number}[]>,
): DateInterval[] => {
  return (
    dateIntervals &&
    map((dateInterval: DateInterval) => {
      return map((date: Date) => {
        return when(
          is(Number),
          (date: Date) => {
            return new Date(date);
          },
          date,
        );
      }, dateInterval);
    }, dateIntervals)
  );
};
// TODO something is turning dates into numbers
export const toDateIfNumber = (date: Date | number) => {
  return when(
    is(Number),
    (date: number) => {
      return new Date(date);
    },
    date,
  );
};

/**
 * Returns the union of the two ranges as an array of one or two ranges
 * If the ranges overlap, one is returned. If they don't overlap, two
 * are returned
 * @param range1
 * @param range2
 * @returns {DistanceRange[]}
 */
export const dateIntervalUnion = <T extends DateInterval = DateInterval>(
  range1: DateInterval,
  range2: DateInterval,
): DateInterval[] => {
  const typename: CemitTypename = onlyOneValueOrThrow(
    uniqBy(identity, map(prop('__typename'), [range1, range2])),
  );
  const intersection = dateIntervalIntersection<T>(range1, range2);

  if (
    intersection ||
    equals(range1.end, range2.start) ||
    equals(range2.end, range1.start)
  ) {
    return [
      createDateInterval(
        new Date(Math.min(range1.start, range2.start)),
        new Date(Math.max(range1.end, range2.end)),
      ),
    ];
  } else {
    return sortBy(prop('start'), [range1, range2]);
  }
};

/**
 * Return the DateInterval or subclass T representing the union of the two DateIntervals
 * @param range1
 * @param range2
 */
export const dateIntervalIntersection = <T extends DateInterval = DateInterval>(
  range1: T,
  range2: T,
): Perhaps<T> => {
  const typename: CemitTypename = onlyOneValueOrThrow(
    uniqBy(identity, map(prop('__typename'), [range1, range2])),
  );
  const maxStart = Math.max(...map(prop('start'), [range1, range2]));
  const minEnd = Math.min(...map(prop('end'), [range1, range2]));
  return minEnd > maxStart
    ? createDateInterval(new Date(maxStart), new Date(minEnd))
    : undefined;
};

/**
 * Return the DateIntervals of what is in range but not in ranges or undefined if they don't overlap.
 * Ranges must be in chronological order and already consolidated with consolidateIntervals so that there
 * are no overlaps
 * @param range
 * @param ranges
 */
export const dateIntervalDifferences = <T extends DateInterval>(
  range: T,
  ranges: T[],
): Perhaps<T[]> => {
  const typename: CemitTypename = onlyOneValueOrThrow(
    uniqBy(identity, map(prop('__typename'), [range, ...ranges])),
  );

  const dateIntervalsIntersectingRanges: T[] = compact(
    chain((aRange: DateInterval) => {
      const intersection = dateIntervalIntersection(range, aRange);
      // If the intersection is undefined or matches range, discard it
      return when((intersection: T) => {
        return !intersection || equals(range, intersection);
      }, always(undefined))(intersection);
    }, ranges),
  );
  if (!length(dateIntervalsIntersectingRanges)) {
    // No intersection, return all of range
    return range;
  }
  // Create missing parts of range between the intersections
  const missingDateIntervalsBetween = zipWith((aRange, bRange) => {
    return ifElse(
      ({start, end}) => start < end,
      ({start, end}) => createDateInterval(start, end),
      always(undefined),
    )({start: aRange.end, end: bRange.start});
  })(init(dateIntervalsIntersectingRanges), tail(dateIntervalsIntersectingRanges));

  return compact([
    // Take the portion at the start of range that doesn't intersect anything, if any
    ifElse(
      ({start, end}) => start < end,
      ({start, end}) => createDateInterval(start, end),
      always(undefined),
    )({start: range.start, end: head(dateIntervalsIntersectingRanges).start}),
    // Missing DateIntervals between
    ...missingDateIntervalsBetween,
    // Take the portion at the end of range that doesn't intersect anything, if any
    ifElse(
      ({start, end}) => start < end,
      ({start, end}) => createDateInterval(start, end),
      always(undefined),
    )({start: last(dateIntervalsIntersectingRanges).end, end: range.end}),
  ]);
};

/**
 * Dump the DateInterval to a string for debugging, optionally converted to the given timezone
 * @param dateInterval
 * @param timezoneStr
 */
export const dumpDateInterval = (
  dateInterval: DateInterval,
  timezoneStr?: Perhaps<string>,
): string => {
  const timezone = timezoneStr || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return `start: ${formatInTimeZone(dateInterval.start, timezone, trainDataSimpleDateTimeStringWithTimeZone)} <-> end: ${formatInTimeZone(dateInterval.end, timezone, trainDataSimpleDateTimeStringWithTimeZone)}`;
};

/**
 * Dump the DateIntervals to a string for debugging
 * @param dateIntervals
 * @param timezoneStr
 */
export const dumpDateIntervals = (
  dateIntervals: DateInterval[],
  timezoneStr?: Perhaps<string>,
): string => {
  return join(
    ', ',
    map((dateInterval: DateInterval) => {
      return dumpDateInterval(dateInterval, timezoneStr);
    }, dateIntervals),
  );
};
