/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Sorting options for asset listing
 */
export enum AssetOrderBy {
    NAME = 'name',
    _NAME = '-name',
    MAIN_CATEGORY = 'main_category',
    _MAIN_CATEGORY = '-main_category',
    SUB_CATEGORY = 'sub_category',
    _SUB_CATEGORY = '-sub_category',
    TYPE = 'type',
    _TYPE = '-type',
    CREATED_DATETIME = 'created_datetime',
    _CREATED_DATETIME = '-created_datetime',
    UPDATED_DATETIME = 'updated_datetime',
    _UPDATED_DATETIME = '-updated_datetime',
}
