import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps.ts';
import {map} from 'ramda';
import TableCell from '@mui/material/TableCell';
import {Box, TableSortLabel, Typography} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {visuallyHidden} from '@mui/utils';
import {TableGroupOverviewTableColumn, TrainGroupOverviewColumnAttributes} from './trainGroupOverviewTableConfig';
import {SortDirection} from 'types/sorting/sortDirection';

export interface TableGroupTableHeadProps extends CemitComponentProps {
  columns: TableGroupOverviewTableColumn[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: TrainGroupOverviewColumnAttributes) => void;
  sortDirection: SortDirection;
  orderBy: string;
}

/**
 * The Head cells for the TrainGroupOverviewTable, including sort function
 * @param appProps
 * @param componentProps
 * @constructor
 */
const TrainGroupOverviewTableHead = (
  {
    appProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<TableGroupTableHeadProps>) => {
  const {orderBy, sortDirection} = componentProps;

  const createSortHandler =
    (property: TrainGroupOverviewColumnAttributes) => (event: React.MouseEvent<unknown>) => {
      componentProps.onRequestSort(event, property);
    };

  // The Head table cells, which include a name and optional sort and sortDirection control
  const tableHeadCells: JSX.Element[] = map((column: TableGroupOverviewTableColumn) => {
      const label = column.label ? appProps.t(column.label) : '';
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{minWidth: column.minWidth}}
        >
          {
            // Create a TableSortLabel unless the column is configured with disableSort true
            column.disableSort ?
              <span class="MuiButtonBase-root MuiTableSortLabel-root" >{label}</span> :
            <TableSortLabel
            active={orderBy === column.id}
          direction={orderBy === column.id ? sortDirection : 'asc'}
          onClick={createSortHandler(column.id)}
        >
          {label}
          {orderBy === column.id ? (
            <Box component="span" sx={visuallyHidden}>
              {appProps.t(sortDirection === 'desc' ? 'sortedDescending' : 'sortedAscending')}
            </Box>
          ) : null}
        </TableSortLabel>
    }

    </TableCell>
      );
    }, componentProps.columns
  );

  return <TableHead>
    <TableRow>
      {tableHeadCells}
    </TableRow>
  </TableHead>;
};
export default TrainGroupOverviewTableHead;
