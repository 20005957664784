import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {always, cond, includes, reduce, sortWith, T} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {Ord, Ordering} from 'types-ramda/es/tools';
import {trainGroupLocalizedNameFunc} from 'appUtils/trainAppUtils/trainGroupSorting/trainGroupSorting.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {AlertGaugeValue} from 'types/alerts/alertGaugeValue.ts';

/**
 * Gives a severity score by adding up the values of each AlertGaugeValue of AlertBaseGauge, where
 * the AlertGaugeValue.count is multiplied by the severity normal = 0, warning = 10, critical = 100
 * @param alertGaugeByTimePeriod
 */
export const alertSeverityScore = (alertGaugeByTimePeriod: AlertGaugeByTimePeriod): number => {
  if (!alertGaugeByTimePeriod) {
    // Return a 0 result while loading
    return 0;
  }

  // We always use today for now, since the user can't pick an interval more than 24 hours
  // In the future this should match the chosen interval
  const alertGauge: AlertBaseGauge = alertGaugeByTimePeriod.today
  const alertTypeConfig: AlertTypeConfig = alertGauge.alertTypeConfig!;
  const {warningLevels, criticalLevels} = alertTypeConfig;
  const getMultiplier = (key: string) => {
    return cond([
      [(key: string) => includes(key, criticalLevels), always(100)],
      [(key: string) => includes(key, warningLevels), always(10)],
      [T, always(0)]
    ])(key);
  };

  const score: number = reduce(
    (accum: number, attributeAlertLevelConfig: AttributeAlertLevelConfig)   => {
      const multiplier = getMultiplier(attributeAlertLevelConfig.attributeAlertLevel);
      return accum + (alertGauge[attributeAlertLevelConfig.attributeAlertLevel as keyof AlertBaseGauge] as AlertGaugeValue).count * multiplier
    },
    0,
    alertTypeConfig.attributeAlertLevelConfigs
  )
  return score
};

/**
 * Sort by the Alert severity of
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param sortDirectionFunc ascend or descend function
 */
export const sortTrainGroupsByAlertSeverity = (
  {appProps, organizationProps, trainProps}: TrainAppTrainDependencyProps,
  sortDirectionFunc: (fn: (obj: TrainGroup) => Ord) => (a: TrainGroup, b: TrainGroup) => Ordering
) => {
  const trainGroups = resolveActiveTrainGroupCrudList(trainProps).list;

  const activeAlertTypeKey = trainProps?.alertConfigProps?.alertTypeConfig?.alertTypeKey;
  // Sort by alertSeverityScore, and then by train name if the former gives the same score to multiple trains
  return sortWith([
      // Sort ascending or descending
      sortDirectionFunc(
        (trainGroup: TrainGroup): number => {

          // If the TrainGroup alertScopeSummaryProps are still loading, give it a score of 0
          // When it loads it will get sorted
          const alertScopeSummaryPropsLoading: boolean = trainGroup.alertScopeSummaryProps?.alertTrainGroupProps?.alertGraphqlStatus !== LoadingStatusEnum.complete
          if (alertScopeSummaryPropsLoading) {
            return 0
          }

          // Get all summaries, then find the one matching the active AlertType
          const alertGaugesByTimePeriod = trainGroup.alertScopeSummaryProps!.alertTrainGroupProps!.alertGaugesByTimePeriod!;
          const alertGaugeByTimePeriod: AlertGaugeByTimePeriod = findOrThrow(
            (alertGaugeByTimePeriod: AlertGaugeByTimePeriod) => {
              // hacky, alertTypeConfig is not stored on alertGaugeByTimePeriod so access on a child property
              return alertGaugeByTimePeriod.today.alertTypeConfig?.alertTypeKey == activeAlertTypeKey;
            },
            alertGaugesByTimePeriod
          );

          // Get the score of the alertGaugeByTimePeriod to sort by
          return alertSeverityScore(alertGaugeByTimePeriod)
        }
      ),
      sortDirectionFunc(trainGroupLocalizedNameFunc(organizationProps.organization?.timezoneStr))
    ],
    trainGroups
  );
};
