import {Stack, Typography} from '@mui/material';

import {useTranslation} from 'react-i18next';
import TrainGroupChooserList from './TrainGroupChooserList.tsx';
import {toggleTrainGroupVisibility} from 'classes/typeCrud/trainGroupCrud.ts';
import React from 'react';
import {Size} from '../../../../../types/layout/size';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * The collection of TrainGroups, meaning TrainRuns chosen by the user at for certain distance
 * intervals for comparison. The same run may appear multiple times for different intervals and different times/dates
 * @prop {Boolean } loading,
 * @prop {Object} sx
 * @prop {Object} trainProps
 * @prap {Boolean} [displaySavedRuns] Default true. Set false to hide saved runs
 * @prop {Object} trainRoute The current TrainRoute
 * @pram {Object} organization
 * @returns {undefined}
 * @constructor
 */
const TrainGroupsActiveList = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {crudSelectedTrainGroups, trainDetailSize},
}: TrainAppTrainDependencyProps & {
  componentProps: {
    crudSelectedTrainGroups: TrainGroup[];
    trainDetailSize: Size;
  };
}) => {
  const {t} = useTranslation();
  const listTitle = t('runsMatchingFilter');

  return (
    <Stack
      spacing={0.5}
      sx={{
        height: '100%',
        maxHeight: '100%',
      }}
    >
      {/* @ts-ignore */}
      <Typography
        key="selectedRuns"
        {...{
          variant: 'subtitle2',
          sx: {color: 'secondary.main'},
        }}
      >
        {listTitle}
      </Typography>
      <TrainGroupChooserList
        {...{
          appProps,
          organizationProps,
          trainProps: {
            ...trainProps,
            trainGroupSingleTrainRunProps: {
              ...trainProps.trainGroupSingleTrainRunProps,
              crudTrainGroups: crudSelectedTrainGroups,
            },
          },
          componentProps: {
            isSelectedTrainRuns: true,
            toggleTrainGroupVisibility: (trainGroup: TrainGroup) => {
              toggleTrainGroupVisibility(crudSelectedTrainGroups, trainGroup);
            },
            trainDetailSize,
          },
        }}
      />
    </Stack>
  );
};

export default TrainGroupsActiveList;
