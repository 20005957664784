import {tableCellClasses} from '@mui/material';
import {TableCell, styled} from '@mui/material';

// TODO replace with TableCell's sx prop
export const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.surfaceContrast,
    color: theme.palette.common.white,
    padding: '0',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.background.surfaceTertiary,
    padding: '0',
  },
}));
