/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OTPData } from '../models/OTPData';
import type { TwoFactorAuthReponse } from '../models/TwoFactorAuthReponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TwoFactorAuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Handle Two Factor Authentication
     * Handle two factor authentication
     * @param requestBody
     * @returns TwoFactorAuthReponse Successful Response
     * @throws ApiError
     */
    public handleTwoFactorAuthentication2FaCheckPost(
        requestBody?: (OTPData | null),
    ): CancelablePromise<TwoFactorAuthReponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/2fa/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
