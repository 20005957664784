/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Defines the various types of device gateways
 */
export enum DeviceGatewayTypeEnum {
    UNSPECIFIED = 'unspecified',
    REVERSE_SSH = 'reverse_ssh',
}
