import React from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import SandboxTrainListContainer from 'pages/sandbox/SandboxTrainListContainer.tsx';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

const SandboxAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* The rest of your application */}
      {/*<ReactQueryDevtools initialIsOpen={false}>*/}
      <SandboxTrainListContainer {...{appProps, organizationProps}} />
      {/*</ReactQueryDevtools>*/}
    </QueryClientProvider>
  );
};
export default SandboxAppContainer;
