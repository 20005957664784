export const TrackStationNames = {
  "7801":	"Kalles väg",
  "7802":	"Norra Kustbanegatan",
  "7804":	"Engelbrektsgatan",
  "7805":	"Dag Hammarskjöldsleden",
  "7806":	"Guldhedsgatan",
  "7807":	"Tullgången Chalmerstunneln",
  "7808":	"Ebbe Lieberathsgatan",
  "7809":	"Göteborgsvägen",
  "7810":	"Hjalmar Brantingsgatan",
  "7811":	"Rosengatan",
  "7812":	"Torgny Segerstedtsgatan",
  "7813":	"Getebergsäng/Buråsliden",
  "7814":	"Kungssten",
  "7815":	"Birkagatan",
  "7816":	"Barlastgatan",
  "7817":	"Fabriksgatan",
  "7818":	"Delsjövägen",
  "7819":	"Balsamingatan",
  "7820":	"Minutgatan",
  "7821":	"Birger Jarlsgatan",
  "7822":	"Vårväderstorget",
  "7823":	"Regementsvägen",
  "7824":	"Gröna gatan",
  "7825":	"Önskevädersgatan",
  "7826":	"Örebrogatan",
  "7827":	"Högsbogatan",
  "7828":	"Orgelgatan",
  "7829":	"Väderilsgatan",
  "7830":	"Östra Larmgatan",
  "7831":	"Lundbybadet",
  "7833":	"Kvadrantgatan",
  "7834":	"Billerudsgatan",
  "7835":	"Holtermansgatan",
  "7836":	"Vasagatan",
  "7837":	"Tandkullegatan",
  "7838":	"Odinsplatsen",
  "7840":	"Olskroksgatan",
  "7841":	"Skånegatan",
  "7843":	"Hjällbovägen",
  "7844":	"Hammarkullen",
  "7846":	"Aschebergsgatan (Molinsgatan)",
  "7847":	"Wavrinskys plats",
  "7848":	"Ullevigatan",
  "7850":	"Mölndals Bro",
  "7851":	"Järntorget",
  "7852":	"Grönsakstorget",
  "7853":	"Landsvägsgatan",
  "7854":	"Nymilsgatan/Flatåsskolan",
  "7855":	"Briljantgatan",
  "7856":	"Ekedalsgatan",
  "7858":	"Härlandavägen",
  "7860":	"Margretebergsgatan",
  "7861":	"Brunnsgatan",
  "7862":	"Älvsborgsgatan",
  "7863":	"Olskrokstorget",
  "7864":	"Aniaragatan",
  "7865":	"Artillerigatan",
  "7866":	"Gamlestadsvägen",
  "7867":	"Aprilgatan",
  "7868":	"Svanebäcksgatan",
  "7869":	"Packhusplatsen",
  "7870":	"Lafingatan",
  "7871":	"Tideräkningsgatan",
  "7872":	"Angered Storåsväg",
  "7873":	"Grepgatan",
  "7874":	"Varmfrontsgatan",
  "7875":	"Östra Torpavägen",
  "7879":	"Wieselgrensplatsen",
  "7883":	"Åkareplatsen",
  "7884":	"Frihamnen",
  "7885":	"Nils Ericssonsplatsen",
}
