import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TagData } from '../types';
import { throwUnlessDefined } from 'utils/functional/functionalUtils.ts';

// Debounce hook to prevent rapid successive API calls
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Cleanup the timeout on value change or unmount
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

// Function to fetch tag data from the API with multiple IDs
const fetchDataStream = async (referenceIds: string[]): Promise<TagData[]> => {
  const REACT_APP_DETECTOR_API_URL = throwUnlessDefined(process.env.REACT_APP_DETECTOR_API_URL);
  
  // Construct the URL with multiple reference_ids
  const queryString = referenceIds
    .map((id) => `reference_ids=${encodeURIComponent(id)}`)
    .join('&');
  const detectorApiUrl = `${REACT_APP_DETECTOR_API_URL}?${queryString}&orderBy=-detection-time&page=1&pageSize=10`;

  const response = await fetch(detectorApiUrl, {
    method: 'GET',
    headers: {
      accept: 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await response.json();

  // Ensure the result is an array. If not, adjust the structure.
  if (Array.isArray(result)) {
    return result;
  } else if (result && Array.isArray(result.data)) {
    // Adjust this based on the actual structure of your API response.
    return result.data;
  } else {
    // If the response does not contain an array, throw an error or return an empty array.
    throw new Error('Unexpected API response structure');
  }
};

// Custom hook to fetch tag data using React Query v5
const useFetchTags = (datastreamIds: string[] = []) => {
  const [errors, setErrors] = useState<string[]>([]);
  const debouncedDatastreamIds = useDebounce(datastreamIds, 200);

  const { data: tagsData, isLoading } = useQuery({
    queryKey: ['fetchTags', debouncedDatastreamIds],
    queryFn: () => fetchDataStream(debouncedDatastreamIds),
 
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  });

  const safeTagsData = Array.isArray(tagsData) ? tagsData : [];

  return {
    data: safeTagsData,
    isLoading,
    errors,
  };
};

export default useFetchTags;
