import {Layer, Source} from 'react-map-gl';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {MapDialog} from 'pages/trafficsim/trafficSimComponents/dialog/mapDialog.tsx';
import CustomPopup from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomPopup.tsx';
import {Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import {MapComponentActionType} from 'pages/trafficsim/trafficSimComponents/track/v2/TrafficSimMap.tsx';
import {InfoText} from '../../../utils/info_texts.ts';
import {ACCESS_LEVELS} from '../../../utils/access_levels.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserStateLoaded} from 'types/userState/userState';

export interface IFeedPoint {
  id: string;
  internal_id: string;
  isOpen: boolean;
  sections: string | null;
  stations: string | null;
  isParentStationOpen: boolean | null;
  isSpare: boolean | null;
  isActive: boolean | null;
  comments: string | null;
  props: IFeedPointExtraData;
}

interface IFeedPointExtraData {
  drift: string | null;
  placering: string | null;
  lsStation: string | null;
  section: string | null;
  sakerhetsSektioner: string | null;
  Andrad_av: number | null;
  Andrad_den: string | null;
  AnlAr: number | null;
  Enkel: string | null;
  FACILITYID: number | null;
  ID: string | null;
  Lankar: string | null;
  Likriktare: string | null;
  Mast_ID: string | null;
  Minusskap_: string | null;
  MP_skap: number | null;
  Namn: number | null;
  Para_ID: string | null;
  Plats: number | null;
  Reserv: number | null;
  Skapad_av: number | null;
  Skapad_den: string | null;
  Status: number | null;
  Stracka: string | null;
  StrNr: string | null;
  Tillv: number | null;
  Warrantyda: string | null;
  Direktmatn: number | null;
  Spiralkabe: number | null;
  Knivtyp: number | null;
  SepMinusSk: number | null;
  namn: string | null;
  coordinates: Array<number>;
}

export interface FeedPointProps {
  mapRef: any;
  feedPoint: IFeedPoint;
  station: any;
  section: any;
  feedPointsInCommonSection: IFeedPoint[];
  onUpdateFeedPoint: (fp: IFeedPoint, action: MapComponentActionType) => void;
}

export function FeedPoint({
  mapRef,
  feedPoint,
  station,
  section,
  feedPointsInCommonSection,
  onUpdateFeedPoint,
}: FeedPointProps) {
  let {props, isOpen} = feedPoint;
  const [showPopUp, setPopupState] = useState(false);
  const [{showInfoDialogStatus, msg}, setInfoDialog] = useState({
    showInfoDialogStatus: false,
    msg: '',
  });

  const coordinates = props.coordinates;
  const layerId = `feedpoint-layer-${feedPoint.internal_id}`;
  const theme = useTheme();
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const isStationOpen = station !== undefined ? station.isOpen : true;
  const isStationClosedBySectionState =
    station === undefined || station.isConnectedSectionOpen === undefined
      ? false
      : !station.isConnectedSectionOpen;
  const isSectionOpen = section !== undefined ? section.isOpen : true;
  const isInSafeTrackMode = section !== undefined ? section.isInSafeTrackMode : false;
  const isFeedPointOpen = isOpen;
  const isFeedPointSpare = feedPoint.isSpare !== undefined ? feedPoint.isSpare : false;

  const listOfDeactivatedFeedPoints = feedPointsInCommonSection.filter(
    (fp) => !fp.isSpare && fp.isActive !== true,
  );
  const deactivatedFeedPointCount =
    !feedPoint.isSpare && !feedPoint.isActive
      ? listOfDeactivatedFeedPoints.length + 1
      : listOfDeactivatedFeedPoints.length;
  const listOfSpareFeedPoints = feedPointsInCommonSection.filter(
    (fp) => fp.isSpare === true,
  );

  // Assumes that only one spare can exist in a section
  const normalFeedPointCount = feedPointsInCommonSection.length;
  let firstSpareFeedPoint =
    listOfSpareFeedPoints.length > 0 ? listOfSpareFeedPoints[0] : undefined;
  firstSpareFeedPoint =
    firstSpareFeedPoint === undefined && isFeedPointSpare
      ? feedPoint
      : firstSpareFeedPoint;

  const canBeActivated = isFeedPointSpare
    ? deactivatedFeedPointCount > 0
    : firstSpareFeedPoint === undefined
      ? false
      : !firstSpareFeedPoint.isActive;
  const containsSpareInSection = isFeedPointSpare || listOfSpareFeedPoints.length > 0;

  useEffect(() => {
    if (!mapRef) {
      return undefined;
    }
    const onClickLayer = (e) => {
      e.originalEvent.stopPropagation();
      if (e.features.length > 0) {
        const clickedId = e.features[0].properties.id;
        updateFeedPointMode(e);
      }
    };
    if (
      userState.access.trafficSimAccessLevel &&
      userState.access.trafficSimAccessLevel !== ACCESS_LEVELS.READ_ONLY
    ) {
      mapRef.current.on('click', layerId, onClickLayer);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', layerId, onClickLayer);
      }
    };
  }, [listOfDeactivatedFeedPoints]);

  const feedPointStatus =
    isStationClosedBySectionState ||
    !isSectionOpen ||
    isInSafeTrackMode ||
    !isStationOpen ||
    !isFeedPointOpen;
  let _color = feedPointStatus ? theme.palette.map.red : theme.palette.map.green;
  const color = isFeedPointSpare && !feedPoint.isActive ? theme.palette.map.gold : _color;
  const feedPointLabel = `Matarpunkt: ${feedPoint.namn}`;

  let prepareGeoJson = (id, feedPoint, color, isFeedPointClosed) => {
    const _regular_icon = isFeedPointClosed
      ? 'feed-point-icon-red'
      : 'feed-point-icon-green';
    const _reserve_icon = !feedPoint.isActive
      ? 'feed-point-icon-gold'
      : isFeedPointClosed
        ? 'active-reserve-feed-point-icon-red'
        : 'active-reserve-feed-point-icon-green';
    const _icon = isFeedPointSpare ? _reserve_icon : _regular_icon;
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            id: id,
            color: color,
            name: feedPoint.internal_id,
            icon: _icon,
          },
          geometry: {
            type: 'Point',
            coordinates: feedPoint.props.coordinates,
          },
        },
      ],
    };
  };
  const updateFeedPointMode = (e) => {
    e.originalEvent.stopPropagation();
    const mapZoomLevel = mapRef.current.getZoom();
    const isMapZoomedIn = mapZoomLevel >= 16;

    if (!isMapZoomedIn) {
      mapRef.current.flyTo({
        center: coordinates,
        zoom: 16,
        essential: true,
      });
      return;
    }

    setPopupState(true);

    mapRef.current.flyTo({
      center: coordinates,
      zoom: mapZoomLevel,
      essential: true,
    });
  };

  const onClose = () => {
    setPopupState(false);
  };

  const onSave = (notes: string, voltageStatus: boolean, isActive: boolean) => {
    const _update = (_deactivate: boolean | null) => {
      const updatedData = {
        ...feedPoint,
        isActive: _deactivate,
        comments: notes,
      };

      const actionType = voltageStatus
        ? MapComponentActionType.OPEN
        : MapComponentActionType.CLOSE;
      onUpdateFeedPoint(updatedData, actionType);
    };

    if (!containsSpareInSection) {
      _update(true);
      onClose();
      return;
    }

    // Closing a feed point that is a spare. This assumes that there is only one spare in a section
    if (isFeedPointSpare) {
      if (
        !isActive &&
        deactivatedFeedPointCount > 0 &&
        deactivatedFeedPointCount < normalFeedPointCount
      ) {
        _update(isActive);
        onClose();
        return;
      }

      if (isActive && deactivatedFeedPointCount > 0) {
        _update(isActive);
        onClose();
        return;
      }
      onClose();
      setInfoDialog({
        showInfoDialogStatus: true,
        msg: InfoText.RESERVE_FEED_POINT_ERROR,
      });
      return;
    }

    // Closing a feed point that is not a spare
    if (!feedPoint.isSpare && firstSpareFeedPoint !== undefined) {
      if (
        voltageStatus &&
        firstSpareFeedPoint.isActive &&
        !feedPoint.isActive &&
        deactivatedFeedPointCount <= 1
      ) {
        onClose();
        setInfoDialog({
          showInfoDialogStatus: true,
          msg: InfoText.LAST_REGULAR_FEED_POINT_ERROR,
        });
        return;
      }

      // Check if spare is active first before doing anything
      if (firstSpareFeedPoint.isActive && !feedPoint.isActive) {
        if (
          voltageStatus &&
          deactivatedFeedPointCount > 1 &&
          deactivatedFeedPointCount <= normalFeedPointCount
        ) {
          _update(voltageStatus);
          onClose();
          return;
        }
        onClose();
        setInfoDialog({
          showInfoDialogStatus: true,
          msg: InfoText.LAST_REGULAR_FEED_POINT_ERROR,
        });
        return;
      }

      if (!voltageStatus) {
        _update(voltageStatus);
        onClose();
        return;
      }

      const active = firstSpareFeedPoint.isActive ? feedPoint.isActive : voltageStatus;
      _update(active);
      onClose();
      return;
    }
    onClose();
    setInfoDialog({
      showInfoDialogStatus: true,
      msg: InfoText.DEACTIVATE_RESERVE_FEED_POINT_ERROR,
    });
  };

  const geoJSON = prepareGeoJson(
    feedPoint.internal_id,
    feedPoint,
    color,
    feedPointStatus,
  );
  const feedPointSourceId = `feedpoint-${feedPoint.internal_id}`;

  return (
    <>
      <Source id={feedPointSourceId} type="geojson" data={geoJSON}>
        <Layer
          id={layerId}
          type="symbol"
          source={feedPointLabel}
          layout={{
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.3,
              14,
              0.25,
              14.1,
              0.25,
              15,
              0.25,
              18,
              0.5,
              20,
              0.69,
            ],
            'text-field': '{name}',
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              5,
              14,
              ['*', 2, 3],
              17,
              ['*', 3, 4],
              22,
              ['*', 5, 5],
            ],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Regular'],
            'text-offset': [0, 0],
            'text-allow-overlap': true,
            'text-anchor': 'center',
            'text-justify': 'center',
            'text-rotation-alignment': 'auto',
          }}
          paint={{
            'text-color': '#ffffff',
          }}
        />
      </Source>

      {showInfoDialogStatus && coordinates && (
        <CustomPopup
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          anchor="bottom"
          onClose={() =>
            setInfoDialog({
              showInfoDialogStatus: false,
              msg: '',
            })
          }
        >
          <Typography variant="body2" style={{color: theme.palette.text.primary}}>
            {msg}
          </Typography>
        </CustomPopup>
      )}

      {showPopUp && coordinates && (
        <CustomPopup
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          anchor="bottom"
          collapse={false}
          onClose={() => onClose()}
        >
          <MapDialog
            type="feedPoint"
            label={feedPointLabel}
            sectionStatus={isFeedPointOpen}
            data={feedPoint}
            onSave={onSave}
            canBeActivated={canBeActivated}
            containsSpareInSection={containsSpareInSection}
            onCancel={onClose}
          />
        </CustomPopup>
      )}
    </>
  );
}
