import {LocalStorage} from 'types/cache/localStorage';
import {getISOWeek, getYear} from 'date-fns';
import {forEach, values} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';

// TODO should work with typescript and process.env namespace
const featureSentry = FEATURE_SENTRY;
// @ts-ignore
const featureServiceWorker = FEATURE_SERVICEWORKER;
// @ts-ignore
const appEnv = APP_ENV;
// @ts-ignore
const appRelease = APP_RELEASE;
// @ts-ignore
const clientSourceKey = CLIENT_SOURCE_KEY;
const date = new Date();
// In dev, expire the cache every week. In prod, key by appRelease
const localCacheSuffix = `${getYear(date)}-${getISOWeek(date)}`;
const localStorageVisualizer = 'visualizer';
const localStorageSuffix = `${localStorageVisualizer}-${appRelease != 'dev' ? appRelease : localCacheSuffix}`;
// When the first date of available sensor data is unknown for an organization, use this as a stopgap.
const defaultEarliestDate = '2024-01-01';

export class AppSettings {
  static readonly featureSentry = featureSentry;
  static readonly featureServiceWorker = featureServiceWorker;
  static readonly appEnv = appEnv;
  static readonly appRelease = appRelease;

  /** Scheme + host name and port used for accessing application, e.g. https://app.cemit.digital or http://localhost:8080 */
  static get urlPrefix(): string {
    return window.location.protocol + '//' + window.location.host;
  }

  /** Main landing page to show after a user logs in (via magic link) */
  static readonly landingPage = '/home';
  static readonly graphqlEndpoint = `https://staging-api.dimensionfour.io/cemit/graph`;
  static readonly subGraphqlEndpoint = `wss://staging-api.dimensionfour.io/subscription`;
  static readonly appsPath = 'dashboard';
  // Used to correct y-acceleration by accounting for gravity
  static readonly gravityAcceleration = -9.80665;
  // Minimum number of meters for a TrainRun Interval so that we are sure to have at least 2 points with the 100m view
  static readonly trainRunIntervalMinimum = 300;
  // TODO TrainPage is hardcoded until we reform authenticate
  static readonly tabAccess = ['train'];

  static readonly localStorageVisualizer = localStorageVisualizer;
  static readonly localStorage: LocalStorage = {

    // These attributes aren't tied to an application version because they are unlikely to ever change
    accelerationType: 'accelerationType',
    isLifetimeSelected: 'isLifetimeSelected',
    realtimeIsActive: 'realtimeIsActive',
    visibleAttributeAlertLevels: 'visibleAttributeAlertLevels',
    // The TrainGroupOverview column to sort by and the sort direction
    trainGroupOverviewSortDirection: 'trainGroupOverviewSortDirection',
    trainGroupOverviewOrderBy: 'trainGroupOverviewOrderBy',
    trainGroupOverviewViewType: 'trainGroupOverviewViewType',

    // These attributes use localStorageSuffix to make sure that they get cleared in the cache each time a new version
    // of the application is released.

    alertTypeConfig: `alertTypeConfig-${localStorageSuffix}`,
    theme: `theme-${localStorageSuffix}`,
    // Version the local storage since ids might change between releases for now
    trainRoute: `trainRoute-${localStorageSuffix}`,
    trainRouteGroup: `trainRouteGroup-${localStorageSuffix}`,
    trainGroups: `trainGroups-${localStorageSuffix}`,
    trainGroupOnlyTrainFormations: `trainGroupOnlyTrainFormations-${localStorageSuffix}`,

    trainGroupFilterTrainFormationDateInterval: `trainGroupsFilterTrainFormationDateInterval-${localStorageSuffix}`,
    trainGroupFilterTrainFormation: `trainGroupsFilterTrainFormation-${localStorageSuffix}`,
    trainGroupFilterTrainRouteDateInterval: `trainGroupsFilterTrainRouteDateInterval-${localStorageSuffix}`,
    trainGroupFilterTrainRouteDateRecurrence: `trainGroupsFilterTrainRouteDateRecurrence-${localStorageSuffix}`,
    cemitFilterAttributeAlerts: `localStorageCemitFilterAttributeAlerts-${localStorageSuffix}`,
    cemitFilterTrainRouteGroups: `localStorageCemitFilterTrainRouteGroups-${localStorageSuffix}`,

    lifetimeInputs: `lifetimeInputs-${localStorageSuffix}`,
    activeViews: `activeViews-${localStorageSuffix}`,
    dateIntervalDescription: `rideComfortIntervalDescription-${localStorageSuffix}`,
    userState: `userState-${localStorageSuffix}`,
    trainGroupSortTypeName: `trainGroupSortTypeName-${localStorageSuffix}`,
    trainGroupSortDirection: `trainGroupSortDirection-${localStorageSuffix}`,
    trainGroupAllExpanded: `trainGroupAllExpanded-${localStorageSuffix}`,
  } as LocalStorage;

  // Current limit for number of TrainRuns to download at once. TODO replace with paging
  static readonly maxDays = 3;
  // The Mapbox style as created in Mapbox studio
  static readonly mapboxStyle = process.env.REACT_MAPBOX_STYLE;
  static readonly mapboxStyleDark = process.env.REACT_MAPBOX_STYLE_DARK;
  static readonly mapboxStyleLight = process.env.REACT_MAPBOX_STYLE_LIGHT;
  // Debugging tool to show how the Dependency or other Containers/Components are changing
  static readonly debugWithUseWhatChanged = process.env.DEBUG_WITH_USED_WHAT_CHANGED;
  static readonly mapboxDivId = 'train-map';
  static readonly isLocalEnvironment = appEnv === 'local';
  static readonly clientSourceKey = clientSourceKey;
  static readonly earliestDate = defaultEarliestDate;
}

export const resolveTrafficSimMapStyleUrl = (themeName: string): string => {
  const mapboxStyleUrl: Perhaps<string> =
    themeName === 'light' ? AppSettings.mapboxStyleLight : AppSettings.mapboxStyleDark;
  if (!mapboxStyleUrl) {
    throw new Error(
      'Configuration error. Check REACT_MAPBOX_STYLE_LIGHT and REACT_MAPBOX_STYLE_DARK',
    );
  }
  return mapboxStyleUrl;
};

/**
 * Clear the cache without logging out
 */
export const clearOrganizationLocalStorage = () => {
  forEach((value: string) => {
    localStorage.removeItem(value);
  }, values(AppSettings.localStorage));
};
