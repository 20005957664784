import React, {memo, useMemo} from 'react';
import TrainGroupListContainer
  from '../../../components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupListContainer.tsx';
import SplitPaneWrapper from './SplitPaneWrapper.tsx';
import {Size} from 'types/layout/size';
import {Box, Stack} from '@mui/material';
import {SplitPaneProps} from 'react-split-pane';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {needToShowTrainGroupOverview} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainGroupsScopeProps} from 'types/trainGroups/trainGroupScopeProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {resolveActiveTrainGroups} from 'appUtils/trainAppUtils/scope/activeTrainGroup.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import TrainGroupRunningCharacteristicsContainer
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainRunningCharacteristicsComponents/TrainGroupRunningCharacteristicsContainer.tsx';
import {
  TrainAppMapComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import TrainGroupOverviewTableContainer
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewTableContainer.tsx';

interface TrainPageListAndFormationProps extends CemitComponentProps {
  handleDrawerOpen: Function;
  handleDrawerClose: Function;
  trainDetailSize: Size;
  onChangeTrainFormationSize: () => void;
  // The minimum width of the TrainList when the TrainFormation is showing
  minimumTrainListLeftWidth: string | number;
}

/**
 * A horizontally separated TrainGroupListContainer and TrainFormation
 * The TrainGroupListContainer can be minimized to the left to show the TrainFormation on the right
 * and the parent component will show a train detail page vertically below when the TrainGroupListContainer
 * is minimized
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainGroupListAndMaybeDetailBoards = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    componentProps
  }: TrainAppMapComponentDependencyProps<TrainPageListAndFormationProps>) => {
  // List of TrainGroups that match the trainGroupFilter
  const trainGroupListContainer: JSX.Element = (
    <TrainGroupListContainer
      key="trainList"
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          handleDrawerOpen: componentProps.handleDrawerOpen,
          handleDrawerClose: componentProps.handleDrawerClose,
          trainDetailSize: componentProps.trainDetailSize
        }
      }}
    />
  );

  // Show the TrainGroup overview if there are no active TrainGroups or
  // the FrontendView.forceTrainGroupOverview has been explicitly requested by the user
  const showTrainGroupOverview =  needToShowTrainGroupOverview({appProps})


  // The active TrainGroupSingleTrainRuns or TrainGroupOnlyTrainFormations
  const activeTrainGroups: Perhaps<TrainGroup[]> = useMemo(() => {
    return resolveActiveTrainGroups(trainProps);
  }, [
    trainProps.trainGroupOnlyTrainFormationProps?.activeTrainGroupFormations,
    trainProps.trainGroupActivityProps?.activeTrainGroups
  ]);

  const trainGroupScopeProps = useMemoClsOrType<TrainGroupsScopeProps>(
    CemitTypename.trainGroupScopeProps,
    {
      scopedTrainGroup: activeTrainGroups
    }
  );
  // Detail view of the active TrainGroup(s)
  const maybeTrainGroupRunningCharacteristicsContainer: Perhaps<JSX.Element> = (
    <Box
      key="trainGroupRunningCharacteristicsContainerBox"
      {...{
        sx: {
          minWidth: 0,
          minHeight: 0,
          flex: 5,
          overflow: 'hidden',
          position: 'relative',
          width: '100%'
        }
      }}
    >
      <Stack
        {...{
          sx: {
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            height: '100%'
          }
        }}
      >
        {!showTrainGroupOverview ? (
          <TrainGroupRunningCharacteristicsContainer
            key="TrainRunningCharacteristicsContainer"
            {...{
              appProps,
              organizationProps,
              trainProps,
              mapProps,
              componentProps: trainGroupScopeProps
            }}
          />
        ) : undefined}

      </Stack>
    </Box>
  );

  // List of trains and the details of the selected train(s)
  const trainOverviewOrTrainListAndMaybeFormation =
    trainProps.trainFormationDateProps.loading ? <LoaderWithText {...{text:''}} /> : (!showTrainGroupOverview ? (
      <SplitPaneWrapper
        {...({
          split: 'vertical',
          defaultSize: componentProps.minimumTrainListLeftWidth,
          onChange: componentProps.onChangeTrainFormationSize,
          minSize: componentProps.minimumTrainListLeftWidth
        } as SplitPaneProps)}
      >
        {trainGroupListContainer}
        {maybeTrainGroupRunningCharacteristicsContainer}
      </SplitPaneWrapper>) :
    (<Box
      {...{
        sx: {width: '100%', height: '100%', minWidth: '100%', minHeight: '100%'}
      }}
    >
      <TrainGroupOverviewTableContainer {...{
        // The container must also check that all the TrainGroup summary alert props are loaded.
        loading: trainProps.alertProps.loading,
        appProps, organizationProps, trainProps
      }} />
    </Box>));

  return (
    <Stack
      key="trainListAndFormation"
      {...{
        spacing: 2,
        sx: {
          minWidth: 0,
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          position: 'relative'
        },
        direction: 'row'
      }}
    >
      {trainOverviewOrTrainListAndMaybeFormation}
    </Stack>
  );
};
TrainGroupListAndMaybeDetailBoards.displayName = 'TrainGroupListAndMaybeDetailBoards';
export default memo(TrainGroupListAndMaybeDetailBoards);
