import {Box, Stack, Typography} from '@mui/material';
import CollectionDevice from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/CollectionDevice.tsx';
import {map} from 'ramda';
import {useTranslation} from 'react-i18next';
import React from 'react';

import {TrainGroup} from 'types/trainGroups/trainGroup';

export const TrainRunEquipment = ({
  showTitle = true,
  trainGroup,
}: {
  showTitle: boolean;
  trainGroup: TrainGroup;
}): JSX.Element => {
  const {t} = useTranslation();
  const collectionDeviceComponents: JSX.Element[] = map(({collectionDevice}) => {
    return (
      <CollectionDevice key={collectionDevice.name} collectionDevice={collectionDevice} />
    );
  }, trainGroup.trainGroupCollectionDevices);
  return (
    <Stack {...{direction: 'row', spacing: 2}}>
      {showTitle && (
        <Box sx={{width: '40px'}}>
          <Typography key="equipmentTitle" variant="caption" sx={{color: 'grey.400'}}>
            {t('equipment')}
          </Typography>
        </Box>
      )}
      <Stack key="cdcs" direction="row" spacing={1} sx={{color: 'secondary.main'}}>
        {collectionDeviceComponents}
      </Stack>
    </Stack>
  );
};
