import {addIndex, is, map, when} from 'ramda';
import {Stack} from '@mui/material';
import React, {forwardRef, ReactElement, RefObject, useRef} from 'react';
import ConfiguredComposedChartContainer from 'components/charts/stackedChart/ConfiguredComposedChartContainer.tsx';
import {
  ChartDataCommonConfigFinalized,
  ChartDataConfigFinalized,
} from '../../../types/dataVisualizations/chartDataConfig.ts';
import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

export interface StackedDataPathComparisonChartProps extends CemitComponentProps {
  yAxisWidth: number;
  chartDataConfigAndFilteredDataFeatureSets: ChartDataConfigFinalized[];
  chartDataCommonConfigFinalizeds: ChartDataCommonConfigFinalized[];
  trainDataPathStackedChartRefWidth: number;
  maybeLifetimeContainers: ReactElement[];
}

/**
 * Compares track data for two different week
 * @param appProps
 * @param appProps.onMouseLeave Send messages about leaving the chart
 * @param yAxisWidth The chart y-axis width in pixels. Set from ancestor containers
 * so we can align the TrainRunLine
 * @param dataPaths The str paths within geojson properties object to display
 * E.g. 'acceleration.sumMaxMean'
 * @param comparisonTrackDataSets[*].data The dataset
 * @param comparisonTrackDataSets[*].label The label of the dataset
 * @param comparisonTrackDataSets[*].featuresPath The string path to the geojson features, e.g. 'features' or 'foo.geojson.features'
 * @param width Width of the dataVisualizations
 * @param height Height of the dataVisualizations
 * @param setChartWidth Setter function to tell the TrainRunLine how wide the svg chart component is
 * @returns {JSX.Element}
 */
const StackedDataPathComparisonChart = forwardRef(
  (
    {
      appProps,
      trainProps,
      organizationProps,
      componentProps: {
        loading,
        yAxisWidth,
        trainDataPathStackedChartRefWidth,
        maybeLifetimeContainers,
        chartDataConfigAndFilteredDataFeatureSets,
        chartDataCommonConfigFinalizeds,
        sx,
      },
    }: TrainAppTrainComponentDependencyProps<StackedDataPathComparisonChartProps>,
    ref: RefObject<any>,
  ) => {
    const chartAndMapDataContainerRef = useRef(undefined);

    const configuredComposedChartContainers: JSX.Element[] = addIndex(map)(
      (chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized, index: number) => {
        const isVisible = when(
          is(Function),
          (isVisible: (depedencyProps: TrainAppTrainDependencyProps) => boolean) => {
            return isVisible({appProps, organizationProps, trainProps});
          },
        )(chartDataCommonConfigFinalized.chartDataCommonConfig.isVisible);
        if (!isVisible) {
          return undefined;
        }
        return (
          <ConfiguredComposedChartContainer
            key={`chart${index}`}
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                loading,
                index,
                yAxisWidth,
                trainDataPathStackedChartRefWidth,
                chartDataCommonConfigFinalized,
                sx,
                forwardedRef: ref,
                chartAndMapDataContainerRef,
              },
            }}
          />
        );
      },
      chartDataCommonConfigFinalizeds || [],
    );

    return (
      <Stack
        key="maybeLifetimeContainers"
        {...{
          direction: 'column',
          spacing: 2,
          sx: {
            position: 'relative',
            marginTop: '4px',
            overflow: 'auto',
            width: '100%',
            height: '100%',
          },
        }}
      >
        {configuredComposedChartContainers}
        {
          // TODO generalize this
          maybeLifetimeContainers
        }
      </Stack>
    );
  },
);
StackedDataPathComparisonChart.displayName = 'StackedDataPathComparisonChart';
export default StackedDataPathComparisonChart;
