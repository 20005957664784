import React, {useContext} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import StatusPageContainer from './StatusPageContainer.tsx';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
const queryClient = new QueryClient();

const SoftprioAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const {themeName} = useContext(CemitThemeContext);
  const realtimeSpaceId = organizationProps?.organization?.realtimeSpaceId;
  return (
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false}>*/}
      <StatusPageContainer spaceId={realtimeSpaceId!} pointId={''} />
      {/*</ReactQueryDevtools>*/}
    </QueryClientProvider>
  );
};

export default SoftprioAppContainer;
