import {add, length, lensPath, map, omit, reduce, split, toPairs} from 'ramda';
import {
  AlertDatum,
  GraphqlResponseAlertData,
  AlertLevelDatum,
} from 'types/alerts/alertMapData';
import {GetTotalCount} from 'types/alerts/alertFunctions';
import {AlertPeriodMapData} from 'types/alerts/alertPeriodMapData';

import {RideComfortAlertLevels} from 'types/alerts/rideComfort/rideComfortAlertLevels';

import {overClassOrType} from '../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {rideComfortLevelToAttribute} from 'types/alerts/rideComfort/rideComfortAlertLevel.ts';
import {clsOrType} from '../typeUtils/clsOrType.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {PeriodEnum, periodLabelToEnum, Periods} from 'types/alerts/periodEnum.ts';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnum.ts';

/**
 * Totals the alerts
 * @param alertTypeConfig
 * @param data
 * @param getTotalCount
 */
export const totalMapDataValues = (
  alertTypeConfig: AlertTypeConfig,
  data: Perhaps<GraphqlResponseAlertData>,
  getTotalCount: GetTotalCount,
): AlertPeriodMapData => {
  // All attributeAlertLevelEnums except for none, which never comes from the query
  const attributeAlertLevelEnums = omit(
    [AttributeAlertLevelEnum.none as string],
    alertTypeConfig.attributeAlertLevelEnum,
  );
  const templatePeriod: AlertLevelDatum = clsOrType<AlertLevelDatum>(
    CemitTypename.alertLevelDatum,
    map((_value) => {
      return 0;
    }, attributeAlertLevelEnums),
  );

  const initial = clsOrType<AlertPeriodMapData>(CemitTypename.alertPeriodMapData, {
    [PeriodEnum.today]: templatePeriod,
    [PeriodEnum.week]: templatePeriod,
    [PeriodEnum.month]: templatePeriod,
  });

  // Each element of the reduction is a pair from the data Record
  // the first of the pair is a label in the form L(1|2|3)_(Day|Week|Month)
  // and the second of the pair is an array of values to total
  return reduce(
    (accum: AlertPeriodMapData, element: [string, AlertDatum[]]) => {
      const [label, rideComfortAlertCounts] = length(element)
        ? element
        : [undefined, undefined];
      // If something isn't defined, skip it
      if (!rideComfortAlertCounts || !length(rideComfortAlertCounts)) {
        return accum;
      }
      const [level, periodLabel] = split('_', label) as [
        keyof RideComfortAlertLevels,
        keyof Periods,
      ];

      const period: PeriodEnum = periodLabelToEnum[periodLabel];
      const alertLevel: RideComfortAttributeAlertLevel =
        alertTypeConfig.alertLevelToAttribute[level];

      const total = getTotalCount(rideComfortAlertCounts);
      // Set accum[period][alertLevel] += total
      return overClassOrType(
        lensPath([period, alertLevel]),
        (existing: number = 0) => {
          return add(existing, total);
        },
        accum,
      );
    },
    initial,
    toPairs(data?.data || []),
  );
};
