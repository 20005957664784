
export const BASE_URL = process.env.TRAFFIC_SIM_APP_API

export const callAPI = async (path, method, data) => {

  const apiUrl = `${BASE_URL}${path}`;

  const config = method === 'GET' ? {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  } : {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  const response = await fetch(apiUrl, config);

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response.json().catch(() => {
    return response
  });
}
