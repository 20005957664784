/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DocumentationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Open Api
     * @returns any Successful Response
     * @throws ApiError
     */
    public openApiOpenapiJsonGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/openapi.json',
        });
    }
    /**
     * Documentation
     * @returns any Successful Response
     * @throws ApiError
     */
    public documentationDocsGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/docs',
        });
    }
}
