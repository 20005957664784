import {TrainRun, TrainRunResponse} from '../../types/trainRuns/trainRun';
import {TrainRouteMinimized} from '../../types/trainRouteGroups/trainRoute';
import {CemitTypename} from '../../types/cemitTypename.ts';

import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';

/**
 * Currently just adds a getter for trainRoute that delegates to journeyPattern.trainRoute
 * @param isPreconfigured Default false If the request was called with isPreconfigured, it indicates the TrainRun is
 * preconfigured, such as a reference TrainRun. The TrainRun is marked as isPreconfigured: true if so, else false
 * @param trainRun
 */
export const createTrainRunFromResponse = (
  {isPreconfigured = false}: {isPreconfigured: boolean},
  trainRun: TrainRunResponse,
): TrainRun => {
  return clsOrType<TrainRun>(CemitTypename.trainRun, {
    __typename: CemitTypename.trainRun,
    isPreconfigured,
    ...trainRun,
  });
};
