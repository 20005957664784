import {ReactElement, useEffect, useMemo, useState} from 'react';
import {headOrThrow} from 'utils/functional/functionalUtils.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {AlertConfigProps} from 'types/alerts/alertConfigProps.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {PeriodEnum} from 'types/alerts/periodEnum.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {alertTypeConfigs} from 'types/alerts/alertTypeConfigs.ts';
import {useCustomLocalStorageDefaulted} from 'utils/hooks/useCustomLocalStorage.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {find, map} from 'ramda';
import {AlertTypeConfigVisibleAttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnums';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {sortConfigByTrainName} from 'config/appConfigs/trainConfigs/trainSortConfig.ts';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';

/**
 * Calculates RideComfortConfigProps, which are RideComfort props that are based on settings and user choices
 * but independent of the selected TrainGroups
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param renderChildren
 * @param loading
 * @constructor
 */
const AlertConfigDependency = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    renderChildren,
    loading
  }: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  const {t} = appProps;

  // All available AlertTypeConfigs
  const availableAlertTypeConfigs: AlertTypeConfig[] = useMemo(() => {
    return alertTypeConfigs(t);
  }, [t]);

  // The currently selected alertTypeConfig, cached by alertTypeKey
  const [alertTypeConfig, setAlertTypeConfig] =
    useCustomLocalStorageDefaulted<AlertTypeConfig>(
      {
        serializer: (alertTypeConfig) => {
          return alertTypeConfig.alertTypeKey;
        },
        rehydrate: (value: string): AlertTypeConfig => {
          const alertTypeConfig = find<AlertTypeConfig>(
            (alertTypeConfig: AlertTypeConfig) => {
              return alertTypeConfig.alertTypeKey === value;
            },
            availableAlertTypeConfigs
          );
          return alertTypeConfig || headOrThrow(availableAlertTypeConfigs);
        }
      },
      AppSettings.localStorage.alertTypeConfig,
      headOrThrow(availableAlertTypeConfigs)
    );


  // The types of attributes that are visible for the current alert type
  // setVisibleAttributeAlertLevels is call whenever the alertTypeConfigChanges
  const [
    alertTypeConfigVisibleAttributeAlertLevelEnums,
    setAlertTypeConfigVisibleAttributeAlertLevelEnums
  ] = useCustomLocalStorageDefaulted<AlertTypeConfigVisibleAttributeAlertLevelEnum[]>(
    {loading: !alertTypeConfig},
    AppSettings.localStorage.visibleAttributeAlertLevels,
    map(
      (
        alertTypeConfig: AlertTypeConfig
      ): AlertTypeConfigVisibleAttributeAlertLevelEnum => {
        return clsOrType<AlertTypeConfigVisibleAttributeAlertLevelEnum>(
          CemitTypename.alertTypeConfigVisibleAttributeAlertLevelEnum,
          {
            alertTypeKey: alertTypeConfig.alertTypeKey,
            visibleAttributeAlertLevelEnums:
            alertTypeConfig.defaultVisibleAttributeAlertLevels
          }
        );
      },
      availableAlertTypeConfigs
    )
  );
  // If alertTypeConfig changes, maintain the
  useNotLoadingEffect(
    !alertTypeConfig,
    () => {
      alertTypeConfig;
    },
    [alertTypeConfig]
  );

  // The AlertGaugeByTimePeriod shown on the map. This is currently always PeriodEnum.today, although
  // AlertIntervalPicker.tsx used to changed it
  const [alertTimePeriodForMap, setAlertTimePeriod] = useState<
    keyof AlertGaugeByTimePeriod
  >(PeriodEnum.today);

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(AlertConfigDependency.name),
    AlertConfigDependency.name,
    {},
    [loading],
    appProps.setWhatDependenciesAreLoading
  );

  const alertConfigProps = useMemo(() => {
    return clsOrType<AlertConfigProps>(CemitTypename.alertConfigProps, {
      whatIsLoading,
      alertTypeConfig,
      setAlertTypeConfig,
      alertTypeConfigs: availableAlertTypeConfigs,
      alertGaugeTimePeriod: alertTimePeriodForMap,
      setAlertTimePeriod,
      alertTypeConfigVisibleAttributeAlertLevelEnums,
      setAlertTypeConfigVisibleAttributeAlertLevelEnums
    });
  }, [
    whatIsLoading,
    alertTypeConfig,
    alertTypeConfigs,
    alertTimePeriodForMap,
    alertTypeConfigVisibleAttributeAlertLevelEnums,
    setAlertTypeConfigVisibleAttributeAlertLevelEnums
  ]);

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'alertConfigProps',
    alertConfigProps
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainPropsMerged,
    'AlertConfigDependency'
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps
  });
};

export default AlertConfigDependency;
// export default memo(
//   RideComfortConfigDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
