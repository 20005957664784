import {
  TrainRouteGroup,
  TrainRouteGroupResponse,
} from '../../types/trainRouteGroups/trainRouteGroup';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {map, omit} from 'ramda';
import {TrainRoute, TrainRouteResponse} from '../../types/trainRouteGroups/trainRoute';
import {createTrainRouteMinimized} from './trainRouteCrud.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {clsOrTypes} from '../../appUtils/typeUtils/clsOrTypes.ts';

/**
 * Creates a TrainRouteOrGroup with TrainRoutes of type TrainRoute
 * @param trainRouteGroupResponse
 */
export const createTrainRouteGroup = (
  trainRouteGroupResponse: TrainRouteGroupResponse,
): TrainRouteGroup => {
  const trainRouteGroup = clsOrType<TrainRouteGroup>(CemitTypename.trainRouteGroup, {
    ...omit<
      TrainRouteGroupResponse,
      'trackAsLineString' | 'orderedTracks' | 'orderedRoutePoints' | 'trainRoutes'
    >(
      ['trackAsLineString', 'orderedTracks', 'orderedRoutePoints', 'trainRoutes'],
      trainRouteGroupResponse,
    ),
    trainRoutes: clsOrTypes<TrainRoute>(
      CemitTypename.trainRoute,
      map((trainRouteResponse: TrainRouteResponse) => {
        return createTrainRouteMinimized(trainRouteResponse);
      }, trainRouteGroupResponse.trainRoutes),
    ),
  } as TrainRouteGroup);
  return trainRouteGroup;
};
