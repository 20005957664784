import {GraphqlResponseAlertData} from 'types/alerts/alertMapData';
import {compose, length, map, prop} from 'ramda';
import {setTrainGroupAlertHeatMapWithQueryData} from 'async/alertAsync/alertHooks.ts';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {CrudList} from 'types/crud/crudList';
import {TFunction} from 'i18next';
import {getAlertGaugeByTimePeriod} from 'appUtils/alertUtils/alertQueryUtils.ts';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import { getSingleTrainGroupFullAlertGraphqlData } from './graphqlSingleAlertTypeQueries';

/**
 * Queries for the Alert data of the given AlertScopeProps, which refers to a TrainGroup
 * that is currently either a TrainGroupOnlyTrainFormation or TrainGroupSingleTrainRun
 * @param t
 * @param alertScopeProps
 * @param trainGroupCrudList
 */
export const queryAlertApiForSingleAlertTypeAndMutate = async <T extends TrainGroup>(
  t: TFunction,
  alertScopeProps: AlertScopeProps<T>,
  trainGroupCrudList: CrudList<TrainGroup>,
): Promise<Perhaps<GraphqlResponseAlertData>> => {
  const trainFormationCollectionDevices: TrainFormationCollectionDevice[] =
    alertScopeProps.scopedTrainGroup.trainGroupCollectionDevices;
  if (!length(trainFormationCollectionDevices || [])) {
    throw new Error('trainFormationCollectionDevices is undefined or empty');
  }
  const vehicleCollectionDevices: VehicleCollectionDevice[] = map(
    prop('vehicleCollectionDevice'),
    trainFormationCollectionDevices,
  );
  // TODO We are not handling TrainFormations with multiple sensors.
  // We are just using the first
  const vehicleCollectionDevice: VehicleCollectionDevice = findOrThrow(
    compose(Boolean, prop('alertPointId')),
    vehicleCollectionDevices,
  );

  // The AlertType specified by the user. This is TrainGroup-independent
  const {alertGaugeTimePeriod, alertTypeConfig} = alertScopeProps.alertConfigProps;
  // The AlertIntervalDescription specified by the user for TrainGroupFormationOnly or
  // the AlertIntervalDescription based on the TrainGroup for TrainGroupSingleTrainRun
  const {dateIntervalDescription} = alertScopeProps.alertTrainGroupProps;

  // Perform the query
  const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];
  const alertGraphqlResponseAlertData: Perhaps<GraphqlResponseAlertData> =
    await getSingleTrainGroupFullAlertGraphqlData(
      t,
      alertTypeConfig,
      pointId,
      dateIntervalDescription!,
      alertGaugeTimePeriod,
    );

  // Maps {today: {warn: total, critical: total, ...}, week: {...}, ....} to
  //  {today: {warn: {value: percentage, count: total}, ...}}
  const alertGaugeByTimePeriod: AlertGaugeByTimePeriod = getAlertGaugeByTimePeriod(
    alertGraphqlResponseAlertData,
  );

  setTrainGroupAlertHeatMapWithQueryData(
    alertScopeProps,
    alertGaugeByTimePeriod,
    alertGraphqlResponseAlertData,
    trainGroupCrudList,
  );
  return alertGraphqlResponseAlertData;
};

