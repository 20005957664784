import {
  CemitApi,
  CemitApiRequest,
  CemitApiResponse,
  CemitApiRoute,
} from '../../types/apis/cemitApi';
import {
  always,
  compose,
  concat,
  cond,
  identity,
  includes,
  is,
  join,
  keys,
  length,
  map,
  omit,
  propOr,
  split,
  T,
  when,
} from 'ramda';
import {slugifyDeep, toArrayIfNot} from '../../utils/functional/functionalUtils.ts';
import {chainObjToValues} from '@rescapes/ramda';
import {
  urlEncodeParamValue,
  urlSearchParamsToQueryString,
} from '../../appUtils/apiUtils/apiUrlUtils.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';

/***
 * Creates a CemitApiRoute of the given request and response types by looking up the matching route by
 * routeKey
 * @param cemitApi
 * @param cemitApiRoute
 * @param params The request parametrs
 * @constructor
 */
export const cemitApiRouteWithParams = <
  API_REQUEST extends CemitApiRequest,
  API_RESPONSE extends CemitApiResponse<T, DATA>,
  T = any,
  DATA extends Record<string, any> = Record<string, any>,
>(
  cemitApiRoute: CemitApiRoute<API_REQUEST, API_RESPONSE>,
  request: API_REQUEST,
): CemitApiRoute<API_REQUEST, API_RESPONSE> => {
  const {routePath, urlResolver} = cemitApiRoute;
  return {
    routePath: route,
    urlResolver: urlResolver,
    params,
  };
};

/**
 * Returns the URL for authentication API
 */
export const getCemitAuthApiUrl = () => {
  return process.env.REACT_APP_AUTH_API;
};
/**
 * Forms a TrainPage API GET request based on the given query string and path
 * If the rawParams produced from cemitApiRoute?.request.props are undefined, then we return undefined
 * to indicate to SWR that no request is ready to make
 * @param cemitApi
 * @param cemitApiRoute
 * @param path
 * @return The full URL or undefined
 */
export const cemitApiGetUrl = <
  API_REQUEST extends CemitApiRequest<PROPS, PARAMS>,
  API_RESPONSE extends CemitApiResponse<T, DATA>,
  T,
  // Optionally the format of the data that comes back from the API. It might be the same as T
  DATA extends Record<string, any> = Record<string, any>,
  // Optionally the format of the parameters given to the request
  PROPS extends Record<string, any> = Record<string, any>,
  PARAMS extends Record<string, any> = Record<string, any>,
>(
  cemitApi: CemitApi,
  cemitApiRoute: CemitApiRoute<API_REQUEST, API_RESPONSE, T, DATA, PROPS, PARAMS>,
  path?: string,
): Perhaps<string> => {
  const paramsFilter = cond([
    // Custom param filter for path
    [
      // type paths[path].paramsFilter
      (request) => path && request?.paths?.[path]?.paramsFilter,
      (request) => request?.paths?.[path]?.paramsFilter,
    ],
    [
      // top-level param filter
      (request) => request.paramsFilter,
      (request) => request.paramsFilter,
    ],
    // No changes to props. Only for simple queries
    [T, identity],
  ])(cemitApiRoute?.request);
  const rawParams = paramsFilter(cemitApiRoute?.request.props);
  // Not ready
  if (!rawParams) {
    return undefined;
  }
  // TODO for now we still send posts with url params
  const isPost = false && cemitApiRoute.requestMethod == 'POST';
  // Slugify if the API expects slugified params
  const params = isPost
    ? undefined
    : when(() => cemitApi.slugifyRequestParams, slugifyDeep)(rawParams);
  // URLSearchParams isn't smart enough to a param as an array. Instead pass the same param multiple times
  // with each value
  const flatParams = isPost
    ? undefined
    : chainObjToValues(
        (value: string | string[], key: string) => {
          return map((v) => [key, urlEncodeParamValue(v)], toArrayIfNot(value));
        },
        omit(['__typename'], params),
      );

  const query = isPost ? undefined : new URLSearchParams(flatParams);
  // Get the url path, either from a string or a function
  // If a function pass the unfiltered cemitApiRoute.request.prop
  const routePath: string = compose(
    // If path is present, resolve the path from cemitApiRoute.paths and concat it to the base routePath.
    when(always(path), (routePath: string) => {
      const resolvedPath = propOr(undefined, path, cemitApiRoute.paths);
      if (!resolvedPath) {
        throw new Error(
          `Path ${path} does not exist for CemitApiRoute paths: ${join(',', keys(cemitApiRoute.paths || []))}`,
        );
      }
      return join('/', concat([routePath], toArrayIfNot(resolvedPath)));
    }),
    // Evaluate the function if routePath is a function, passing props
    when(is(Function), (func: (props: API_REQUEST['props']) => string | never) => {
      return func(cemitApiRoute.request.props);
    }),
  )(cemitApiRoute.routePath);
  if (includes('undefined', routePath)) {
    throw new Error(`Something is undefined in ${routePath}`);
  }
  const queryString = isPost ? undefined : urlSearchParamsToQueryString(query);
  // Only put a / in front of the query string if the root is single
  // segment. For some reason fastapi redirects /train_runs/with_sensor_data/ to /train_runs/with_sensor_data
  // and /train_runs to /train_runs/
  const maybeSlash = length(split('/', routePath)) > 1 ? '' : '/';
  const maybeQuestionMark = Boolean(queryString) ? '?' : '';
  return concat(
    join('/', [cemitApi.baseUrl, cemitApi.basePath, `v${cemitApi.version}`, routePath]),
    `${maybeSlash}${maybeQuestionMark}${queryString || ''}`,
  );
};
