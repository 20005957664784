import {SortConfig} from 'types/sorting/sortConfig.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {SortDirectionEnum} from 'types/sorting/sortDirection.ts';
import {RailwayAlert, Train} from '@mui/icons-material';
import {sortTrainGroupsByName} from 'appUtils/trainAppUtils/trainGroupSorting/trainGroupSorting.ts';
import {sortTrainGroupsByAlertSeverity} from 'appUtils/trainAppUtils/trainGroupSorting/trainGroupAlertSorting.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {PickDeep} from 'type-fest';
import {propEq} from 'ramda';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';

/**
 * Sort by TrainGroup name
 * @param trainAppTrainDependencyProps Only trainAppTrainDependencyProps.appProps.t is needed
 */
export const sortConfigByTrainName = (trainAppTrainDependencyProps: PickDeep<TrainAppTrainDependencyProps, 'appProps.t'>) => {
  return clsOrType<SortConfig>(CemitTypename.sortConfig, {
    name: 'train',
    description: trainAppTrainDependencyProps.appProps.t('trainNameSortConfigDescription'),
    defaultSortDirection: SortDirectionEnum.ascending,
    icon: Train,
    sortFunction: sortTrainGroupsByName
  });
};

/**
 * Sort by the active Alert seveerity
 * @param appProps
 * @param trainProps Access to the current alert type
 */
export const sortConfigByAlertSeverity = ({appProps, trainProps}: TrainAppTrainDependencyProps) => {
  return clsOrType<SortConfig>(CemitTypename.sortConfig, {
    name: 'alertSeverity',
    description: `${trainProps.alertConfigProps.alertTypeConfig.label} ${appProps.t('alertSeverity')}`,
    defaultSortDirection: SortDirectionEnum.descending,
    icon: RailwayAlert,
    sortFunction: sortTrainGroupsByAlertSeverity
  });
};

/**
 * The currently available ways to sort TrainGroups, including the
 * name key, default sort direction, icon, and sortFunction, which receives
 * ({appProps, organizationProps, trainProps}: TrainDependencyProps) and returns
 * the sorted list of TrainGroups
 * @param trainAppTrainDependencyProps
 */
export const trainGroupSortConfigs = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps): SortConfig[] => {
  return [
    sortConfigByTrainName(trainAppTrainDependencyProps),
    sortConfigByAlertSeverity(trainAppTrainDependencyProps)
  ];
};
/**
 * Resolve the TrainGroupSortConfig from the given name
 * @param trainAppTrainDependencyProps
 * @param name
 */
export const resolveTrainGroupSortConfig = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, name: string) : SortConfig  => {
  return findOrThrow(
    propEq(name, 'name'),
    trainGroupSortConfigs(trainAppTrainDependencyProps)
  );
};
