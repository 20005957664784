import {alpha, Box, Button, Stack, Tooltip} from '@mui/material';
import {CEMIT_GRAY, CEMIT_WHITE} from 'theme/cemitColors.ts';
import React from 'react';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {SortConfig} from 'types/sorting/sortConfig.ts';
import {eqProps, equals} from 'ramda';
import {
  TrainAppComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppComponentDependencyProps';

export interface TrainGroupSortButtonProps extends CemitComponentProps {
  sortConfig: SortConfig;
}

/**
 * Sorts by TrainGroup based on the given componentProps.sortConfig
 * @param loading
 * @param appProps
 * @param componentProps
 * @constructor
 */
const TrainGroupSortButton = (
  {
    loading,
    appProps,
    componentProps
  }: TrainAppComponentDependencyProps<TrainGroupSortButtonProps>) => {

  const {sortConfig} = componentProps;
  const Icon = sortConfig.icon;
  const disabled: boolean = loading || equals(appProps.trainGroupSortConfigName, sortConfig.name);
  return (
      <Box {...{sx:{}}}>
        <Button
          {...{
            onClick: componentProps.onClick,
            disabled,
            variant: 'text',
            sx: {
              '&:disabled': {
                color:  alpha(CEMIT_WHITE, 0.5)
              }
            },
            disabled,
            startIcon: (
              <Icon
                {...{
                  fill: loading ? CEMIT_GRAY : CEMIT_WHITE
                }}
              />
            ),
            size: 'small'
          }}
        >
        {
          // sortConfig.description is already translated in the config so we can have dynamic descriptions
          `${appProps.t('sortBy')} ${sortConfig.description}`
        }
        </Button>
      </Box>
  );
};
export default TrainGroupSortButton;
