/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DeviceOrderBy {
    NAME = 'name',
    _NAME = '-name',
    CREATED_DATETIME = 'created_datetime',
    _CREATED_DATETIME = '-created_datetime',
    UPDATED_DATETIME = 'updated_datetime',
    _UPDATED_DATETIME = '-updated_datetime',
}
