import {Box, Stack, SvgIcon, Typography} from '@mui/material';
import AuthPageImage from 'assets/images/auth-page.png';
import LogoSvg from 'assets/images/cemit-visualizer-logo.svg';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import React, {FC, ReactNode, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {cemitColors} from 'theme/cemitColors.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import AuthFormComponent from './AuthForm.tsx';
import {UserContext, UserContextType} from './UserContext.ts';
import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';
import {AppSettings} from 'config/appConfigs/appSettings.ts';

/**
 * Controls access to child trafficSimComponents given that the user is authenticated.
 * If the user is invalid a login page is shown.
 * Otherwise the user state is initial and loading screen is shown.
 */
export const AppAccess: FC<{children: ReactNode}> = ({children}) => {
  const {t} = useTranslation();
  const {userState} = useContext(UserContext) as UserContextType;

  if (userState.status === AccessStatus.Initial) {
    const appRelease = AppSettings.appRelease;
    const logoTitle = `${t('cemitVisualizer')} - Build: ${appRelease}`;
    return (
      <Stack
        {...{
          sx: {
            p: 2,
            height: '100vh',
            width: '100%',
            alignItems: 'top',
            justifyContent: 'center',
          },
        }}
      >
        <Stack {...{spacing: 2, sx: {color: 'secondary.main'}, alignItems: 'center'}}>
          <SvgIcon
            {...{
              component: CemitLogo,
              viewBox: '0 0 33 33',
              sx: {width: '200px', height: 'auto'},
              title: logoTitle,
            }}
          />
          <Typography key="title" variant="h3" color="inherit" textAlign={'center'}>
            {t('visualizer')}
          </Typography>
          <LoaderWithText text={t('loading')} spinner={true} />
        </Stack>
        ;
      </Stack>
    );
  }

  if (userState.status === AccessStatus.Authenticated) return <>{children}</>;

  // Invalid user or no token, show login page
  return (
    <Box
      {...{
        sx: {
          display: 'flex',
          minHeight: '100vh',
          height: '100%',
          backgroundColor: cemitColors.darkBlue,
          width: '100%',
        },
      }}
    >
      <Stack
        {...{
          spacing: 1,
          sx: {
            width: '50$',
            justifyContent: 'center',
            justifyItems: 'center',
          },
        }}
      >
        <AuthFormComponent />
      </Stack>
      <Box
        {...{
          sx: {
            width: '50%',
            position: 'fixed',
            right: 0,
            bottom: 0,
            img: {
              maxWidth: '100%',
            },
          },
        }}
      >
        <Box
          {...{
            sx: {
              position: 'fixed',
              left: '45px',
              top: '45px',
              width: '180px',
              img: {
                width: '100%',
              },
            },
          }}
        >
          <LogoSvg />
        </Box>
        <img src={AuthPageImage} />
      </Box>
    </Box>
  );
};
