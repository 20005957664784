import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {TFunction} from 'i18next';
import {
  resolveAlertScoreForTimePeriod,
  resolveCriticalAlertCountForTimePeriod
} from 'appUtils/alertUtils/alertUtils.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {createElement, ReactElement} from 'react';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes';
import {Cemited} from 'types/cemited';
import {Link, Stack, Tooltip} from '@mui/material';
import {always, cond, includes, is, mapObjIndexed, memoizeWith, T, values, without} from 'ramda';
import {reqStrPathThrowing} from '@rescapes/ramda';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitFilterTrainFormationProps} from 'types/cemitFilters/cemitFilterTrainFormationProps.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {addTrainFormationsToCemitFilter} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {cemitColors} from 'theme/cemitColors.ts';
import {compact, findOrThrow} from 'utils/functional/functionalUtils.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig.ts';
import {AlertTypeKey} from 'types/alerts/alertTypeKey.ts';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums.ts';
import {AlertLevels} from 'types/alerts/alertLevels.ts';

export type TrainGroupOverviewColumnAttributes =
  'trendIcon'
  | 'trainName'
  | 'current'
  | 'last30Days'
  | 'last90Days'
  | 'last180Days'
  | 'reviewDetails';
export type TrainGroupOverviewViewType = 'overview' | 'criticalAlerts'

/**
 * Table column interface for showint TrainGroup overviews
 */
export interface TableGroupOverviewTableColumn extends Cemited {
  id: TrainGroupOverviewColumnAttributes
  label: string;
  minWidth?: number;
  align?: 'right';
  // Disables sorting on this column
  disableSort?: true,
  // Format the resolved value of resolveValue or propPath
  format?: (value: number) => string;
  // Resolve the value from TrainGroup instead of using a propPath
  // E.g. ({appProps: TrainAppProps, organizationProps: OrganizationProps,  trainProps: TrainProps}, trainGroup: TrainGroup ) => trainGroup.localizedName(organizationProps.organization.timezoneStr)
  resolveValue?: (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => string | number | boolean | ReactElement;
  // Used only for columns whose resolveValue returns a component. This makes it possible to sort them
  resolveValueForSort?: (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => string | number | boolean
  // Dot-separated propPath, e.g. 'trainFormation.name'
  propPath?: string;
}


/**
 * The column definitions for the TrainGroupOverviewTable. These, most importantly, include a resolver
 * for the value of each cell
 * @param t
 */
export const trainGroupOverviewTableColumns = (t: TFunction): TableGroupOverviewTableColumn[] => {
  /**
   * Compares today to 30 days to see if the alert score is improving. If today is N/A, compares
   * 30 days to 90 days
   * @param trainGroup
   */
  const isPerformanceImproving = (trainGroup: TrainGroup): boolean => {
    const todayPercent = resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days1, trainGroup);
    const days30Percent = resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days30, trainGroup);
    const days90Percent = isNaN(todayPercent) ? resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days90, trainGroup) : undefined;
    return (!isNaN(todayPercent) ?
      (todayPercent >= days30Percent) :
      (days30Percent >= days90Percent!));
  };

  return clsOrTypes<TableGroupOverviewTableColumn>(CemitTypename.tableGroupOverviewColumn, [
    {
      id: 'trendIcon',
      label: '',
      minWidth: 20,
      // Return the icon to indicate improvement or worsening
      resolveValue: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        const performanceImproving = isPerformanceImproving(trainGroup);
        return createElement(
          Tooltip, {
          arrow: true,
          title: t(performanceImproving ? 'performanceImproving': 'performanceWorsening')
          },
          createElement(performanceImproving ? ArrowUpward : ArrowDownward, {sx: {color: performanceImproving ? cemitColors.trackGreenLighter : cemitColors.reallyRed}})
      )
      },
      // Return true or false to sort with
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): boolean => {
        return isPerformanceImproving(trainGroup);
      }
    },
    {
      id: 'trainName',
      label: 'trainName',
      minWidth: 80,
      resolveValue: ({organizationProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return trainGroup.localizedName(organizationProps!.organization!.timezoneStr);
      }
    },
    {
      id: 'last24Hours',
      label: 'last24Hours',
      minWidth: 80,
      align: 'right',
      resolveValue: ({appProps}: TrainAoppTrainDependencyProps, trainGroup: TrainGroup): string => {
        // Call the resolver matching appProps.trainGroupOverviewViewType
        // The overview gets the percent of normal alerts in the last day and the percent change from the previous day
        return resolveForTrainGroupOverviewViewType[appProps.trainGroupOverviewViewType](
          t,
          AlertOverviewByTimePeriod.Days1,
          AlertOverviewByTimePeriod.Days2To1, trainGroup
        );
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        return resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days1, trainGroup);
      }
    },
    {
      id: 'last30Days',
      label: 'last30Days',
      minWidth: 80,
      align: 'right',
      resolveValue: ({appProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): string => {
        // Call the resolver matching appProps.trainGroupOverviewViewType
        // The overview gets the percent of normal alerts in the last 30 days and the percent change from the previous 30 days
        return resolveForTrainGroupOverviewViewType[appProps.trainGroupOverviewViewType](
          t,
          AlertOverviewByTimePeriod.Days30,
          AlertOverviewByTimePeriod.Days60To30,
          trainGroup
        );
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        return resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days30, trainGroup);
      }
    },
    {
      id: 'last90Days',
      label: 'last90Days',
      minWidth: 80,
      align: 'right',
      resolveValue: ({appProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): string => {
        // Call the resolver matching appProps.trainGroupOverviewViewType
        // The overview gets the percent of normal alerts in the last 90 days and the percent change from the previous 90 days
        return resolveForTrainGroupOverviewViewType[appProps.trainGroupOverviewViewType](
          t,
          AlertOverviewByTimePeriod.Days90,
          AlertOverviewByTimePeriod.Days180To90,
          trainGroup
        );
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        return resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days90, trainGroup);
      }
    },
    {
      id: 'last180Days',
      label: 'last180Days',
      minWidth: 80,
      align: 'right',
      resolveValue: ({appProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): string => {
        // Either call the overview or criticalAlerts resolver
        // The overview shows the percent of normal alerts in the last 180 days
        return {
          overview: resolveAlertScoreForTimePeriodOrNoData(t, AlertOverviewByTimePeriod.Days180, trainGroup),
          criticalAlerts: `${resolveCriticalAlertCount(t, AlertOverviewByTimePeriod.Days180, undefined, trainGroup)}`
        }[appProps.trainGroupOverviewViewType];
      },

      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        return resolveAlertScoreForTimePeriod(AlertOverviewByTimePeriod.Days180, trainGroup);
      }
    },
    {
      id: 'reviewDetails',
      label: 'reviewDetails',
      minWidth: 80,
      align: 'right',
      disableSort: true,
      resolveValue: ({appProps, trainProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): ReactElement => {
        const cemitFilterWithTrainFormations =
          trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations;
        const cemitFilterTrainFormationProps = ts<CemitFilterTrainFormationProps>(
          {
            trainGroupOnlyTrainFormations:
            trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations
          }
        );
        const isActive = includes(
          trainGroup,
          trainProps.trainGroupActivityProps!.activeTrainGroups!
        );
        const onClick = () => {
          // Remove FrontendView.forceTrainGroupOverview from the activeViews if present
          appProps.setActiveViews((activeViews: FrontendView[]): FrontendView[] => {
            return without(
              [FrontendView.forceTrainGroupOverview],
              activeViews
            );
          });
          if (!isActive) {
            // Active the TrainGroup
            addTrainFormationsToCemitFilter(
              cemitFilterWithTrainFormations!,
              trainProps.trainGroupOnlyTrainFormationProps?.setCemitFilterWithTrainFormations!,
              [trainGroup],
              cemitFilterTrainFormationProps
            );
          }
        };

        return createElement(Link, {
            component: 'button',
            variant: 'body2',
            onClick
          },
          appProps.t(isActive ? 'backToDetails' : 'reviewDetails')
        );
      }
    }
  ]);
};
/**
 * Return the overview alert percent for the dataIntervalLabel.
 * If the percent is NaN, it means there were no alerts at any level, so 'noData' is returned
 * @param t
 * @param dataIntervalLabel
 * @param trainGroup
 */
const resolveAlertScoreForTimePeriodOrNoData = (
  t: TFunction,
  dataIntervalLabel: AlertOverviewByTimePeriod,
  trainGroup: TrainGroup
): string => {
  const percent: number = resolveAlertScoreForTimePeriod(dataIntervalLabel, trainGroup);
  if (isNaN(percent)) {
    return t('noData');
  }
  return `${percent.toFixed(0)}%`;
};
/**
 * Return a label that is the percent page between the period previousDataIntervalLabel and dataIntervalLabel
 * The value will either be +percent% -percent& or 0%
 * @param t
 * @param dataIntervalLabel
 * @param previousDataIntervalLabel
 * @param trainGroup
 */
const resolveAlertRateOfChangeScoreForTimePeriodOrNoData = (
  t: TFunction,
  dataIntervalLabel: AlertOverviewByTimePeriod,
  previousDataIntervalLabel: AlertOverviewByTimePeriod,
  trainGroup: TrainGroup
): string => {
  const previousPercent: number = resolveAlertScoreForTimePeriod(previousDataIntervalLabel, trainGroup);
  const percent: number = resolveAlertScoreForTimePeriod(dataIntervalLabel, trainGroup);
  if (isNaN(percent)) {
    // No current data, so previous doesn't matter
    return '';
  }
  if (isNaN(previousPercent)) {
    // The current interval has data but the previous doesn't
    return `(${t('n/a')})`;
  }
  const diff = percent - previousPercent;
  const sign = cond([
    // Already signed negative
    [(diff: number) => Math.round(diff) < 0, always('')],
    // Sign with +
    [(diff: number) => Math.round(diff) > 0, always('+')],
    // No change
    [T, always('')]
  ])(diff);
  return `(${sign}${(percent - previousPercent).toFixed(0)}%)`;
};

/**
 * Calls the given column's resolveValue function if it has one or resolves the column's propPath
 * This gives the value for the cell of the column of the given trainGroup
 * @param trainAppTrainDependencyProps
 * @param trainGroup
 * @param column
 */
export const resolveTrainGroupOverviewTableColumnValue = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup, column: TableGroupOverviewTableColumn) => {
  return column.resolveValue ?
    column.resolveValue(trainAppTrainDependencyProps, trainGroup) :
    reqStrPathThrowing(column.propPath, trainGroup);
};

/**
 * Calls the given column's resolveValue function if it has one or resolves the column's propPath.
 * If column.resolveValueForSort is defined, resolves with this function got get a non-react Element value that
 * can be sorted on.
 * The function also converts NaN to -1 so that NaN isn't considered a high value
 * @param trainAppTrainDependencyProps
 * @param trainGroup
 * @param column
 */
export const resolveTrainGroupOverviewTableColumnValueForSort = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup, column: TableGroupOverviewTableColumn) => {
  const result = column.resolveValue ?
    // resolveValueForSort is needed for columns that normally return react components, which can't be sorted of course
    (column.resolveValueForSort || column.resolveValue)(trainAppTrainDependencyProps, trainGroup) :
    reqStrPathThrowing(column.propPath, trainGroup);
  return is(Number, result) && isNaN(result) ? -1 : result;
};


/**
 * Computes a current time period performance value as well as the previous time period
 * (e.g. today and yesterday or last 30 months, and penultimate 30 months)
 * Returns `[current performance percnnt] (+/- change since previous period percent)`
 * @param t
 * @param currentTimePeriod
 * @param previousTimePeriod
 * @param trainGroup
 */
const resolvePercentValueWithDiff = (t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, previousTimePeriod: AlertOverviewByTimePeriod, trainGroup: TrainGroup): ReactElement => {
  const overviewPercent = resolveAlertScoreForTimePeriodOrNoData(t, currentTimePeriod, trainGroup);
  const rateOfChangePercent = resolveAlertRateOfChangeScoreForTimePeriodOrNoData(t, currentTimePeriod, previousTimePeriod, trainGroup);
  const color = cond([
    [includes('+'), always(cemitColors.trackGreenLighter)],
    [includes('-'), always(cemitColors.reallyRed)],
    [T, always('white')]
  ])(rateOfChangePercent);
  return createElement(Tooltip, {
    arrow: true,
    title: `${t('percentNormalReadings')} (${t('percentChangeFrom')} ${t('from')} ${t(previousTimePeriod)} ${t('to')} ${t(currentTimePeriod)})`
  }, createElement(Stack, {spacing: 0.5, direction: 'row', justifyContent: 'flex-end'}, [
    createElement('span', {key: 'overviewPercent'}, overviewPercent),
    createElement('span', {key: 'rateOfChangePercent', style: {color}}, rateOfChangePercent)
  ]));
};

/**
 * The critical alert level keys for RideComfort
 */
const memoizedCriticalAlertLevelKeys = memoizeWith(
  (_trainGroup: TrainGroup) => AlertTypeKey.alertPointId,
  (trainGroup: TrainGroup): (keyof AlertLevels)[] => {
    // TODO we are currently hard coded to use RideComfort alerts only
    const alertTypeConfig = findOrThrow(
      (alertTypeConfig: AlertTypeConfig) => {
        return alertTypeConfig.alertTypeKey == AlertTypeKey.alertPointId;
      },
      trainGroup.alertScopeSummaryProps!.alertConfigProps.alertTypeConfigs
    );
    // Get what constitutes a critical alert level for the alertTypeConfig
    const criticalAlertLevels: AttributeAlertLevel[] = alertTypeConfig.criticalLevels;
    // Do a reverse lookup of alertLevelToAttribute to go from 'critical' to 'L2' or similar
    return compact(values(mapObjIndexed(
      (attribute: string, level: string) => {
        return includes(attribute, criticalAlertLevels) ? level : undefined;
      },
      alertTypeConfig.alertLevelToAttribute
    )));
  });
const resolveCriticalAlertCount = (_t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, _previousTimePeriod?: AlertOverviewByTimePeriod, trainGroup: TrainGroup): ReactElement => {
  const criticalAlertLevelKeys = memoizedCriticalAlertLevelKeys(trainGroup);
  return `${resolveCriticalAlertCountForTimePeriod(currentTimePeriod, trainGroup, criticalAlertLevelKeys)}`;
};

// Matches TrainGroupOverviewViewType to the overview or criticalAlerts resolver
const resolveForTrainGroupOverviewViewType: Record<
  TrainGroupOverviewViewType,
  ((t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, previousTimePeriod?: AlertOverviewByTimePeriod, trainGroup: TrainGroup) => string)> = {
  overview: resolvePercentValueWithDiff,
  criticalAlerts: resolveCriticalAlertCount
};
