import {Stack, SvgIcon} from '@mui/material';
import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';
import CemitAppNavigationItemsContainer from 'components/apps/cemitAppComponents/CemitAppNavigationItemsContainer.tsx';
import {cemitColors} from 'theme/cemitColors.ts';
import React from 'react';
import {Link} from 'react-router-dom';

type CemitAppNavigationProps = {
  currentPath: string;
  logoTitle: string;
};

/**
 * A vertical Stack of application navigation items, enabled or disabled based on the user's access rights
 * @param currentPath
 * @param logoTitle
 * @constructor
 */
const CemitAppNavigation = ({currentPath, logoTitle}: CemitAppNavigationProps) => {
  return (
    <Stack
      id="navigation"
      direction="column"
      {...{
        sx: {
          justifyContent: 'space-between',
          maxWidth: '70px',
          width: '70px',
          left: 0,
          background: cemitColors.darkBlue,
          zIndex: 3,
          paddingTop: '10px',
        },
      }}
    >
      <Stack
        direction="column"
        {...{
          sx: {
            justifyContent: 'center',
            alignItems: 'center',
            borderLeft: '5px transparent solid',
            px: '15px',
            minHeight: '60px',
          },
        }}
      >
        <Link key="home" to={'/home'}>
          <SvgIcon
            {...{
              component: CemitLogo,
              viewBox: '0 0 33 33',
              sx: {},
              title: logoTitle,
            }}
          />
        </Link>
      </Stack>
      <CemitAppNavigationItemsContainer currentPath={currentPath} />
    </Stack>
  );
};

export default CemitAppNavigation;
