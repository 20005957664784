import {Stack} from '@mui/material';
import React, {forwardRef, RefObject} from 'react';

import TrainGroupSelectButton from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupSelectButton.tsx';
import TrainRunDescriptionContainer from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainRunDescriptionContainer.tsx';
import {Size} from '../../../../../types/layout/size';
import TrainGroupDescriptionCompact from './TrainGroupDescriptionCompact.tsx';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CemitComponentProps} from '../../../../../types/propTypes/cemitComponenProps';
import {CrudList} from '../../../../../types/crud/crudList';
import {Perhaps} from '../../../../../types/typeHelpers/perhaps';
import {TrainRoute} from '../../../../../types/trainRouteGroups/trainRoute';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {TrainGroup} from 'types/trainGroups/trainGroup';

export interface TrainGroupSelectButtonProps extends CemitComponentProps {
  disabled: boolean;
  crudTrainGroups: CrudList<TrainGroup>;
  trainGroup: Perhaps<TrainGroupSingleTrainRun>;
  trainRoute: TrainRoute;
}

export interface TrainRunComponentProps extends CemitComponentProps {
  trainRungGroup: TrainGroupSingleTrainRun;
  trainGroupSelectButtonProps: TrainGroupSelectButtonProps;
  trainDetailSize: Size;
  serviceLineIdToColor: Record<string, string>;
}
/**
 * A run of a train represented as a TrainGroup with a valid singleTrainRun property,
 * meaning a specific train and time and service name if the service is revenue service
 * Can also be the representation of the Route without specific times
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainProps.trainRun The specific TrainRun being displayed
 * @param componentProps
 * @param componentProps.trrainRunGroupSelectButtonProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupSingleTrainRun = forwardRef(
  (
    {
      appProps,
      organizationProps,
      trainProps,
      componentProps: {
        trainGroupSelectButtonProps,
        trainDetailSize,
        serviceLineIdToColor,
      },
    }: TrainAppTrainComponentDependencyProps<TrainRunComponentProps>,
    ref: RefObject<any>,
  ) => {
    const trainGroup = trainProps.filteredTrainGroupProps?.filteredTrainGroup;
    const serviceLineColor = serviceLineIdToColor[trainGroup.serviceLine.id];

    const timezoneStr = organizationProps.organization!.timezoneStr;
    if (true) {
      return (
        <TrainGroupDescriptionCompact {...{trainGroup, timezoneStr, serviceLineColor}} />
      );
    } else {
      /// TODO Old detailed view
      return (
        <Stack
          ref={ref}
          spacing={2}
          sx={{
            borderRadius: 2,
            border: 1,
            p: 1,
          }}
        >
          <TrainRunDescriptionContainer
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                buttons: [
                  <TrainGroupSelectButton
                    key="trainGroupSelectButton"
                    {...trainGroupSelectButtonProps}
                  />,
                ],
                showDate: true,
                isBaseline: false,
                trainGroup: undefined,
                showTrainRunLine: true,
                trainDetailSize,
              },
            }}
          />
        </Stack>
      );
    }
  },
);

TrainGroupSingleTrainRun.displayName = 'TrainRun';
export default TrainGroupSingleTrainRun;
