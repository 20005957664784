/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DetectorEventOrderBy {
    RSSI = 'rssi',
    _RSSI = '-rssi',
    DETECTION_TIME = 'detection-time',
    _DETECTION_TIME = '-detection-time',
}
