import { useQuery } from '@tanstack/react-query';
import { TagData } from '../types';
import { throwUnlessDefined } from 'utils/functional/functionalUtils.ts';


const REACT_APP_DETECTOR_API_URL = throwUnlessDefined(process.env.REACT_APP_DETECTOR_API_URL);
const detectorApiUrl  =  `${REACT_APP_DETECTOR_API_URL}?orderBy=-detection-time&page=1&pageSize=200`;
const fetchDataStream = async (pageSize: number = 50): Promise<TagData[]> => {

  const response = await fetch(
    detectorApiUrl,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    },
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useFetchAllTags = (pageSize: number = 50, shouldFetch: boolean = true) => {
  const { isPending, error, data} = useQuery<TagData[]>({
    queryKey: ['fetchAllTags', pageSize],
    queryFn: () => fetchDataStream(pageSize),
    // staleTime: 5 * 60 * 1000, // 5 minutes
    staleTime:0,
    refetchInterval: 10 * 1000, // Refetch every 10 seconds
    enabled: shouldFetch, // Fetch only when shouldFetch is true
    // gcTime: 10 * 60 * 1000, // Cache time of 10 minutes
  });

  return {
    data,
    isPending,
    error,
  };
};

export default useFetchAllTags;