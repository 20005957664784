/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Defines all possible positions a turnout can be in.
 */
export enum TurnoutTrackPositionEnum {
    STRAIGHT = 'straight',
    DIVERGENT = 'divergent',
    UNDEFINED = 'undefined',
}
