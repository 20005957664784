import {Box, Stack} from '@mui/material';
import React, {useMemo} from 'react';
import {addIndex, always, ascend, cond, descend, equals, map, T} from 'ramda';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import CemitFilterTrainGroupOnlyTrainFormationContainer, {
  CemitFilterTrainGroupOnlyTrainFormationContainerProps
} from './CemitFilterTrainGroupOnlyTrainFormationContainer.tsx';
import {
  CemitFilterTrainFormationsViewProps
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsViewProps.ts';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Ord, Ordering} from 'types-ramda/es/tools';
import {SortDirectionEnum} from 'types/sorting/sortDirection.ts';
import {resolveTrainGroupSortConfig} from 'config/appConfigs/trainConfigs/trainSortConfig.ts';
import {Error} from '@mui/icons-material';

/**
 * List of TrainGroupOnlyTrainFormation instances
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainFormationsViewList = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<CemitFilterTrainFormationsViewProps>): JSX.Element => {

  // Sort by appProps.trainGroupSortConfigNameDirection and appProps.trainGroupSortConfigName
  const sortedTrainGroupOnlyTrainFormations = useMemo(
    () => {

      const sortDirectionFunc: (fn: (obj: TrainGroup) => Ord) => (a: TrainGroup, b: TrainGroup) => Ordering = cond([
        [equals(SortDirectionEnum.ascending), always(ascend)],
        [equals(SortDirectionEnum.descending), always(descend)],
        [T, (sortDirection) => {
          throw new Error(`sortDirection must be of type SortDirectionEnum, got ${sortDirection || 'undefined'}`);
        }]
      ])(appProps.trainGroupSortConfigNameDirection);
      return resolveTrainGroupSortConfig(
        {appProps, organizationProps, trainProps},
        appProps.trainGroupSortConfigName
      ).sortFunction({appProps, organizationProps, trainProps}, sortDirectionFunc);
    },
    [
      appProps.trainGroupSortConfigName,
      appProps.trainGroupSortConfigNameDirection,
      trainProps.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations
    ]
  );

  return (
    <Box
      key="trainsComponent"
      {...{
        spacing: 1,
        sx: {
          minWidth: 0,
          flex: 1,
          position: 'relative',
          minHeight: 'auto',
          maxWidth: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingLeft: '8px'
        }
      }}
    >
      {addIndex(map)(
        (trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation, index: number) => {
          const childComponentProps =
            ts<CemitFilterTrainGroupOnlyTrainFormationContainerProps>({
              ...componentProps,
              trainGroupOnlyTrainFormation
            });
          return (
            <CemitFilterTrainGroupOnlyTrainFormationContainer
              key={index.toString()}
              {...{
                appProps,
                trainProps: trainProps,
                componentProps: childComponentProps
              }}
            />
          );
        },
        sortedTrainGroupOnlyTrainFormations
      )}
    </Box>
  );
};
export default CemitFilterTrainFormationsViewList;
