import {
  chain,
  compose,
  equals,
  init,
  join,
  last,
  length,
  lensProp,
  map,
  over,
  pick,
  prop,
  propOr,
  sortBy,
  split,
  uniq,
  values,
} from 'ramda';
import {lineString, point} from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import bearing from '@turf/bearing';
import pointToLineDistance from '@turf/point-to-line-distance';
import {memoizedWith, strPathOr} from '@rescapes/ramda';
import {MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {useMemo} from 'react';
import {BearingDistance} from '../../../types/geometry/bearingDistance';
import {UnaryOneFromMany} from '../../../types/unaryOneFromMany';
import {RoutePoint} from '../../../types/trainRouteGroups/routePoint';
import {ChartDataCategoryConfig} from '../../../types/dataVisualizations/chartDataCategoryConfig';
import {
  ChartDataConfig,
  ChartDataConfigWithCustomControls,
} from '../../../types/dataVisualizations/chartDataConfig.ts';
import {
  BBox,
  Feature,
  FeatureCollection,
  LineString,
  MultiLineString,
  Point,
  Position,
} from 'geojson';
import {PointPair} from '../../../types/geometry/pointPair';
import {
  listToPairs,
  mergeRightIfDefined,
} from '../../../utils/functional/functionalUtils.ts';
import {PropPathStr} from '../../../types/propPath';
import {SensorDataPoint} from '../../../types/sensors/sensorDataPoint';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {TrackRoute, TrackRouteMinimized} from '../../../types/railways/trackRoute';
import bbox from '@turf/bbox';
import {TimetabledPassingDateTime} from '../../../types/timetables/timetabledPassingDateTime';
import {clsOrType} from '../../typeUtils/clsOrType.ts';

import {TrainGroup} from '../../../types/trainGroups/trainGroup';
import {multiLineStringOrLineStringToLineSegments} from 'utils/geojson/geojsonUtils.ts';
import {TrackData} from 'types/railways/track';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DirectionType} from 'types/trainRouteGroups/directionType.ts';

/**
 * Transforms lineSegment +/-90 degrees by transformDistance
 * @param geojsonIndex The index of the underlying data that indicates what direction and how far to transform
 * the line segment
 * @param lineSegment 2 Point LineSegment feature
 * @returns A Feature with a single LineString
 */
export const transformLineSegment = ({
  geojsonIndex,
  lineSegment,
}: {
  geojsonIndex: number;
  lineSegment: Feature<LineString>;
}): Feature<LineString> => {
  const pointCoords: Position[] = lineSegment.geometry.coordinates;

  const pointPairs: PointPair[] = listToPairs(pointCoords);

  // Update the features to the transformed version
  return compose<[PointPair[]], Feature<LineString>[], Position[], Feature<LineString>>(
    (coords: Position[]) => {
      return lineString(coords);
    },
    (lines: Feature<LineString>[]) => {
      return uniq(chain((line: Feature<LineString>) => line.geometry.coordinates, lines));
    },
    (pointPairs: PointPair[]): Feature<LineString>[] => {
      return chain<PointPair[], Feature<LineString>>(
        // Transform each line segment perpendicular to itself
        // @ts-ignore wants return type to be Feature<LineString>[]
        (pointPair: PointPair): Feature<LineString> => {
          const {distance, direction} = calculateTransformDistanceAndDirection({
            geojsonIndex,
            bearing: bearing(...pointPair),
          });
          return transformTranslate<Feature<LineString>>(
            lineString(pointPair),
            distance,
            direction,
          );
        },
        pointPairs,
      );
    },
  )(pointPairs);
};

/**
 *  transform perpendicular to closest line of track transformDistance km
 * transformDistance is multiplied by the geojsonIndex divided by two with a quotient starting a 1
 * since every other track is tranformed 90 or -90, we want to tranfsorm 90 degrees transformDistance, then,
 * -90 degrees transformDistance, then 90 degrees transformDistance * 2, then -90 degrees transformDistance * 2,
 // etc
 * @param [transformDistance] Default MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL
 * Usually a constant distance interval to transform perpendicular from the track
 * @param geojsonIndex The index of the TrainRun being shown, used to decide where to place the trainRun's
 * track perpendicular to the main track
 * @param bearing
 * @returns The BearingDistance
 */
const calculateTransformDistanceAndDirection = ({
  transformDistance = MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL,
  geojsonIndex,
  bearing,
}: {
  transformDistance?: number;
  geojsonIndex: number;
  bearing: number;
}): BearingDistance => {
  const maybeMinus = equals(0, geojsonIndex % 2) ? -1 : 1;
  const direction = bearing + maybeMinus * 90;
  const distance = transformDistance * ((geojsonIndex + 3) / 2);
  return {direction, distance};
};

/**
 * Like transformGeojson but just moves each point in the geojson to the nearest point on the lineString
 * Memoized with a reference check of trainGroup so that whenever the trainGroup or distanceRange
 * of trainGroup changes, this will rerun
 * @param trainGroup
 * @param lineString
 * @param geojson
 * @returns The FeatureCollection with the points transformed
 */
export const memoizedTransformGeojsonToNearest = memoizedWith(
  ({trainGroup}: {trainGroup: TrainGroup}) => {
    // Only recalculate if the geojsonIndex or geojson has changed for this trainGroup
    return [trainGroup.id, trainGroup.geojsonIndex, trainGroup.sensorDataGeojson];
  },
  ({
    lineString,
    geojson,
  }: {
    lineString: LineString;
    geojson: FeatureCollection<Point>;
  }): FeatureCollection<Point> => {
    return over<FeatureCollection<Point>, Point[]>(
      lensProp('features'),
      (features: Feature<Point>[]): Point[] => {
        return map<Feature, Point>((feature: Feature<Point>): Point => {
          const nearest = nearestPointOnLine(lineString, feature.geometry.coordinates, {
            units: 'meters',
          });
          // Take the geometry of the nearest, maintaining point's properties
          return mergeRightIfDefined(feature, pick(['geometry'], nearest));
        }, features);
      },
      geojson,
    );
  },
);

/**
 * Find the nearest line on lineSegments to point
 * @param trackData
 * @param trackAsMultiLineString Multiple feature sets of oldTrackConfig, each representing a different railroad or similar
 * @param lineSegments TrainPage data line segment
 * @param point The turf point to transform
 * @returns {Object} The nearest line from lineSegment
 */
export const nearestLineToPoint = (
  {trackAsMultiLineString, lineSegments}: TrackData,
  point: Point,
): Perhaps<Feature<LineString>> => {
  const nearestPoint = nearestPointOnLine(trackAsMultiLineString, point, {
    units: 'meters',
  });
  // I don't know why this doesn't work, use the line below it instead
  //const line = find(lineString => booleanPointOnLine(nearestPoint, lineString), lineStrings)
  return sortBy((line: Feature<LineString>) => {
    return pointToLineDistance(nearestPoint, line);
  }, lineSegments)[0];
};

/**
 * Returns the sensorDataPoints of the TrainRun from the device with the ost points
 * @param trainGroup
 * @returns {*}
 */
export const trainGroupSensorDataPointsWithMostData = (
  trainGroup: TrainGroup,
): SensorDataPoint[] => {
  // TODO take the SensorDataPoints with most data for now.
  return compose(
    last,
    sortBy(length),
    // Discard the keys
    values,
  )(trainGroup.sensorDataPoints);
};

/**
 * Retrieves the first or last point of the LineStringFeature
 * @param firstLast head or tail function
 * @param lineStringFeature
 * @returns The Point
 */
export const lineStringFirstOrLastPoint = (
  firstLast: UnaryOneFromMany<Position>,
  lineStringFeature: Feature<LineString>,
): Feature<Point> => {
  const position: Position = firstLast(lineStringFeature.geometry.coordinates);
  return point(position);
};

export const nameOfTimetabledPassingTimeStopNamePath =
  'stopPointInJourneyPattern.scheduledStopPoint.name';
/**
 * The underling name of the stpp of the timetabledPassingTime
 * @param timetabledPassingTime
 * @returns {*}
 */
export const nameOfTimetabledPassingTimeStop = (
  timetabledPassingTime: TimetabledPassingDateTime,
) => {
  return strPathOr(
    undefined,
    nameOfTimetabledPassingTimeStopNamePath,
    timetabledPassingTime,
  );
};
export const nameOfRoutePointStopNamePath = 'scheduledStopPoint.name';
/**
 * The underling name of the stop of the routePoint
 * @param routePoint
 * @returns {*}
 */
export const nameOfRoutePointStop = (routePoint: RoutePoint) => {
  return strPathOr(undefined, nameOfRoutePointStopNamePath, routePoint);
};

/**
 * Creates two point line segments from a trackAsMultiLineString
 * @param trackAsLineString A multi or single line string
 * @returns The two-point LineString Feature
 */
export const trackLineSegmentsMemoized = memoizedWith(
  (trackRoute: TrackRoute) => {
    return trackRoute.id;
  },
  (trackRoute: TrackRoute): Feature<LineString>[] => {
    const trackAsLineString: Feature<LineString | MultiLineString> = trackRoute.geojson;
    return multiLineStringOrLineStringToLineSegments(trackAsLineString);
  },
);

/**
 * Returns the bbox of the trackRoute.geojson
 */
export const bboxOfTrackRouteMemoized = memoizedWith(
  (trackRoute: TrackRoute) => {
    return trackRoute.id;
  },
  (trackRoute: TrackRoute): BBox => {
    return bbox(trackRoute.geojson);
  },
);

export const bboxOfTrackRoutesMemoized = memoizedWith(
  (trackRoutes: TrackRoute[]) => {
    return map(prop('id'), trackRoutes);
  },
  (trackRoutes: TrackRoute[]): BBox => {
    return bbox({
      type: 'FeatureCollection',
      features: map((trackRoute: TrackRoute) => trackRoute.geojson, trackRoutes),
    });
  },
);

/**
 * Given a resolvedDataPathConfiguration for showing data on a chart or map and a category into that configuration
 * and itemPropPath where `properties.${itemPropPath}`matches one or more of the category's config.datPath,
 * return all matches
 * @param appProps
 * @param chartDataCategoryConfigs See appConfigs.trainConfigs.dataConfigs for an example
 * @param category
 * @param itemPropPath
 * @returns {Object}
 */
export const useMemoGetChartDataConfigs = (
  appProps: TrainAppProps,
  chartDataCategoryConfigs: ChartDataCategoryConfig[],
  category: string,
  itemPropPath: PropPathStr,
): ChartDataConfigWithCustomControls[] => {
  return useMemo(() => {
    return chain((chartDataCategoryConfig: ChartDataCategoryConfig) => {
      return map<ChartDataConfig, ChartDataConfigWithCustomControls>(
        // Merge customControls into each config
        (chartDataConfig: ChartDataConfig): ChartDataConfigWithCustomControls => {
          return clsOrType<ChartDataConfigWithCustomControls>(
            CemitTypename.chartDataConfigWithCustomControls,
            {
              ...chartDataConfig,
              // Backreference chartDataCategoryConfig to accesss configs
              chartDataCategoryConfig,
              customControls: propOr(
                undefined,
                'customControls',
                chartDataCategoryConfig,
              ),
            },
          );
        },
        chartDataCategoryConfig.configs,
      );
    }, chartDataCategoryConfigs);
  }, [category, itemPropPath, appProps.isLifetimeSelected, appProps.isTimeSelected]);
};

/**
 * Parse a legacy track style track id to a TrackRouteMinimized
 * @param trackId
 */
export const trackIdToTrackRoute = (trackId: string): TrackRouteMinimized => {
  // e.g. "no_oslo_subway_kolsas_ib",
  const names = init(split('_', trackId));
  const directionId = last(split('_', trackId));
  const nameId = join('_', names);

  // TODO formalize
  const directionIdToDirection = {ib: DirectionType.inbound, ob: DirectionType.outbound};
  const nameToRailwayName = {
    no_oslo_subway_kolsas: 'Kolsåsbanen (T-bane)',
    no_oslo_subway_holmenkollen: 'Holmenkollen (T-bane)'
  };

  return clsOrType<TrackRouteMinimized>(CemitTypename.trackRouteMinimized, {
    name: nameToRailwayName[nameId],
    direction: directionIdToDirection[directionId],
  });
};
