import {Box} from '@mui/material';
import SensorDataPointsCustomTooltip, {
  SensorDataPointsCustomTooltipProps,
} from 'components/charts/stackedChart/SensorDataPointsCustomTooltip.tsx';
import {equals, length} from 'ramda';
import {
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import React, {RefObject, SyntheticEvent} from 'react';
import {ChartPayloadItem} from 'types/dataVisualizations/chartPayloadItem';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {DataFeatureCollection} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {ChartDataCommonConfigFinalized} from 'types/dataVisualizations/chartDataConfig.ts';
import {CEMIT_WHITE} from 'theme/cemitColors.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import CustomCursor from './RechartsCustomCursor.tsx';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {
  useMemoChartHeaderLabelAndControls,
  useMemoCreateChartComponents,
  useMemoGradientDefs,
  useMemoReferenceLines,
} from './ConfiguredComposedChartMemoComponents.tsx';
import {hashPayload} from 'utils/dataFeatures/dataFeaturePayloadUtils.ts';
import {headOrThrow} from 'utils/functional/functionalUtils.ts';
import {featureCollectionFromPayload} from 'utils/chart/chartMapInteractionUtils.ts';

export interface ConfiguredComposedChartProps extends CemitComponentProps {
  yAxisWidth: number;
  index: number;
  onMouseMove: (e: SyntheticEvent) => void;
  onMouseLeave: (e: SyntheticEvent) => void;
  onMouseEnter: (e: SyntheticEvent) => void;
  onMouseOver: (e: SyntheticEvent) => void;
  mostRecentTooltipPayload: ChartPayloadItem[];
  setMostRecentTooltipPayload: StateSetter<ChartPayloadItem[]>;
  activeChartDataKey: string;
  xValueOfHoveredItem: number;
  maybeLoaderComponent: React.ReactNode;
  chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized;
  valueMin: number;
  valueMax: number;
  areaName: (label: string, dataFeatureCollection: DataFeatureCollection) => string;
  nameToDataFeatureCollectionLookup: Record<string, DataFeatureCollection>;
  forwardedRef: RefObject<any>;
  chartAndMapDataContainerRef: RefObject<any>;
  dataFeatureCollections: DataFeatureCollection[];
}

/**
 * Shows the graph for one dataPath for all selected TrainRuns (e.g. acceleration or velocity)
 * dataPathsConfigs is passed in addition to dataPath so that the legend/info are can show all configured values
 * @returns {JSX.Element}
 * @constructor
 */
const ConfiguredComposedChart = ({
  appProps,
  trainProps,
  componentProps,
}: {
  appProps: TrainAppProps;
  trainProps: TrainProps;
  componentProps: ConfiguredComposedChartProps;
}): JSX.Element => {
  const {
    yAxisWidth,
    index,
    onMouseMove,
    onMouseLeave,
    onMouseEnter,
    onMouseOver,
    mostRecentTooltipPayload,
    setMostRecentTooltipPayload,
    activeChartDataKey,
    xValueOfHoveredItem,
    maybeLoaderComponent,
    chartDataCommonConfigFinalized,
    sx,
    valueMax = 0,
    valueMin = 0,
    areaName,
    nameToDataFeatureCollectionLookup,
    forwardedRef,
    dataFeatureCollections,
  } = componentProps;

  const {
    xAxisDataPath,
    label,
    labelXAxis,
    labelYAxis,
    // Axis domains. Defaults to using the range of the values being plotted
    yAxisDomain,
    xAxisDomain,
    // Recharts scale type
    xAxisScale,
    xAxisInterval,
    xAxisTickFormatter,
    ticks,
  } = chartDataCommonConfigFinalized.chartDataCommonConfig;

  const chartComponents: Perhaps<JSX.Element[]> = useMemoCreateChartComponents(
    chartDataCommonConfigFinalized,
    areaName,
    label,
  );

  // Svg gradient <def>s
  const defs: JSX.Element = useMemoGradientDefs(
    chartDataCommonConfigFinalized.chartDataConfigFinalizeds,
    dataFeatureCollections,
  );
  ``;
  const chartHeaderLabelAndControls: JSX.Element = useMemoChartHeaderLabelAndControls(
    chartDataCommonConfigFinalized,
    label,
  );

  const referenceLines: (typeof ReferenceLine)[] = useMemoReferenceLines(
    appProps,
    chartDataCommonConfigFinalized.chartDataConfigFinalizeds,
  );

  return (
    <Box key={index} {...(equals(0, index) ? {ref: forwardedRef} : {})}>
      {chartHeaderLabelAndControls}
      <Box
        sx={{
          position: 'relative',
          ...sx,
          border: 1,
          padding: 1,
        }}
      >
        {maybeLoaderComponent}

        <ResponsiveContainer key="responsiveContainer">
          <ComposedChart
            key="composedChart"
            {...{
              id: label,
              syncId: 'sameSyncIdForAllAreaCharts',
              // syncMethod: 'value' tells tooltips and brushes to sync items by value, not index
              // This is essential since the datasets have different lengths and we want to select those
              // close to one another: https://recharts.org/en-US/api#syncMethod
              // TODO this only works across AreaCharts, not within the series
              syncMethod: 'value',
              // onMouseDown: onChartMouseDown,
              // onMouseUp: onChartMouseUp,
              // onMouseMove: compose(args => onChartMouseMove(...args), onMouseMove),
              onMouseMove,
              onMouseLeave,
              onMouseEnter,
              onMouseOver,

              margin: {
                top: 10,
                right: 25,
                left: 0,
                bottom: 0,
              },
            }}
          >
            {defs}
            {referenceLines}

            <Tooltip
              {...{
                // This receives the payload as well from Recharts.
                // SensorDataPointsCustomTooltip doesn't render anything. It sets a state so that we can render the data
                // elsewhere
                content: ({
                  payload: chartPayloadItems,
                }: {
                  payload: ChartPayloadItem[];
                }) => {
                  if (
                    !length(chartPayloadItems) ||
                    !equals(headOrThrow(chartPayloadItems).dataKey, activeChartDataKey)
                  ) {
                    return undefined;
                  }
                  // TODO this shouldn't be needed since has the same
                  // test in it's memo function. But I think that
                  // SensorDataPointsCustomTooltip is often rendered as a new
                  // component since it's in the render function Tooltip.content
                  const payloadHash = hashPayload(chartPayloadItems);
                  const mostRecentTooltipPayloadHash =
                    mostRecentTooltipPayload && hashPayload(mostRecentTooltipPayload);
                  if (payloadHash == mostRecentTooltipPayloadHash) {
                    return undefined;
                  }
                  // This doesn't render anything. It just calls an effect to call setMostRecentTooltipPayload
                  return (
                    <SensorDataPointsCustomTooltip
                      {...{
                        appProps,
                        trainProps,
                        componentProps: clsOrType<SensorDataPointsCustomTooltipProps>(
                          CemitTypename.sensorDataPointsCustomTooltipProps,
                          {
                            payloadHash,
                            chartPayloadItems,
                            nameToDataFeatureCollectionLookup,
                            mostRecentTooltipPayload,
                            setMostRecentTooltipPayload,
                            activeChartDataKey,
                            xValueOfHoveredItem,
                            trainGroups:
                              trainProps.trainGroupOnlyTrainFormationProps
                                .activeTrainGroupFormations,
                          },
                        ),
                      }}
                    />
                  );
                },
                cursor: <CustomCursor />,
              }}
            />
            {chartComponents}
            <XAxis
              key="xAxis"
              {...{
                type: 'number',
                // Just show start end and make the rest automatic
                interval: xAxisInterval,
                scale: xAxisScale,
                // padding: { left: xPadding[0], right: xPadding[1] },
                allowDataOverflow: true,
                allowOverflow: true,
                // Important, this allows the selection of a payload item
                // from each line on the same chart, however is stupidly copies the values
                // of the active line if the other lines don't have a domain where the cursor is.
                // This bug is addressed in SensorDataPointStatsContainer.js
                allowDuplicatedCategory: true,
                dataKey: xAxisDataPath,
                domain: xAxisDomain || ['auto', 'auto'],
                tickFormatter: xAxisTickFormatter,
                ticks: ticks ? ticks(valueMin, valueMax) : undefined,
                style: {
                  fontSize: '10px',
                },
                //axisLine: false,
                tick: {
                  fill: CEMIT_WHITE,
                  fontSize: '10px',
                  fontFamily: 'Roboto, sans-serif',
                },
                ...(labelXAxis
                  ? {
                      label: {
                        value: labelXAxis,
                        offset: 0,
                        position: 'insideBottom',
                        fontSize: 12,
                        fontFamily: 'Roboto, sans-serif',
                        fill: CEMIT_WHITE,
                      },
                    }
                  : {}),
              }}
            />
            {/* Draw the xAxis at 0 with the default color used for the axes */}
            {/*<ReferenceLine y={0} stroke='rgb(102, 102, 102)' fill='none' />*/}
            <YAxis
              key="yAxis"
              {...{
                type: 'number',
                allowDataOverflow: true,
                allowOverflow: true,
                tick: {
                  fontSize: 12,
                  fill: CEMIT_WHITE,
                  fontFamily: 'Roboto, sans-serif',
                },
                padding: {right: 10, left: 10, top: 10, bottom: 10},
                label: {
                  value: labelYAxis,
                  angle: -90,
                  fontSize: 12,
                  fill: 'white',
                  fontFamily: 'Arial',
                },
                width: yAxisWidth,
                allowDecimals: false,
                domain: yAxisDomain,
                dy: 10,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
ConfiguredComposedChart.displayName = 'ConfiguredComposedChart';
export default ConfiguredComposedChart;
