import {Button, CircularProgress, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {PointMachineEventSchema, PointMachineMeasurementSchema} from "../../net/repository";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {fetchPointMachineMeasurements} from "../../net/repo";
import {LineChart,} from '@mui/x-charts';
import Typography from "@mui/material/Typography";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {useTheme} from "@mui/styles";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue?: PointMachineEventSchema;
  onClose: (value: boolean) => void;
  eventId: string;
  pointMachineAverage: Array<number>;
  measurement: Array<number>;
  pointMachineEvents: Array<PointMachineEventSchema>;
  pointMachinePosition: number;
}
export function ChartDialog(props: SimpleDialogProps) {
  const theme = useTheme();
  const {onClose, open, measurement, pointMachineEvents} = props;
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(props.pointMachinePosition)

  const [isLoading, setLoadingState] = React.useState(true);
  const [xAxisData, setXAxisData] = useState<Array<number>>([]);
  const [yAxisData, setYAxisData] = useState<Array<number | null>>([]);

  const pointMachineEvent = pointMachineEvents.at(currentEventIndex)
  const createdAt = pointMachineEvent ? new Date(pointMachineEvent.createdAt) : null;
  const timestamp = createdAt ? `${createdAt.toDateString()} ${createdAt.toTimeString().substring(0, 12)}` : "";

  const stdChartArray = Array.from(props.pointMachineAverage);

  // TODO(Ben): Change the fetch measurement API to return all measurements for a single event.
  // then pass the required measurement to the chart component to select what to display
  useEffect(() => {
    if (pointMachineEvent) {
      const request = fetchPointMachineMeasurements({eventId: pointMachineEvent.id ?? "", page: 1, pageSize: 10});
      request.then((response) => {
        const measurementData = response.data;

        const xAxisData = measurementData.map((data, index) => {
          return index / 100
        });
        const yAxisData = measurementData.map((data: PointMachineMeasurementSchema) => data.current);

        if (yAxisData.length < stdChartArray.length) {
          const diff = stdChartArray.length - yAxisData.length
          let len = measurementData.length + diff;
          const resizeYAxisArr = Array.from([]);
          let resizeXAxisArr = Array.from([]);

          resizeYAxisArr.push(...yAxisData)
          for (let v = 0; v < diff; v++) {
            len += 1;
            resizeYAxisArr.push(0)
            resizeXAxisArr.push(len)
          }

          resizeXAxisArr = resizeYAxisArr.map((data, index) => {
            return index / 100
          });

          setYAxisData(resizeYAxisArr);
          setXAxisData(resizeXAxisArr);
        } else {
          setYAxisData(yAxisData);
          setXAxisData(xAxisData);
        }
        setLoadingState(false)
      }).catch((reason) => {
        setLoadingState(false)
      });
    }
  }, [pointMachineEvent]);

  const handleClose = () => {
    onClose(!open);
  };

  return (
    <Dialog
      style={{backgroundColor: 'transparent'}}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      PaperProps={{
        style: {
          width: '50%',
          boxShadow: 'none',
        },
      }}
      onClose={handleClose} open={open}>
      <DialogTitle>Event measurement</DialogTitle>
      <Typography sx={{ ml: theme.spacing(2) }}>{timestamp}</Typography>
      <Typography sx={{
        position: 'relative',
        top: '40px',
        left: '-290px',
        p: 0,
        pr: 5,
        m: 0,
        color: "black",
        transform: 'rotate(-90deg)'
      }}>Current [A]</Typography>
      <Box sx={{
        background: 'linear-gradient(to right, ' +
          '    #FFFFFF 8.5%,  ' +
          '    #FFFFA8 8.5%,  ' +
          '    #FFFFA8 33%, ' +
          '    #A8FFFF 33%, ' +
          '    #A8FFFF 66%, ' +
          '    #99FFA8 66%, ' +
          '    #99FFA8 92.5%, ' +
          '    #FFFFFF 92.5%, ' +
          '    #FFFFFF 100% ) ',
      }}>
        {isLoading ? <CircularProgress/> : <LineChart
          width={600}
          height={600}
          series={[
            {data: yAxisData, showMark: false, color: 'red'},
            {data: stdChartArray, showMark: false, color: 'green'},
          ]}
          xAxis={[{data: xAxisData}]}
          grid={{vertical: true, horizontal: true}}
          sx={{
            "& .MuiChartsAxis-root .MuiChartsAxis-line": {
              fill: "black",
              stroke: "black"
            },
            stroke: "black",
          }}
        />}
      </Box>
      <Typography sx={{m: 0, p: 0}} align={'center'}> Time [s] </Typography>
      <Box sx={{justifyContent: "space-between",}}>
        <Button
          onClick={() => {
            if(currentEventIndex > 0){
              const index = currentEventIndex - 1;
              setCurrentEventIndex(index);
            }
          }}
          sx={{
            ml: theme.spacing(1),
            borderColor: theme.palette.action.active,
            color: theme.palette.action.active
          }}
          variant="outlined"
          startIcon={<ArrowBack fontSize={'large'}/>}>
          Previous
        </Button>
        <Button
          onClick={() => {
            if(currentEventIndex < pointMachineEvents.length){
              const index = currentEventIndex + 1;
              setCurrentEventIndex(index);
            }
          }}
          sx={{
            ml: theme.spacing(1),
            borderColor: theme.palette.action.active,
            color: theme.palette.action.active
          }}
          variant="outlined"
          endIcon={<ArrowForward fontSize={'large'}/>}>
          Next
        </Button>
      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
