import {HttpClient} from "../repository";
import {BASE_API_URL} from "./config/api_config";

export const AppHttpClient = new HttpClient({
    BASE: BASE_API_URL,
    // CREDENTIALS: 'include',
    HEADERS: {
        // "JWT_TOKEN": "",
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "http://localhost:8090"
    }
});
