import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupMinimized,
} from 'types/trainRouteGroups/trainRouteOrGroup';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {Activity} from 'types/userState/activity';
import {CemitApiLoadingStatus} from 'types/apis/cemitApiLoadingStatus.ts';
import {MinimizedClass} from '../cemitAppCemitedClasses/cemitAppCemitedClasses.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainFormation} from 'types/trains/trainFormation';
import {TrainRun} from 'types/trainRuns/trainRun';
import {ServiceLineMinimized} from 'types/trainRouteGroups/serviceLine';
import {MaintenanceReport} from 'types/trainFormations/trainFormationDetail.ts';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {chain, uniqBy} from 'ramda';
import {createDateInterval} from '../typeCrud/dateIntervalCrud.ts';
import {collectionDevicesOfTrainFormation} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';
import {TrainGroup, TrainGroup, TrainGroupMinimized} from 'types/trainGroups/trainGroup';

import {TrainGroupSensorDataGeojson} from 'types/trainGroups/trainGroupSensorDataGeojson';
import {Error} from '@mui/icons-material';
import {CemitedClass} from 'classes/cemitAppCemitedClasses/cemitedClass.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {AlertConfigProps} from 'types/alerts/alertConfigProps.ts';
import {AlertTrainGroupProps} from 'types/alerts/alertTrainGroupProps.ts';
import {TrainGroupAlertGeojson} from 'types/trainGroups/trainGroupAlertGeojson.ts';
import {TrainGroupRealtimeTrainGeojson} from 'types/trainGroups/trainGroupRealtimeTrainGeojson.ts';
import {RealtimeTrainScopeProps} from 'types/realtimeTrain/realtimeTrainScopeProps';

export class TrainGroupMinimizedClass
  extends MinimizedClass
  implements TrainGroupMinimized
{
  constructor(obj: TrainGroupMinimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainGroupMinimized;
  trainRouteOrGroup?: TrainRouteOrGroupMinimized;
  isPreconfigured?: boolean;
  overrideTrainRoute?: TrainRoute;
  localUpdateDate: Date = new Date();
  localUpdateVersion: number = 0;
}

export class TrainGroupIncompleteClass
  extends TrainGroupMinimizedClass
  implements TrainGroup
{
  constructor(obj: TrainGroup) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  activity?: Activity;
  loadingStatus?: CemitApiLoadingStatus;
  error: any;
  errorDate: Date;
  sensorDataGeojson: Perhaps<TrainGroupSensorDataGeojson>;
  singleTrainRun: never;
  trainFormation: Perhaps<TrainFormation>;
  trainRuns: never;

  get singleTrainRunTrainRoute(): Perhaps<TrainRoute> {
    return undefined;
  }

  get singleTrainRunTrainRouteLoaded(): Perhaps<TrainGroup> {
    return undefined;
  }

  get trainGroupCollectionDevices(): TrainFormationCollectionDevice[] {
    return [];
  }
}

/**
 * Basic implementation of a TrainGroup
 */
export class TrainGroupClass extends CemitedClass implements TrainGroup {
  __typename: CemitTypename.trainGroup;

  localUpdateDate: Date = new Date();
  localUpdateVersion: number = 0;
  trainFormation: Perhaps<TrainFormation>;
  trainRuns: TrainRun[] = [];
  trainRouteOrGroup?: Perhaps<TrainRouteOrGroup>;

  activity?: Activity | undefined;
  loadingStatus?: CemitApiLoadingStatus | undefined;
  isPreconfigured?: boolean | undefined;
  overrideTrainRoute?: TrainRoute | undefined;
  name?: Perhaps<string>;
  description?: Perhaps<string>;
  alertConfigProps?: Perhaps<AlertConfigProps>;
  alertTrainGroupProps?: Perhaps<AlertTrainGroupProps>;
  realtimeTrainScopeProps?: Perhaps<RealtimeTrainScopeProps>;
  realtimeTrainGeojson: Perhaps<TrainGroupRealtimeTrainGeojson>;
  error: any;
  errorDate: Date;
  sensorDataGeojson: Perhaps<TrainGroupSensorDataGeojson>;
  alertGeojson: Perhaps<TrainGroupAlertGeojson>;
  id: string;

  get dateInterval(): Perhaps<DateInterval> {
    return undefined;
  }

  get singleTrainRunTrainRoute(): Perhaps<TrainRoute> {
    return (this.singleTrainRun as TrainRun).trainRoute;
  }

  get singleTrainRunTrainRouteLoaded(): Perhaps<TrainGroup> {
    return (this.singleTrainRun as TrainRun).trainRoute;
  }

  get singleTrainRun(): never {
    throw Error('TrainGroup interface expects 0 or mor TrainRuns, not a single TrainRun');
  }

  constructor(obj: Partial<TrainGroup>) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get serviceLine(): Perhaps<ServiceLineMinimized> {
    return undefined;
  }

  /**
   * For all TrainGroup.trainRuns, returns a flat, unique list of their TrainFormationCollectionDevices
   *
   * @param trainGroup
   * @returns {[Object]} Objects keyed by collectionDevice and vehicle
   */
  get trainGroupCollectionDevices(): TrainFormationCollectionDevice[] {
    // Zero or more TrainRuns
    const zeroOrMoreTrainFormationCollectionDevices: TrainFormationCollectionDevice[] =
      chain((trainRun: TrainRun) => {
        const dateInterval = createDateInterval(
          trainRun?.departureDatetime,
          trainRun?.arrivalDatetime,
        );
        // Limit the TrainFormationCollectionDevices to the TrainRun dateInterval because
        // TrainFormationCollectionDevices get swapped in and out over time
        return collectionDevicesOfTrainFormation(trainRun.trainFormation, dateInterval);
      }, this.trainRuns);
    return uniqBy(
      (trainFormationCollectionDevice: TrainFormationCollectionDevice) =>
        trainFormationCollectionDevice.collectionDevice.id!,
      zeroOrMoreTrainFormationCollectionDevices,
    );
  }
}
