/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Defines the various states of system heartbeat
 */
export enum HeartbeatStatusEnum {
    UNSPECIFIED = 'unspecified',
    STARTUP = 'startup',
    ALIVE = 'alive',
    SHUTDOWN = 'shutdown',
    RECOVER = 'recover',
    FAILED = 'failed',
}
