/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Possible values for alert attributes
 */
export enum AlertAttributeEnum {
    FLANGE_WIDTH = 'flange_width',
    FLANGE_WIDTH_SUM = 'flange_width_sum',
    FLANGE_DISTANCE = 'flange_distance',
    FLANGE_HEIGHT = 'flange_height',
    QR_QUOTE = 'qr_quote',
    FLANGE_ROLLOVER = 'flange_rollover',
    THREAD_ROLLOVER = 'thread_rollover',
    RIM_WIDTH = 'rim_width',
    WHEEL_DIAMETER = 'wheel_diameter',
    EQUIVALENT_CONICITY = 'equivalent_conicity',
    BACK_TO_BACK_DISTANCE = 'back_to_back_distance',
}
