import {Stack, Typography} from '@mui/material';
import {filter, length} from 'ramda';
import React from 'react';
import ChosenTrainGroups from './ChosenTrainGroups.tsx';
import {TrainGroupMenuProps} from '../../chooserComponents/trainGroupChooserComponents/TrainGroupMenu.tsx';
import {OnlyComponentProps} from '../../../../../types/propTypes/cemitComponenProps';
import {CEMIT_WHITE} from '../../../../../theme/cemitColors.ts';
import {useTranslation} from 'react-i18next';
import TrainGroupChooser from 'components/apps/trainAppComponents/chooserComponents/trainGroupSingleTrainRunChooserComponents/TrainGroupChooser.tsx';

/**
 * Presents CemitFilter TrainGroups in a menu with chips for the selected and
 * an expand button to switch to the list view
 *
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupsViewMenu = ({
  componentProps,
}: OnlyComponentProps<TrainGroupMenuProps>) => {
  const selectedTrainGroups = filter(
    componentProps.isSelected,
    componentProps.trainGroups,
  );

  const {t} = useTranslation();
  const trainRunSelected = Boolean(length(selectedTrainGroups));
  const trainGroupsLength = length(componentProps.trainGroups);

  return (
    <Stack
      {...{
        spacing: 0.5,
        direction: 'row',
        sx: {
          width: '100%',
          alignItems: 'center',
          borderRadius: '5px',
          border: trainRunSelected ? `1px solid ${CEMIT_WHITE}` : undefined,
          fontSize: '12px',
        },
      }}
    >
      <TrainGroupChooser {...{...componentProps, selectedTrainGroups}} />
      {
        // Show Chips for Formations in the filter or show an all chosenFormations message.
        trainRunSelected ? (
          <ChosenTrainGroups {...{...componentProps, selectedTrainGroups}} />
        ) : (
          <Typography
            {...{
              sx: {
                fontSize: 'inherit',
                color: 'secondary.main',
              },
            }}
          >
            {`${trainGroupsLength} ${trainGroupsLength == 1 ? t('availableTrainRun') : t('availableTrainRuns')}`}
          </Typography>
        )
      }
    </Stack>
  );
};

CemitFilterTrainGroupsViewMenu.displayProps = 'CemitFilterTrainGroupsMenuChooser';
export default CemitFilterTrainGroupsViewMenu;
