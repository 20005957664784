import {concat, is, lensProp, map, omit, props, when, zipWith} from 'ramda';
import React, {forwardRef, ReactElement, useMemo} from 'react';
import {trainGroupUniqueLabel} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';

import StackedDataPathComparisonChartContainer from 'components/charts/stackedChart/StackedDataPathComparisonChartContainer.tsx';
import {useTranslation} from 'react-i18next';
import {TrainGroupWithFormatting} from 'types/trainGroups/trainGroupWithFormatting';
import {CemitTypename} from 'types/cemitTypename.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  headOrThrow,
  toArrayIfNot,
  undefinedToTrue,
} from 'utils/functional/functionalUtils.ts';
import {DataFeatureCollection} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {
  overClassOrType,
  pickClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {
  DataFeatureSet,
  DataFeatureSetMinimized,
} from 'types/dataVisualizations/dateFeatureSet';
import {useWhatChanged} from '@simbathesailor/use-what-changed';

export interface TrainDataPathStackedChartContainerProps extends CemitComponentProps {
  yAxisWidth: number;
  dataPathsConfigs: string[];
  trainDataPathStackedChartRefWidth: number;
  maybeLifetimeContainers?: ReactElement[];
}

/**
 *
 * This Component shows a stacked chart, mostly based on DashboardContext, TODO these props should be passed
 * into the component instead of coming from a context. Context props:
 *       track, trackCompare, reconstructedTrack, reconstructedTrackCompare, comparisonLabelPair, toWeek
 * See DashboardContextProvider for documentation of these props
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @param componentProp.yAxisWidth SampleChart y-axis width in pixels
 * @param componentProps.dataPathsConfigs the paths and labels to the data to display in each of the two trackData objects.
 * A unary function can also be used that expects the {geometry, properties, type} (Feature) object and computes the value
 * e.g. {dataPath: 'properties.acceleration.sumMaxMean', label: 'Mean Acceleration'}
 * @param sx
 * @returns {JSX.Element}
 */
const TrainDataPathStackedChartContainer = forwardRef(
  (
    {
      appProps,
      organizationProps,
      trainProps,
      componentProps: {
        loading,
        yAxisWidth,
        dataPathsConfigs,
        trainDataPathStackedChartRefWidth,
        maybeLifetimeContainers,
      },
    }: TrainAppTrainComponentDependencyProps<TrainDataPathStackedChartContainerProps>,
    ref,
  ) => {
    const {t} = useTranslation();
    // The sx for each chart
    const chartSx = {
      height: 200,
      width: '100%',
    };

    const loadingExplanation =
      trainProps.trainGroupSingleTrainRunProps.trainGroupChartProps?.whatIsLoading
        ?.loadingExplanation;

    const activeTrainGroups: TrainGroup[] =
      trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors;

    const trainGroupsWithFormatting = useNotLoadingMemo(
      loading || !activeTrainGroups,
      (activeTrainGroups) => {
        // Add formatting to each TrainGroup
        return map((trainGroup) => {
          return clsOrType<TrainGroupWithFormatting>(
            CemitTypename.trainGroupWithFormatting,
            {
              trainGroup,
              trainFormationName: trainGroup.trainFormation.name,
              label: trainGroupUniqueLabel(t, trainGroup),
              color: trainGroup.activity.isActiveColor,
              isVisible: trainGroup.activity.isVisible,
            },
          );
        }, activeTrainGroups);
      },
      [activeTrainGroups] as const,
    );

    // These DataFeatureSets match the TrainGroup in order and number at their top level. Within each
    // there can be multiple DataFeatureCollection that are used for different DataConfig find the correct DataFeatureCollection
    // where featureSetSourceTypeName helpers the DataConfig
    const dataFeatureSetsMinimized: Perhaps<DataFeatureSetMinimized[]> =
      useNotLoadingMemo(loading, () => {
        // Otherwise the charts rely on dataSets configured specifically for chart
        // TODO for now take the head as we assume only one wheelset is selectable.
        // This gives us a single dimension array of DataFeatureSets
        // Then put it back in an array to represent one TrainGroup
        return [
          headOrThrow(
            trainProps.trainGroupSingleTrainRunProps.trainGroupChartProps!
              .chartDataFeatureSets,
          ),
        ];
      }, [
        trainProps.trainGroupSingleTrainRunProps.trainGroupChartProps!
          .chartDataFeatureSets,
      ]);

    const dataFeatureSets: Perhaps<DataFeatureSet[]> = useNotLoadingMemo(
      // loading is only based  on the length of chartDataFeatureSets, so we have to check the other
      // collections, which go undefined when the date interval changes or similar
      loading || !trainGroupsWithFormatting || !dataFeatureSetsMinimized,
      (trainGroupsWithFormatting, dataFeatureSetsMinimized) => {
        return zipWith(
          (
            formatting: TrainGroupWithFormatting,
            dataFeatureSet: DataFeatureSetMinimized | DataFeatureSetMinimized[],
          ) => {
            const dataFeatureSets = toArrayIfNot(
              dataFeatureSet,
            ) as DataFeatureSetMinimized[];
            return map((dataFeatureSet: DataFeatureSetMinimized) => {
              // Add formatting to the label if defined
              const resolvedDataFeatureSetLabel = when(
                () => dataFeatureSet.sourceTypename,
                (label: string) => {
                  return concat(label, dataFeatureSet.sourceTypename!);
                },
              )(formatting.label);
              // TODO do something to vary the color
              const color = formatting.color;
              return clsOrType<DataFeatureSet>(CemitTypename.dataFeatureSet, {
                ...omit(['dataFeatureCollections'], dataFeatureSet),
                dataFeatureCollections: map(
                  (dataFeatureCollection: DataFeatureCollection) => {
                    return clsOrType<DataFeatureCollection>(
                      CemitTypename.dataFeatureCollection,
                      {
                        // We can now convert the DataFeatureCollection label function to a label string since the
                        // dataFeatureSet.label is in scope
                        ...overClassOrType(
                          lensProp('label'),
                          (label: string | ((dataFeatureSetLabel: string) => string)) => {
                            return when(is(Function), (labelFunc) =>
                              labelFunc(resolvedDataFeatureSetLabel),
                            )(label);
                          },
                          omit(['color', 'isVisible'], dataFeatureCollection),
                        ),
                        // Resolve the color if it's a function
                        color: dataFeatureCollection.color || color,
                        isVisible: dataFeatureCollection.isVisible,
                      },
                    );
                  },
                  dataFeatureSet.dataFeatureCollections,
                ),
                ...omit(['label', 'color'], formatting),
                label: resolvedDataFeatureSetLabel,
                color,
              });
            }, dataFeatureSets);
          },
          trainGroupsWithFormatting,
          // This can either be a single DataFeatureSet for each TrainGroup or multiple if we are using multiple
          // data sources
          dataFeatureSetsMinimized,
        );
      },
      [trainGroupsWithFormatting, dataFeatureSetsMinimized] as const,
    );

    // TODO Something in StackedDataPathComparisonChartContainer is updated based on realtime updates,
    // which shouldn't impact the component. So pick just the relevant props from trainProps
    const trainPropsLimited: TrainProps = useMemo(
      () => {
        return pickClassOrType(
          [
            'railwayLineProps',
            'serviceLineProps',
            'trainRouteGroupProps',
            'trainFormationDateProps',
            'filteredTrainGroupProps',
            'trainGroupOnlyTrainFormationProps',
            'trainGroupSingleTrainRunProps',
          ],
          trainProps,
        );
      },
      props(
        [
          'railwayLineProps',
          'serviceLineProps',
          'trainRouteGroupProps',
          'trainFormationDateProps',
          'filteredTrainGroupProps',
          'trainGroupOnlyTrainFormationProps',
          'trainGroupSingleTrainRunProps',
        ],
        trainProps,
      ),
    );
    return (
      <StackedDataPathComparisonChartContainer
        {...{
          ref,
          appProps,
          organizationProps,
          trainProps: trainPropsLimited,
          componentProps: {
            loading: loading || !dataFeatureSets,
            loadingExplanation,
            yAxisWidth,
            dataPathsConfigs,
            trainDataPathStackedChartRefWidth,
            dataFeatureSets,
            maybeLifetimeContainers,
            sx: chartSx,
          },
        }}
      />
    );
  },
);
TrainDataPathStackedChartContainer.displayName = 'TrainDataPathStackedChartContainer';
export default TrainDataPathStackedChartContainer;
