import {add, reduce} from 'ramda';
import {AlertDatum} from 'types/alerts/alertMapData';

/**
 * Adds up the count attirbute value of each RideComfortAlertCount
 * @param rideComfortAlertCounts
 */
export const getTotalCount = (rideComfortAlertCounts: AlertDatum[]): number => {
  return reduce(
    (accum, rideComfortAlertCount: AlertDatum) => {
      return add(accum, rideComfortAlertCount.count);
    },
    0,
    rideComfortAlertCounts,
  );
};
