// @ts-ignore
import TrainGroupListContainer from '../../../components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupListContainer.tsx';
import React from 'react';
import {ReverseTrainRouteOrGroup} from '../../../types/eventHandlers/reverseTrainRouteOrGroup';
import {Size} from '../../../types/layout/size';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

export type TrainPageListContainerCollapsedProps = {
  handleDrawerOpen: Function;
  handleDrawerClose: Function;
  reverseTrainRouteOrGroup: ReverseTrainRouteOrGroup;
  trainDetailSize: Size;
  sx;
};
/***
 * A collapsed view the TrainGroup list  when the Map is fully expanded
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainGroupListBoardCollapsed = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppMapDependencyProps & {
  componentProps: TrainPageListContainerCollapsedProps;
}) => {
  return (
    <TrainGroupListContainer
      key="trainGroupListBoardCollapsed"
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          ...componentProps,
          sx: {
            zIndex: 100,
            position: 'absolute',
            left: 0,
            overflow: 'hidden',
            height: '100%',
            width: '260px',
            top: 0,
          },
        },
      }}
    />
  );
};
export default TrainGroupListBoardCollapsed;
