import * as React from 'react';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
    fill: '#E5C349FF',
    stroke: 'none'
};

function RestrictionPin(props) {
    const {size = 20} = props;

    return (
        <svg width="41" height="76" viewBox="0 0 41 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.8002 21.123C39.8002 31.7412 31.2839 40.3312 20.8002 40.3312C10.3165 40.3312 1.8002 31.7412 1.8002 21.123C1.8002 10.5048 10.3165 1.91483 20.8002 1.91483C31.2839 1.91483 39.8002 10.5048 39.8002 21.123Z" fill="#FFD952" stroke="#FA5760" strokeWidth="2"/>
            <path d="M14.508 9.54324H15.6466V9.14694V8.75064H15.9582H16.2699V21.123L16.2699 33.4953H15.9582H15.6466V33.07V32.6447H14.5128L13.3814 32.6467V31.7613V30.8759L14.5128 30.8701L15.6466 30.8662V29.9808V29.0974H14.5152H13.3814L13.3743 28.2139L13.3695 27.3285H14.508H15.6466V26.4392V25.55L14.5128 25.5461L13.3814 25.5403L13.3743 24.6568L13.3695 23.7715H14.508H15.6466V22.8822V21.9929L14.5128 21.9891L13.3814 21.9833L13.3743 21.0998L13.3695 20.2144H14.508H15.6466V19.3251V18.4359H14.508H13.3695V17.5466L13.3695 16.6573H14.508H15.6466V15.7681L15.6466 14.8788H14.508H13.3695V13.9896V13.1003H14.508H15.6466V12.211V11.3218H14.508H13.3695V10.4325V9.54324H14.508Z" fill="#3A4750"/>
            <path d="M17.2047 8.75064H17.5163V9.14694V9.54324L20.6684 9.54324H23.8204V9.14694V8.75064H24.1321H24.4437V21.123L24.4437 33.4953H24.1321H23.8204L23.8156 33.0758L23.8085 32.6544H20.6684H17.5283L17.5211 33.0758L17.5163 33.4953H17.2047H16.8931L16.8931 21.123V8.75064H17.2047ZM20.6684 13.1003H23.8204V12.211V11.3218H20.6684H17.5163V12.211V13.1003H20.6684ZM20.6612 16.6535L23.8085 16.6477L23.8156 15.7642L23.8204 14.8788H20.6684H17.5163V15.7681V16.6573L20.6612 16.6535ZM20.6684 20.2047H23.8085L23.8156 19.3213L23.8204 18.4359H20.6684H17.5163L17.5211 19.3213L17.5283 20.2047H20.6684ZM20.6684 23.7618H23.8085L23.8156 22.8783L23.8204 21.9929H20.6684H17.5163L17.5211 22.8783L17.5283 23.7618H20.6684ZM20.6684 27.3188H23.8085L23.8156 26.4354L23.8204 25.55H20.6684H17.5163L17.5211 26.4354L17.5283 27.3188H20.6684ZM20.6684 30.8662H23.8204L23.8156 29.9866L23.8085 29.107H20.6732C18.9497 29.107 17.5355 29.107 17.5283 29.107C17.5211 29.107 17.5163 29.5033 17.5163 29.9866V30.8662H20.6684Z" fill="#3A4750"/>
            <path d="M25.3545 8.75064H25.6662L25.6709 9.14308L25.6781 9.53358L26.9533 9.53938L28.231 9.54324V10.4325V11.3218H26.9486H25.6662L25.6709 12.2072L25.6781 13.0906L26.9533 13.0964L28.231 13.1003V13.9896V14.8788H26.9486H25.6662L25.6709 15.7642L25.6781 16.6477L26.9533 16.6535L28.231 16.6573V17.5466V18.4359H26.9486H25.6662L25.6709 19.3213L25.6781 20.2047L26.9533 20.2105L28.231 20.2144V21.1037L28.231 21.9929H26.9486H25.6662L25.6709 22.8783L25.6781 23.7618L26.9533 23.7676L28.231 23.7715V24.6607V25.55H26.9486H25.6662L25.6709 26.4354L25.6781 27.3188L26.9533 27.3246L28.231 27.3285V28.2178V29.107H26.9533L25.6781 29.1051L25.6709 29.9866L25.6662 30.8662H26.9486H28.231V31.7613V32.6544H26.9557H25.6781L25.6709 33.0758L25.6662 33.4953H25.3545H25.0429L25.0429 21.123V8.75064H25.3545Z" fill="#3A4750"/>
            <path d="M34.5397 6.65042L7.56613 35.869" stroke="#FA5760" strokeWidth="2"/>
        </svg>
    );
}

export default React.memo(RestrictionPin);
