import {inspect} from 'util';
import {
  always,
  chain,
  cond,
  equals,
  filter,
  flatten,
  head,
  identity,
  ifElse,
  join,
  length,
  map,
  prop,
  propOr,
  sortBy,
  T,
  when,
} from 'ramda';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {TrainRouteGroup} from 'types/trainRouteGroups/trainRouteGroup';
import {DateRecurrence, DateRecurrenceValue} from 'types/datetime/dateRecurrence';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainApiTrainRunRequestProps} from 'types/trainRuns/trainRun';

import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {IdsRequired} from 'types/typeHelpers/identifiedRequired';
import {listToIds} from 'utils/functional/functionalUtils.ts';

import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Gives the TrainApi parameter for querying for TrainRuns matching the given TrainRoute or TrainRouteGroup
 * @param trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {Object} The query params
 */
export const trainRunTrainRouteParams = (
  trainRouteOrGroup: Partial<TrainRouteOrGroup>,
) => {
  return cond([
    // Use the TrainRouteIds
    [
      (trainRouteOrGroup) =>
        implementsCemitTypeViaClass(
          CemitTypename.trainRouteGroupMinimized,
          trainRouteOrGroup,
        ),
      (trainRouteGroup: TrainRouteGroup): Record<string, string> => {
        return {
          journeyPattern_trainRouteId_in_: listToIds(
            sortBy(prop('id'), trainRouteGroup.trainRoutes),
          ),
        };
      },
    ],
    // Use the TrainRouteId
    [
      (trainRouteOrGroup) =>
        implementsCemitTypeViaClass(CemitTypename.trainRouteMinimized, trainRouteOrGroup),
      (trainRoute: IdsRequired<TrainRoute>): Record<string, string> => {
        return {journeyPattern_trainRouteId: trainRoute.id};
      },
    ],
    [
      T,
      (trainRoute: TrainRouteOrGroup): never => {
        throw Error(
          `Non-TrainRoute or TrainRouteGroup instance: ${inspect(trainRoute, {depth: 1})}`,
        );
      },
    ],
  ])(trainRouteOrGroup);
};

/**
 * Creates params for querying TrainRun by DateInterval
 * TODO only uses the first dateInterval of dateIntervals for now
 * @param dateIntervals
 * @returns {*}
 */
export const trainDateParams = (
  dateIntervals: DateInterval[] | undefined,
): TrainApiTrainRunRequestProps => {
  const dateInterval = head(dateIntervals || []);

  return ifElse(
    Boolean,
    (dateInterval: DateInterval): TrainApiTrainRunRequestProps => {
      return {
        departureDatetime_ge: dateInterval.start!,
        departureDatetime_le: dateInterval.end!,
      };
    },
    always({}),
  )(dateInterval);
};

/**
 * Creates params for querying TrainRun by dateRecurrences
 * @param dateRecurrences
 * @returns {*}
 */
export const trainDateRecurrenceParams = (
  dateRecurrences: DateRecurrence[],
): TrainApiTrainRunRequestProps => {
  return ifElse(
    identity,
    (dateRecurrences: DateRecurrence[]): TrainApiTrainRunRequestProps => {
      const dateRecurrenceDays = join(
        ',',
        chain(
          (dateRecurrence: DateRecurrence) => {
            // flatten is here for weekend and weekday groupings
            return flatten((dateRecurrence as DateRecurrence).values as string[]);
          },
          filter(
            (dateRecurrence: DateRecurrence) =>
              equals('getDay', dateRecurrence.attribute),
            dateRecurrences || [],
          ),
        ),
      );

      const values: string[] = map(
        // TODO this format of dateRecurrence.values.values  needs to be fixed

        (dateRecurrence: DateRecurrence) =>
          chain((value: DateRecurrenceValue) => {
            return propOr([value], 'values', value);
          }, dateRecurrence.values),
        filter(
          (dateRecurrence: DateRecurrence) => equals('getTime', dateRecurrence.attribute),
          dateRecurrences || [],
        ),
      );

      const dateRecurrenceDepartureTimes = when(
        (list: string[]) => length(list),
        (list: string[]) => {
          return encodeURIComponent(join(',', list));
        },
      )(values);
      return {
        departureDay_in_: dateRecurrenceDays,
        departureTime_in_: dateRecurrenceDepartureTimes,
      };
    },
    always({}),
  )(dateRecurrences);
};
