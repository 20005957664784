import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
import {PieChart, Pie, Cell, Label, LabelProps, Text} from 'recharts';
import {StatusCount, TagData, VehicleStatus} from 'pages/softPrio/types';
// Define the TableData type
interface PieData {
  name: string;
  value: number;
  color: string;
}
// Sample data for the doughnut chart
const pieData = [
  {name: 'Good', value: 60, color: '#4caf50'},
  {name: 'Alert', value: 30, color: '#ffb300'},
  {name: 'Warning', value: 10, color: '#f44336'},
];
const convertToPieData = (data: StatusCount): PieData[] => {
  return [
    {name: 'Good', value: data.good, color: '#4caf50'},
    {name: 'Alert', value: data.alert, color: '#ffb300'},
    {name: 'Warning', value: data.warning, color: '#f44336'},
  ];
};
// Custom Label Component
interface CustomLabelProps extends LabelProps {
  x: number;
  y: number;
  viewBox: {
    cx: number;
    cy: number;
  };
}
interface DetectorProps {
  singleProcessedTag: VehicleStatus;
}
const gettingAverageOfSignals = (value: number, count: number): number => {
  const result = (value / count) * 100;
  return parseFloat(result.toFixed(1));
};
function getSignalColor(signalValue: number): string {
  if (signalValue > -65) {
    // above -65
    return '#379C54';
  } else if (signalValue <= -65 && signalValue >= -75) {
    // between -65 and -75
    return '#CBA70E';
  } else if (signalValue < -75 && signalValue >= -85) {
    // between -75 and -85
    return '#fab157';
  } else if (signalValue < -85 && signalValue >= -95) {
    // between -85 and -95
    return '#FA5760';
  } else {
    // below -95
    return ''; // Default case, you can set this to any color or handle differently
  }
}
const DetectorTable: React.FC<DetectorProps> = ({singleProcessedTag}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        color: '#000000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        minHeight: '100vh',
        width: '100%',
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          padding: 3,
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={4} style={{position: 'relative', textAlign: 'center'}}>
            <PieChart width={200} height={200}>
              <Pie
                data={convertToPieData(singleProcessedTag.signalAverage)}
                innerRadius={70}
                outerRadius={90}
                dataKey="value"
                cx="50%"
                cy="50%"
              >
                <Label
                  value={singleProcessedTag.vehicleid}
                  position="center"
                  style={{
                    textAnchor: 'middle',
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    fill: 'black',
                  }}
                />
                <Label
                  content={(props: CustomLabelProps) => {
                    const {
                      viewBox: {cx, cy},
                    } = props;

                    return (
                      <>
                        <Text
                          x={cx}
                          y={cy + 30} // Adjust vertical position
                          textAnchor="middle"
                          fontSize="0.8rem"
                          fontWeight="bold"
                          fill="black"
                        >
                          Spårvagn
                        </Text>
                      </>
                    );
                  }}
                />
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{pt: 3}}>
              <Typography variant="h6">
                <span style={{color: '#4caf50'}}>✔ God</span>:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.good,
                  singleProcessedTag.count,
                )}
                %
              </Typography>
              <Typography variant="h6">
                <span style={{color: '#ffb300'}}>⚠ Alert</span>:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.alert,
                  singleProcessedTag.count,
                )}
                %
              </Typography>
              <Typography variant="h6">
                <span style={{color: '#f44336'}}>❌ Warning</span>:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.warning,
                  singleProcessedTag.count,
                )}
                %
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          style={{marginTop: 30, backgroundColor: '#ffffff'}}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{color: 'black', textAlign: 'center'}}>Tag</TableCell>
                {/* <TableCell style={{color: 'black', textAlign: 'center'}}>
                  Ursprung
                </TableCell> */}
                <TableCell style={{color: 'black', textAlign: 'center'}}>
                  Apparatur Namn
                </TableCell>
                <TableCell style={{color: 'black', textAlign: 'center'}}>
                  Länkkvalitet
                </TableCell>
                <TableCell style={{color: 'black', textAlign: 'center'}}>
                  Buffringsvärde
                </TableCell>
                <TableCell style={{color: 'black', textAlign: 'center'}}>
                  Omsändningar
                </TableCell>
                <TableCell style={{color: 'black', textAlign: 'center'}}>Datum</TableCell>
                <TableCell style={{color: 'black', textAlign: 'center'}}>
                  RSSI-värde
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {singleProcessedTag.items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    {row.eventData.tag_id}
                  </TableCell>

                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    {row.eventData.udp_packets.destination_address}
                  </TableCell>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    <Box display="flex" alignItems="center">
                      <Box
                        width="100%"
                        bgcolor={
                          row.eventData.udp_packets.lqi > 50
                            ? '#4caf50'
                            : row.linkQuality > 20
                              ? '#ffb300'
                              : '#f44336'
                        }
                        height={10}
                        mr={1}
                        borderRadius={5}
                      />
                      {row.eventData.udp_packets.lqi}%
                    </Box>
                  </TableCell>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    {row.eventData.udp_packets.tag_buffer
                      ? row.eventData.udp_packets.tag_buffer
                      : 0}
                  </TableCell>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    {row.eventData.udp_packets.resend_flag ? 'True' : 'False'}
                  </TableCell>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    {row.detectionTime}
                  </TableCell>
                  <TableCell style={{color: 'black', textAlign: 'center'}}>
                    <Typography
                      style={{
                        color: getSignalColor(row.eventData.udp_packets.rssi),
                      }}
                    >
                      {row.eventData.udp_packets.rssi} dbm
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DetectorTable;
