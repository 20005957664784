import {Link, useNavigate} from 'react-router-dom';
import {Button, Stack, Switch, Typography} from '@mui/material';
import {join, map} from 'ramda';
import {capitalize} from '@rescapes/ramda';
import React, {useContext} from 'react';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import OrganizationChooser from 'components/apps/cemitAppComponents/CemitAdminOrganizationChooser.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {OrganizationMenuProps} from 'components/apps/cemitAppComponents/OrganizationMenu.tsx';
import {useToken} from 'utils/hooks/useTokenHook.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {hasAdminAccess} from 'appUtils/authentication/authenticationUtils.ts';
import {logout} from 'pages/protectedRoute/userUtils.ts';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import {handleAddOrganizationToFilter} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/trainApiOrganizationHooks.ts';
import {CemitAppTrainDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppTrainDependencyProps';

/**
 * User profile panel showing user information and a logout button
 *
 * @param appProps
 * @param organizationProps
 * @returns {JSX.Element}
 * @constructor
 */
const ProfilePanel = ({
  appProps,
  organizationProps,
  trainProps,
}: CemitAppTrainDependencyProps): JSX.Element => {
  const navigate = useNavigate();
  const [, setToken] = useToken();
  const {userState} = useContext(UserContext) as UserContextType;

  if (userState.status !== AccessStatus.Authenticated) {
    return <></>;
  }
  const access = userState.access;
  if (!access || !organizationProps.organization) {
    return <></>;
  }

  const isAdmin = hasAdminAccess(userState);
  const adminOverride = (defaults: string[]): string[] => {
    return isAdmin ? appProps.t('all') : defaults;
  };
  const joinTitleAndValues = (titleKey: string, values: string | string[]) => {
    return join(': ', [appProps.t(titleKey), join(', ', toArrayIfNot(values))]);
  };
  const organizationLabel = joinTitleAndValues(
    'organization',
    organizationProps.organization.name,
  );
  const emailLabel = userState?.email && joinTitleAndValues('email', userState?.email);
  const applicationAccessLabel = joinTitleAndValues(
    'applicationAccess',
    adminOverride(access.applicationAccess),
  );
  const railwayAccessLabel = joinTitleAndValues(
    'railwayAccess',
    adminOverride(map(capitalize, access.trackAccess)),
  );
  const componentProps: OrganizationMenuProps = clsOrType<OrganizationMenuProps>(
    CemitTypename.organizationMenuProps,
    {
      chosenOrganization: organizationProps.organization,
      handleAddOrganizationToFilter,
      label: organizationProps.organization.name,
    },
  );
  return (
    <Stack
      {...{
        sx: {
          p: 2,
          width: '100%',
          alignItems: 'top',
          justifyContent: 'right',
        },
      }}
    >
      <Stack
        key="organization"
        {...{spacing: 2, sx: {color: 'secondary', textAlign: 'right'}}}
      >
        <Stack {...{spacing: 2}}>
          {isAdmin ? (
            <OrganizationChooser
              key="organizationChooser"
              {...{
                organizationProps,
                componentProps,
              }}
            />
          ) : undefined}
          <Typography key="orgName" color="inherit">
            {organizationLabel}
          </Typography>
          {emailLabel && (
            <Typography key="accountEmail" color="inherit">
              {emailLabel}
            </Typography>
          )}
          {AppSettings.appEnv === 'local'
            ? [
                <Typography key="notice">SHOWN ONLY IN LOCAL DEVELOPMENT:</Typography>,
                <Typography key="appAccess" color="inherit">
                  {applicationAccessLabel}
                </Typography>,
                <Typography key="railwayAccess" color="inherit">
                  {railwayAccessLabel}
                </Typography>,
              ]
            : []}
          {hasAdminAccess(userState) ? (
            <Stack
              {...{
                direction: 'row',
                sx: {justifyContent: 'right', justifyItems: 'center'},
              }}
            >
              <Typography
                key="railwayAccess"
                {...{color: 'inherit', sx: {alignSelf: 'center'}}}
              >
                {appProps.t('showTrainFormationRunCounts')}
              </Typography>
              <Switch
                {...{
                  inputProps: {'aria-label': 'Run Count Toggle'},
                  checked:
                    trainProps?.trainGroupOnlyTrainFormationProps
                      ?.showTrainFormationRunCounts,
                  onChange: () => {
                    trainProps?.trainGroupOnlyTrainFormationProps?.setShowTrainFormationRunCounts?.(
                      !trainProps?.trainGroupOnlyTrainFormationProps
                        ?.showTrainFormationRunCounts,
                    );
                  },
                }}
              />
            </Stack>
          ) : undefined}
        </Stack>
        <Link key="logout" to="/">
          <Button {...{variant: 'outlined', onClick: () => logout(setToken, navigate)}}>
            {appProps.t('logout')}
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default ProfilePanel;
