import * as React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {useMemo} from 'react';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import TrainGroupOverviewTableHead, {
  TableGroupTableHeadProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewTableHead.tsx';
import {
  TableGroupOverviewTableColumn,
  TrainGroupOverviewColumnAttributes
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewTableConfig.ts';

export interface TrainGroupOverviewComponentProps extends CemitComponentProps {
  trainGroups: TrainGroup[],
  trainGroupTableRows: JSX.Element[],
  tableHeadComponent: JSX.Element,
  rowsPerPage: number,
  page: number,
  handleChangePage: (event: unknown, newPage: number) => void,
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: TrainGroupOverviewColumnAttributes
  ) => void
  columns: TableGroupOverviewTableColumn[]
}

/**
 * Shows the TrainGroup Overview table
 * @param componentProps
 * @constructor
 */
const TrainGroupOverviewTable = (
  {
    appProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<TrainGroupOverviewComponentProps>
) => {

  const {
    trainGroupTableRows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage
  } = componentProps;

  // The TrainGroupOverviewTableHead needs to be rerendered when the sort choice changes
  const trainGroupOverviewTableHeadProps = useMemo(() => {
      return ts<TableGroupTableHeadProps>({
        columns: componentProps.columns,
        sortDirection: appProps.trainGroupOverviewSortDirection,
        orderBy: appProps.trainGroupOverviewOrderBy,
        onRequestSort: componentProps.handleRequestSort
      });
    }, [appProps.trainGroupOverviewSortDirection, appProps.trainGroupOverviewOrderBy]
  );
  const tableHeadComponent = <TrainGroupOverviewTableHead {...{
    appProps,
    componentProps: trainGroupOverviewTableHeadProps
  }} />;

  return <Paper {...{sx: {width: '100%', height: '100%', minHeight: 0, overflow: 'hidden'}}}>
    <TableContainer {...{sx: {height: '100%', minHeight: 0}}}>
      <Table stickyHeader aria-label="sticky table">
        {tableHeadComponent}
        <TableBody {...{sx: {height: '100%', minHeight: 0, overflowY: 'auto'}}}>
          {trainGroupTableRows}
        </TableBody>
      </Table>
    </TableContainer>
    {
      trainGroupTableRows.length > 100 ?
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={trainGroupTableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : undefined
    }
  </Paper>;
};
export default TrainGroupOverviewTable;
