/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CachesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Clear Caches
     * Clears the caches
     * @returns any Successful Response
     * @throws ApiError
     */
    public clearCachesCachesDelete(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/caches/',
        });
    }
}
