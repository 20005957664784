import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TFunction} from 'i18next';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {CrudList} from 'types/crud/crudList';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {GraphqlResponseAlertData, GraphqlResponseDateIntervalOverviewAlertData} from 'types/alerts/alertMapData';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod';
import {zipWith} from 'ramda';
import {getAlertGaugeByTimePeriod} from 'appUtils/alertUtils/alertQueryUtils';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod';
import {setTrainGroupAlertSummariesWithQueryData} from 'async/alertAsync/alertHooks';
import {getAllTrainGroupsSummaryAlertGraphqlData} from './graphqlAlertOverviewAndSummaryQueries';
import { AlertTypeConfig } from 'types/alerts/alertTypeConfig';

/**
 * Queries for the Alert data of the given AlertScopeProps, which is scoped to a single TrainGroup
 * Returns overview and summary alerts for the TrainGroup
 * Overview are the Ride Comfort Alert values for 1 day, 30 days, 90 days, and 180 days
 * Summary is a summary for each alert type for the current date interval
 * @param t
 * @param alertScopePropSets
 * @param trainGroupCrudList
 */
export const queryAlertApiForAllOverviewAndSummaryAlertTypesAndMutate = async <T extends TrainGroup>(
  t: TFunction,
  alertScopeProps: AlertScopeProps<T>,
  trainGroupCrudList: CrudList<TrainGroup>
): Promise<void> => {

  // Makes a separate query the TrainGroup in the scope of alertScopeProps
  // querying for alert overview data for the last day, 30 days, 90 days, and 180 days
  // and query for summary data for each alert type for the current date interval
  const {summaryAlertGraphqlResponseAlertData, overviewAlertGraphqlResponseAlertData}: {
    summaryAlertGraphqlResponseAlertData: GraphqlResponseAlertData[],
    overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[]
} =
    await getAllTrainGroupsSummaryAlertGraphqlData(t, alertScopeProps);

  if (summaryAlertGraphqlResponseAlertData) {
    const {alertTypeConfigs} = alertScopeProps.alertConfigProps;

    // Zip each alertTypeConfig with the corresponding alertGraphqlResponseAlertDataSet
    // to create an AlertGaugeByTimePeriod
    const summaryAlertGaugesByTimePeriod: AlertGaugeByTimePeriod[] = zipWith(
      (
        alertTypeConfig: AlertTypeConfig,
        alertGraphqlResponseAlertData: GraphqlResponseAlertData
      ): AlertGaugeByTimePeriod => {
        return getAlertGaugeByTimePeriod(alertGraphqlResponseAlertData);
      },
      alertTypeConfigs,
      summaryAlertGraphqlResponseAlertData
    );

    // Sets the trainGroup.alertScopeSummaryProps
    setTrainGroupAlertSummariesWithQueryData(
      alertScopeProps,
      summaryAlertGaugesByTimePeriod,
      overviewAlertGraphqlResponseAlertData,
      trainGroupCrudList
    );
  }
};
