import React from 'react';
import {Chip, ThemeProvider, useTheme} from '@mui/material';
import {alpha, Autocomplete, Box, createFilterOptions, TextField} from '@mui/material';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';

export default function TrafficSimSearchBar({
  searchEntries,
  onChange,
  onSearch,
  value,
  mapRef,
}) {
  const theme = useTheme();
  const filter = createFilterOptions();

  const moveToCoord = (obj) => {
    if (obj && obj.props.coordinates) {
      const coordinates = obj.props.coordinates;
      if (mapRef.current && coordinates) {
        mapRef.current.flyTo({
          center: [coordinates[0], coordinates[1]],
          zoom: 16,
          essential: true,
        });
      }
    }
  };

  const lightTheme = themeLookup[ThemeEnum.light];
  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        {...{
          sx: {
            marginBottom: '5px',
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 1),
            // '&:hover': {
            //   backgroundColor: alpha(theme.palette.common.white, 0.25),
            // },
            marginLeft: 10,
            // marginTop: '50px',
            // marginBottom: '50px',
            marginRight: '15px',
            width: '15vw',
            // height: '20vh',
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.spacing(1),
              width: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
              // height: '30px',
            },
          },
        }}
      >
        <Autocomplete
          value={''}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              moveToCoord(newValue);
            } else if (newValue && newValue.inputValue) {
              // do nothing
              const item = searchEntries.find(
                (entry) => (entry.internal_id = newValue.inputValue),
              );
              if (item) {
                moveToCoord(item);
              }
            } else {
              moveToCoord(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="header-search-bar"
          options={searchEntries}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return '';
            }
            // Regular option
            // if(option.namn){
            //   return option.namn
            // }
            // if(option.internal_id){
            //   return option.internal_id
            // }
            return option.internal_id;
          }}
          renderOption={(props, option) => {
            delete props.key;
            if(option.internal_id === "") return;
            const listKey = `${option.id}-${Date.now()}`
            return <li key={listKey} {...props}>
              {option.internal_id}
            </li>
        }}
          sx={{
            '& legend': {display: 'none'},
            '& fieldset': {top: 0},
            margin: '0px 0px 0px 0',
            width: '15vw',
          }}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, tagProps } = getTagProps({ index });
              return (
                <Chip variant="outlined" label={option} key={key} {...tagProps} />
              );
            })
          }
          renderInput={(params) => {
            return <TextField key={}  {...params} label="Search" />;
          }}
          // margin="dense"
          // fullWidth
        />
      </Box>
    </ThemeProvider>
  );
}
