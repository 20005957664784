import {styled} from "@mui/system";
import {TablePagination} from "@mui/base/TablePagination";
import {tablePaginationClasses as classes} from "@mui/base/TablePagination/tablePaginationClasses";

export const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 8px 12px 8px 12px;
    background-color: transparent;
    color: ${theme.palette.text.primary};

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.text.primary};
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.text.primary};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};
    }

    &:focus {
      outline: 3px solid ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.text.primary};
    color: ${theme.palette.text.primary};
    transition: all 100ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};
    }

    &:focus {
      outline: 3px solid ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.text.primary};
        background-color: transparent;
      }
    }
  }
  `,
);
