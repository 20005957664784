import {
  addIndex,
  any,
  chain,
  equals,
  filter,
  forEach,
  forEachObjIndexed,
  groupBy,
  has,
  head,
  ifElse,
  includes,
  indexBy,
  last,
  length,
  lensProp,
  map,
  prop,
  propEq,
  propOr,
  reduce,
  set,
  startsWith,
  uniqBy,
  zipWith,
} from 'ramda';
import {mapObjToValues} from '@rescapes/ramda';
import bbox from '@turf/bbox';
import {
  MapboxIconConfig,
  MapboxLayer,
  MapSourceInfo,
  MapSourceVisual,
  MapSourceVisualForTrainGroup,
} from '../../types/mapbox/mapSourceVisual';
import {GeoJSONSource, GeoJSONSourceRaw, Map} from 'mapbox-gl';
import {Feature, FeatureCollection, LineString, Point, Position} from 'geojson';
import {MapSourceChangeStatus} from '../../types/mapbox/mapSourceChangeStatus';
import {BBox2d} from '@turf/helpers/dist/js/lib/geojson';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {ChangeTypeEnum} from '../../types/mapbox/changeTypeEnum.ts';
import {setChangeStatusesWithMerge} from '../../appUtils/cemitAppUtils/mapboxSourceChangeStatusUtils.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {compact, headOrThrow, toArrayIfNot} from '../functional/functionalUtils.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {lineString} from '@turf/helpers';
import turfLength from '@turf/length';
import along from '@turf/along';
import lineSlice from '@turf/line-slice';
import rhumbBearing from '@turf/rhumb-bearing';
import {lineSliceWithCorrectOrientation} from 'utils/geojson/geojsonUtils.ts';

/**
 * Create a Mapbox geojson source configuration.
 * @param featureCollection Geojson FeatureCollection
 * @param sourceName The name of the source
 * @param [options] Options for addSource.
 * @return {Object} The source configuration
 */
export const mapboxGeojsonSource = ({
  sourceName,
  featureCollection,
  options = {},
}: {
  sourceName: string;
  featureCollection: FeatureCollection;
  options?: Record<string, any>;
}): MapSourceInfo => {
  return clsOrType<MapSourceInfo>(CemitTypename.mapSourceVisual, {
    name: sourceName,
    type: 'geojson',
    data: featureCollection,
    ...(options || {}),
  });
};

/**
 * Get the Mapbox source if it exists
 * @param mapboxMap
 * @param sourceInfo
 */
export const getExistingMapboxSource = (
  mapboxMap: Map,
  sourceInfo: MapSourceInfo,
): Perhaps<GeoJSONSource> => {
  const {name, type = 'geojson', data, options = {}} = sourceInfo;
  return mapboxMap.getSource(name) as Perhaps<GeoJSONSource>;
};

/**
 * Update or create the given Mapbox source
 * @param mapboxMap The mapbox map
 * @param sourceInfo
 * @returns {Object} The name passed in and a change field that is 'update', 'create', or undefined, where
 *     undefined occurs if no change to the data was detected with a reference check
 */
export const updateOrCreateMapboxSourceIfNeeded = (
  mapboxMap: Map,
  sourceInfo: MapSourceInfo,
  // HACK for realtime layer. TODO generalize
  animate: boolean = false,
  trackRouteLineGeojson: Perhaps<Feature<LineString>> = undefined,
): MapSourceChangeStatus => {
  const {name, type = 'geojson', data, options = {}} = sourceInfo;

  const dataSource: Perhaps<GeoJSONSource> = getExistingMapboxSource(
    mapboxMap,
    sourceInfo,
  );
  const now = new Date();
  if (dataSource) {
    // Mapbox doesn't publicly offer the source data, and _data is the same object with a different reference
    // So compare feature length and then do a deep equals if needed
    // @ts-ignore Private member
    const privateData = dataSource._data as FeatureCollection;
    if (
      data.features.length !== privateData.features.length ||
      !equals(data, privateData)
    ) {
      const previousFeature = head(privateData!.features);
      if (animate && previousFeature) {
        // Used to increment the value of the point measurement against the route.
        // Get the lineString along the Railways from previousFeature to the current if possible.
        // Otherwise just make a straight line between the two
        // TODO We currently only download RailwayLine.geojson as segments.
        // If we download RailwayLine.trackRoutes, we can get unique tracks.
        // So just ignore the track and caculate a direct path from previous to current
        const featurePoints: Feature<Point>[] = uniqBy(
          (feature: Feature<Point>) => feature.geometry.coordinates,
          compact([
            previousFeature,
            headOrThrow(sourceInfo.data.features) as Feature<Point>,
          ]),
        );
        if (2 === length(featurePoints)) {
          const featureLineString = ifElse(
            Boolean,
            // Use trackRouteLineGeojson if available
            (trackRouteLineGeojson: Feature<LineString>) => {
              // Take a slice oriented from the first to second featurePoint
              return lineSliceWithCorrectOrientation(
                ...featurePoints,
                trackRouteLineGeojson,
              );
            },
            () => {
              // Else just use the line between the two points
              return lineString(
                map(
                  (feature: Feature<Point>): Position => feature.geometry.coordinates,
                  featurePoints,
                ),
              );
            },
          )(trackRouteLineGeojson);

          const steps = 100;
          let counter = 0;

          function animate() {
            const distance = turfLength(featureLineString, {units: 'meters'});

            const pointAlongLine = along(
              featureLineString,
              distance * (counter++ / steps),
              {units: 'meters'},
            );
            // Maintain the properties
            const pointAlongLineWithProperties = set(
              lensProp('properties'),
              last(featurePoints)!.properties,
              pointAlongLine,
            );
            const source: GeoJSONSource = getExistingMapboxSource(mapboxMap, sourceInfo)!;
            // Update the source with this new data
            if (source) {
              source.setData({
                type: 'FeatureCollection',
                features: [pointAlongLineWithProperties],
              });
            }

            // Request the next frame of animation as long as the end has not been reached
            if (counter < steps) {
              requestAnimationFrame(animate);
            } else {
              // Done
              dataSource.setData(data);
            }
          }

          animate();
        }
      } else {
        // Update case
        dataSource.setData(data);
        return clsOrType<MapSourceChangeStatus>(CemitTypename.changeStatus, {
          name,
          changeType: ChangeTypeEnum.updated,
          sourceInfo,
          lastUpdated: now,
        });
      }
    }
    return clsOrType<MapSourceChangeStatus>(CemitTypename.changeStatus, {
      name,
      changeType: ChangeTypeEnum.unchanged,
      sourceInfo,
    });
  } else {
    // Create case
    mapboxMap.addSource(name, {type, data, ...options} as GeoJSONSourceRaw);
    return clsOrType<MapSourceChangeStatus>(CemitTypename.changeStatus, {
      name,
      changeType: ChangeTypeEnum.created,
      sourceInfo,
      lastUpdated: now,
    });
  }
};

/**
 * Calls setMapboxSourceAndLayers on multiple sets of mapSourceVisuals
 * @param mapboxMap
 * @param setChangeStatuses
 * @param mapSourceVisuals
 * @param [zoomToSources] Default false, zoom to the sources
 */
export const setMapboxSourceAndLayersSets = (
  mapboxMap: Map,
  setChangeStatuses: StateSetter<MapSourceChangeStatus[]>,
  mapSourceVisuals: MapSourceVisualForTrainGroup[],
  zoomToSources: boolean,
  // Hacks for realtime layer, TODO generalize
  animate: boolean = false,
  easingSeconds?: Perhaps<number> = undefined,
): MapSourceChangeStatus[] => {
  const changeStatuses: MapSourceChangeStatus[] = map(
    (mapSourceVisual: MapSourceVisualForTrainGroup): MapSourceChangeStatus => {
      return setMapboxSourceVisual({
        mapboxMap,
        mapSourceVisual,
        animate,
      });
    },
    mapSourceVisuals,
  );

  // Zoom to the total bbox of the sources if zoomToSources is true and any of the sources actually changed
  if (
    length(mapSourceVisuals) &&
    zoomToSources &&
    any((changeStatus: MapSourceChangeStatus) => {
      return !propEq(ChangeTypeEnum.unchanged, 'changeType', changeStatus);
    }, changeStatuses)
  ) {
    mapboxZoomToSources(mapboxMap, mapSourceVisuals, easingSeconds);
  }
  setChangeStatusesWithMerge(setChangeStatuses, changeStatuses);
  return changeStatuses;
};

/**
 * Zoom to the given MapSourceVisuals
 * @param mapboxMap
 * @param mapSourceVisuals
 */
export const mapboxZoomToSources = (
  mapboxMap: Map,
  mapSourceVisuals: MapSourceVisual[],
  easingSeconds?: Perhaps<number> = undefined,
  paddingNormal: number = 100,
  passingFewFeatures: number = 200,
) => {
  const bboxableFeatures = chain(({source, layers}: MapSourceVisual) => {
    // If any layer defines a filter, assume they all have a filter and
    // use mapboxMap.querySourceFeatures with each layer's filter
    const features = layers?.[0]?.filter
      ? chain((layer) => {
          return mapboxMap.querySourceFeatures(source.name, {filter: layer.filter});
        }, layers)
      : source.data.features;
    const featureOrFeatureCollection = length(features)
      ? {type: 'FeatureCollection', features}
      : undefined;
    return featureOrFeatureCollection?.type === 'FeatureCollection'
      ? featureOrFeatureCollection.features
      : toArrayIfNot(featureOrFeatureCollection);
  }, mapSourceVisuals);
  if (length(bboxableFeatures) > 1) {
    const encompassingBbox: BBox2d = reduce(
      (previousBbox: BBox2d, feature: Feature): BBox2d => {
        const box = bbox(feature) as BBox2d;
        return addIndex(zipWith)(
          (l: number, r: number, index: number): number => {
            // take the min of the min lat lon and the max of the max lat lon
            return index < 2 ? Math.min(l, r) : Math.max(l, r);
          },
          // SW pair to NE pair
          previousBbox,
          // SW pair to NE pair
          box,
        );
      },
      [Infinity, Infinity, -180, -90],
      bboxableFeatures,
    );

    // For debugging
    // const geojson = bboxPolygon(encompassingBbox);
    mapboxMap.fitBounds(encompassingBbox, {
      essential: true,
      // Pad a bunch if there are few features
      padding: length(bboxableFeatures) > 5 ? paddingNormal : passingFewFeatures,
      ...(easingSeconds
        ? {
            duration: 5 * 1000,
            animate: true,
          }
        : {}),
    });
  } else if (length(bboxableFeatures) == 1) {
    mapboxMap.setCenter(bboxableFeatures[0].geometry.coordinates);
    mapboxMap.setZoom(12);
  }
};

/**
 * Given a Mapbox source and layers that style it, update or create the source
 * and remove/create the layers
 * @param mapboxMap
 * @param mapSourceVisual
 * @returns {Object} {name: source name, change: 'create'|'update'|undefined} where undefined is returned
 * if the data reference did not change since the last setData
 */
export const setMapboxSourceVisual = ({
  mapboxMap,
  mapSourceVisual,
  // Hacks TODO generalize
  animate = false,
  trackRouteLineGeojson = undefined,
}: {
  mapboxMap: Map;
  mapSourceVisual: MapSourceVisualForTrainGroup;
  animate: boolean;
}): MapSourceChangeStatus => {
  const {source, layers} = mapSourceVisual;
  const changeStatus: MapSourceChangeStatus = updateOrCreateMapboxSourceIfNeeded(
    mapboxMap,
    source,
    animate,
    mapSourceVisual.trackRouteLineGeojson,
  );
  const layerIds = map(prop('id'), layers);
  const matchingLayerLookup = indexBy(
    prop('id'),
    filter((layer) => {
      return includes(layer.id, layerIds);
    }, mapboxMap.getStyle().layers),
  );
  forEach((layer: MapboxLayer): void => {
    // Remove the layer and any svg icons
    if (has(layer.id, matchingLayerLookup)) {
      if (layer.iconConfig) {
        const {iconConfigs} = layer.iconConfig;
        forEach((mapboxIconConfig: MapboxIconConfig) => {
          const {name} = mapboxIconConfig;
          if (mapboxMap.hasImage(name)) {
            mapboxMap.removeImage(name);
          }
        }, iconConfigs);
      }
      mapboxMap.removeLayer(layer.id);
    }
    // New layer, see if any svg icons are defined
    if (layer.iconConfig) {
      const {iconConfigs, width, height} = layer.iconConfig;
      forEach((mapboxIconConfig: MapboxIconConfig) => {
        const {svg, name, iconConfigSize} = mapboxIconConfig;
        if (!mapboxMap.hasImage(name)) {
          // iconConfigSize overrides the default width and height of the image.
          // This allows individual icons to scale up without blurring, which happens if they are scaled
          // by the mapbox image-size expression
          const img: HTMLImageElement = new Image(
            iconConfigSize || width,
            iconConfigSize || height,
          );
          img.onload = () => mapboxMap.addImage(name, img);
          img.src = svg;
        }
      }, iconConfigs);
    }
    mapboxMap.addLayer(layer);
  }, layers);
  return changeStatus;
};

/***
 * Remove sources and layers matching the sourcePrefix and layerPrefix that are not in
 * the exclude mapSourceVisuals
 * @param layerPrefix
 * @param sourcePrefix
 * @param exclude mapSourceVisuals to not remove
 * @param mapboxMap
 */
export const removeMapboxLayersAndSources = ({
  layerPrefix,
  sourcePrefix,
  mapboxMap,
  preserveSourceVisuals = [],
}: {
  layerPrefix: string;
  sourcePrefix: string;
  mapboxMap: Map;
  preserveSourceVisuals?: Perhaps<MapSourceVisualForTrainGroup[]>;
}) => {
  const excludeSourcesById = groupBy(
    prop('name'),
    map(prop('source'), preserveSourceVisuals),
  );

  const excludeLayersById = groupBy(
    prop('id'),
    chain(prop('layers'), preserveSourceVisuals),
  );
  forEach((layer) => {
    if (startsWith(layerPrefix, layer.id)) {
      if (!propOr(false, layer.id, excludeLayersById)) {
        mapboxMap.removeLayer(layer.id);
      }
    }
  }, mapboxMap.getStyle().layers);
  forEachObjIndexed((source, id) => {
    if (startsWith(sourcePrefix, id)) {
      if (!propOr(false, id, excludeSourcesById)) {
        mapboxMap.removeSource(id);
      }
    }
  }, mapboxMap.getStyle().sources);
};
