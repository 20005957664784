/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetectorEventOrderBy } from '../models/DetectorEventOrderBy';
import type { ListDetectorEventSchema } from '../models/ListDetectorEventSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DetectorsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Detector Events
     * Get list of detector events
     * @param trainReferenceIds Limit the look up query to the set of trains
     * @param referenceIds Limit the look up query to the set of detectors
     * @param orderBy order_by
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListDetectorEventSchema Successful Response
     * @throws ApiError
     */
    public getDetectorEventsDetectorDetectorEventsGet(
        trainReferenceIds?: Array<string>,
        referenceIds?: Array<string>,
        orderBy?: Array<DetectorEventOrderBy>,
        page: number = 1,
        pageSize: number = 10,
        withCount: boolean = false,
    ): CancelablePromise<ListDetectorEventSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/detector/detector_events',
            query: {
                'train_reference_ids': trainReferenceIds,
                'reference_ids': referenceIds,
                'orderBy': orderBy,
                'page': page,
                'pageSize': pageSize,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
