import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../../trainAppHooks/loadingExplanationHooks.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Domain} from 'types/distances/domain';
import {TrainGroupChartProps} from 'types/propTypes/trainPropTypes/trainGroupChartProps';
import {
  doesOrganizationHaveServiceLines,
  doesOrganizationHaveWheelScan,
} from 'utils/organization/organizationUtils.ts';
import {isLoadingStringOfDependencyUnit} from '../../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {useEffectSetChartDataFeatureCollectionsForTrainGroups} from '../../../trainAppHooks/typeHooks/trainGroupDataFeatureCollectionHooks.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {
  idListsEqual,
  idsEqual,
  propOfListsEqual,
} from 'utils/functional/functionalUtils.ts';
import {GeojsonDataFeatureSet} from 'types/railways/geojsonDataFeatureSet.ts';
import {useNotLoadingActiveSensorDataEligibleTrainGroups} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {length} from 'ramda';
import {TrainGroupSet} from 'types/trainGroups/trainGroup';

/**
 * Keeps track of TrainGroup chart data
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const TrainGroupChartDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement => {
  const {t} = useTranslation();

  // The xAxis domain for the dataVisualizations based on trainProps.trainRouteGroupProps.trainRouteOrGroup.trainDistanceInterval.distanceRange
  // TODO This is only used for organizations with ServiceLines where the domain is always the same. It should be moved to the ChartDataConfig
  const [distanceRangeXAxisDomain, setDistanceRangeXAxisDomain] =
    useState<Perhaps<Domain>>(undefined);

  // Stores the activeChartDataKey dataKey so that we can ignore the payload of the inactive one
  const [activeChartDataKey, setActiveChartDataKey] =
    useState<Perhaps<string>>(undefined);
  const [chartDataFeatureSets, setChartDataFeatureSets] =
    useState<Perhaps<GeojsonDataFeatureSet<TrainGroupSet>[][]>>(undefined);

  // Sets the chartDataFeatureSets
  useEffectSetChartDataFeatureCollectionsForTrainGroups(
    loading,
    appProps,
    trainProps,
    chartDataFeatureSets,
    setChartDataFeatureSets,
  );

  // Whenever the distanceRange changes, update the xAxisDomain
  // The distanceRange can change when the user changes the aggregate TrainRunOrGroup bar or in the
  // future when the user zooms and pans on one of the dataVisualizations or on the map if the latter is set to sync
  // useNotLoadingEffect(
  //   loading,
  //   (distanceRange) => {
  //     const _xAxisDomain = props(['start', 'end'], distanceRange) as [number, number];
  //     if (!equals(distanceRangeXAxisDomain, _xAxisDomain)) {
  //       setDistanceRangeXAxisDomain(_xAxisDomain);
  //     }
  //   },
  //   [distanceRangeTrainGroupGeojsons?.distanceRange] as const,
  // );

  // Indicates whether the chartDataFeatureSets is synced with
  // trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors yet
  // If the trainGroups or their activeDateIntervals have chnaged from that of the source,
  // We are not up-to-date
  const activeSensorDataEligibleTrainGroups =
    useNotLoadingActiveSensorDataEligibleTrainGroups(
      loading,
      trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors,
    );
  const chartDataFeaturesUpToDate = useNotLoadingMemo(
    loading,
    () => {
      if (!length(activeSensorDataEligibleTrainGroups)) {
        return true;
      }
      const chartDataFeature = chartDataFeatureSets?.[0]?.[0];
      const source = chartDataFeature?.source;
      return (
        source?.trainGroups &&
        idListsEqual(source.trainGroups, activeSensorDataEligibleTrainGroups) &&
        propOfListsEqual(
          'activeDateInterval',
          source.trainGroups,
          activeSensorDataEligibleTrainGroups,
        )
      );
    },
    [chartDataFeatureSets, activeSensorDataEligibleTrainGroups],
    false,
  );

  const hasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainGroupChartDependency.name),
    TrainGroupChartDependency.name,
    {
      chartDataFeatureSets,
      chartDataFeaturesUpToDate,
    },
    [
      loading,
      hasServiceLines,
      trainProps.trainGroupSingleTrainRunProps?.geojsonProps?.loading,
      chartDataFeatureSets,
      chartDataFeaturesUpToDate,
    ],
    appProps.setWhatDependenciesAreLoading,
  );

  const trainGroupChartProps: TrainGroupChartProps =
    useMemoClsOrType<TrainGroupChartProps>(CemitTypename.trainGroupChartProps, {
      loading: whatIsLoading.loading,
      whatIsLoading,
      distanceRangeXAxisDomain,
      activeChartDataKey,
      setActiveChartDataKey,
      chartDataFeatureSets,
      setChartDataFeatureSets,
    });
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupSingleTrainRunProps.trainGroupChartProps',
    trainGroupChartProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    {
      ...trainProps,
      ...trainGroupChartProps,
    },
    'TrainGroupChartDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};

TrainGroupChartDependency.displayName = 'TrainGroupChartDependency';
export default TrainGroupChartDependency;
// export default memo(
//   TrainGroupChartDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
