import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {sortBy, sortWith} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {Ord, Ordering} from 'types-ramda/es/tools';

/**
 * Sorts the trains by name
 * @param appProps
 * @param organizationProps
 * @param sortDirectionFunc asc or desc function
 */
export const sortTrainGroupsByName = (
  {organizationProps, trainProps}: TrainAppTrainDependencyProps,
  sortDirectionFunc: (fn: (obj: TrainGroup) => Ord) => (a: TrainGroup, b: TrainGroup) => Ordering
) => {
  const trainGroups = resolveActiveTrainGroupCrudList(trainProps).list;
  const func = trainGroupLocalizedNameFunc(organizationProps?.organization?.timezoneStr);
  return sortWith([
    sortDirectionFunc(
      trainGroup => func(trainGroup)
    )
  ])(trainGroups);
};

/**
 * Provides a sort function by localized trainGroup name
 * @param timeZoneStr
 */
export const trainGroupLocalizedNameFunc = (timeZoneStr: Perhaps<string>): (trainGroup: TrainGroup) => string => {
  return (trainGroup: TrainGroup) => {
    return trainGroup.localizedName(timeZoneStr);
  };
};
