/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AlertLevel {
    NORMAL = 'normal',
    NOTICE = 'notice',
    WARNING = 'warning',
    ERROR = 'error',
    NODATA = 'nodata',
}
