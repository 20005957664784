import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {addIndex, all, ascend, descend, includes, map, slice, sortWith} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {useTranslation} from 'react-i18next';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import TrainGroupOverviewTable, {
  TrainGroupOverviewComponentProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewTable.tsx';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {
  resolveTrainGroupOverviewTableColumnValue,
  resolveTrainGroupOverviewTableColumnValueForSort,
  TableGroupOverviewTableColumn,
  TrainGroupOverviewColumnAttributes,
  trainGroupOverviewTableColumns
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewTableConfig.ts';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import TrainGroupOverviewToggleButton
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewToggleButton.tsx';
import {Stack} from '@mui/material';

/**
 * A TrainGroup overview, including alert trends
 * @param loading
 * @param trainProps
 * @constructor
 */
const TrainGroupOverviewTableContainer = (
  {
    loading,
    appProps,
    organizationProps,
    trainProps
  }: TrainAppTrainDependencyProps) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const trainGroups: Perhaps<TrainGroup[]> = trainProps?.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations?.list;

  const allTrainGroupAlertSummaryPropsLoaded = useNotLoadingMemo(loading || !trainGroups,
    (trainGroups) => {
      return all(
        (trainGroup: TrainGroup) => {
          return trainGroup.alertScopeSummaryProps?.alertTrainGroupProps.alertGraphqlStatus == LoadingStatusEnum.complete;
        },
        trainGroups
      );
    },
    [trainGroups]
  );
  const anyLoading = loading || !allTrainGroupAlertSummaryPropsLoaded;

  // Get the TableGroupOverviewColumn[], passing the translation function for the names
  const columns: TableGroupOverviewTableColumn[] = trainGroupOverviewTableColumns(appProps.t);

  // Slice the trainGroups to the page
  const sliceTrainGroups: Perhaps<TrainGroup[]> = useNotLoadingMemo(anyLoading || !trainGroups,
    (trainGroups, trainGroupOverviewSortDirection, trainGroupOverviewOrderBy, page, rowsPerPage, columns) => {
      const directionFunc = trainGroupOverviewSortDirection == 'asc' ? ascend : descend;

      // Find the active orderBy TableGroupOverviewTableColumn
      const column: TableGroupOverviewTableColumn = findOrThrow(
        (column: TableGroupOverviewTableColumn) => {
          return column.id === trainGroupOverviewOrderBy;
        },
        columns
      );

      const orderByFunc = (trainGroup: TrainGroup) => {
        // Resolve the TableCell value for the given column (TableGroupOverviewColumn) and row (TrainGroup)
        return resolveTrainGroupOverviewTableColumnValueForSort(
          {appProps, organizationProps, trainProps},
          trainGroup,
          column
        );
      };

      // Sort by the orderByFunc, either ascending or descending
      const sortTrainGroups = sortWith([directionFunc(orderByFunc)])(trainGroups);

      return slice<TrainGroup>(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
        sortTrainGroups
      );
    }, [trainGroups, appProps.trainGroupOverviewSortDirection, appProps.trainGroupOverviewOrderBy, page, rowsPerPage, columns] as const
  );

  // If FrontendView.forceTrainGroupOverview is in the active views, we want to highlight active TrainGroups
  const activeTrainGroups = trainProps.trainGroupActivityProps?.activeTrainGroups;

  // Take the sorted and slice TrainGroups to create the final table rows containing the TableCell for each column
  const trainGroupTableRows: Perhaps<JSX.Element[]> = useNotLoadingMemo(
    !sliceTrainGroups,
    (slicedTrainGroups: TrainGroup[]) => {
      return addIndex(map)(
        (trainGroup: TrainGroup, index: number) => {
          const formattedColumns: JSX.Element[] = map((column: TableGroupOverviewTableColumn) => {
              // Resolve the TableCell value for the given column (TableGroupOverviewColumn) and row (TrainGroup)
              const value = resolveTrainGroupOverviewTableColumnValue(
                {appProps, organizationProps, trainProps},
                trainGroup,
                column
              );

              return (
                <TableCell key={column.id} align={column.align}>
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            }, columns
          );
          return (
            <TableRow key={trainGroup.id} {...{
              role: 'checkbox', tabIndex: index, selected: includes(trainGroup, activeTrainGroups)
            }}>
              {formattedColumns}
            </TableRow>
          );
        }, slicedTrainGroups);
    }, [sliceTrainGroups] as const);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: TrainGroupOverviewColumnAttributes
  ) => {
    const isAsc = appProps.trainGroupOverviewOrderBy === property && appProps.trainGroupOverviewSortDirection === 'asc';
    appProps.setTrainGroupOverviewSortDirection(isAsc ? 'desc' : 'asc');
    appProps.setTrainGroupOverviewOrderBy(property);
  };


  const componentProps: Perhaps<TrainGroupOverviewComponentProps> = useNotLoadingMemo(anyLoading, (): TrainGroupOverviewComponentProps => {
    return {
      trainGroups,
      trainGroupTableRows,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      columns,
      handleRequestSort
    } as TrainGroupOverviewComponentProps;
  }, [trainGroups, trainGroupTableRows]);

  return anyLoading ?
    <LoaderWithText key='loader' {...{text: 'loadingTrainGroupOverview'}} /> :
    <Stack key='topStack' {...{
      spacing: 0.5, sx: {
        minWidth: 0,
        minHeight: 0,
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%'
      }
    }}>
      <Stack key='trainGroupOverviewToggleButton' {...{
        direction: 'row', sx: {
          justifyContent: 'flex-end',
        }
      }} >
        <TrainGroupOverviewToggleButton key='trainGroupOverviewToggleButton' {...{appProps, organizationProps, trainProps}} />
      </Stack>
      <TrainGroupOverviewTable key='TrainGroupOverviewTable' {...{appProps, organizationProps, trainProps, componentProps: componentProps!}} />;
    </Stack>;
};
export default TrainGroupOverviewTableContainer;


