/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceDetailResponse } from '../models/DeviceDetailResponse';
import type { DeviceGatewayDetailResponse } from '../models/DeviceGatewayDetailResponse';
import type { DeviceGatewayOrderBy } from '../models/DeviceGatewayOrderBy';
import type { DeviceGatewayTypeEnum } from '../models/DeviceGatewayTypeEnum';
import type { DeviceOrderBy } from '../models/DeviceOrderBy';
import type { DeviceSshAuthKeyOrderBy } from '../models/DeviceSshAuthKeyOrderBy';
import type { DeviceTypeEnum } from '../models/DeviceTypeEnum';
import type { ListDeviceGatewaysResponse } from '../models/ListDeviceGatewaysResponse';
import type { ListDevicesResponse } from '../models/ListDevicesResponse';
import type { ListDeviceSshKeysResponse } from '../models/ListDeviceSshKeysResponse';
import type { ListSensorsResponse } from '../models/ListSensorsResponse';
import type { SensorMeasurementTypeIdEnum } from '../models/SensorMeasurementTypeIdEnum';
import type { SensorOrderBy } from '../models/SensorOrderBy';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DevicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List Devices
     * Returns a list of devices in specified order. Devices are an abstraction for hardware devices such as CDCs, edge computers as well as software gateways.
     * @param deviceType Limit list to devices of a specific type
     * @param device Limit list to device with a specific name (identifier)
     * @param gatewayType Limit list to devices assigned to specific gateway types
     * @param gateway Limit list devices assigned to specific gateways. Gateway is specified with an identifier
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param orderBy A comma-separated list of fields used to sort the returned elements. Specify the sort direction using a prefix of minus (-) for descending order
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListDevicesResponse Successful Response
     * @throws ApiError
     */
    public listDevicesDevicesGet(
        deviceType?: Array<DeviceTypeEnum>,
        device?: Array<string>,
        gatewayType?: Array<DeviceGatewayTypeEnum>,
        gateway?: Array<string>,
        page: number = 1,
        pageSize: number = 10,
        orderBy?: Array<DeviceOrderBy>,
        withCount: boolean = false,
    ): CancelablePromise<ListDevicesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/devices/',
            query: {
                'deviceType': deviceType,
                'device': device,
                'gatewayType': gatewayType,
                'gateway': gateway,
                'page': page,
                'pageSize': pageSize,
                'orderBy': orderBy,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Device Details
     * Returns details on a device
     * @param deviceId
     * @returns DeviceDetailResponse Successful Response
     * @throws ApiError
     */
    public getDeviceDetailsDevicesDeviceIdGet(
        deviceId: string,
    ): CancelablePromise<DeviceDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/devices/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Device Gateways
     * Returns a list of device gateways in specified order
     * @param ids
     * @param type
     * @param isEnabled
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param orderBy A comma-separated list of fields used to sort the returned elements. Specify the sort direction using a prefix of minus (-) for descending order
     * @returns ListDeviceGatewaysResponse Successful Response
     * @throws ApiError
     */
    public listDeviceGatewaysDeviceGatewaysGet(
        ids?: Array<string>,
        type?: DeviceGatewayTypeEnum,
        isEnabled?: boolean,
        page: number = 1,
        pageSize: number = 10,
        orderBy?: Array<DeviceGatewayOrderBy>,
    ): CancelablePromise<ListDeviceGatewaysResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/device-gateways/',
            query: {
                'ids': ids,
                'type': type,
                'isEnabled': isEnabled,
                'page': page,
                'pageSize': pageSize,
                'orderBy': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Device Gateway Details
     * Returns details on a device gateway
     * @param gatewayId
     * @returns DeviceGatewayDetailResponse Successful Response
     * @throws ApiError
     */
    public getDeviceGatewayDetailsDeviceGatewaysGatewayIdGet(
        gatewayId: string,
    ): CancelablePromise<DeviceGatewayDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/device-gateways/{gatewayId}',
            path: {
                'gatewayId': gatewayId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Device Ssh Authentication Keys
     * Returns a list of SSH authentication keys associated with devices in specified order
     * @param deviceType Limit list to keys found on devices of a specific type
     * @param device Limit list to keys found on specified devices. Devices is specified with an name (identifier)
     * @param gatewayType Limit list to keys found on gateways of a specific type
     * @param gateway Limit list to keys found on specified gateways. Gateway is specified with an identifier
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param orderBy A comma-separated list of fields used to sort the returned elements. Specify the sort direction using a prefix of minus (-) for descending order
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListDeviceSshKeysResponse Successful Response
     * @throws ApiError
     */
    public listDeviceSshAuthenticationKeysDeviceSshAuthKeysGet(
        deviceType?: Array<DeviceTypeEnum>,
        device?: Array<string>,
        gatewayType?: Array<DeviceGatewayTypeEnum>,
        gateway?: Array<string>,
        page: number = 1,
        pageSize: number = 200,
        orderBy?: Array<DeviceSshAuthKeyOrderBy>,
        withCount: boolean = false,
    ): CancelablePromise<ListDeviceSshKeysResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/device-ssh-auth-keys/',
            query: {
                'deviceType': deviceType,
                'device': device,
                'gatewayType': gatewayType,
                'gateway': gateway,
                'page': page,
                'pageSize': pageSize,
                'orderBy': orderBy,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Sensors Installed On Devices
     * Returns a list of installed sensor in specified order. Each sensor is associated with a device and a device type.
     * @param deviceType Limit list to sensors belonging to specific device type
     * @param device Limit list to sensors belonging to specific devices. Devices is specified with an name (identifier)
     * @param measurementType Limit list to sensors of a specific measurement type
     * @param page The page number of the results to retrieve
     * @param pageSize The number of elements to return per page
     * @param orderBy A comma-separated list of fields used to sort the returned elements. Specify the sort direction using a prefix of minus (-) for descending order
     * @param withCount A flag indicating whether to include the total number of elements in the response, in addition to the requested page of data.
     * @returns ListSensorsResponse Successful Response
     * @throws ApiError
     */
    public listSensorsInstalledOnDevicesDeviceSensorsGet(
        deviceType?: Array<DeviceTypeEnum>,
        device?: Array<string>,
        measurementType?: Array<SensorMeasurementTypeIdEnum>,
        page: number = 1,
        pageSize: number = 10,
        orderBy?: Array<SensorOrderBy>,
        withCount: boolean = false,
    ): CancelablePromise<ListSensorsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/device-sensors/',
            query: {
                'deviceType': deviceType,
                'device': device,
                'measurementType': measurementType,
                'page': page,
                'pageSize': pageSize,
                'orderBy': orderBy,
                'withCount': withCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
