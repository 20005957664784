import React, {useState, useEffect} from 'react';
import {Typography, Divider, CardContent, Card, Chip, Stack} from '@mui/material';
import {Tram, Search} from '@mui/icons-material';
import {TagData, VehicleStatus} from '../../types/index.ts';
import {findTramInfoByNumber} from '../../utils/findTramInformation.tsx';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
interface SideBarTrainCardProps {
  processedTags: VehicleStatus[];
  onSelectTag: (tram: TagData) => void;
  selectedTag: VehicleStatus | undefined;
}
const allTramWithDate = new Map<string, string>();
const gettingAverageOfSignals = (value: number, count: number): number => {
  const result = (value / count) * 100;
  return parseFloat(result.toFixed(1));
};
const SideBarTrainCard: React.FC<SideBarTrainCardProps> = ({
  processedTags,
  onSelectTag,
  selectedTag,
}) => {
  const [localSelectedTag, setLocalSelectedTag] = useState<VehicleStatus | undefined>(
    selectedTag,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<VehicleStatus[]>([]);
  const [closeFocusTram, setCloseFocusTram] = useState<Boolean>(true);
  useEffect(() => {
    if (processedTags) {
      let updatedData = [...processedTags];
      processedTags.forEach((e) => {
        allTramWithDate.set(e.vehicleid, e.detectionTime);
      });
  
      if (localSelectedTag) {
        const updatedTag = processedTags.find(
          (tag) => tag.vehicleid === localSelectedTag.vehicleid
        );
        if (updatedTag) {
          setLocalSelectedTag(updatedTag); // Ensure we use the found tag instead
          // Remove the localSelectedTag from the list if it's already there
          updatedData = updatedData.filter(
            (tag) => tag.vehicleid !== localSelectedTag.vehicleid
          );
        }
      }
  
      const filtered = updatedData.filter((tram: VehicleStatus) =>
        tram.vehicleid.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
  
      if (
        localSelectedTag &&
        searchTerm &&
        !localSelectedTag.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        setFilteredData(filtered);
      } else {
        setFilteredData(localSelectedTag ? [localSelectedTag, ...filtered] : filtered);
      }
    }
  }, [processedTags, searchTerm, localSelectedTag]);
  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" mb={2}>
          <div style={{position: 'relative', width: '100%'}}>
            <input
              type="text"
              placeholder="Search Tram"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: '100%',
                color: 'black',
                padding: '8px 8px 8px 40px',
                borderRadius: '20px',
                backgroundColor: 'white',
                border: '1px solid #3A4750',
                outline: 'none',
              }}
            />
            <Search
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'gray',
              }}
            />
          </div>
        </Stack>
        <Stack
          spacing={2}
          mr={1}
          sx={{
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          {localSelectedTag && closeFocusTram && (
            <Stack
              key={`${localSelectedTag}`}
              direction={{xs: 'column', sm: 'row'}}
              spacing={2}
              alignItems="center"
            >
              {/* Icon and Chip */}

              <Stack direction="column" alignItems="center">
                <Tram sx={{fontSize: 50, color: '#B29739'}} />
                <Chip
                  label="NU"
                  sx={{
                    backgroundColor: '#B29739',
                    ml: 1,
                    borderRadius: 1,
                    fontWeight: '500',
                  }}
                  size="small"
                />
              </Stack>

              {/* Tram Card */}
              <Card
                sx={{
                  width: '100%',
                  backgroundColor: '#f9f1d1',
                  border: '1.96px solid #B29739',
                }}
                // onClick={() => onSelectTag(tram)}
              >
                <CardContent>
                  <Stack
                    direction={{xs: 'column', sm: 'row'}}
                    justifyContent="space-between"
                    textAlign={{xs: 'center', sm: 'left'}}
                  >
                    <Typography sx={{color: 'black'}}>Spårvagn ID</Typography>

                    <Chip
                      label={localSelectedTag.vehicleid}
                      sx={{
                        backgroundColor: '#45D175',
                        color: '#3A4750',
                        fontWeight: 'bold',
                        ml: {sm: 2},
                        borderRadius: 1,
                        mt: {xs: 1, sm: 0},
                      }}
                      size="small"
                    />
                    {findTramInfoByNumber(localSelectedTag.vehicleid)?.model && (
                      <Chip
                        label={findTramInfoByNumber(localSelectedTag.vehicleid)?.model}
                        sx={{
                          backgroundColor: '#45D175',
                          color: '#3A4750',
                          fontWeight: 'bold',
                          ml: {sm: 2},
                          borderRadius: 1,
                          mt: {xs: 1, sm: 0},
                        }}
                        size="small"
                      />
                    )}
                  </Stack>
                  <Divider sx={{my: 1, backgroundColor: '#B29739'}} />
                  {/* Tram Details */}
                  <Stack textAlign={{xs: 'center', sm: 'left'}}>
                    <Typography sx={{color: 'black'}}>
                      Starta: {   format(localSelectedTag.items[0]?.detectionTime, 'yyyy-MM-dd HH:mm:ss', { locale: sv })}
                    </Typography>
                    {/* <Typography sx={{color: 'black'}}>Slut: {}</Typography> */}
                  </Stack>
                  {/* Signal Averages */}
                  <Stack
                    direction={{
                      xs: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                      xl: 'row',
                    }}
                    spacing={1}
                    mt={1}
                  >
                    <Chip
                      label={`Warning ${gettingAverageOfSignals(localSelectedTag.signalAverage.warning, localSelectedTag.count)}%`}
                      sx={{backgroundColor: '#F17279', color: '#fff', borderRadius: 1}}
                      size="small"
                    />
                    <Chip
                      label={`Alert ${gettingAverageOfSignals(localSelectedTag.signalAverage.alert, localSelectedTag.count)}%`}
                      sx={{backgroundColor: '#B29739', color: '#fff', borderRadius: 1}}
                      size="small"
                    />
                    <Chip
                      label={`Good ${gettingAverageOfSignals(localSelectedTag.signalAverage.good, localSelectedTag.count)}%`}
                      sx={{backgroundColor: '#45C469', color: '#fff', borderRadius: 1}}
                      size="small"
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          )}
          {filteredData &&
            filteredData.map((tram: VehicleStatus, index: number) => (
              <Stack
                key={index + `${tram.data}`}
                direction={{xs: 'column', sm: 'row'}}
                spacing={2}
                alignItems="center"
              >
                {/* Icon and Chip */}
                <Stack direction="column" alignItems="center">
                  <Tram sx={{fontSize: 50, color: '#B29739'}} />
                  <Chip
                    label="NU"
                    sx={{
                      backgroundColor: '#B29739',
                      ml: 1,
                      borderRadius: 1,
                      fontWeight: '500',
                    }}
                    size="small"
                  />
                </Stack>
                {/* Tram Card */}
                <Card
                  sx={{
                    width: '100%',
                    // backgroundColor: localSelectedTag == tram ? '#f9f1d1' : '#fff',
                    backgroundColor: '#fff',
                    border: '1.96px solid #B29739',
                  }}
                  onClick={() => {
                    onSelectTag(tram);
                    setLocalSelectedTag(tram);
                  }}
                >
                  <CardContent>
                    <Stack
                      direction={{xs: 'column', sm: 'row'}}
                      justifyContent="space-between"
                      textAlign={{xs: 'center', sm: 'left'}}
                    >
                      <Typography sx={{color: 'black'}}>Spårvagn ID</Typography>

                      <Chip
                        label={tram.vehicleid}
                        sx={{
                          backgroundColor: '#45D175',
                          color: '#3A4750',
                          fontWeight: 'bold',
                          ml: {sm: 2},
                          borderRadius: 1,
                          mt: {xs: 1, sm: 0},
                        }}
                        size="small"
                      />
                      {findTramInfoByNumber(tram.vehicleid)?.model && (
                        <Chip
                          label={findTramInfoByNumber(tram.vehicleid)?.model}
                          sx={{
                            backgroundColor: '#45D175',
                            color: '#3A4750',
                            fontWeight: 'bold',
                            ml: {sm: 2},
                            borderRadius: 1,
                            mt: {xs: 1, sm: 0},
                          }}
                          size="small"
                        />
                      )}
                    </Stack>
                    <Divider sx={{my: 1, backgroundColor: '#B29739'}} />
                    {/* Tram Details */}
                    <Stack textAlign={{xs: 'center', sm: 'left'}}>
                      <Typography sx={{color: 'black'}}>
                        Starta: {   format(tram.items[0]?.detectionTime, 'yyyy-MM-dd HH:mm:ss', { locale: sv })}
                      </Typography>
                      {/* <Typography sx={{color: 'black'}}>Slut: {}</Typography> */}
                    </Stack>
                    {/* Signal Averages */}
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'row',
                        xl: 'row',
                      }}
                      spacing={1}
                      mt={1}
                    >
                      <Chip
                        label={`Warning ${gettingAverageOfSignals(tram.signalAverage.warning, tram.count)}%`}
                        sx={{backgroundColor: '#F17279', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                      <Chip
                        label={`Alert ${gettingAverageOfSignals(tram.signalAverage.alert, tram.count)}%`}
                        sx={{backgroundColor: '#B29739', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                      <Chip
                        label={`Good ${gettingAverageOfSignals(tram.signalAverage.good, tram.count)}%`}
                        sx={{backgroundColor: '#45C469', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </>
  );
};

export default SideBarTrainCard;
