/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthenticationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Home
     * @returns string Successful Response
     * @throws ApiError
     */
    public homeGet(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }
    /**
     * Remove Cookie
     * @returns any Successful Response
     * @throws ApiError
     */
    public removeCookieLogoutGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/logout',
        });
    }
    /**
     * Set Cookie
     * @returns any Successful Response
     * @throws ApiError
     */
    public setCookieLoginGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login',
        });
    }
}
