/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * A selection of known device types
 */
export enum DeviceTypeEnum {
    UNKNOWN = 'unknown',
    CDC = 'cdc',
    CIC = 'cic',
    WHEEL_SCANNER = 'wheel-scanner',
    DISTANCE = 'distance',
    POINT_MACHINE = 'point-machine',
    TURNOUT = 'turnout',
    GATEWAY = 'gateway',
}
