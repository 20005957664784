import {ReactElement, useState} from 'react';
import {RailwayLine} from 'types/railways/railwayLine';
import {useConfiguredApiForRailwayLines} from '../../trainAppHooks/trainApiHooks/trainApiRailwayLineHooks.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {RailwayLineMinimizedProps} from 'types/propTypes/trainPropTypes/railwayLineProps';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Loads updates RailwayLine props in trainProps.railwayLineProps
 * Depends directly on trainProps.serviceLineProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const RailwayLineDependency = ({
  appProps,
  organizationProps,
  trainProps,
  renderChildren,
  loading,
}: Required<TrainAppTrainDependencyProps>): ReactElement<
  Required<TrainAppTrainDependencyProps>
> => {
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  // The unique physical railway lines of the organization's serviceLines
  // These contain the geojson we need to render the lines on the map
  const [railwayLines, setRailwayLines] = useState<Perhaps<RailwayLine[]>>(undefined);
  const serviceLines = trainProps.serviceLineProps.serviceLines;

  // Load the physical RailwayLines and augment them with frontend track computations
  useConfiguredApiForRailwayLines(
    loading,
    organizationProps.organization,
    serviceLines,
    railwayLines,
    setRailwayLines,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(RailwayLineDependency.name),
    RailwayLineDependency.name,
    {
      ...(organizationHasServiceLines ? {railwayLines} : {}),
    },
    [railwayLines],
    appProps.setWhatDependenciesAreLoading,
    !(loading || organizationHasServiceLines ? !railwayLines : false),
  );

  const childProps = useMemoClsOrType<Omit<RailwayLineMinimizedProps, 'loading'>>(
    CemitTypename.railwayLineMinimizedProps,
    {
      whatIsLoading,
      railwayLines,
      setRailwayLines,
    },
  );
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'railwayLineProps',
    childProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainPropsMerged,
    'RailwayLineDependency',
  );

  return renderChildren<TrainAppTrainDependencyProps>({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
  });
};
RailwayLineDependency.displayName = 'RailwayLineDependency';
export default RailwayLineDependency;
// export default memo(RailwayLineDependency);
