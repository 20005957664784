import React from 'react';
import {length, lensPath, lensProp} from 'ramda';
import TrainGroupSingleTrainRun, {
  TrainRunComponentProps,
  TrainGroupSelectButtonProps,
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupSingleTrainRun.tsx';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {scopedFilteredTrainGroup} from '../../../../../appUtils/trainAppUtils/scope/trainPropsScope.ts';
import {useMemoClsOrType} from '../../../../../appUtils/typeUtils/useMemoClsOrType.ts';
import {CemitTypename} from '../../../../../types/cemitTypename.ts';
import {SingleTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {setClassOrType} from '../../../../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {PartialCemited} from '../../../../../types/cemited';
import {useMemoLimitTrainPropsCrudToTrainGroup} from '../../../../../async/trainAppAsync/trainAppHooks/typeHooks/trainPropsHooks.ts';
import {TrainGroupSingleTrainRunProps} from 'types/propTypes/trainPropTypes/trainGroupSingleTrainRunProps';
import {TrainProps} from '../../../../../types/propTypes/trainPropTypes/trainProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * A run of a train represented by a TrainGroup with a valid singleTrainGroup property,
 * meaning a specific train and time and service name if the service is revenue service
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupSingleTrainRunContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: SingleTrainComponentDependencyProps<
  PartialCemited<TrainRunComponentProps>
>): JSX.Element => {
  return trainProps.filteredTrainGroupProps.loading ? (
    <LoaderWithText {...{sx: {visibility: 'hidden'}, text: 'loadingTrainRuns'}} />
  ) : (
    <LoadedTrainGroupSingleTrainRunContainer
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps,
      }}
    />
  );
};

const LoadedTrainGroupSingleTrainRunContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: SingleTrainComponentDependencyProps<
  PartialCemited<TrainRunComponentProps>
>): JSX.Element => {
  const filteredTrainGroup: TrainGroup = scopedFilteredTrainGroup(trainProps);

  // Filter the crud list to only container the one or zero TrainGroup/s for this TrainRun
  // We use this to add a TrainGroup if the user clicks the add button
  const trainPropsWithFilteredCrud = useMemoLimitTrainPropsCrudToTrainGroup(
    trainProps,
    lensPath<TrainProps, keyof TrainProps, keyof TrainGroupSingleTrainRunProps>([
      'trainGroupSingleTrainRunProps',
      'crudTrainGroups',
    ]),
    filteredTrainGroup,
  );

  // Disable the add button if the TrainRun is already in the active runs
  // TODO currently disable if the active runs are full so the organization
  // isn't confused
  const trainGroupSelectButtonProps = useMemoClsOrType<TrainGroupSelectButtonProps>(
    CemitTypename.trainGroupSelectButtonProps,
    {
      disabled:
        Boolean(
          !trainProps.trainGroupSingleTrainRunProps.crudTrainGroups ||
            length(
              trainPropsWithFilteredCrud.trainGroupSingleTrainRunProps.crudTrainGroups
                .list,
            ),
        ) ||
        length(trainProps.trainGroupActivityProps.activeTrainGroups || []) >=
          TRAIN_GROUP_MAX_ACTIVE_COUNT,
      organization: organizationProps.organization,
      crudTrainGroups: trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
      // undefined if not yet a trainGroup, else the already created TrainGroup
      trainGroup: scopedFilteredTrainGroup(trainProps),
      trainRoute: trainProps.trainRouteGroupProps.trainRouteOrGroup,
    },
  );

  const childComponentProps = useMemoClsOrType<TrainRunComponentProps>(
    CemitTypename.trainRunComponentProps,
    setClassOrType(
      lensProp('trainGroupSelectButtonProps'),
      trainGroupSelectButtonProps,
      componentProps,
    ),
  );

  return (
    <TrainGroupSingleTrainRun
      {...{
        appProps,
        organizationProps,
        // Constrain the TrainGroups
        trainProps: trainPropsWithFilteredCrud,
        componentProps: childComponentProps,
      }}
    />
  );
};

export default TrainGroupSingleTrainRunContainer;
