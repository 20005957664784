import {MapHookDependencyProps} from 'types/propTypes/mapPropTypes/mapHookDependencyProps.ts';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';
import {unlessLoadingProps} from 'utils/componentLogic/loadingUtils.ts';
import {useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.ts';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {VisionProps} from 'types/propTypes/trainPropTypes/visionPropTypes/visionProps';
import {
  chain,
  compose,
  equals,
  filter,
  forEach,
  head,
  identity,
  ifElse,
  indexOf,
  lensPath,
  lensProp,
  map,
  props,
  set,
  sortBy,
} from 'ramda';
import {findOrThrow, lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import {Feature, LineString} from 'geojson';
import {
  MapboxIconConfig,
  MapboxIconsConfig,
  MapboxLayer,
  MapSourceVisual,
  MapSourceVisualForTrainGroup,
} from 'types/mapbox/mapSourceVisual';

import nearestPointOnLine from '@turf/nearest-point-on-line';
import {VisionMeta} from 'types/vision/visionMeta';
import {trackIdToTrackRoute} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {RailwayLine} from 'types/railways/railwayLine';
import {
  removeMapboxLayersAndSources,
  setMapboxSourceAndLayersSets,
} from 'utils/map/mapboxSourceUtils.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {svgIconComponentToBase64Encoded} from 'utils/svg/svgUtils.ts';
import TrainFacingRealtimeSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainFacingRealtimeSvgIcon.tsx';
import {svgDataUrlIconNameForIconOfTrainGroup} from 'appUtils/trainAppUtils/trainGroupMapUtils/trainGroupMapUtils.ts';
import {
  memoizedTrainGroupIconImagesSourceAndLayers,
  MemoizedTrainGroupIconImagesSourceAndLayersProps,
} from 'utils/map/layers/dynamicIconLayers.ts';
import {
  REALTIME_TRAIN_GROUP_LAYER_PREFIX,
  REALTIME_TRAIN_GROUP_SOURCE_PREFIX,
  VISION_TRAIN_GROUP_LAYER_PREFIX,
  VISION_TRAIN_GROUP_SOURCE_PREFIX,
} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {CemitTypename} from 'types/cemitTypename.ts';
import {setMapboxOnHover} from 'async/cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts';
import {CEMIT_WHITE, CEMIT_YELLOW} from 'theme/cemitColors.ts';
import TrainFacingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainFacingSvgIcon.tsx';
import {
  omitClassOrType,
  pickClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {useEffect, useMemo, useState} from 'react';

/**
 * Creates the RealtimeTrain Mapbox layers for each active TrainGroup
 * TODO we don't currently store trainGroup.realtimeTrainGeojson with crud because it's only used by the map layer
 * @param loading If true, do nothing
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param hookProps
 */
export const useNotLoadingEffectUpdateVisionLayerTrainMap = (
  loading: boolean,
  {appProps, organizationProps, trainProps, mapProps}: VisionAppMapDependencyProps,
) => {
  const trainMap = mapProps.trainMap;
  const [visionPropsWithoutMapSourceVisuals, setVisionPropsWithoutMapSourceVisuals] =
    useState(undefined);

  // All this is just to remove mapSourceVisualSetPairs so it doesn't keep triggering the useNotLoadingSetterEffect
  // when it gets set
  useEffect(() => {
    const updatedVisionPropsWithoutMapSourceVisuals = pickClassOrType(
      ['visionMeta', 'loading', 'setVisionMapSourceVisuals'],
      trainProps.visionProps,
    );
    if (
      !equals(
        updatedVisionPropsWithoutMapSourceVisuals,
        visionPropsWithoutMapSourceVisuals,
      )
    ) {
      setVisionPropsWithoutMapSourceVisuals(updatedVisionPropsWithoutMapSourceVisuals);
    }
  }, [trainProps.visionProps]);

  return useNotLoadingSetterEffect(
    {
      loading: loading || !visionPropsWithoutMapSourceVisuals,
    },
    visionLayers,
    visionPropsWithoutMapSourceVisuals,
    // Update the trainMap to the sources and layers that are defined.
    // Those not defined indicate that the corresponding TrainGroup's Vision is in a loading state
    (mapSourceVisualSetPairs: MapSourceVisualForTrainGroup[]): void => {
      const visionSourceVisuals = filter(
        Boolean,
        chain(identity, mapSourceVisualSetPairs),
      );

      visionPropsWithoutMapSourceVisuals.setVisionMapSourceVisuals(visionSourceVisuals);

      // Set the sources and layers on TrainMap
      setMapboxSourceAndLayersSets(
        mapProps.trainMap,
        mapProps.setChangeStatuses,
        visionSourceVisuals,
        true,
        false,
        3,
      );

      // Move these layers to the top so they are above tracks
      // Sort by Alert type so that the more important errors are on top
      // Prioritize RealtimeTrainAttributeAlertLevel so we can sort the icons
      const allLayers = chain(
        (realtimeTrainSourceVisual: MapSourceVisualForTrainGroup) => {
          return realtimeTrainSourceVisual.layers;
        },
        visionSourceVisuals,
      );

      forEach((layer: MapboxLayer) => {
        trainMap.moveLayer(layer.id);
      }, allLayers);

      // Remove any REALTIME_TRAIN_GROUP_LAYER_PREFIX layers from previous iterations that are no longer train
      removeMapboxLayersAndSources({
        layerPrefix: VISION_TRAIN_GROUP_LAYER_PREFIX,
        sourcePrefix: VISION_TRAIN_GROUP_SOURCE_PREFIX,
        preserveSourceVisuals: visionSourceVisuals,
        mapboxMap: trainMap,
      });
    },
    [trainProps.railwayLineProps, visionPropsWithoutMapSourceVisuals, mapProps.trainMap],
  );
};

const visionLayers = (visionProps: VisionProps): MapSourceVisual[] => {
  const visionMeta: VisionMeta = visionProps!.visionMeta!;
  if (!visionMeta) {
    return [];
  }
  const pointFeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: props(['lon', 'lat'], visionMeta.trackGeoPosition),
        },
        properties: {value: 1},
      },
    ],
  };
  //const trackRoute = trackIdToTrackRoute(visionMeta.trackSectionId);
  // const matchingRailway = findOrThrow((railwayLine: RailwayLine) => {
  //   return trackRoute.name == railwayLine.name;
  // }, trainProps.railwayLineProps.railwayLines);
  //
  const loading = visionProps?.loading;
  if (loading) {
    return [];
  }

  // Convert the React component svg to a data url that Mapbox can use
  const encodedSvg = map(
    (iconComponent) =>
      svgIconComponentToBase64Encoded(iconComponent, {
        fill: CEMIT_YELLOW,
      }),
    {visionTrainIcon: TrainFacingSvgIcon},
  );

  const iconPrefix = 'visionTrain';
  // TODO we don't yet know the TraingGroup, and there is only one ever shown
  const onlyTrainGroup: TrainGroupOnlyTrainFormation =
    clsOrType<TrainGroupOnlyTrainFormation>(CemitTypename.trainGroupOnlyTrainFormation, {
      id: 'visionTrainId',
      activity: {isVisible: true},
    });
  const trainGroupIconConfigs: MapboxIconConfig[] = [
    {
      name: svgDataUrlIconNameForIconOfTrainGroup(iconPrefix, onlyTrainGroup),
      svg: encodedSvg['visionTrainIcon'],
    } as MapboxIconConfig,
  ];

  // Creates a MapSourceVisual for each TrainGroup. Each MapSourceVisual has two layers, one for
  // critical and one for warning alerts
  const columnSourceAndLayers: MapSourceVisual =
    memoizedTrainGroupIconImagesSourceAndLayers(
      ts<MemoizedTrainGroupIconImagesSourceAndLayersProps>({
        trainGroup: onlyTrainGroup,
        trainGroupIconConfigs,
        featurePropPath: 'value',
        geojson: pointFeatureCollection,
        sourceNamePrefix: VISION_TRAIN_GROUP_SOURCE_PREFIX,
        layerNamePrefix: VISION_TRAIN_GROUP_LAYER_PREFIX,
        iconPrefix,
        iconConfigSize: 40,
      }),
    );
  return [columnSourceAndLayers];
};
