export const STATION_LOCATIONS = [{
  "id": "node/30486750",
  "name": "Majvallen",
  "network": "Västtrafik",
  "coordinates": [11.935553, 57.6910747]
}, {
  "id": "node/31098715",
  "name": "Prinsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9512499, 57.6968874]
}, {
  "id": "node/33541076",
  "name": "Linnéplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9520488, 57.6901338]
}, {
  "id": "node/60142086",
  "name": "Järntorget",
  "network": "Västtrafik",
  "coordinates": [11.9526163, 57.7002011]
}, {
  "id": "node/60142111",
  "name": "Hjällbo",
  "network": "Västtrafik",
  "coordinates": [12.0223783, 57.7692595]
}, {
  "id": "node/60142114",
  "name": "Storås",
  "network": "Västtrafik",
  "coordinates": [12.0462261, 57.785139]
}, {
  "id": "node/84670169",
  "name": "Chapmans Torg",
  "network": "Västtrafik",
  "coordinates": [11.9193533, 57.6942497]
}, {
  "id": "node/84670174",
  "name": "Jaegerdorffsplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9150155, 57.6914347]
}, {
  "id": "node/91380481",
  "name": "Dr Sydows Gata",
  "network": "Västtrafik",
  "coordinates": [11.9751665, 57.6808055]
}, {
  "id": "node/91380509",
  "name": "Wavrinskys Plats",
  "network": "Västtrafik",
  "coordinates": [11.9685893, 57.6889831]
}, {
  "id": "node/94229503",
  "name": "Medicinaregatan",
  "network": "Västtrafik",
  "coordinates": [11.9643706, 57.6854325]
}, {
  "id": "node/94229509",
  "name": "Sahlgrenska Huvudentré",
  "network": "Västtrafik",
  "coordinates": [11.9610978, 57.6836073]
}, {
  "id": "node/94243780",
  "name": "Axel Dahlströms torg",
  "network": "Västtrafik",
  "coordinates": [11.9275696, 57.672288]
}, {
  "id": "node/96544989",
  "name": "Väderilsgatan",
  "network": "Västtrafik",
  "coordinates": [11.8930904, 57.7266283]
}, {
  "id": "node/96546384",
  "name": "Frihamnen",
  "network": "Västtrafik",
  "coordinates": [11.9601168, 57.7203339]
}, {
  "id": "node/96546390",
  "name": "Hjalmar Brantingsplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9532604, 57.7205954]
}, {
  "id": "node/96546398",
  "name": "Vågmästareplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9451473, 57.7205725]
}, {
  "id": "node/96546412",
  "name": "Rambergsvallen",
  "network": "Västtrafik",
  "coordinates": [11.9277024, 57.7195189]
}, {
  "id": "node/96546416",
  "name": "Gropegårdsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9191511, 57.7183442]
}, {
  "id": "node/96546423",
  "name": "Eketrägatan",
  "network": "Västtrafik",
  "coordinates": [11.9101366, 57.7167011]
}, {
  "id": "node/96546459",
  "name": "Önskevädersgatan",
  "network": "Västtrafik",
  "coordinates": [11.8930502, 57.7201505]
}, {
  "id": "node/96546466",
  "name": "Friskväderstorget",
  "network": "Västtrafik",
  "coordinates": [11.8933238, 57.7233637]
}, {
  "id": "node/96546500",
  "name": "Varmfrontsgatan",
  "network": "Västtrafik",
  "coordinates": [11.8961482, 57.7367366]
}, {
  "id": "node/101344918",
  "name": "Wieselgrensplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9356288, 57.7206418]
}, {
  "id": "node/101462493",
  "name": "Hagen",
  "network": "Västtrafik",
  "coordinates": [11.8841282, 57.6748499]
}, {
  "id": "node/101463736",
  "name": "Käringberget",
  "network": "Västtrafik",
  "coordinates": [11.8720126, 57.6695317]
}, {
  "id": "node/101464415",
  "name": "Hinsholmen",
  "network": "Västtrafik",
  "coordinates": [11.860756, 57.6678877]
}, {
  "id": "node/124171107",
  "name": "Krokslätts torg",
  "network": "Västtrafik",
  "coordinates": [12.0080508, 57.6752757]
}, {
  "id": "node/137726100",
  "name": "Scandinavium",
  "network": "Västtrafik",
  "coordinates": [11.9858582, 57.7006276]
}, {
  "id": "node/145124958",
  "name": "Ekmanska",
  "network": "Västtrafik",
  "coordinates": [12.0096351, 57.6996668]
}, {
  "id": "node/145124980",
  "name": "Töpelsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0221389, 57.7023397]
}, {
  "id": "node/145124992",
  "name": "Welandergatan",
  "network": "Västtrafik",
  "coordinates": [12.0239131, 57.7060773]
}, {
  "id": "node/281040945",
  "name": "Frölunda Torg",
  "network": "Västtrafik",
  "coordinates": [11.9115297, 57.6527834]
}, {
  "id": "node/282928385",
  "name": "Mariaplan",
  "network": "Västtrafik",
  "coordinates": [11.9207548, 57.6867412]
}, {
  "id": "node/282929383",
  "name": "Sannaplan",
  "network": "Västtrafik",
  "coordinates": [11.9164755, 57.684194]
}, {
  "id": "node/282929717",
  "name": "Sandarna",
  "network": "Västtrafik",
  "coordinates": [11.9098416, 57.682819]
}, {
  "id": "node/282930012",
  "name": "Vagnhallen Majorna",
  "network": "Västtrafik",
  "coordinates": [11.9126923, 57.6889761]
}, {
  "id": "node/282930071",
  "name": "Ostindiegatan",
  "network": "Västtrafik",
  "coordinates": [11.9150836, 57.6878362]
}, {
  "id": "node/283253430",
  "name": "Lilla Bommen",
  "network": "Västtrafik",
  "coordinates": [11.9667699, 57.7092219]
}, {
  "id": "node/283253822",
  "name": "Ullevi Norra",
  "network": "Västtrafik",
  "coordinates": [11.9861801, 57.7080639]
}, {
  "id": "node/283254029",
  "name": "Centralstationen",
  "network": "Västtrafik",
  "coordinates": [11.9734989, 57.7075529]
}, {
  "id": "node/283255107",
  "name": "Brunnsparken",
  "network": "Västtrafik",
  "coordinates": [11.9689358, 57.7065955]
}, {
  "id": "node/285732348",
  "name": "Saltholmen",
  "network": "Västtrafik",
  "coordinates": [11.8464671, 57.6606627]
}, {
  "id": "node/285732384",
  "name": "Kungssten",
  "network": "Västtrafik",
  "coordinates": [11.901761, 57.6805538]
}, {
  "id": "node/285732446",
  "name": "Nya Varvsallén",
  "network": "Västtrafik",
  "coordinates": [11.8946559, 57.6778774]
}, {
  "id": "node/285732591",
  "name": "Långedrag",
  "network": "Västtrafik",
  "coordinates": [11.8535293, 57.6681317]
}, {
  "id": "node/312896172",
  "name": "Smaragdgatan",
  "network": "Västtrafik",
  "coordinates": [11.8993432, 57.645458]
}, {
  "id": "node/312916056",
  "name": "Positivgatan",
  "network": "Västtrafik",
  "coordinates": [11.9175939, 57.6567765]
}, {
  "id": "node/312916199",
  "name": "Nymilsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9251018, 57.6627437]
}, {
  "id": "node/312916223",
  "name": "Lantmilsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9247118, 57.6671579]
}, {
  "id": "node/313609652",
  "name": "Vasaplatsen",
  "network": "Västtrafik",
  "coordinates": [11.97005, 57.6989459]
}, {
  "id": "node/315544334",
  "name": "Korsvägen",
  "network": "Västtrafik",
  "coordinates": [11.9863037, 57.6968324]
}, {
  "id": "node/316083098",
  "name": "Hagakyrkan",
  "network": "Västtrafik",
  "coordinates": [11.9621089, 57.6995488]
}, {
  "id": "node/316131916",
  "name": "Bokekullsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9327977, 57.6771253]
}, {
  "id": "node/316131919",
  "name": "Högsbogatan",
  "network": "Västtrafik",
  "coordinates": [11.9302815, 57.6801215]
}, {
  "id": "node/316137086",
  "name": "Bögatan",
  "network": "Västtrafik",
  "coordinates": [12.016452, 57.7011187]
}, {
  "id": "node/316137581",
  "name": "Bäckeliden",
  "network": "Västtrafik",
  "coordinates": [12.0033271, 57.6999186]
}, {
  "id": "node/316137723",
  "name": "Sankt Sigfrids Plan",
  "network": "Västtrafik",
  "coordinates": [11.9994709, 57.6987301]
}, {
  "id": "node/316138159",
  "name": "Olivedalsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9525896, 57.6924153]
}, {
  "id": "node/316141189",
  "name": "Vasa Viktoriagatan",
  "network": "Västtrafik",
  "coordinates": [11.9669196, 57.6981665]
}, {
  "id": "node/316259001",
  "name": "Valand",
  "network": "Västtrafik",
  "coordinates": [11.9741954, 57.7004569]
}, {
  "id": "node/331415240",
  "name": "Almedal",
  "network": "Västtrafik",
  "coordinates": [11.9965721, 57.6875502]
}, {
  "id": "node/409752621",
  "name": "Sälöfjordsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9015914, 57.7134922]
}, {
  "id": "node/409752624",
  "name": "Vårväderstorget",
  "network": "Västtrafik",
  "coordinates": [11.8931812, 57.7129636]
}, {
  "id": "node/409752625",
  "name": "Temperaturgatan",
  "network": "Västtrafik",
  "coordinates": [11.8952747, 57.7325718]
}, {
  "id": "node/591286372",
  "name": "Angered Centrum",
  "network": "Västtrafik",
  "coordinates": [12.0492714, 57.7956033]
}, {
  "id": "node/661147431",
  "name": "Stigbergstorget",
  "network": "Västtrafik",
  "coordinates": [11.9338853, 57.699039]
}, {
  "id": "node/661152382",
  "name": "Godhemsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9261176, 57.6853318]
}, {
  "id": "node/661152446",
  "name": "Ekedal",
  "network": "Västtrafik",
  "coordinates": [11.9292594, 57.6871505]
}, {
  "id": "node/661178805",
  "name": "Berzeliigatan",
  "network": "Västtrafik",
  "coordinates": [11.9823431, 57.6983047]
}, {
  "id": "node/705125071",
  "name": "Hammarkullen",
  "network": "Västtrafik",
  "coordinates": [12.0364998, 57.7796924]
}, {
  "id": "node/955310924",
  "name": "Tranered",
  "network": "Västtrafik",
  "coordinates": [11.8794745, 57.6725083]
}, {
  "id": "node/1072965354",
  "name": "Marklandsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9368454, 57.6748589]
}, {
  "id": "node/1267180391",
  "name": "Kortedala Torg",
  "network": "Västtrafik",
  "coordinates": [12.0330403, 57.752779]
}, {
  "id": "node/1267180415",
  "name": "Januarigatan",
  "network": "Västtrafik",
  "coordinates": [12.0412752, 57.7603496]
}, {
  "id": "node/1267180487",
  "name": "Aprilgatan",
  "network": "Västtrafik",
  "coordinates": [12.0410207, 57.7608937]
}, {
  "id": "node/1267180568",
  "name": "Galileis Gata",
  "network": "Västtrafik",
  "coordinates": [12.051446, 57.7605336]
}, {
  "id": "node/1267180782",
  "name": "Allhelgonakyrkan",
  "network": "Västtrafik",
  "coordinates": [12.0371972, 57.7572277]
}, {
  "id": "node/1267229045",
  "name": "Rymdtorget",
  "network": "Västtrafik",
  "coordinates": [12.0667621, 57.7559202]
}, {
  "id": "node/1267231066",
  "name": "Teleskopgatan",
  "network": "Västtrafik",
  "coordinates": [12.0600092, 57.7593244]
}, {
  "id": "node/1271994730",
  "name": "Komettorget",
  "network": "Västtrafik",
  "coordinates": [12.0713114, 57.7511423]
}, {
  "id": "node/1272031323",
  "name": "Bellevue",
  "network": "Västtrafik",
  "coordinates": [12.0246213, 57.732516]
}, {
  "id": "node/1272031437",
  "name": "Nymånegatan",
  "network": "Västtrafik",
  "coordinates": [12.0298737, 57.7427511]
}, {
  "id": "node/1272284947",
  "name": "Gamlestads Torg",
  "network": "Västtrafik",
  "coordinates": [12.0056828, 57.7275816]
}, {
  "id": "node/1275171641",
  "name": "SKF",
  "network": "Västtrafik",
  "coordinates": [12.0137267, 57.7292315]
}, {
  "id": "node/1275929579",
  "name": "Redbergsplatsen",
  "network": "Västtrafik",
  "coordinates": [12.0046916, 57.7164881]
}, {
  "id": "node/1275935022",
  "name": "Ejdergatan",
  "network": "Västtrafik",
  "coordinates": [12.0059497, 57.7210227]
}, {
  "id": "node/1277049016",
  "name": "Härlanda",
  "network": "Västtrafik",
  "coordinates": [12.0194421, 57.7181219]
}, {
  "id": "node/1277049020",
  "name": "Östra Sjukhuset",
  "network": "Västtrafik",
  "coordinates": [12.0478355, 57.7224582]
}, {
  "id": "node/1277049024",
  "name": "Tingvallsvägen",
  "network": "Västtrafik",
  "coordinates": [12.0421007, 57.7246507]
}, {
  "id": "node/1277049027",
  "name": "Ättehögsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0283352, 57.7198855]
}, {
  "id": "node/1277049044",
  "name": "Stockholmsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0140855, 57.7170967]
}, {
  "id": "node/1277049048",
  "name": "Munkebäckstorget",
  "network": "Västtrafik",
  "coordinates": [12.0260027, 57.7183958]
}, {
  "id": "node/1277049049",
  "name": "Kaggeledstorget",
  "network": "Västtrafik",
  "coordinates": [12.0332249, 57.722895]
}, {
  "id": "node/1313416324",
  "name": "Virginsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0235381, 57.7113575]
}, {
  "id": "node/1313438790",
  "name": "Sanatoriegatan",
  "network": "Västtrafik",
  "coordinates": [12.0240584, 57.7132161]
}, {
  "id": "node/1313438792",
  "name": "Solrosgatan",
  "network": "Västtrafik",
  "coordinates": [12.0250239, 57.7162721]
}, {
  "id": "node/1331283436",
  "name": "Runstavsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0303317, 57.748042]
}, {
  "id": "node/1331298131",
  "name": "Beväringsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0284945, 57.7397399]
}, {
  "id": "node/1331298134",
  "name": "Kviberg",
  "network": "Västtrafik",
  "coordinates": [12.0264064, 57.736484]
}, {
  "id": "node/1331318533",
  "name": "Olskrokstorget",
  "network": "Västtrafik",
  "coordinates": [11.9993711, 57.7146502]
}, {
  "id": "node/1331318540",
  "name": "Svingeln",
  "network": "Västtrafik",
  "coordinates": [11.9917409, 57.713358]
}, {
  "id": "node/1386002065",
  "name": "Grönsakstorget",
  "network": "Västtrafik",
  "coordinates": [11.9635991, 57.702532]
}, {
  "id": "node/1455776291",
  "name": "Ullevi Södra",
  "network": "Västtrafik",
  "coordinates": [11.9849463, 57.7048367]
}, {
  "id": "node/1517531658",
  "name": "Mildvädersgatan",
  "network": "Västtrafik",
  "coordinates": [11.890926, 57.7156823]
}, {
  "id": "node/1570146689",
  "name": "Domkyrkan",
  "network": "Västtrafik",
  "coordinates": [11.9636908, 57.7042393]
}, {
  "id": "node/1570146743",
  "name": "Kapellplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9735487, 57.6927695]
}, {
  "id": "node/1570367029",
  "name": "Kungsportsplatsen",
  "network": "Västtrafik",
  "coordinates": [11.9697972, 57.7039668]
}, {
  "id": "node/1585219793",
  "name": "Lana",
  "network": "Västtrafik",
  "coordinates": [12.003866, 57.6780518]
}, {
  "id": "node/1585220017",
  "name": "Mölndals sjukhus",
  "network": "Västtrafik",
  "coordinates": [12.0136632, 57.6614548]
}, {
  "id": "node/1585220249",
  "name": "Lackarebäck",
  "network": "Västtrafik",
  "coordinates": [12.0114421, 57.6663479]
}, {
  "id": "node/1585220305",
  "name": "Krokslätts Fabriker",
  "network": "Västtrafik",
  "coordinates": [12.0100249, 57.6717887]
}, {
  "id": "node/1691294724",
  "name": "Masthuggstorget",
  "network": "Västtrafik",
  "coordinates": [11.9447351, 57.6995343]
}, {
  "id": "node/1751728835",
  "name": "Liseberg Station",
  "network": "Västtrafik",
  "coordinates": [11.9940669, 57.6975437]
}, {
  "id": "node/1752632594",
  "name": "Chalmers",
  "network": "Västtrafik",
  "coordinates": [11.9730927, 57.6900225]
}, {
  "id": "node/1753222905",
  "name": "Elisedal",
  "network": "Västtrafik",
  "coordinates": [12.0004371, 57.6834396]
}, {
  "id": "node/1753222909",
  "name": "Varbergsgatan",
  "network": "Västtrafik",
  "coordinates": [12.0030599, 57.6796154]
}, {
  "id": "node/1753355815",
  "name": "Briljantgatan",
  "network": "Västtrafik",
  "coordinates": [11.9035015, 57.6486751]
}, {
  "id": "node/1757383274",
  "name": "Roddföreningen",
  "network": "Västtrafik",
  "coordinates": [11.8516381, 57.6640062]
}, {
  "id": "node/1767990477",
  "name": "Botaniska Trädgården",
  "network": "Västtrafik",
  "coordinates": [11.9455821, 57.6812619]
}, {
  "id": "node/1767990496",
  "name": "Musikvägen",
  "network": "Västtrafik",
  "coordinates": [11.9204561, 57.6589341]
}, {
  "id": "node/1795743508",
  "name": "Opaltorget",
  "network": "Västtrafik",
  "coordinates": [11.900071, 57.642929]
}, {
  "id": "node/1845284490",
  "name": "Klintens Väg",
  "network": "Västtrafik",
  "coordinates": [11.9281376, 57.6823353]
}, {
  "id": "node/1912648524",
  "name": "Doktor Fries Torg",
  "network": "Västtrafik",
  "coordinates": [11.972663, 57.6844758]
}, {
  "id": "node/1913261811",
  "name": "Mölndals Innerstad",
  "network": "Västtrafik",
  "coordinates": [12.0172243, 57.6560881]
}, {
  "id": "node/2087898166",
  "name": "Fjällgatan",
  "network": "Västtrafik",
  "coordinates": [11.9362536, 57.6941944]
}, {
  "id": "node/2087922967",
  "name": "Brunnsgatan",
  "network": "Västtrafik",
  "coordinates": [11.9591759, 57.6936796]
}, {
  "id": "node/2087922969",
  "name": "Handelshögskolan",
  "network": "Västtrafik",
  "coordinates": [11.9624549, 57.696179]
}, {
  "id": "node/2087922971",
  "name": "Kaptensgatan",
  "network": "Västtrafik",
  "coordinates": [11.927039, 57.696949]
}, {
  "id": "node/2087922974",
  "name": "Seminariegatan",
  "network": "Västtrafik",
  "coordinates": [11.9571597, 57.6920634]
}, {
  "id": "node/3707818835",
  "name": "Stenpiren",
  "network": "Västtrafik",
  "coordinates": [11.9574354, 57.70566]
}, {
  "id": "node/9016006478",
  "name": "Nordstan",
  "network": "Västtrafik",
  "coordinates": [11.9711554, 57.708939]
}, {
  "id": "node/11101305782",
  "name": "Svingeln södra",
  "network": "Västtrafik",
  "coordinates": [11.9908919, 57.7102522]
}, {
  "id": "node/7901",
  "name": "Ringön",
  "network": "",
  "coordinates": [
    11.958873403595991,
    57.72112119640401
  ]
}]
