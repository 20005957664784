import useSWR, {SWRResponse} from 'swr';
import {
  CemitApi,
  CemitApiRequest,
  CemitApiResponse,
  CemitApiRoute,
} from 'types/apis/cemitApi';
import {
  unlessLoadingProps,
  unlessLoadingValue,
} from 'utils/componentLogic/loadingUtils.ts';
import {compose, includes, lensPath, lensProp, mergeAll, set, values, when} from 'ramda';
import {processApiResponse} from 'appUtils/apiUtils/apiUtils.ts';
import {resolveOrganizationManifest} from 'utils/organization/organizationUtils.ts';
import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemo} from 'react';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {OrganizationOrUserStateMinimized} from 'types/userState/userState';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

export const resolveCemitApiRoute = <
  ROUTE extends CemitApiRoute<API_REQUEST, API_RESPONSE, T, DATA, PROPS, PARAMS>,
  API_REQUEST extends CemitApiRequest<PROPS, PARAMS> = ROUTE['request'],
  API_RESPONSE extends CemitApiResponse<T, DATA> = Exclude<ROUTE['response'], undefined>,
  T = Exclude<ROUTE['t'], undefined>,
  // Optionally the format of the data that comes back from the API. It might be the same as T
  DATA extends Record<string, any> = Exclude<ROUTE['data'], undefined>,
  // The props sent by the caller that get formated to PARAMS
  PROPS extends Record<string, any> = ROUTE['request']['props'],
  // Optionally the format of the parameters given to the request
  PARAMS extends Record<string, any> = ROUTE['request']['params'],
>(
  loading: boolean,
  organizationOrUserState: PerhapsIfLoading<
    typeof loading,
    OrganizationOrUserStateMinimized
  >,
  cemitApiRouteKey: string,
  // TODO make this the type of the request params somehow
  props: any,
  // Optional route request path
  path?: string,
): CemitApiRoute<API_REQUEST, API_RESPONSE, T, DATA, PROPS, PARAMS> => {
  return unlessLoadingProps(loading, () => {
    const organizationManifest = resolveOrganizationManifest(organizationOrUserState!);
    const {routes, api} = organizationManifest.apiConfig;
    const cemitApiRouteMinimized = routes[cemitApiRouteKey] as ROUTE;
    const request = clsOrType<API_REQUEST>(
      CemitTypename.cemitApiRequest,
      mergeRightIfDefined(cemitApiRouteMinimized.request, {props, path}),
    );
    // api can be overridden by the Route if it's delegating, so give it low priority
    const cemitApiRoutePartial = clsOrType<ROUTE>(
      cemitApiRouteMinimized.__typename,
      mergeAll([{api}, cemitApiRouteMinimized, {request}]),
    );
    // Gets the URL with params converted to paths and/or querystrings
    const url: Perhaps<string> =
      loading ||
      !cemitApiRoutePartial.request?.props ||
      cemitApiRoutePartial.overrideResponse
        ? undefined
        : cemitApiRoutePartial.urlResolver(api, cemitApiRoutePartial, path);
    if (includes('undefined', url || '')) {
      throw new Error(`Something in the url was undefined: ${url}`);
    }
    const cemitApiRoute = setClassOrType<ROUTE, string>(
      lensPath(['request', 'url']),
      url,
      cemitApiRoutePartial,
    );

    return cemitApiRoute;
  });
};

/**
 * Uses an SWR to get organization data.
 * TODO In the future we won't have organization-specific methods
 * {data, error} is returned when data is loaded or an error occurs
 * @param loading If true do nothing
 * @param organizationOrUserState
 * @param cemitApiRouteKeyOrObj
 * @param organizationOrUserState.sourceKey
 * label for the current organization 'flytoget', 'cargonet', etc
 * @param props The props of the request. Depending on the configured API, some props might be ignored
 * @param path
 * @returns {{data, error: undefined}|*} The {data, error} structure when the load completes
 * where data is formatted by the resolve if there is no eror
 */
export const useCemitApiSwrResolveData = <
  ROUTE extends CemitApiRoute<API_REQUEST, API_RESPONSE, T, DATA, PROPS, PARAMS>,
  API_REQUEST extends CemitApiRequest<PROPS, PARAMS> = ROUTE['request'],
  API_RESPONSE extends CemitApiResponse<T, DATA> = Exclude<ROUTE['response'], undefined>,
  T = ROUTE['t'],
  // Optionally the format of the data that comes back from the API. It might be the same as T
  DATA extends Record<string, any> = Exclude<ROUTE['data'], undefined>,
  // The props sent by the caller that get formated to PARAMS
  PROPS extends Record<string, any> = ROUTE['request']['props'],
  // Optionally the format of the parameters given to the request
  PARAMS extends Record<string, any> = ROUTE['request']['params'],
>(
  loading: boolean,
  organizationOrUserState: PerhapsIfLoading<
    typeof loading,
    OrganizationOrUserStateMinimized
  >,
  cemitApiRouteKey: string,
  props: ROUTE['request']['props'],
  path?: string,
  // TODO should be type SWROptions
  swrOptions?: any,
): SWRResponse<DATA> => {
  const cemitApiRoute = useMemo(() => {
    return resolveCemitApiRoute<ROUTE>(
      loading,
      organizationOrUserState,
      cemitApiRouteKey,
      props,
      path,
    );
  }, [loading, organizationOrUserState, cemitApiRouteKey, props, path]);

  // Get the api in use. The Route only specifies an api if it is delegating to an external api
  const apiConfig: CemitApi | undefined = unlessLoadingValue(loading, () => {
    const organizationManifest = resolveOrganizationManifest(organizationOrUserState!);
    return cemitApiRoute.api || organizationManifest.apiConfig.api;
  });

  // An undefined url tells useSwr to not do anything
  const response = useSWR(
    cemitApiRoute?.request?.url,
    cemitApiRoute ? cemitApiRoute?.request?.params : {},
    swrOptions,
  );
  const maybeErrorResponse = when(
    (response) => Boolean(response?.data?.detail),
    (response: Response) => {
      // This is an error
      return compose(
        (response: Response) => {
          return set(lensProp('data'), undefined, response);
        },
        (response: Response) => {
          return set(lensProp('error'), response.data.detail, response);
        },
      )(response);
    },
  )(response);
  // Only process the response when it has changed. useSWR returns the same instance unless the params change.
  const updatedResponse =
    useNotLoadingMemo(
      response.isLoading ||
        response.isValidating ||
        !response.data ||
        maybeErrorResponse.error,
      () => {
        return processApiResponse<ROUTE>(cemitApiRoute, apiConfig, response);
      },
      [
        cemitApiRoute?.request?.url,
        ...values(cemitApiRoute?.request?.params),
        response.data,
        response.error,
      ],
    ) ||
    // Sometimes response.isValidating is true and response.data is set. In this case make data undefined
    // so the caller doesn't process without having put it through processApiResponse
    set(lensProp('data'), undefined, response);

  if (maybeErrorResponse.error) {
    // Don't process, let the caller handle the error
    return maybeErrorResponse;
  } else {
    return updatedResponse;
  }
};
