import {Stack, Tooltip, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Train} from '@mui/icons-material';
import {CEMIT_GREEN_TRAIN, CEMIT_WHITE, cemitColors} from 'theme/cemitColors.ts';
import {SxProps} from '@mui/system';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {always, cond, length, T} from 'ramda';
import {idsInclude} from 'utils/functional/functionalUtils.ts';
import TrainGroupAlertStatus, {
  TrainGroupAlertStatusProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/TrainGroupAlertStatus.tsx';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import CemitFilterTrainGroupExpander, {
  CemitFilterTrainGroupExpanderProps,
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainGroupComponents/CemitFilterTrainGroupExpander.tsx';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {useMemoAnyNonNormalAlerts} from 'async/alertAsync/alertHooks.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {StateSetter} from 'types/hookHelpers/stateSetter';

export interface CemitFilterTrainGroupOnlyTrainFormationComponentProps
  extends CemitComponentProps {
  onClick: () => void;
  iconSx: SxProps;
  stackSx: SxProps;
  typographySx: SxProps;
  trainFormationLabel: string;
  collectionDeviceLabel: string;
  trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation;
  handleAddTrainGroupOnlyTrainFormationToFilter: (
    trainGroupFormation: TrainGroupOnlyTrainFormation,
  ) => void;
  setAlertTypeConfig: StateSetter<AlertTypeConfig>;
}

/**
 * Shows a TrainGroupOnlyTrainFormation listing. When clicked, the TrainGroupOnlyTrainFormation
 * is activated
 * @param appProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupOnlyTrainFormationComponent = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<CemitFilterTrainGroupOnlyTrainFormationComponentProps>): JSX.Element => {
  // Tracks if a child tooltip is active so this componet can disactive its own
  const [childTooltipActive, setChildTooltipActive] = useState<boolean>(false);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);

  const activeTrainGroupFormations =
    trainProps.trainGroupOnlyTrainFormationProps.activeTrainGroupFormations;
  const isAlreadyActive = idsInclude(
    componentProps.trainGroupOnlyTrainFormation,
    activeTrainGroupFormations,
  );
  const disabled = length(activeTrainGroupFormations) == TRAIN_GROUP_MAX_ACTIVE_COUNT;
  const tooltipLabel = cond([
    [
      ({isAlreadyActive}) => {
        return isAlreadyActive;
      },
      always('removeTrainFormation'),
    ],
    [
      ({disabled}) => {
        return disabled;
      },
      always('removeTrainFormationBeforeAdding'),
    ],
    // If not add mode, the menu is for changing trains. This currently can't be disabled
    [T, always('addTrainFormation')],
  ])({disabled, isAlreadyActive});
  const canClick = isAlreadyActive || !disabled;

  // componentProps for TrainGroupAlertStatus
  const trainGroupAlertStatusProps = useNotLoadingMemo(
    trainProps.filteredTrainGroupProps.loading,
    () => {
      return ts<TrainGroupAlertStatusProps>({
        trainGroup: componentProps.trainGroupOnlyTrainFormation,
        setChildTooltipActive,
        handleAddTrainGroupOnlyTrainFormationToFilter:
          componentProps.handleAddTrainGroupOnlyTrainFormationToFilter,
        setAlertTypeConfig: componentProps.setAlertTypeConfig,
      });
    },
    [componentProps.trainGroupOnlyTrainFormation],
  );

  // componentProps for CemitFilterTrainGroupExpanderProps
  const trainGroup = componentProps.trainGroupOnlyTrainFormation;
  // We don't enable the expander unless there are any non normal alerts
  const hasAnythingToShow = useMemoAnyNonNormalAlerts(trainGroup);
  const cemitFilterTrainGroupExpanderProps = useNotLoadingMemo(
    trainProps.filteredTrainGroupProps.loading,
    () => {
      return ts<CemitFilterTrainGroupExpanderProps>({
        trainGroup,
        crudTrainGroups: resolveActiveTrainGroupCrudList(trainProps),
        hasAnythingToShow,
      });
    },
    [componentProps.trainGroupOnlyTrainFormation, trainProps.filteredTrainGroupProps.tr],
  );

  return (
    <Tooltip
      {...{
        arrow: true,
        title: appProps.t(tooltipLabel),
        disableHoverListener: childTooltipActive,
        open: childTooltipActive ? false : tooltipActive,
        onMouseEnter: () => setTooltipActive?.(true),
        onMouseLeave: () => setTooltipActive?.(false),
      }}
    >
      <Stack
        key="trainComponent"
        {...{
          spacing: 1,
          direction: 'row',
          disabled: !canClick,
          onClick: canClick ? componentProps.onClick : undefined,
          sx: {
            minWidth: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            alignItems: 'flex-start',
            '&:hover': {
              cursor: !canClick ? 'not-allowed' : 'pointer',
            },
            padding: '1px',
            borderRadius: '8px',
          },
        }}
      >
        <Stack
          key="train"
          {...{
            spacing: 0.5,
            direction: 'column',
            sx: {
              justifyContent: 'flex-start',
              alignItems: 'center',
            },
          }}
        >
          <Train
            key="icon"
            {...{
              sx: componentProps.iconSx,
            }}
          />
          <CemitFilterTrainGroupExpander
            key="cemitFilterTrainGroupExpander"
            {...{
              loading: !cemitFilterTrainGroupExpanderProps,
              appProps,
              componentProps: cemitFilterTrainGroupExpanderProps,
            }}
          />
        </Stack>
        <Stack
          key="box"
          {...{
            direction: 'column',
            spacing: 0.5,
            sx: {
              padding: '5px',
              minWidth: 0,
              minHeight: 0,
              flex: 1,
              overflow: 'hidden',
              osition: 'relative',
              margin: 2,
              ...componentProps.stackSx,
              border: `1.64px solid ${CEMIT_GREEN_TRAIN}`,
              borderRadius: '8px',
            },
          }}
        >
          <Stack
            key="nameAndStatuses"
            {...{
              spacing: 0.5,
              sx: {
                height: '100%',
                minWidth: 0,
                minHeight: 0,
                flex: 1,
                overflow: 'hidden',
                alignItems: 'left',
              },
            }}
          >
            <Typography
              key="trainIdTitle"
              {...{
                variant: 'subtitle',
                sx: {
                  color: cemitColors.white,
                  ...componentProps.typographySx,
                },
              }}
            >
              {componentProps.trainFormationLabel}
            </Typography>
            <Stack
              key="trainGroupAlertStatus"
              {...{
                sx: {
                  height: '100%',
                  minWidth: 0,
                  minHeight: 0,
                  flex: 1,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                },
              }}
            >
              <TrainGroupAlertStatus
                {...{
                  loading: trainProps.filteredTrainGroupProps.loading,
                  appProps,
                  trainProps,
                  componentProps: trainGroupAlertStatusProps,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Tooltip>
  );
};
export default CemitFilterTrainGroupOnlyTrainFormationComponent;
