import React, {useState} from 'react';
import {Drawer, IconButton, Stack, Typography} from '@mui/material';
import ProfileIcon from './svgIconComponents/sidebar/profileIcon.tsx';
import ProfilePanel from '../../../pages/profile/ProfilePanel.tsx';
import {minAppProps} from 'utils/hooks/cemitBootstrapHooks.ts';
import {CEMIT_WHITE} from 'theme/cemitColors.ts';
import {
  CemitAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppTrainDependencyProps';

/**
 * Shows the Organization's profile
 * @param currentPath
 * @return {JSX.Element}
 * @constructor
 */
export const CemitAppProfileItem = ({
                                      organizationProps,
                                      trainProps,
                                    }: CemitAppTrainDependencyProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <React.Fragment key="rightDrawer">
      <Stack
        {...{
          sx: {
            color: 'secondary',
            minWidth: 0,
            minHeight: 0,
            overflow: 'auto',
            position: 'relative',
            zIndex: 1000,
          },
        }}
      >
        <IconButton {...{
          sx: {
            width: 'fit-content',
            alignSelf: 'center',
            color: 'secondary',
            minWidth: 0,
            minHeight: 0,
            overflow: 'auto',
            position: 'relative',
            zIndex: 1000,
          },
          onClick: toggleDrawer(true)}}>
          <ProfileIcon {...{fill: CEMIT_WHITE}} />
        </IconButton>
        <Typography {...{variant: 'subtitle2', color: 'secondary'}}>
          {organizationProps?.organization?.name}
        </Typography>
      </Stack>
      <Drawer {...{anchor: 'right', open, onClose: toggleDrawer(false)}}>
        <ProfilePanel {...{appProps: minAppProps(), organizationProps, trainProps}} />
      </Drawer>
    </React.Fragment>
  );
};
