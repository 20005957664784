import {
  trainApiAvailableDatesResponseResolver,
  trainApiMinimizedTrainRoutesResponseResolver,
  trainApiOrganizationsResponseResolver,
  trainApiRailwayLinesResponseResolver,
  trainApiServiceLinesResponseResolver,
  trainApiTrainFormationsResponseResolver,
  trainApiTrainFormationsRunCountResponseResolver,
  trainApiTrainRouteGroupsResponseResolver,
  trainApiTrainRunsResponseResolver,
} from 'apis/cemitApis/trainApi/trainApiConfig.ts';
import {
  CemitApiOrganizationRouteParams,
  OrganizationLoaded,
} from 'types/organizations/organization.ts';
import {
  always,
  and,
  concat,
  identity,
  ifElse,
  join,
  length,
  map,
  prop,
  sortBy,
  unless,
} from 'ramda';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {
  trainDateParams,
  trainDateRecurrenceParams,
  trainRunTrainRouteParams,
} from './trainApiTrainRunUtils.ts';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {compactEmpty} from '@rescapes/ramda';
import {
  TrainApiAvailableDatesRoute,
  TrainApiOrganizationRoute,
  TrainApiRailwayLinesRoute,
  TrainApiServiceLinesRoute,
  TrainApiTrainFormationsRoute,
  TrainApiTrainRouteGroupsRoute,
  TrainApiTrainRoutesRoute,
  TrainApiTrainRunRequestParams,
  TrainApiTrainRunsRequestProps,
  TrainApiTrainRunsRoute,
} from 'types/apis/trainApi';
import {
  CemitApiTrainFormationsRouteRequestParams,
  CemitApiTrainFormationsRouteRequestProps,
  TrainFormation,
} from 'types/trains/trainFormation';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainApiTrainRunRequestProps} from 'types/trainRuns/trainRun';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {typeObject} from 'appUtils/typeUtils/typeObject.ts';
import {
  compact,
  hasNonZeroLength,
  mergeRightIfDefined,
} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {
  CemitApiTrainRouteGroupsRouteParams,
  CemitApiTrainRouteGroupsRouteProps,
} from 'types/trainRouteGroups/trainRouteGroup';
import {CemitApiRequest} from 'types/apis/cemitApi';
import {cemitApiGetUrl} from 'apis/cemitApis/apiConfig.ts';

export const ERROR_NO_SENSOR_DATA = 'noSensorData';

// DistanceRange start/end distances are round to .02 kilometers to prevent comparison errors
export const DISTANCE_RANGE_PRECISION = 2;

// API statuses
export const statusProps = ['error', 'errorDate', 'retry', 'status', 'loading'];

export const trainApi: CemitApi = {
  baseUrl: process.env.TRAIN_APP_API as string,
  basePath: 'api',
  version: 2,
  slugifyRequestParams: false,
  // Responses from trainApi are camelized already
  camelizeResponse: false,
  routes: {
    organization: typeObject<TrainApiOrganizationRoute>(
      CemitTypename.cemitApiOrganizationRoute,
      {
        routePath: 'organizations',
        paths: {
          adminOnlyAllOrganizations: 'adminOnlyAllOrganizations',
        },
        request: {
          paramsFilter: ({
            organization: {sourceKey},
          }): CemitApiOrganizationRouteParams => {
            return {sourceKey};
          },
          paths: {
            adminOnlyAllOrganizations: {
              paramsFilter: () => {
                return {};
              },
            },
          },
        },

        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiOrganizationsResponseResolver,
      },
    ),

    availableDateRanges: typeObject<TrainApiAvailableDatesRoute>(
      CemitTypename.cemitApiAvailableDatesRoute,
      {
        routePath: 'trainRuns/withFormationsAvailableDateRanges',
        request: {
          /***
           Request parameters by TrainRoute or TrainRouteGroup and/or by TrainFormation
           ***/
          paramsFilter: ({
            trainRouteOrGroup,
            trainFormation,
            organization,
          }: {
            trainRouteOrGroup: TrainRouteOrGroup;
            trainFormation: TrainFormation;
            organization: OrganizationLoaded;
          }) => {
            return trainRouteOrGroup || trainFormation
              ? mergeRightIfDefined(
                  trainRouteOrGroup ? trainRunTrainRouteParams(trainRouteOrGroup) : {},
                  trainFormation ? {trainFormationId: trainFormation.id} : {},
                )
              : {
                  // The organization is only needed when no other params are given
                  operatorId: organization.operator.id,
                };
          },
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiAvailableDatesResponseResolver,
      },
    ),
    serviceLines: typeObject<TrainApiServiceLinesRoute>(
      CemitTypename.cemitApiServiceLinesRoute,
      {
        routePath: 'serviceLines',
        request: {
          // ServiceLines expects only the operator id from the organization
          paramsFilter: ({
            organization: {
              operator: {id},
            },
          }: {
            organization: OrganizationLoaded;
          }) => ({operatorId: id}),
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiServiceLinesResponseResolver,
      },
    ),
    railwayLines: typeObject<TrainApiRailwayLinesRoute>(
      CemitTypename.cemitApiRailwayLinesRoute,
      {
        routePath: 'railwayLines',
        paths: {
          detail: 'detail',
        },
        request: {
          // RailwayLines expects only the id_in_
          paramsFilter: ({id_in_}: {id_in_: string[]}) => {
            return {
              id_in_: join(',', id_in_),
            };
          },
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiRailwayLinesResponseResolver,
      },
    ),
    trainRouteGroups: typeObject<TrainApiTrainRouteGroupsRoute>(
      CemitTypename.cemitApiTrainRouteGroupsRoute,
      {
        routePath: 'trainRouteGroups',
        request: {
          // TrainRouteGroups expects only the operator id from the organization
          paramsFilter: ({
            organization: {
              operator: {id},
            },
          }: CemitApiTrainRouteGroupsRouteProps): CemitApiTrainRouteGroupsRouteParams => {
            return {operatorId: id};
          },
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiTrainRouteGroupsResponseResolver,
      },
    ),
    trainRoutes: typeObject<TrainApiTrainRoutesRoute>(
      CemitTypename.cemitApiTrainRoutesRoute,
      {
        routePath: 'trainRoutes',
        paths: {
          detail: 'detail',
        },
        request: {
          // TrainRoutes expects only the operator id from the organization
          paramsFilter: ({serviceLines}: {serviceLines: ServiceLine[]}) => {
            if (!length(serviceLines)) {
              return undefined;
            }
            return {
              serviceLineId_in_: join(',', map(prop('id'), serviceLines)),
            };
          },
          paths: {
            detail: {
              paramsFilter: ({trainRoutes}: {trainRoutes: TrainRoute[]}) => {
                if (!length(trainRoutes)) {
                  return undefined;
                }
                const ids = sortBy(identity, map(prop('id'), trainRoutes));
                return {id_in_: join(',', ids)};
              },
            },
          },
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiMinimizedTrainRoutesResponseResolver,
      },
    ),
    trainRuns: typeObject<TrainApiTrainRunsRoute>(CemitTypename.cemitApiTrainRunsRoute, {
      routePath: 'trainRuns',
      paths: {
        // For TrainGroupOnlySingleTrainRun
        withSensorDataPoints: 'withSensorDataPoints',
        // For TrainGroupOnlyTrainFormation
        withTrainFormationSensorDataPoints: 'withTrainFormationSensorDataPoints',
      },
      urlResolver: cemitApiGetUrl,
      request: {
        paramsFilter: (
          props: TrainApiTrainRunsRequestProps,
        ): TrainApiTrainRunRequestParams | undefined => {
          // Combine trainGroupIds and trainGroup.id, defaulting to undefined if empty
          // TODO we only query for one TrainGroup id per request for now
          const allTrainGroupIds = unless<string[], undefined>(
            hasNonZeroLength,
            always(undefined),
          )(
            concat(
              (props.trainGroupIds || []) as string[],
              compact([props.sensorDataTrainGroup?.id]) as string[],
            ),
          );

          const {trainRouteOrGroup} = props.trainRouteGroupProps || {};

          const allDateIntervals: DateInterval[] = compact([
            ...(props.dateIntervals || ([] as DateInterval[])),
            props.dateInterval,
          ]);

          if (!length(allDateIntervals) && !allTrainGroupIds) {
            // We must either be limited to a dateInterval or trainGroupIds to proceed
            return undefined;
          }

          const allTrainFormations = compact([
            ...(props.trainFormations || []),
            ...(props.sensorDataTrainGroup?.trainFormations || []),
          ]);
          const trainFormationIds = length(allTrainFormations)
            ? map(prop('id'), allTrainFormations)
            : undefined;
          const dateParams = trainDateParams(allDateIntervals);

          const dateRecurrenceParams = trainDateRecurrenceParams(props.dateRecurrences);
          const trainRouteParams =
            !allTrainGroupIds && trainRouteOrGroup
              ? trainRunTrainRouteParams(trainRouteOrGroup as TrainRouteOrGroup)
              : {};

          return compactEmpty(
            typeObject<TrainApiTrainRunRequestProps>(
              CemitTypename.trainApiTrainRunRequestProps,
              {
                // Limit TrainRuns to the current TrainRoute or TrainRouteGroup's TrainRoutes
                // This is only required if dateInterval is specified. If allTrainGroupIds is, we don't need a trainRoute
                ...trainRouteParams,
                ...dateParams,
                ...dateRecurrenceParams,
                trainFormationId_in_: trainFormationIds,
                // TODO we don't currently allow trainFormationIds and ids,
                // TrainRuns specified by id don't need to specify their TrainFormation id
                id_in_: ifElse<[string[]], string, undefined>(
                  // When allTrainGroupIds is defined and trainFormationIds is not
                  (allTrainGroupIds: Perhaps<string[]>) => {
                    return and(!trainFormationIds, Boolean(allTrainGroupIds));
                  },
                  (allTrainGroupIds: Perhaps<string[]>) => {
                    return join(',', allTrainGroupIds!);
                  },
                  always(undefined),
                )(allTrainGroupIds),
              },
            ),
          );
        },
      } as CemitApiRequest<TrainApiTrainRunsRequestProps, TrainApiTrainRunRequestParams>,
      responseResolver: trainApiTrainRunsResponseResolver,
    }),
    trainFormations: typeObject<TrainApiTrainFormationsRoute>(
      CemitTypename.cemitApiTrainFormationsRoute,
      {
        routePath: 'trainFormations',
        paths: {
          // Only for admins, returns the number of runs all trainFormations have of an organiz
          runCount: 'runCount',
        },
        request: {
          // Formations expects only the operator id from the organization
          paramsFilter: ({
            organization,
            dateInterval,
          }: CemitApiTrainFormationsRouteRequestProps): CemitApiTrainFormationsRouteRequestParams => {
            return {operatorId: organization.operator.id};
          },
          paths: {
            runCount: {
              // Formations expects only the operator id from the organization
              paramsFilter: ({
                organization,
                dateInterval,
              }: CemitApiTrainFormationsRouteRequestParams) => {
                return {
                  id: organization.id,
                  datetime_ge: dateInterval.start,
                  datetime_le: dateInterval.end,
                };
              },
            },
          },
        },
        urlResolver: cemitApiGetUrl,
        responseResolver: trainApiTrainFormationsResponseResolver,
        responsePathResolvers: {
          runCount: trainApiTrainFormationsRunCountResponseResolver,
        },
      },
    ),
  },
};
