import {ButtonGroup, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {SortConfig} from 'types/sorting/sortConfig.ts';
import {is, map} from 'ramda';
import {resolveTrainGroupSortConfig, trainGroupSortConfigs} from 'config/appConfigs/trainConfigs/trainSortConfig.ts';
import React, {SyntheticEvent, useMemo} from 'react';
import TrainGroupSortButton
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupSortButton.tsx';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {Sort} from '@mui/icons-material';
import TrainGroupToggleSortDirectionButton, {
  TrainGroupToggleSortDirectionButtonProps
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupToggleSortDirectionButton.tsx';
import {SortDirectionEnum} from 'types/sorting/sortDirection.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CEMIT_GRAY} from 'theme/cemitColors.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

/**
 * Presents a ButtonGroup with available TrainGroup sorting options,
 * which is controlled by appProps.[set]ActiveTrainGroupSortConfig
 * @param loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @constructor
 */
export const TrainGroupSortButtons = ({loading, appProps, organizationProps, trainProps}: TrainAppTrainDependencyProps) => {
  // Used to change  the application's current SortConfig
  const [anchorEl, setAnchorEl] = React.useState<Perhaps<HTMLElement>>(undefined);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_e: SyntheticEvent, sortConfig: SortConfig) => {
    setAnchorEl(undefined);
    // if interval comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, sortConfig)) {
      appProps.setTrainGroupSortConfigName(sortConfig.name);
      // Use the default sorting of the sortConfig
      appProps.setTrainGroupSortConfigNameDirection(sortConfig.defaultSortDirection);
    }
  };

  /**
   * Toggles the sort direction between ascending and descending with
   * appProps.setTrainGroupSortConfigNameDirection
   */
  const trainGroupToggleSortDirectionButtonProps = ts<TrainGroupToggleSortDirectionButtonProps>({
    onClick: () => {
      appProps.setTrainGroupSortConfigNameDirection((sortDirectionEnum: SortDirectionEnum) => {
        return (sortDirectionEnum == SortDirectionEnum.ascending) ? SortDirectionEnum.descending : SortDirectionEnum.ascending;
      });
    }
  });

  const trainGroupSortMenuItems = useMemo(() => map(
    (sortConfig: SortConfig) => {
      const componentProps = {
        sortConfig
      };
      return <MenuItem key={sortConfig.name} {...{
        onClick: (e: SyntheticEvent) => handleClose(e, sortConfig),
        sx: {padding: '2px'}
      }}>
        <TrainGroupSortButton {...{loading, appProps, componentProps}} />
      </MenuItem>;
    },
    trainGroupSortConfigs({appProps, organizationProps, trainProps})
  ), [loading, appProps, trainGroupSortConfigs]);

  const ActiveSortIcon = resolveTrainGroupSortConfig(
    {appProps, trainProps, organizationProps},
    appProps.trainGroupSortConfigName
  ).icon
  const sortConfig = resolveTrainGroupSortConfig(
    {appProps, trainProps, organizationProps},
    appProps.trainGroupSortConfigName
  )
  // sortConfig.description is already translated in the config so we can have dynamic descriptions
  return <Tooltip {...{
    arrow: true,
    title:`${appProps.t('sortingBy')} ${sortConfig.description} (${appProps.t(appProps.trainGroupSortConfigNameDirection)})`,
    disableHoverListener: open }} >
    <ButtonGroup {...{
    sx: {border: `${CEMIT_GRAY} 1px solid`},
    variant: 'contained',
    size: 'small',
    color: 'secondary',
    'aria-label': 'Sort button group'
  }}>
      <IconButton
        key="button"
        size="small"
        id="sort-button"
        color="secondary"
        aria-controls={open ? 'sort-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: 'inline-block',
          padding: '2px',
          minHeight: 0,
          minWidth: 0
        }}
      >
        <Sort />
      </IconButton>
      <Menu {...{
        id: 'sort-positioned-menu',
        'aria-labelledby': 'sort-button',
        anchorEl,
        open,
        onClose: handleClose,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      }} >
        {trainGroupSortMenuItems}
      </Menu>
    <ActiveSortIcon {...{color: 'secondary', size: 'small', sx: {alignSelf: 'center'} }}/>
    <TrainGroupToggleSortDirectionButton {...{appProps, componentProps: trainGroupToggleSortDirectionButtonProps}} />
  </ButtonGroup></Tooltip>;
};
export default TrainGroupSortButtons;

