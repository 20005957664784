/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DeviceSshAuthKeyOrderBy {
    DEVICE_NAME = 'device_name',
    _DEVICE_NAME = '-device_name',
    GATEWAY_IDENTIFIER = 'gateway_identifier',
    _GATEWAY_IDENTIFIER = '-gateway_identifier',
    CREATED_DATETIME = 'created_datetime',
    _CREATED_DATETIME = '-created_datetime',
    UPDATED_DATETIME = 'updated_datetime',
    _UPDATED_DATETIME = '-updated_datetime',
}
