import React, {useState, useEffect} from 'react';
import {DirectionPoint, Feature, DetectorData} from './types';
import StatusPage from './StatusPage.tsx';
import tagglist from './tempFile/tagglista20240627.csv';
import useExcelToGeoJson from './hooks/useExcelToGeoJson.ts';
import {AppBar, Toolbar, Stack, MenuItem} from '@mui/material';
import {Button, Menu} from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';
import Sidebar from './components/sidepanel/SideBar.tsx';
import useFetchAllTags from './hooks/useFetchAllTags.ts';
import { VehicleStatus } from './types/index.ts';
const StausPageConatiner: React.FC<{
  spaceId: string | null;
  pointId: string | null;
}> = ({}) => {
  const [detectors, setDetectors] = useState<DetectorData[]>([]);
  const addDetectorData = (newData: DetectorData) => {
    setDetectors((prevDetectors) => [...prevDetectors, newData]);
  };
  const {data,} = useFetchAllTags();
  const [singleProcessedTag, setSingleProcessedTag] = useState<VehicleStatus | undefined>();
  const [selectedCdc,] = useState<string>(''); // State to hold selected CDC
  const {pointsGeoJsonData, dataLoaded, convertExcelToGeoJson} =
    useExcelToGeoJson(tagglist);
  const [pointGeoJsonDataV, setGeoJsonDataV] = useState<DirectionPoint | null>(null);
  const [selectedTag] = useState<Feature | null>(null);
  const [isFullScreenForTramDetail, setIsFullScreenForTramDetail] = useState<Boolean>(false)

  const [selectedValueForFunction, setSelectedValueForFunction] =
    useState<string>('SOFTPRIO');

  useEffect(() => {
    convertExcelToGeoJson();
  }, [convertExcelToGeoJson]);

  useEffect(() => {
    if (dataLoaded) {
      setGeoJsonDataV(pointsGeoJsonData);
    }
  }, [dataLoaded, pointsGeoJsonData]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: string | null) => {
    setAnchorEl(null);
    if (value) {
      setSelectedValueForFunction(value);
    }
  };
  return (
    <>
      <Stack sx={{width: '100%', height: '100%'}}>
        {/* AppBar with slight border to stand out */}
        <AppBar
          position="static"
          sx={{backgroundColor: '#FFFFFF', borderBottom: '1px solid #E0E0E0'}}
        >
          <Toolbar>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ArrowDropDown />}
              sx={{color: '#6c6c6c', fontSize: '16px', fontWeight: 500}}
            >
              SOFTPRIO
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => handleClose(null)}
              sx={{'& .MuiPaper-root': {backgroundColor: 'white'}}}
            >
              <MenuItem sx={{color: '#6c6c6c'}} onClick={() => handleClose('Detektorer')}>
                Detektorer
              </MenuItem>
              <MenuItem
                sx={{color: '#6c6c6c'}}
                onClick={() => handleClose('Radiokvalitet')}
              >
                Radiokvalitet
              </MenuItem>
              <MenuItem
                sx={{color: '#6c6c6c'}}
                onClick={() => handleClose('Kraftförsörjning')}
              >
                Kraftförsörjning
              </MenuItem>
              <MenuItem
                sx={{color: '#6c6c6c'}}
                onClick={() => handleClose('Missade taggar')}
              >
                Missade taggar
              </MenuItem>
              <MenuItem
                sx={{color: '#6c6c6c'}}
                onClick={() => handleClose('Live Status')}
              >
               Live Status
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        {/* Main content area with sidebar and status page */}
        <Stack direction="row" sx={{flexGrow: 1}}>
          <Sidebar functionName={selectedValueForFunction} isFullScreenForTramDetail={(isFullScreen:Boolean)=>{setIsFullScreenForTramDetail(isFullScreen)}} selectedTram={(singleTram:VehicleStatus)=>{setSingleProcessedTag(singleTram)} }fetchedTags ={data} />
          {pointGeoJsonDataV && !isFullScreenForTramDetail && (
            <StatusPage
              functionName={selectedValueForFunction}
              directionPoints={pointGeoJsonDataV}
              selectedTag={selectedTag}
              selectedCDC={selectedCdc}
              singleProcessedTag={singleProcessedTag}
              onAddDetector={addDetectorData}
              fetchedTags = {data}

            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default StausPageConatiner;
