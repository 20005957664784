import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {getUpcomingRestrictions, removeRestriction,} from 'pages/trafficsim/api/net/repo.ts';
import {restrictionTypes} from 'pages/trafficsim/api/data/consts.ts';
import EditButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/EditButton/EditButton.tsx';
import DeleteButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/DeleteButton/DeleteButton.tsx';
import ZoomLocationButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/IconsButtons/ZoomLocationButton.tsx';
import DeleteConfirmationDialog
  from 'pages/trafficsim/trafficSimComponents/themedComponents/DeleteConfirmationDialog.tsx';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {falsyToUndefined} from 'utils/functional/functionalUtils.ts';
import {
  extractTrafficSimAccess,
  hasTrafficSimAdminAccess
} from "../../../../appUtils/authentication/authenticationUtils.ts";
import {extractDateObject} from "../track/utils/datetime_util.ts";
import {useTheme} from "@mui/styles";
import {TablePagination} from "@mui/base/TablePagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import {CustomTablePagination} from "../pagination/CustomTablePagination.tsx";

const UpcomingLimitations = ({
                               setActiveSubPage = (restriction) => {
                               },
                               onMoveMapToCoord = (coordinates) => {
                               },
                               onDeleteRestriction = (currentRestriction) => {
                               },
                               isExpanded,
                             }) => {
  const theme = useTheme();
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const [pages, setPages] = useState<number>(0);
  const [foldedPageNumber, setFoldedPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = useState('');
  const [restrictions, setRestrictions] = useState([]);
  const [isLoading, setIsLoadingState] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentRestriction, setCurrentRestriction] = useState(null);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPages(newPage);
    fetchRestriction(false, newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRows = parseInt(event.target.value, 10)
    if (newRows) {
      setRowsPerPage(newRows);
      setPages(0);
      setRestrictions([]);
      fetchRestriction(false, 1, newRows);
    }
  };

  const handleDeleteClick = (restriction) => {
    setCurrentRestriction(restriction);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    const response = await onDeleteRestriction(currentRestriction);
    if (response) {
      setIsLoadingState(true);
    }
    setDialogOpen(false);
  };

  const fetchRestriction = (isInit = false, _page, _rowsPerPage) => {
    if (isInit && isLoading) return;
    getUpcomingRestrictions(_page, _rowsPerPage).then((response) => {
      if (response !== null) {
        setIsLoadingState(false);
        setTotal(response.total);
        if (!isExpanded) {
          setFoldedPageNumber((prevState) => prevState + 1)
          setRestrictions((prev) => [...prev, ...response.data]);
          return;
        }
        setRestrictions(response.data);
      }
    });
  }

  useEffect(() => {
    if (isExpanded) {
      setFoldedPageNumber(0);
      setRowsPerPage(10);
      return;
    }
    fetchRestriction(false, foldedPageNumber + 1, rowsPerPage)
  }, [isExpanded]);

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  const getIcon = (begransning) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img src={IconSrc} alt={begransning} style={{marginRight: '8px'}}/>
    ) : null;
  };

  const deleteRestriction = async (restriction) => {
    const response = await removeRestriction(restriction.id);
    if (response) {
      setIsLoadingState(true);
    }
  };

  const editRestriction = async (restriction) => {
    setActiveSubPage(restriction);
  };

  const isNotReadOnlyAccess = () => {
    return hasTrafficSimAdminAccess(userState) || extractTrafficSimAccess(userState) !== ACCESS_LEVELS.READ_ONLY;
  };
  const Row = ({row}) => {
    const [open, setOpen] = useState(false);

    const startTimestampDate = extractDateObject(row.startTimestamp);
    const endTimestampDate = extractDateObject(row.endTimestamp ?? "");

    return (
      <React.Fragment>
        <TableRow sx={{
          'td, th': {color: theme.palette.text.primary},
          '& > *': {
            borderBottom: 'unset'
          }
        }}>
          <TableCell
            align="left"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 0,
              height: '100%',
            }}
          >
            {getIcon(row.restrictionType)}

            {restrictionTypes[row.restrictionType]}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.startPlace.name} ${row.endPlace.name}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.switchId}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.speedLimit}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.creationReason}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.endTimestamp ? endTimestampDate.toDateString() : ""} ${row.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : ""}`}
          </TableCell>
          <TableCell>
            {isNotReadOnlyAccess() && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="2px"
                marginTop="5px"
              >
                <EditButton onEdit={() => editRestriction(row)}/>
                <DeleteButton onDelete={() => handleDeleteClick(row)}/>
                <DeleteConfirmationDialog
                  open={isDialogOpen}
                  onClose={handleClose}
                  onConfirm={handleConfirmDelete}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1}}>
                <Typography variant="body2" gutterBottom>
                  Notering: {row.notes}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Begränsning på begäran av: {row.authorizedBy}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Begränsning inlagd av: {row.createdBy}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = restrictions.filter((item) => {
    return filter === '' || item.begransning === filter;
  });

  const filterDropdown = (
    <FormControl
      sx={{m: 0, minWidth: 260, marginBottom: 3, fontSize: '12px'}}
      size="small"
    >
      <InputLabel id="filter-label" sx={{fontSize: '14px'}}>
        Filter
      </InputLabel>
      <Select
        style={{width: '200px'}}
        labelId="filter-label"
        id="filter-select"
        value={filter}
        label="Filter"
        onChange={handleFilterChange}
        sx={{fontSize: '12px'}}
      >
        <MenuItem value="" sx={{fontSize: '12px'}}>
          Alla
        </MenuItem>
        <MenuItem value="Avstängt spår" sx={{fontSize: '12px'}}>
          Avstängt spår
        </MenuItem>
        <MenuItem value="Backförbud" sx={{fontSize: '12px'}}>
          Backförbud
        </MenuItem>
        <MenuItem value="Hastighetsbegränsning" sx={{fontSize: '12px'}}>
          Hastighetsbegränsning
        </MenuItem>
        <MenuItem value="Annan begränsning" sx={{fontSize: '12px'}}>
          Annan begränsning
        </MenuItem>
      </Select>
    </FormControl>
  );

  const drawerLayout = (
    <div style={{width: '280px'}}>
      <Grid container spacing={2}>
        {restrictions.map((item, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Card variant="outlined">
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                  <RestrictionContent
                    {...{
                      getIcon,
                      onMoveMapToCoord,
                      item,
                    }}
                  />
                  <TableInfo {...{item}} />
                  <MaybeDelete
                    {...{
                      isNotReadOnlyAccess,
                      editRestriction,
                      handleDeleteClick,
                      isDialogOpen,
                      handleClose,
                      handleConfirmDelete,
                      item,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {falsyToUndefined(isLoading) && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <CircularProgress/>
          </Grid>
        )}
        {total && total > restrictions.length &&
          <Grid sx={{mt: 2}} colSpan={8}>
            {!isLoading &&
              <Button onClick={() => fetchRestriction(true, foldedPageNumber + 1, rowsPerPage)}>Load More</Button>}
          </Grid>
        }
      </Grid>
    </div>
  );

  const expandedLayout = (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Begränsning</TableCell>
            <TableCell>Plats</TableCell>
            <TableCell>Berörda</TableCell>
            <TableCell>Hastighet</TableCell>
            <TableCell>Orsak</TableCell>
            <TableCell>Starttid</TableCell>
            <TableCell>Sluttid</TableCell>
            <TableCell>Detaljer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restrictions.map((row, index) => (
            <Row key={index} row={row}/>
          ))}
          {isLoading && (
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
              <TableCell>
                <CircularProgress/>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              rowsPerPageOptions={[10, 20, 30]}
              colSpan={9}
              count={total}
              rowsPerPage={rowsPerPage}
              page={pages}
              slotProps={{
                select: {
                  'aria-label': 'Rows',
                },
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                  slots: {
                    firstPageIcon: FirstPageRoundedIcon,
                    lastPageIcon: LastPageRoundedIcon,
                    nextPageIcon: ChevronRightRoundedIcon,
                    backPageIcon: ChevronLeftRoundedIcon,
                  },
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      {/*{filterDropdown}*/}
      {isExpanded ? expandedLayout : drawerLayout}
    </div>
  );
};

const RestrictionContent = ({getIcon, onMoveMapToCoord, item}) => {
  return (
    <Stack
      {...{
        direction: 'row',
        sx: {
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }}
    >
      <Stack
        {...{
          direction: 'row',
          sx: {
            alignItems: 'center',
            justifyContent: 'start',
          },
        }}
      >
        {getIcon(item.restrictionType)}
        {restrictionTypes[item.restrictionType]}
      </Stack>
      <span>
        <ZoomLocationButton onClick={() => onMoveMapToCoord(item.coordinates)}/>
      </span>
    </Stack>
  );
};

const MaybeDelete = ({
                       isNotReadOnlyAccess,
                       editRestriction,
                       handleDeleteClick,
                       isDialogOpen,
                       handleClose,
                       handleConfirmDelete,
                       item,
                     }) => {
  return (
    falsyToUndefined(isNotReadOnlyAccess()) && (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap="2px"
        marginTop="5px"
      >
        <EditButton onEdit={() => editRestriction(item)}/>
        <DeleteButton onDelete={() => handleDeleteClick(item)}/>
        <DeleteConfirmationDialog
          open={isDialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    )
  );
};

const TableInfo = ({item: restriction}) => {
  const startTimestampDate = extractDateObject(restriction.startTimestamp);
  const endTimestampDate = extractDateObject(restriction.endTimestamp ?? "");
  return (
    <Stack {...{direction: 'column'}}>
      <Typography variant="caption" color="text.secondary">
        <strong>Plats:</strong> {restriction.startPlace.name} - {restriction.endPlace.name}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Berörda:</strong>
        {restriction.switchId}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Hastighet:</strong> {restriction.speedLimit}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Orsak:</strong> {restriction.creationReason}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Starttid:</strong>{' '}
        {`${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Sluttid:</strong>{' '}
        {`${restriction.endTimestamp ? endTimestampDate.toDateString() : ""} ${restriction.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : ""}`}
      </Typography>
    </Stack>
  );
};
export default UpcomingLimitations;
