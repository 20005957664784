import {CrudList} from 'types/crud/crudList';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {AlertTrainGroupProps} from 'types/alerts/alertTrainGroupProps';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks';
import {
  always,
  complement,
  filter,
  forEach,
  ifElse,
  includes,
  isNil,
  join,
  lensPath,
  lensProp,
  map,
  mergeAll,
  mergeRight,
  or,
  pick,
  props,
  toPairs,
  unless,
  values
} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers';
import {CemitTypename} from 'types/cemitTypename';
import {clsOrType} from 'appUtils/typeUtils/clsOrType';
import {overClassOrType, setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum';
import {lengthAsBoolean} from 'utils/functional/functionalUtils';
import {queryAlertApiForSingleAlertTypeAndMutate} from 'async/alertAsync/alertSingleAlertTypeQueries';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps';
import {
  formatInTimeZoneUnlessLocal,
  trainDataFriendlyTimeFormatString,
  trainDataFriendlyTimeFormatStringWithTimeZone
} from 'utils/datetime/timeUtils';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {AlertConfigProps} from 'types/alerts/alertConfigProps';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {omitSensorBasedDataExceptProps} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil';
import {PeriodEnum} from 'types/alerts/periodEnum';
import {TFunction} from 'i18next';
import {
  queryAlertApiForAllOverviewAndSummaryAlertTypesAndMutate
} from 'async/alertAsync/alertOverviewAndSummaryQueries';

export const resolveAlertDateInterval = (
  trainFormationDateInterval: DateInterval,
  activeTrainGroup: Perhaps<TrainGroup>,
): DateInterval => {
  if (!activeTrainGroup) {
    return trainFormationDateInterval;
  }
  // If activeTrainGroup is TrainGroupSingleTrainRun, use the TrainRun's dateInterval
  const dateInterval: DateInterval = ifElse<
    [Perhaps<TrainGroup>],
    DateInterval,
    DateInterval
  >(
    (activeTrainGroup): boolean => {
      return implementsCemitTypeViaClass(
        CemitTypename.trainGroupSingleTrainRun,
        activeTrainGroup,
      );
    },
    ({singleTrainRun}: TrainGroupSingleTrainRun) => {
      return clsOrType<DateInterval>(CemitTypename.dateInterval, {
        start: singleTrainRun.departureDatetime,
        end: singleTrainRun.arrivalDatetime,
      });
    },
    () => trainFormationDateInterval,
  )(activeTrainGroup);
  return dateInterval;
};
/**
 * Creates AlertScopeProps for each activeTrainGroup
 * which can be a TrainGroup or TrainGroupOnlyTrainFormation depending on whether a TrainRun or only
 * a TrainFormation has been selected by the user
 * @param loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param alertTrainGroupInitialProps
 * @param trainGroups
 */
export const useNotLoadingMemoCreateTrainGroupAlertScopePropsSets = (
  loading: boolean,
  appProps: TrainAppProps,
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  alertTrainGroupInitialProps: AlertTrainGroupProps,
  trainGroups: Perhaps<TrainGroup[]>,
): AlertScopeProps[] => {
  const alertConfigProps = trainProps.alertConfigProps;
  const dateIntervalDescription =
    trainProps?.trainFormationDateProps?.dateIntervalDescription;
  // useWhatChanged(
  //   [
  //     loading,
  //     alertConfigProps,
  //     alertTrainGroupInitialProps,
  //     activeTrainGroups,
  //     dateIntervalDescription,
  //   ],
  //   'loading, alertConfigProps, alertTrainGroupInitialProps, activeTrainGroups, dateIntervalDescription',
  //   'useNotLoadingCreateTrainGroupAlertScopePropsSets',
  // );
  return useNotLoadingMemo(
    // datetime and trainGroupOnlyTrainFormation must be defined if loading is false
    loading,
    (
      alertConfigProps,
      alertTrainGroupInitialProps,
      activeTrainGroups,
      dateIntervalDescription,
    ) => {
      const alertScopePropSets = map((activeTrainGroup: TrainGroup) => {
        const dateInterval = activeTrainGroup.activeDateInterval;

        // Use this TrainRun's IntervalDescription if this is a TrainGroupSingleTrainRun
        const trainRunIntervalDescription: Perhaps<DateIntervalDescription> = ifElse(
          (activeTrainGroup: TrainGroup) => {
            return implementsCemitTypeViaClass(
              CemitTypename.trainGroupSingleTrainRun,
              activeTrainGroup,
            );
          },
          (activeTrainGroup: TrainGroup) => {
            const singleTrainRun = activeTrainGroup.singleTrainRun;
            const dateInterval = clsOrType<DateInterval>(CemitTypename.dateInterval, {
              start: singleTrainRun.departureDatetime,
              end: singleTrainRun.arrivalDatetime,
            });
            return clsOrType<DateIntervalDescription>(
              CemitTypename.alertIntervalDescription,
              {
                sourceKey: 'trainRun',
                label: appProps.t('trainRunDuration'),
                // Duration text is departureTime-arrivalTime
                durationText: join(
                  '-',
                  map(
                    (date: Date) => {
                      return formatInTimeZoneUnlessLocal(
                        organizationProps.localTimezoneMatchesOrganization,
                        date,
                        organizationProps.organization.timezoneStr,
                        trainDataFriendlyTimeFormatString,
                        trainDataFriendlyTimeFormatStringWithTimeZone,
                      );
                    },
                    [dateInterval.start, dateInterval.end],
                  ),
                ),
                shortenDurationText: true,
                allowsPeriods: [PeriodEnum.today],
              },
            );
          },
          always(undefined),
        )(activeTrainGroup);

        const dateIntervalDescriptionWithDateInterval = setClassOrType<
          DateIntervalDescription,
          DateInterval
        >(lensProp('dateInterval'), dateInterval, dateIntervalDescription);

        // Use trainRunIntervalDescription if defined, else use the dateIntervalDescription
        const chosenIntervalDescription = or<
          DateIntervalDescription,
          DateIntervalDescription
        >(trainRunIntervalDescription, dateIntervalDescriptionWithDateInterval);

        const alertScopeProps = clsOrType<AlertScopeProps>(
          CemitTypename.alertScopeProps,
          {
            // scopedTrainGroup.alertScopeProps.alertTrainGroupProps represents our existing values
            scopedTrainGroup: activeTrainGroup,
            // The current non-TrainGroup-specific configuration
            alertConfigProps: alertConfigProps,

            // This is set and updated when the heatmap data downloads for the activeTrainGroup
            // alertTrainGroupProps represents our incoming values
            alertTrainGroupProps: clsOrType<AlertTrainGroupProps>(
              CemitTypename.alertTrainGroupProps,
              mergeAll<
                AlertTrainGroupProps,
                [AlertTrainGroupProps, {dateIntervalDescription: DateIntervalDescription}]
              >([
                alertTrainGroupInitialProps,
                // Merge in read-only props of activeTrainGroup?.alertTrainGroupProps if set
                // We don't need to query again if alertTrainGroupProps already has the heatmap
                // data for the date interval
                activeTrainGroup?.alertScopeProps.alertTrainGroupProps ||
                  ({} as AlertTrainGroupProps),
                {dateIntervalDescription: chosenIntervalDescription},
              ]),
            ),
          },
        );

        return alertScopeProps;
      }, activeTrainGroups);
      return alertScopePropSets;
    },
    [
      alertConfigProps,
      alertTrainGroupInitialProps,
      trainGroups,
      dateIntervalDescription,
    ] as const,
  );
};

/**
 * For each alertScopeProps in alertScopePropSets, downloads Alert data
 * if alertScopeProps.alertTrainGroupProps is not up-to-date with alertScopeProps.alertConfigProps,
 * meaning the latter has been changed by user action and the former has stale or no Alert data
 * @param loading
 * @param alertScopePropSets
 * @param alertConfigProps
 * @param trainGroupCrudList
 */
export const useNotLoadingEffectQueryAlertApiAndMutateDependencies = (
  loading: boolean,
  t: TFunction,
  alertScopePropSets: Perhaps<AlertScopeProps[]>,
  alertConfigProps: AlertConfigProps,
  trainGroupCrudList: CrudList<TrainGroup>,
) => {
  const {alertGaugeTimePeriod} = alertConfigProps;
  const alertTypeConfig = alertConfigProps.alertTypeConfig;
  const queryAlertApiAndMutateDependencies = [
    alertScopePropSets,
    alertGaugeTimePeriod,
    alertTypeConfig,
  ] as const;

  // Phase 1, LoadingStatusEnum.notLoading|complete => LoadingStatusEnum.needsToLoad
  // For each scopedTrainGroup of alertScopePropSets, set the status to  LoadingStatusEnum.needsToLoad
  // if their alertScopeProps with the incoming alertScopePropSets
  useNotLoadingEffect<typeof queryAlertApiAndMutateDependencies>(
    loading,
    (alertScopePropSets, _alertTimePeriodForMap, _alertTypeConfig) => {
      forEach((incomingAlertScopeProps: AlertScopeProps) => {
        const activeTrainGroup = incomingAlertScopeProps.scopedTrainGroup;
        // If already in a loading or needsToLoad state, skip this check
        // If in a notLoaded or complete state, run the check to see if something has changed that requires a load
        if (
          includes(
            activeTrainGroup.alertScopeProps?.alertTrainGroupProps?.alertGraphqlStatus,
            [LoadingStatusEnum.loading, LoadingStatusEnum.needsToLoad],
          )
        ) {
          return;
        }
        const existingAlertScopeProps = activeTrainGroup.alertScopeProps;

        // Only fetch data if activeTrainGroup!.alertConfigProps isn't already set to the same configuration
        // as has been set on activeTrainGroup.alertScopeProps,
        // Otherwise, the desired data was already loaded and stored on this activeTrainGroup
        // because activeTrainGroup.alertConfigProps is set after the data loads
        const whatChanged: Record<string, [string, string]> = filter(
          complement(isNil),
          map(
            (accessor: (alertScopePropSets: Perhaps<AlertScopeProps>) => any) => {
              return unless(isNil, (incomingValue: any) => {
                const existingValue = accessor(existingAlertScopeProps);
                return incomingValue != existingValue
                  ? [existingValue?.toString() || 'undefined', incomingValue!.toString()]
                  : undefined;
              })(accessor(incomingAlertScopeProps));
            },
            // Check if any of these values changed
            {
              duration: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return alertScopePropSets?.alertTrainGroupProps?.dateIntervalDescription
                  ?.duration;
              },
              dateInterval: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return join(
                  ' to ',
                  props(
                    ['start', 'end'],
                    alertScopePropSets?.alertTrainGroupProps?.dateIntervalDescription
                      ?.dateInterval ||
                      ({
                        start: 'undefined',
                        end: 'undefined',
                      } as Record<'start' | 'end', string | Date>),
                  ),
                );
              },
              alertType: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return alertScopePropSets?.alertConfigProps?.alertTypeConfig
                  ?.alertTypeKey;
              },
              alertTimePeriodForMap: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return alertScopePropSets?.alertConfigProps?.alertGaugeTimePeriod;
              },
            },
          ),
        );
        // If any of the computed properties above have changed, update
        //  existingAlertScopeProps!.alertTrainGroupProps.alertGraphqlStatus
        // and persist within the crudList
        if (lengthAsBoolean(values(whatChanged))) {
          const diff = map(
            (pair: [string, string]) => join(': ', pair),
            toPairs(
              map((values: [any, any]) => {
                return join('<=>', values);
              }, whatChanged),
            ),
          );
          console.debug(
            `For TrainGroup: ${activeTrainGroup.localizedName()}, the following properties changed: ${diff}`,
          );

          const updatedActiveTrainGroup = overClassOrType(
            lensPath(['alertScopeProps', 'alertTrainGroupProps']),
            (alertTrainGroupProps: AlertTrainGroupProps) => {
              return mergeRight<AlertTrainGroupProps, Partial<AlertTrainGroupProps>>(
                alertTrainGroupProps,
                {
                  alertGraphqlStatus: LoadingStatusEnum.needsToLoad,
                  // Clear the old data to prepare for a new load
                  heatMapData: undefined,
                },
              );
            },
            activeTrainGroup,
          );
          const omitted = omitSensorBasedDataExceptProps(
            ['alertScopeProps'],
            updatedActiveTrainGroup,
          );
          trainGroupCrudList.updateOrCreate(omitted);
        }
      }, alertScopePropSets);
    },
    queryAlertApiAndMutateDependencies,
  );

  // Phase 2, LoadingStatusEnum.needsToLoad => LoadingStatusEnum.loading
  useNotLoadingEffect(
    loading,
    (alertScopePropSets) => {
      forEach((alertScopeProps: AlertScopeProps) => {
        const activeTrainGroup = alertScopeProps.scopedTrainGroup;
        const {alertGraphqlStatus} =
          activeTrainGroup!.alertScopeProps!.alertTrainGroupProps;

        if (alertGraphqlStatus == LoadingStatusEnum.needsToLoad) {
          const updatedActiveTrainGroup = setClassOrType(
            lensPath(['alertScopeProps', 'alertTrainGroupProps', 'alertGraphqlStatus']),
            LoadingStatusEnum.loading,
            activeTrainGroup,
          );

          const omitted = omitSensorBasedDataExceptProps(
            ['alertScopeProps'],
            updatedActiveTrainGroup,
          );
          trainGroupCrudList.updateOrCreate(omitted);

          async function fetchData(): Promise<void> {
            const updatedScopeProps = setClassOrType(
              lensProp('scopedTrainGroup'),
              updatedActiveTrainGroup,
              alertScopeProps,
            );
            await queryAlertApiForSingleAlertTypeAndMutate(
              t,
              updatedScopeProps,
              trainGroupCrudList,
            );
          }

          fetchData();
        }
      }, alertScopePropSets);
    },
    [alertScopePropSets, alertTypeConfig] as const,
  );
};

/**
 * For each alertScopeProps in alertScopePropSets, downloads Alert summary data
 * if alertScopeProps.alertTrainGroupProps is not up-to-date with alertScopeProps.alertConfigProps,
 * meaning the latter has been changed by user action and the former has stale or no Alert data
 * The only user action makes this reload is a change the date interval, since there is currently
 * now way to change the TrainGroups. The loaded data is saved
 * to trainGroup.alertScopeSummaryProps.alertTrainGroupProps.alertGaugesByTimePeriod
 * for each trainGroup in trainGroupCrudList.list
 *
 * In addition to alert summary data, the queries called by this effect also includes
 * a query of overview data, which is a percentage of warning/critical alerts for
 * 1 day, 30 days, 90 days, and 180 days.  This loaded data is saved
 * to trainGroup.alertScopeSummaryProps.alertTrainGroupProps.alertOverviewsByTimePeriod
 * for each trainGroup in trainGroupCrudList.list
 *
 * @param loading
 * @param alertScopePropSets
 * @param alertConfigProps
 * @param trainGroupCrudList
 */
export const useNotLoadingEffectQueryAlertSummaryApiAndMutateDependencies = (
  loading: boolean,
  t: TFunction,
  alertScopePropSets: Perhaps<AlertScopeProps[]>,
  alertConfigProps: AlertConfigProps,
  trainGroupCrudList: CrudList<TrainGroup>,
) => {
  const {alertGaugeTimePeriod} = alertConfigProps;
  const alertTypeConfig = alertConfigProps.alertTypeConfig;
  const queryAlertApiAndMutateDependencies = [
    alertScopePropSets,
    alertGaugeTimePeriod,
    alertTypeConfig,
  ] as const;

  // Phase 1, LoadingStatusEnum.notLoading|complete => LoadingStatusEnum.needsToLoad
  // For each scopedTrainGroup of alertScopePropSets, set the status to  LoadingStatusEnum.needsToLoad
  // if their alertScopeProps with the incoming alertScopePropSets
  useNotLoadingEffect<typeof queryAlertApiAndMutateDependencies>(
    loading,
    (alertScopePropSets, _alertTimePeriodForMap, _alertTypeConfig) => {
      forEach((incomingAlertScopeProps: AlertScopeProps) => {
        const activeTrainGroup = incomingAlertScopeProps.scopedTrainGroup;
        // If already in a loading or needsToLoad state, skip this check
        // If in a notLoaded or complete state, run the check to see if something has changed that requires a load
        if (
          includes(
            activeTrainGroup.alertScopeSummaryProps?.alertTrainGroupProps
              ?.alertGraphqlStatus,
            [LoadingStatusEnum.loading, LoadingStatusEnum.needsToLoad],
          )
        ) {
          return;
        }
        const existingAlertScopeProps = activeTrainGroup.alertScopeSummaryProps;

        // Only fetch data if activeTrainGroup!.alertConfigProps isn't already set to the same configuration
        // as has been set on activeTrainGroup.alertScopeSummaryProps,
        // Otherwise, the desired data was already loaded and stored on this activeTrainGroup
        // because activeTrainGroup.alertConfigProps is set after the data loads
        const whatChanged: Record<string, [string, string]> = filter(
          complement(isNil),
          map(
            (accessor: (alertScopePropSets: Perhaps<AlertScopeProps>) => any) => {
              return unless(isNil, (incomingValue: any) => {
                const existingValue = accessor(existingAlertScopeProps);
                return incomingValue != existingValue
                  ? [existingValue?.toString() || 'undefined', incomingValue!.toString()]
                  : undefined;
              })(accessor(incomingAlertScopeProps));
            },
            // Check if any of these values changed
            {
              duration: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return alertScopePropSets?.alertTrainGroupProps?.dateIntervalDescription
                  ?.duration;
              },
              dateInterval: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return join(
                  ' to ',
                  props(
                    ['start', 'end'],
                    alertScopePropSets?.alertTrainGroupProps?.dateIntervalDescription
                      ?.dateInterval ||
                      ({
                        start: 'undefined',
                        end: 'undefined',
                      } as Record<'start' | 'end', string | Date>),
                  ),
                );
              },
              alertTimePeriodForMap: (alertScopePropSets: Perhaps<AlertScopeProps>) => {
                return alertScopePropSets?.alertConfigProps?.alertGaugeTimePeriod;
              },
            },
          ),
        );
        // If any of the computed properties above have changed, update
        //  existingAlertScopeProps!.alertTrainGroupProps.alertGraphqlStatus
        // and persist within the crudList
        if (lengthAsBoolean(values(whatChanged))) {
          const diff = map(
            (pair: [string, string]) => join(': ', pair),
            toPairs(
              map((values: [any, any]) => {
                return join('<=>', values);
              }, whatChanged),
            ),
          );
          console.debug(
            `For TrainGroup: ${activeTrainGroup.localizedName()}, the following properties changed: ${diff}`,
          );

          const updatedActiveTrainGroup = overClassOrType(
            lensPath(['alertScopeSummaryProps', 'alertTrainGroupProps']),
            (alertTrainGroupProps: AlertTrainGroupProps) => {
              return mergeRight<AlertTrainGroupProps, Partial<AlertTrainGroupProps>>(
                alertTrainGroupProps,
                {
                  alertGraphqlStatus: LoadingStatusEnum.needsToLoad,
                  // Clear the old data to prepare for a new load
                  heatMapData: undefined,
                },
              );
            },
            activeTrainGroup,
          );
          const omitted = omitSensorBasedDataExceptProps(
            ['alertScopeSummaryProps'],
            updatedActiveTrainGroup,
          );
          trainGroupCrudList.updateOrCreate(omitted);
        }
      }, alertScopePropSets);
    },
    queryAlertApiAndMutateDependencies,
  );

  // Phase 2, LoadingStatusEnum.needsToLoad => LoadingStatusEnum.loading
  useNotLoadingEffect(
    loading,
    (alertScopePropSets) => {
      forEach((alertScopeProps: AlertScopeProps) => {
        const activeTrainGroup = alertScopeProps.scopedTrainGroup;
        const {alertGraphqlStatus} =
          activeTrainGroup!.alertScopeSummaryProps!.alertTrainGroupProps;

        if (alertGraphqlStatus == LoadingStatusEnum.needsToLoad) {
          const updatedActiveTrainGroup = setClassOrType(
            lensPath([
              'alertScopeSummaryProps',
              'alertTrainGroupProps',
              'alertGraphqlStatus',
            ]),
            LoadingStatusEnum.loading,
            activeTrainGroup,
          );

          const omitted = omitSensorBasedDataExceptProps(
            ['alertScopeSummaryProps'],
            updatedActiveTrainGroup,
          );
          trainGroupCrudList.updateOrCreate(omitted);

          async function fetchData(): Promise<void> {
            const updatedScopeProps = setClassOrType(
              lensProp('scopedTrainGroup'),
              updatedActiveTrainGroup,
              alertScopeProps,
            );
            await queryAlertApiForAllOverviewAndSummaryAlertTypesAndMutate(
              t,
              updatedScopeProps,
              trainGroupCrudList,
            );
          }

          fetchData();
        }
      }, alertScopePropSets);
    },
    [alertScopePropSets, alertTypeConfig] as const,
  );
};

/***
 * Converts the given activeTrainGroups to TrainGroups based on
 * changes to 'id', 'activeDateInterval', 'alertScopeProps', and 'activity'
 * activity is included so we catch changes to the TrainGroup.activity.(isActiveColor and isVisible)
 * @param loading
 * @param activeTrainGroups
 */
export const useNotLoadingTrainGroupsForAlert = (
  loading: boolean,
  activeTrainGroups: Perhaps<TrainGroup[]>,
): Perhaps<TrainGroup[]> => {
  const activeTrainGroupToTrainGroup = (activeTrainGroup: TrainGroup): TrainGroup => {
    return pick(
      ['id', 'activeDateInterval', 'alertScopeProps', 'activity'],
      activeTrainGroup,
    ) as TrainGroup;
  };
  // Create a minimized version of activeTrainGroups for Alert so that we only detect changes
  // to activeTrainGroups that are relevant to Alert
  // I can't think of any other way to detected limited changes other than jsonifying
  const hashedActiveTrainGroupForAlerts = useNotLoadingMemo(
    loading,
    (activeTrainGroups) => {
      return JSON.stringify(
        map<TrainGroup, Partial<TrainGroup>>(
          activeTrainGroupToTrainGroup,
          activeTrainGroups,
        ),
      );
    },
    [activeTrainGroups] as const,
  );
  // Only call if something relevant changed
  const trainGroups: Perhaps<TrainGroup[]> = useNotLoadingMemo(
    loading,
    () => {
      return activeTrainGroups;
    },
    [hashedActiveTrainGroupForAlerts] as const,
  );
  return trainGroups;
};
