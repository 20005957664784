import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {Stack} from '@mui/material';
import VisionSideBar from 'pages/vision/visionComponents/VisionSideBar.tsx';
import VisionImagePane from 'pages/vision/visionComponents/VisionImagePane.tsx';
import React, {useContext, useMemo, useState} from 'react';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';
import {undefinedToTrue} from 'utils/functional/functionalUtils.ts';
import {
  omitClassOrType,
  pickClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {AccessStatus} from "../../../utils/userTypes.ts";
import {TwoFactorAuthenticationAccess} from "../../auth/TwoFactorAuthenticationAccess.tsx";
import {UserContext, UserContextType} from "../../auth/UserContext.ts";
import {useToken} from "../../../utils/hooks/useTokenHook.ts";

/**
 * The entry point the VisionApp
 * @param appProps
 * @param organizationProps
 * @param visionProps
 * @constructor
 */
export const VisionAppContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: VisionAppMapDependencyProps) => {
  const {whatIsLoading, visionMetasError} = trainProps.visionProps;
  // Vision components often only need trainProps.visionProps
  const trainPropsOnlyVisionProps = useMemo(() => {
    return pickClassOrType(['visionProps'], trainProps);
  }, [trainProps.visionProps]);


  const {userState} = useContext(UserContext) as UserContextType;
  const [token,] = useToken();

  // If two-factor-authentication is enabled, force the user through the TwoFactorAuthenticationAccess component
  if (process.env.REACT_ENABLE_2FA_AUTH_CHECK === 'true' && userState.status === AccessStatus.Authenticated) {
    const is2FARequired = userState.access.is2FARequired ?? false;
    const is2FAFulfilled = userState.access.is2FAFulfilled ?? false;

    if (
      is2FARequired &&
      !is2FAFulfilled
    ) {
      return <TwoFactorAuthenticationAccess token={token} />;
    }
  }

  return whatIsLoading.loading ? (
    <LoaderWithText
      {...{
        text: visionMetasError ? 'Image service is currently unavailable' : 'loading',
        loadingExplanation: visionMetasError
          ? undefined
          : whatIsLoading.loadingExplanation,
      }}
    />
  ) : (
    <Stack
      key="vision"
      {...{
        direction: 'row',
        sx: {
          minWidth: '100%',
          minHeight: '100%',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <VisionSideBar
        key="visionSideBar"
        {...{
          appProps,
          organizationProps,
          trainProps,
          mapProps,
        }}
      />
      <VisionImagePane
        key="visionImagePane"
        {...{
          appProps,
          organizationProps,
          trainProps: trainPropsOnlyVisionProps,
          mapProps,
        }}
      />
    </Stack>
  );
};
