/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Sorting options for train listing
 */
export enum TrainOrderBy {
    MARK = 'mark',
    _MARK = '-mark',
    NUMBER = 'number',
    _NUMBER = '-number',
    COMPANY = 'company',
    _COMPANY = '-company',
}
