import React from 'react';
import './Home.css';
import {
  AppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  createTheme,
  CSSObject,
  Drawer,
  IconButton,
  Slide,
  Stack,
  styled,
  Theme,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import Map from 'react-map-gl';
import SwitchDetails from '../switch/details/SwitchDetails.tsx';
import HomeTab from '../../components/home_tab/HomeTab.tsx';
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import {TurnoutsSchema} from 'pages/switch/net/repository/repositoryIndex.ts';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import packageJson from '../../../../../package.json'
const MAPBOX_TOKEN =
  'pk.eyJ1IjoiY2VtaXR1c2VyIiwiYSI6ImNsdDczNWlkODBlY3Uya212dGdzeWZ6bzkifQ.5mhzZOJTySDiKcDjMHHd8w';

const mapStyle = 'mapbox://styles/cemituser/cltbg985m00ja01nw7lb3gdo3';

const SwitchAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const theme = createTheme({
    palette: {
      background: {
        paper: '#45535f',
      },
      text: {
        primary: '#ffffff',
        // primary: '#173A5E',
        // secondary: '#46505A',
        secondary: '#ffffff',
      },
      action: {
        // active: '#001E3C',
        active: '#ffda52',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersistentDrawerLeft />
    </ThemeProvider>
  );
};

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(8)} + 1px)`,
  // width: drawerWidth,
  // position: 'fixed',
  backgroundColor: '#3b4651',
  // zIndex: theme.zIndex.drawer + 2,
  border: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  // zIndex: theme.zIndex.drawer - 2,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#3b4651',
  border: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    // width: drawerWidth,
  },
});

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// TODO remove styled as use AppBar's sx prop instead
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// TODO remove styled as use div's sx prop instead
const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-start',

  padding: theme.spacing(3, 1, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// TODO remove styled as use div's sx prop instead
const StyledSettingsIcon = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.6, 0.6),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

// TODO remove styled as use Drawer's sx prop instead
const StyledDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    position: 'relative',
    '& .MuiPaper-root': {
      position: 'absolute',
    },
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    // zIndex: theme.zIndex.drawer + 2,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [seeRecordState, onSeeRecordState] = React.useState(false);
  const [isInWideScreenState, setWideScreenState] = React.useState(false);
  const [turnout, onSetTurnout] = React.useState<TurnoutsSchema | null>(null);

  const toggleOpenAndClose = () => {
    if (isInWideScreenState) setWideScreenState(false);
    if (seeRecordState) {
      onSeeRecordState(!seeRecordState);
      return;
    }

    setOpen(!open);
  };

  const onSeeRecord = (turnout: TurnoutsSchema) => {
    onSeeRecordState(!seeRecordState);
    onSetTurnout(turnout);
  };

  const updateWidth = (state: boolean) => {
    setWideScreenState(state);
    console.log(` Updated ${isInWideScreenState}`);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <StyledDrawer variant="permanent" sx={{}} open={open}>
        <DrawerHeader>
          <IconButton onClick={toggleOpenAndClose}>
            {open ? (
              <ArrowBack fontSize={'large'} />
            ) : (
              <ArrowForward fontSize={'large'} />
            )}
          </IconButton>
        </DrawerHeader>
      </StyledDrawer>

      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            height: '100vh',
            width: isInWideScreenState ? '100vw' : '55vw',
            position: 'relative',
            backgroundColor: '#3A4750',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              height: '90vh',
              width: isInWideScreenState ? '100vw' : '50vw',
              mt: '0vh',
              ml: '0vw',
            }}
          >
            {seeRecordState ? (
              <SwitchDetails turnout={turnout} setWideScreenState={updateWidth} />
            ) : (
              <HomeTab onSeeRecord={onSeeRecord} />
            )}
          </Box>
        </Box>
      </Slide>

      <Box component="main" sx={{position: 'fixed'}}>
        <Box sx={{width: '100vw', height: '100vh'}}>
          <Map
            id="trafficMap"
            initialViewState={{
              latitude: 60.66598559465892,
              longitude: 17.16564107633204,
              zoom: 14,
            }}
            cursor="pointer"
            mapStyle={mapStyle}
            mapboxAccessToken={MAPBOX_TOKEN}
            interactiveLayerIds={['section-layer-section01']}
            // ref={mapRef}
            maxPitch={0}
            minPitch={0}
            touchPitch={false}
          ></Map>
        </Box>
      </Box>

      <Stack direction="row"
             position="fixed" color="primary" style={{top: 'auto', margin: 8, right: 0, bottom: 5}}
             spacing={1} aria-describedby={'version-number'}>
        <Typography variant="body1" style={{
          fontWeight: 'bold',
          color: 'white'
        }}>v{packageJson.versions.switch}</Typography>
      </Stack>
    </Box>
  );
}

export default SwitchAppContainer;
