import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Box, IconButton, Tooltip} from '@mui/material';
import {CrudList} from 'types/crud/crudList';
import {lensPath, over} from 'ramda';
import {
  TrainAppComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppComponentDependencyProps';
import {useTranslation} from 'react-i18next';
import {SyntheticEvent} from 'react';
import {CEMIT_GRAY} from 'theme/cemitColors.ts';

export interface CemitFilterTrainGroupExpanderProps {
  trainGroup: TrainGroup;
  crudTrainGroups: CrudList<TrainGroup>;
  // Is expanding needed
  hasAnythingToShow: boolean;
}

/**
 * Expand or contract a TrainGroup in the left side list
 * @param loading
 * @param appProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupExpander = (
  {
    loading,
    appProps,
    componentProps
  }: TrainAppComponentDependencyProps<CemitFilterTrainGroupExpanderProps>) => {
  const {t} = useTranslation();
  if (loading) {
    return undefined;
  }
  const {trainGroup, crudTrainGroups} = componentProps;
  /**
   * Toggle trainGroup.activity.isListingExpanded between true and false and persist the state using crudTrainGroups
   */
  const handleClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    const updatedTrainGroup: TrainGroup = over<TrainGroup, boolean>(
      lensPath(['activity', 'isListingExpanded']),
      (isListingExpanded: boolean): boolean => {
        return !isListingExpanded;
      },
      trainGroup
    );
    crudTrainGroups.updateOrCreate(updatedTrainGroup);
  };

  const isExpanded = appProps.areAllTrainGroupsExpanded || trainGroup.activity.isListingExpanded;
  return componentProps.hasAnythingToShow && !appProps.areAllTrainGroupsExpanded ? (
    <Tooltip
      {...{
        arrow: true,
        title: t(
          isExpanded
            ? 'collapseAlertSummary'
            : 'expandAlertSummary'
        )
      }}
    >
      <Box>
        <IconButton
          key="cemitFilterTrainGroupExpanderButton"
          {...{
            sx: {
              padding: 0,
              '&:disabled': {
                color: CEMIT_GRAY
              }
            },
            // Disable if the global expander is true
            disabled: appProps.areAllTrainGroupsExpanded,
            color: 'secondary',
            onClick: handleClick
          }}
        >
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
    </Tooltip>
  ) : undefined;
};
export default CemitFilterTrainGroupExpander;
