import {Stack} from '@mui/material';
import {length, map} from 'ramda';
import React from 'react';

import TrainGroupDescriptionCompact from '../../chooserComponents/trainGroupChooserComponents/TrainGroupDescriptionCompact.tsx';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainGroupMenuProps} from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupMenu.tsx';

/**
 * Creates Chips for the Chosen TrainGroups with the ability to remove them from the filter
 * @returns {JSX.Element}
 * @constructor
 */
export const ChosenTrainGroups = (componentProps: TrainGroupMenuProps): JSX.Element => {
  const trainGroupChips = map((trainGroup: TrainGroup) => {
    const serviceLineColor =
      componentProps.serviceLineIdToColor[trainGroup.serviceLine.id];
    return (
      <TrainGroupDescriptionCompact
        key={trainGroup.id}
        {...{
          trainGroup,
          timezoneStr: componentProps.timezoneStr,
          serviceLineColor,
          showColorPicker: componentProps.showColorPicker,
          updateTrainGroupAfterColorChange:
            componentProps.updateTrainGroupAfterColorChange,
        }}
      />
    );
  }, componentProps.selectedTrainGroups);
  return length(trainGroupChips) ? (
    <Stack direction="column" spacing={1}>
      {trainGroupChips}
    </Stack>
  ) : undefined;
};
export default ChosenTrainGroups;
