import React from 'react';
import {Stack, Typography} from '@mui/material';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';

/**
 * A Description of a TrainFormation as a MenuItem
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainFormationDescriptionMenuItem = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<AlertScopeProps>) => {
  return (
    <Stack
      {...{
        minWidth: 0,
        minHeight: 0,
        flex: 1,
        overflow: 'auto',
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Typography>{componentProps.scopedTrainGroup.trainFormation.name}</Typography>
    </Stack>
  );
};
TrainFormationDescriptionMenuItem.displayName = 'TrainFormationDescription';
export default TrainFormationDescriptionMenuItem;
