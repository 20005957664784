import {AppSettings} from 'config/appConfigs/appSettings.ts';
const {appsPath} = AppSettings;

export type ApplicationAccessControl = {
  /** true if the application is available/visible, false otherwise */
  isEnabled: boolean;
  /** The required key to access this application, or undefined if no requirements. Must match tabAccess in JWT token. */
  accessKey?: string;
  /** Controls access to route, must match the regexp */
  routeRegex: RegExp;
  /** The route of the index for this application */
  indexRoute: string;
};

export enum ApplicationType {
  Train = 'train',
  Track = 'track',
  Catenary = 'catenary',
  Depot = 'depot',
  RoutePlanner = 'routePlanner',
  TrackSim = 'tracksim',
  SoftPrio = 'softPrio',
  Switch = 'switch',
  Vision = 'vision',
}

/**
 * All applications which are available in Visualizer.
 * Defines the route regexp, index route and an optional access key.
 * Application visibility/availability is also controlled via the isEnabled flag.
 *
 * See also appConfig.ts for application navigation setup which is by left menu.
 */
export const applicationRegistry: {[key: string]: ApplicationAccessControl} = {
  train: {
    isEnabled: process.env.REACT_FEATURE_TRAIN === 'true',
    accessKey: 'train',
    routeRegex: new RegExp(`/${appsPath}/train(/.*)?$`),
    indexRoute: `/${appsPath}/train/overview`,
  },
  track: {
    isEnabled: process.env.REACT_FEATURE_TRACK === 'true',
    accessKey: 'rails',
    routeRegex: new RegExp(`/${appsPath}/rails(/.*)?$`),
    indexRoute: `/${appsPath}/rails/overview`,
  },
  catenary: {
    isEnabled: process.env.REACT_FEATURE_CATENARY === 'true',
    accessKey: 'catenary',
    routeRegex: new RegExp(`/${appsPath}/catenary(/.*)?$`),
    indexRoute: `/${appsPath}/catenary/overview`,
  },
  depot: {
    isEnabled: process.env.REACT_FEATURE_DEPOTS === 'true',
    accessKey: 'depots',
    routeRegex: new RegExp(`/${appsPath}/depots(/.*)?$`),
    indexRoute: `/${appsPath}/depots/overview`,
  },
  routePlanner: {
    accessKey: 'grps',
    isEnabled: process.env.REACT_FEATURE_ROUTE_PLANNER === 'true',
    routeRegex: new RegExp(`/${appsPath}/grps(/.*)?$`),
    indexRoute: `/${appsPath}/grps/overview`,
  },
  tracksim: {
    isEnabled: process.env.REACT_FEATURE_TRACK_SIM === 'true',
    accessKey: 'tracksim',
    routeRegex: new RegExp(`/${appsPath}/tracksim(/.*)?$`),
    indexRoute: `/${appsPath}/tracksim/overview`,
  },
  softPrio: {
    isEnabled: process.env.REACT_FEATURE_SOFT_PRIO === 'true',
    accessKey: 'softprio',
    routeRegex: new RegExp(`/${appsPath}/softprio(/.*)?$`),
    indexRoute: `/${appsPath}/softprio/overview`,
  },
  switch: {
    isEnabled: process.env.REACT_FEATURE_SWITCH === 'true',
    accessKey: 'switch',
    routeRegex: new RegExp(`/${appsPath}/switch(/.*)?$`),
    indexRoute: `/${appsPath}/switch/overview`,
  },
  sandbox: {
    isEnabled: process.env.REACT_FEATURE_SANDBOX === 'true',
    accessKey: 'sandbox',
    routeRegex: new RegExp(`/${appsPath}/sandbox(/.*)?$`),
    indexRoute: `/${appsPath}/sandbox/overview`,
  },
  vision: {
    isEnabled: process.env.REACT_FEATURE_VISION === 'true',
    accessKey: 'vision_poc',
    routeRegex: new RegExp(`/${appsPath}/vision(/.*)?$`),
    indexRoute: `/${appsPath}/vision/overview`,
  },
  // Profile is always available to authenticated users
  profile: {
    isEnabled: process.env.REACT_FEATURE_USER_PROFILE === 'true',
    routeRegex: new RegExp(`/${appsPath}/profile(/.*)?$`),
    indexRoute: `/${appsPath}/profile`,
  },
};
