import React, {useContext, useMemo, useState} from 'react';

import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {WeekYear} from 'types/datetime/weekYear';
import {fromPairs, isNil, map, reverse} from 'ramda';
import {VisionProps} from 'types/propTypes/trainPropTypes/visionPropTypes/visionProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {VisionMeta, VisionMetaMinimized} from 'types/vision/visionMeta';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from 'async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from 'async/trainAppAsync/trainAppDependencies/trainDependencyUnitConfig.ts';
import {eachWeekOfInterval, getISOWeek, getYear, subYears} from 'date-fns';
import {useNotLoadingQueryVisionNearbyPicturesApi} from 'async/visionAppAsync/visionApiHooks.ts';
import {trackIdToTrackRoute} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {handleAddOrganizationToFilter} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/trainApiOrganizationHooks.ts';
import {OrganizationMinimized} from 'types/organizations/organization.ts';
import {OrganizationSourceKey} from 'apis/apiOrganizationConfigs/organizationManifest.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {weekYearInitFromParams} from 'classes/typeCrud/weekYearCrud.ts';
import {getVisionConfig} from 'pages/vision/visionConfiguration/visionConfig.ts';
import {ForwardBackward} from 'types/navigation/directionEnum..ts';
import {VisionAppContainer} from 'pages/vision/visionComponents/VisionAppContainer.tsx';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {useNotLoadingEffectUpdateAlertLayerForRealtimeTrainMap} from 'async/realtimeTrainAsync/realtimeTrainLayersHooks.ts';
import {useNotLoadingEffectUpdateVisionLayerTrainMap} from 'async/visionAppAsync/visionLayerHooks.ts';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';
import {MapSourceVisual} from 'types/mapbox/mapSourceVisual';

interface VisionUrlParams {
  year: Perhaps<string>;
  week: Perhaps<string>;
  km: Perhaps<string>;
  trackIdentifier: Perhaps<string>;
  // If the user came from the vision icon rather than the track app
  fromIcon: boolean;
}

/**
 * App Container for the Vision App
 * @param loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @constructor
 */
const VisionAppDependency = ({
  loading,
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: VisionAppMapDependencyProps) => {
  // Store the Mapbox source/layers
  const [visionMapSourceVisuals, setVisionMapSourceVisuals] =
    useState<MapSourceVisual[]>(undefined);

  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;
  if (
    userState?.access?.group == 'sysadmin' &&
    organizationProps.userState.sourceKey !== OrganizationSourceKey.sporveien
  ) {
    // Redirect to Sporveien for now so we don't access from other organizations
    handleAddOrganizationToFilter(
      organizationProps,
      clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
        sourceKey: OrganizationSourceKey.sporveien,
      }),
    );
    return undefined;
  }

  const visionConfig = useMemo(() => getVisionConfig(), []);

  const params: VisionUrlParams = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    return ts<VisionUrlParams>(
      fromPairs(
        map(
          (prop) => {
            return [prop, queryParams.has(prop) ? queryParams.get(prop) : undefined];
          },
          ['year', 'week', 'km', 'trackIdentifier', 'fromIcon'],
        ),
      ),
    );
  }, [location.search]);

  const [visionMetas, setVisionMetas] = useState<Perhaps<VisionMeta[]>>(undefined);
  const [visionMetasError, setVisionMetasError] = useState<Perhaps<any>>(undefined);
  const [visionMeta, setVisionMeta] = useState<Perhaps<VisionMeta>>(undefined);

  const [weekYear, setWeekYear] = useState(
    weekYearInitFromParams(params.year, params.week),
  );

  // Get the last year of weeks
  const weekYears: WeekYear[] = useMemo(() => {
    const now = new Date();
    const weeks: Date[] = eachWeekOfInterval(
      {start: subYears(now, 1), end: now},
      {weekStartsOn: 1},
    );
    const weekYears = map((startOfWeek: Date) => {
      return weekYearInitFromParams(getYear(startOfWeek), getISOWeek(startOfWeek));
    }, weeks);
    return reverse(weekYears);
  }, []);

  const [trackDistanceInMeters, setTrackDistanceInMeters] = useState<number>(
    params.km ? parseFloat(params.km * 1000) : 0,
  );
  const [trackSectionId, setTrackSectionId] = useState<string>(
    params.trackIdentifier || 'no_oslo_subway_kolsas_ob',
  );
  const [latestClick, setLatestClick] = useState<Perhaps<ForwardBackward>>(
    ForwardBackward.initial,
  );

  const [prevApiParams, setPrevApiParams] = useState<VisionMetaMinimized>(
    ts<VisionMetaMinimized>({
      km: undefined,
      weekYear: undefined,
      trackSectionId: undefined,
    }),
  );

  // Load the VisionMetas
  const localPropsLoading = isNil(trackDistanceInMeters) || isNil(weekYear);
  useNotLoadingQueryVisionNearbyPicturesApi(
    localPropsLoading || visionMetasError,
    organizationProps,
    weekYear,
    trackDistanceInMeters,
    trackSectionId,
    prevApiParams,
    setPrevApiParams,
    setVisionMetas,
    setVisionMeta,
    visionMetasError,
    setVisionMetasError,
    visionConfig.meterDelta,
    // TODO we currently set the trackDistanceInMeters to the single returned result, since we have few images
    // and query for a large  visionConfig.meterDelta in order to always find something
    latestClick,
    setLatestClick,
    setTrackDistanceInMeters,
  );

  const whatIsLoading = useWhatIsLoading(
    localPropsLoading,
    isLoadingStringOfDependencyUnit(VisionAppDependency.name),
    VisionAppDependency.name,
    {
      km: trackDistanceInMeters,
      weekYear,
      weekYears,
      visionMetas,
    },
    [trackDistanceInMeters, weekYear, weekYears, visionMeta, visionMetas],
    appProps.setWhatDependenciesAreLoading,
  );

  const visionProps: VisionProps = useMemo(() => {
    return clsOrType<VisionProps>(CemitTypename.visionProps, {
      whatIsLoading,
      visionConfig,
      visionMeta,
      setVisionMeta,
      visionMetas,
      weekYear,
      setWeekYear,
      weekYears,
      trackDistanceInMeters,
      setTrackDistanceInMeters,
      trackRoute: trackIdToTrackRoute(trackSectionId),
      fromIcon: params.fromIcon,
      latestClick,
      setLatestClick,
      visionMapSourceVisuals,
      setVisionMapSourceVisuals,
    });
  }, [
    visionMeta,
    visionMetas,
    weekYear,
    trackDistanceInMeters,
    trackSectionId,
    trackDistanceInMeters,
    visionMapSourceVisuals,
    latestClick,
  ]);
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'visionProps',
    visionProps,
    undefined,
  );
  // Make sure the RailwayLines load first so the map zooming works
  useNotLoadingEffectUpdateVisionLayerTrainMap(
    mapProps.loading || mapProps.mapLayerProps.trainMapLayerProps.loading,
    {
      appProps,
      organizationProps,
      trainProps: trainPropsMerged,
      mapProps,
    },
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading.loadingExplanation,
    trainPropsMerged,
    'VisionDependency',
  );

  return (
    <VisionAppContainer
      {...{appProps, organizationProps, trainProps: trainPropsMerged, mapProps}}
    />
  );
};

export default VisionAppDependency;
