import {compact} from '@rescapes/ramda';
import {all, always, any, chain, ifElse, join, length, map} from 'ramda';
import {extremes, toBoolean} from 'utils/functional/functionalUtils.ts';
import {areIntervalsOverlapping, format} from 'date-fns';
import {TFunction} from 'i18next';
import {TrainFormation} from 'types/trains/trainFormation';
import {OrderedVehicle} from 'types/trains/orderedVehicle';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {dateIntervalPairToObj} from 'utils/datetime/dateUtils.ts';
import {Vehicle} from 'types/trains/vehicle';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import {trainDataFriendlyDatetimeFormatString} from 'utils/datetime/timeUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Returns a unique label for the TrainRun
 * @returns {*}
 * @param t
 * @param trainGroup
 */
export const trainGroupUniqueLabel = (t: TFunction, trainGroup: TrainGroup) => {
  return join(
    '\n',
    compact([
      `${t('trainFormation')}: ${trainGroup.trainFormation.name}`,
      trainGroup.trainRouteOrGroup
        ? `${t('departure')}: ${
            trainGroup.singleTrainRun.departureDatetime
              ? format(
                  trainGroup.singleTrainRun.departureDatetime,
                  trainDataFriendlyDatetimeFormatString,
                )
              : 'N/A'
          }`
        : undefined,
    ]),
  );
};
/**
 * Returns the locomotive names or id of the TrainFormation (trainset)
 * @param trainFormation
 */
export const namesOfTrainFormationExtremeVehicles = (
  trainFormation: TrainFormation,
): string[] => {
  return map(
    (orderedVehicle: OrderedVehicle) => {
      const vehicle: Vehicle = orderedVehicle.vehicle;
      return vehicle.name || 'Unnamed vehicle';
    },
    extremes(trainFormation.orderedVehicles || []),
  );
};

/**
 * Returns true if any formation.orderedVehicles[*].vehicle has vehicleCollectionDevices
 * @param trainFormation
 * @returns {*}
 */
export const trainFormationHasCollectionDevices = (trainFormation: TrainFormation) => {
  return (
    length(trainFormation.orderedVehicles || []) > 1 &&
    any<OrderedVehicle>(
      (orderedVehicle: OrderedVehicle): boolean => {
        return Boolean(length(orderedVehicle.vehicle.vehicleCollectionDevices));
      },
      // TODO we currently expected collectionDevices at the extreme ends of trainsets
      extremes(trainFormation.orderedVehicles),
    )
  );
};

export const extractFormation = (
  trainGroupFormation: TrainGroupOnlyTrainFormation,
): Perhaps<TrainFormation> => {
  return trainGroupFormation.trainFormation;
};

/**
 * Returns the CollectionDevices of the given TrainFormation
 * @param trainFormation
 * @param dateInterval Optional start and end datetime. If specified, they must overlap with
 * a  vehicleCollectionDevice.serviceDatetimeRange to avoid an undefined result for that vehicleCollectionDevice
 * @returns {[Object]} Objects keyed by collectionDevice and vehicle
 */
export const collectionDevicesOfTrainFormation = (
  trainFormation: TrainFormation,
  dateInterval: Perhaps<DateInterval> = undefined,
): TrainFormationCollectionDevice[] => {
  const collectionDevices: TrainFormationCollectionDevice[] = chain(
    (orderedVehicle: OrderedVehicle) => {
      const trainFormationCollectionDevices = map(
        (vehicleCollectionDevice: VehicleCollectionDevice) => {
          const serviceDateTimeRange = vehicleCollectionDevice.serviceDatetimeRange;

          // If serviceDateTimeRange and dateTimeRange are defined, make sure they overlap
          const overlappingDateInterval = ifElse(
            (ranges: Perhaps<[[Date, Date], [Date, Date]]>): boolean => {
              return all(toBoolean, ranges);
            },
            (ranges: [[Date, Date], [Date, Date]]): boolean => {
              return areIntervalsOverlapping(
                ...(map<[Date, Date], DateInterval>((range: [Date, Date]) => {
                  return dateIntervalPairToObj({rangePair: range});
                }, ranges) as [DateInterval, DateInterval]),
              );
            },
            always(true),
          )([
            serviceDateTimeRange,
            dateInterval ? [dateInterval.start, dateInterval.end] : undefined,
          ]);

          const trainFormationCollectionDevice: Perhaps<TrainFormationCollectionDevice> =
            overlappingDateInterval
              ? clsOrType<TrainFormationCollectionDevice>(
                  CemitTypename.trainFormationCollectionDevice,
                  {
                    trainFormation,
                    vehicleCollectionDevice,
                    vehicle: orderedVehicle.vehicle,

                    collectionDevice: vehicleCollectionDevice.collectionDevice,
                  },
                )
              : undefined;
          return trainFormationCollectionDevice;
        },
        orderedVehicle?.vehicle?.vehicleCollectionDevices || [],
      );
      return trainFormationCollectionDevices || [];
    },
    trainFormation.orderedVehicles!,
  );
  return collectionDevices || [];
};

