import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {Stack, Typography} from '@mui/material';

import {length} from 'ramda';

/**
 * A Header for the TrainGroup Overview table
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @constructor
 */
const TrainGroupOverviewHeader = (
  {
    appProps,
    trainProps
  }: TrainAppMapDependencyProps
) => {
  const trainGroupCount: number = trainProps.trainGroupOnlyTrainFormationProps.loading ?
    undefined :
    length(trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations.list);

  return <Typography {...{
    variant: 'h4', sx: {textAlign: 'center', alignSelf: 'center'}, color: 'secondary'
  }}>
    {`${appProps.t('trainOverview')} ${trainGroupCount ? `(${trainGroupCount} ${appProps.t('trainGroups')})` : ''}`}
  </Typography>;
};
export default TrainGroupOverviewHeader;
