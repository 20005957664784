/**
 * Describes the AlertOverview DateIntervals back in days from the present
 */
export enum AlertOverviewByTimePeriod {
  // The interval of the current day
  Days1 = 'Days1',
  // The interval of the last 30 days
  Days30 = 'Days30',
  Days90 =  'Days90',
  Days180 = 'Days180',

  // The interval of the previous day
  Days2To1 = 'Days2To1',
  // The interval of the penultimate 30 days
  Days60To30 = 'Days60To30',
  // The interval of the penultimate 90 days
  Days180To90 = 'Days180To90',
}
