/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SensorMeasurementTypeIdEnum {
    IMU = 'imu',
    GNSS = 'gnss',
    ELECTRIC = 'electric',
    CELLULAR = 'cellular',
    TEMPERATURE = 'temperature',
    TRAIN_WHEEL = 'train_wheel',
    TRAVEL = 'travel',
    TRACK_VISION = 'track_vision',
    POINT_MACHINE = 'point_machine',
    SYSTEM = 'system',
    CAMERA = 'camera',
}
