import {CemitTypename} from '../../types/cemitTypename.ts';
import {any, is} from 'ramda';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Create a DateInterval, converting if either start or end are numbers
 * @param start
 * @param end
 */
export const createDateInterval = <T extends DateInterval>(
  start: Date,
  end: Date,
): T => {
  if (any(is(Number), [start, end])) {
    //console.warn(`start and/or end are timestamps, not dates. start: ${start}, end: ${end}`)
    return clsOrType<T>(CemitTypename.dateInterval, {
      start: new Date(start),
      end: new Date(end),
    });
  }

  return clsOrType<T>(CemitTypename.dateInterval, {
    start,
    end,
  });
};
