import { TramModelInformation } from "../types";

export const tramData: TramModelInformation[] = [
    { model: 'M29', tramRangeRegex: /^8\d{2}$/, current: '600 A' }, // 8xx range
    { model: 'M31', tramRangeRegex: /^3([0-5]\d|[0-2]\d{2})$/, current: '1200 A', comment: '' }, // 300-380 range, excluding 306 and 353
    { model: 'M32', tramRangeRegex: /^4([0-6]\d)$/, current: '1800 A', comment: '' }, // 401-465 range, excluding 436, 418, and 429
    { model: 'M33A', tramRangeRegex: /^49[0-9]$/, current: '1500 A' }, // 490-499 range
    { model: 'M33B', tramRangeRegex: /^5([0-2]\d)$/, current: '1500 A' }, // 501-530 range
    { model: 'M34', tramRangeRegex: /^6([0-5]\d)$/, current: '1500 A', comment: '' }, // 601-660 range
  ];
  
  // Function to find tram information based on tram number
  export const findTramInfoByNumber = (tramNumber: number): TramModelInformation | null => {
    return tramData.find(tram => tram.tramRangeRegex.test(tramNumber.toString())) || null;
  };